import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAnLOB, createAnLob, resetLobForm } from '../../../redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

export class LineOfBusinessForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingAnLob: true
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    const {
      lobId,
      fetchAnLOB,
      resetLobForm
    } = this.props;
    if (lobId) {
      fetchAnLOB(lobId)
    } else {
      resetLobForm()
      this.setState({
        loadingAnLob: false
      })
    }
    this.setState({ isPaneOpen: true })
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    const { identifier, enabled, name, loadingAnLob } = nextProps

    this.setState({ identifier, enabled, name, loadingAnLob })

    if (nextProps.statusCode === "SUCCESS") {
      this.props.closeEditLobPane(null, true)
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const { identifier, enabled, name, } = this.state
    let params = { identifier, enabled, name };
    this.props.createAnLob(params)
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    const {
      enabled, isPaneOpen, loadingAnLob, identifier, name
    } = this.state;
    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Line of Business Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closeEditLobPane()
          }}>
          { loadingAnLob ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="name" id="name" placeholder="Name" onChange={(e) => this.handleChange('name', e.target.value)} value={name}/>
                    <Label for="name">Name</Label>
                  </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleChange('identifier', e.target.value)} value={identifier}/>
                    <Label for="identifier">Identifier</Label>
                  </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="enabled" id="enabled" checked={enabled} onChange={(e) => this.handleChange('enabled', !enabled)} />{' '}
                <Label check className="text-center"> Enabled </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({LineOfBusinessData, FlashMessageData}) => {
  return {
    loadingAnLob: LineOfBusinessData.loadingAnLob,
    identifier: LineOfBusinessData.identifier,
    name: LineOfBusinessData.name,
    enabled: LineOfBusinessData.enabled,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchAnLOB, createAnLob, resetLobForm
})(LineOfBusinessForm);
