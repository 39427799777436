import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchAllPPVPackages, grandFatherToggle} from '../../../../scripts/redux/actions';
import DisplayCard from '../DisplayCard/DisplayCard.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import PPVForm from './PPVForm'
class PayPerView extends Component {
  constructor (props) {
    super(props);
    this.state = {
      payPerViewPackages: [],
      displayingPayPerViewPackages: [],
      showPPVPackageForm: false,
      openedPayPerViewPackages: null,
      loading: true,
      footerOpts: {0: false, 1: true, 2: true},
      showNotification: false,
      statusCode: '',
      statusMessage: '',
      displayCardClass: 'col-md-3',
    };
    this.togglePPVForm = this.togglePPVForm.bind(this)
    this.grandFatherToggle = this.grandFatherToggle.bind(this)
  }

  setDisplayCardClass() {
    let displayCardClass = (window.innerWidth > 1440) ? 'col-lg-2' : 'col-md-3'
    this.setState({displayCardClass})
  }

  componentWillMount() {
    this.setDisplayCardClass();
    this.props.fetchAllPPVPackages()
  }

  componentWillReceiveProps(nextProps) {
    const {payPerViewPackages, displayingPayPerViewPackages, loading} = nextProps;
    this.setState({payPerViewPackages, displayingPayPerViewPackages, loading})
  }

  togglePPVForm(id=null, getPPV=false) {
    if (id) {
      this.setState({ showPPVPackageForm: true, openedPPVPackage: id });
    } else {
      this.setState({ showPPVPackageForm: false})
    }
    if (getPPV) {
      this.props.fetchAllPPVPackages()
    }
  }

  grandFatherToggle(value) {
    let params = {
      packageId: value.id,
      enabled: !value.enabled ? true : false,
    }
    this.props.grandFatherToggle(params, 'pacakage', {packageType: 'ppv'})
  }

  search(val) {
    const {payPerViewPackages} = this.state;
    let displayingPayPerViewPackages = payPerViewPackages.filter(c => (c.identifier.toLowerCase().search(val.toLowerCase()) !== -1) || (c.name.toLowerCase().search(val.toLowerCase()) !== -1));
    this.setState({displayingPayPerViewPackages})
  }

  render() {
    const {
      loading, footerOpts, openedPPVPackage, displayingPayPerViewPackages,
      showPPVPackageForm, displayCardClass,
    } = this.state;
    return (
      <div className='ppv'>
        <FlashMessage />
        <div style={{display:'flex',padding:'20px 0px 20px 40px',width:'100%',background:'white',flexDirection:'row',position: 'fixed','zIndex':'99'}}>
          <div className="search-section" style={{width: 'calc(100% * (1/3) - 10px - 1px)',marginRight:'40px'}}>
            <div className='aaa'>
              <input onKeyUp={(e)=>this.search(e.target.value)}
                     type="search" placeholder="What are you looking for?" />
            </div>
          </div>
        </div>
        <div style={{'padding':'90px 50px 50px 50px'}}>
          { loading && <Loading /> }
          {(!loading && displayingPayPerViewPackages.length === 0) && <NoData /> }
          <div className='row'>
            {
              displayingPayPerViewPackages.map((value, index) => (
                <div className={displayCardClass} key={index} style={{padding: '0px 5px 10px'}}>
                  <DisplayCard value={value}
                               toggleEditForm={this.togglePPVForm}
                               footerOpts={footerOpts}
                               grandFatherToggle={this.grandFatherToggle}
                               />
                </div>
              ))
            }
          </div>
            
              <PPVForm PPVId={openedPPVPackage} 
                        showPPVPackageForm={showPPVPackageForm}
                        closeEditPPVPane={this.togglePPVForm}
                        showNotificationPanel={this.showNotificationPanel}/>
          
        </div>

      </div>
    )
  }
}

const mapStateToProps = ({payPerViewData}) => {
  return {
    payPerViewPackages: payPerViewData.ppvPackages,
    displayingPayPerViewPackages: payPerViewData.displayingPPVPackages,
    loading: payPerViewData.loading,
  };
};

export {PayPerView};

export default connect(mapStateToProps, {
  fetchAllPPVPackages,
  grandFatherToggle,
})(PayPerView);
