import React from "react";
import {connect} from 'react-redux';
import {fetchAllDmaCodesZipcodes} from '../../../../scripts/redux/actions';
import DmaCodesZipcodeForm  from '../DmaCodesZipcodeForm/DmaCodesZipcodeForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loading from '../../Common/Loading/Loading.js';
import './DmaCodesZipcode.css';


class DmaCodesZipcode extends React.Component {
  constructor() {
    super();
    this.state = {
      localLoader: false,
      dmaCodesZipcodes: [],
			displayingDmaCodesZipcodes: [],
      showDmaCodesZipcodeForm: false,
      zipCodeVal: '',
      dmaCodeVal: '',
      otaQual: 'indoor',
      ontechQual: false,
      locastQual: false,
      id: '',
      filtered: [],
      loading: true,
      newDmaCodesZipcode: false,
    };
    this.renderEditable = this.renderEditable.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
    this.closeDmaCodesZipcodeForm = this.closeDmaCodesZipcodeForm.bind(this)
  }

  componentWillMount() {
    this.props.fetchAllDmaCodesZipcodes()
	}

  componentWillReceiveProps(nextProps) {
    const {dmaCodesZipcodes, displayingDmaCodesZipcodes, loading} = nextProps
    this.setState({dmaCodesZipcodes, displayingDmaCodesZipcodes, loading, localLoader: false})
  }

  refresh(){
    this.setState({localLoader: true});
    this.props.fetchAllDmaCodesZipcodes().then(res =>  this.setState({localLoader: false}))
  }

  renderEditable(cellInfo) {
    return (
      <div className="dmaCodesZipcode-input text-left">
        {this.state.dmaCodesZipcodes[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  updateState(cellInfo, value) {
    const {displayingDmaCodesZipcodes} = this.state;
    displayingDmaCodesZipcodes[cellInfo.index][cellInfo.column.id] = value;
    displayingDmaCodesZipcodes[cellInfo.index]['edited'] = true
    this.setState({ displayingDmaCodesZipcodes });
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => {this.setState({showNotification: false})}, 3000);
  }

  updateDmaCodesZipcode(params) {
    let { zipcode, dmaCode, otaQual, ontechQual, locastQual, id } = params
    this.setState({ showDmaCodesZipcodeForm: true, zipcode, dmaCode, otaQual, ontechQual, locastQual, id })
  }

  closeDmaCodesZipcodeForm(localLoader=false) {
    if (localLoader) {
      this.setState({localLoader: true, filtered: [], showDmaCodesZipcodeForm: false, zipcode: {}, dmaCode: {}, otaQual: '', ontechQual: '', locastQual: '', id: '', newDmaCodesZipcode: false})
    } else {
      this.setState({filtered: [], showDmaCodesZipcodeForm: false, zipcode: {}, dmaCode: {}, otaQual: '', ontechQual: '', locastQual: '', id: '', newDmaCodesZipcode: false})
    }
  }

  renderButton(cell, row) {
    return (
      <div className="text-center">
        <i className="fa fa-pencil edit-icon" onClick={(e) => {this.updateDmaCodesZipcode(row)}}></i>
      </div>
    )
  }

  render() {
    const {
      zipcode, dmaCode, loading, displayingDmaCodesZipcodes, showDmaCodesZipcodeForm, dmaCodeVal, zipCodeVal, otaQual, ontechQual, locastQual, id, localLoader
    } = this.state;

    const columns = [
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'left' };
        },
        text: "DMA Code",
        dataField: 'dmaCodeVal',
        sort: true,
        filter: textFilter({ placeholder: ' ' })
      },
      {
        headerStyle: () => {
          return { width: '3vw', textAlign: 'left' };
        },
        text: "Zipcode",
        dataField: 'zipCodeVal',
        sort: true,
        filter: textFilter({ placeholder: ' ' })
      },
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'left' };
        },
        text: "OTA Qual",
        dataField: "otaQual",
        sort: true
      },
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'left' };
        },
        text: "On-Tech Qual",
        dataField: "ontechQual",
        sort: true
      },
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'left' };
        },
        text: "Locast Qual",
        dataField: "locastQual",
        sort: true
      },
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'center' };
        },
        text: "Edit",
        dataField: " ",
        formatter: this.renderButton
      }
    ];

    return (
      <div >
        <FlashMessage />
        <div className='dmaCodesZipcode-header'>
          {!loading
          ?<div className='new-dmaCodesZipcode-div'>
            <button className="btn btn-primary new-dmaCodesZipcode-btn"
                    onClick={()=> this.setState({
                      showDmaCodesZipcodeForm: true,
                      newDmaCodesZipcode: true
                    })}>
            <i className="fa fa-plus"></i> DMA Code-Zipcode
            </button>
            <div className="text-center refresh" onClick={()=> this.refresh()}>
              <i className="fa fa-refresh black"></i>
            </div>
          </div>
          : ''}
        </div>
        <div className='dmaCodesZipcode-content'>
          {loading
          ?<div><Loading /> <h3 className="text-center">Please wait, dma codes zipcodes are loading...</h3></div>
          :<div>
            <BootstrapTable
            class="my-table"
            keyField='id'
            data={ displayingDmaCodesZipcodes }
            columns={ columns }
            placeHolder={ false }
            filter={ filterFactory() }
            pagination={ paginationFactory(
              {
                sizePerPageList: ([15, 20, 25, 50, 100]),
                style: {
                  fontSize: '50px'
                }
              }
            )}
            />
            {localLoader ? <div className="localLoading">Loading&#8230;</div> : '' }
            <br />
            { showDmaCodesZipcodeForm ? <DmaCodesZipcodeForm id={id} zipcodeId={zipcode ? zipcode.id : null} dmaCodeId={dmaCode ? dmaCode.id : null} otaQual={otaQual} ontechQual={ontechQual} locastQual={locastQual} newDmaCodesZipcode={this.state.newDmaCodesZipcode} closeDmaCodesZipcodeFormPane={this.closeDmaCodesZipcodeForm} showNotificationPanel={this.showNotificationPanel}/> : null }
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({dmaCodesZipcodesData}) => {
  return {
    dmaCodesZipcodes: dmaCodesZipcodesData.dmaCodesZipcodes,
    displayingDmaCodesZipcodes: dmaCodesZipcodesData.displayingDmaCodesZipcodes,
    loading: dmaCodesZipcodesData.loading,
  }
}
export default connect(mapStateToProps, {
  fetchAllDmaCodesZipcodes,
})(DmaCodesZipcode);
