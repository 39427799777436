import {
  FETCH_OFFERS_COMPLETE,
  FETCHING_OFFERS,
  OFFER_POST_STATUS,
} from '../ActionType';

const INITIAL_STATE = {
  offers: [],
  fetchingOffers: false,
  offerPostStatus: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_OFFERS:
      return {
        ...state,
        fetchingOffers: action.payload
      }
    case FETCH_OFFERS_COMPLETE:
      let {offers} = action.payload
      return {
        ...state,
        offers,
        fetchingOffers: false
      };
    case OFFER_POST_STATUS:
      return {
        ...state,
        offerPostStatus: action.payload,
      }
    default:
    return state;
  }
}