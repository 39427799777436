import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createAClone } from '../../../../scripts/redux/actions'
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label } from 'reactstrap';

export class CloneForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      identifier: '',
      guid: '',
      sku: ''
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.statusCode === "Success") {
      this.props.closeClonePane(null, true)
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    let { parentIdentifier, type } = this.props;
    let typeId=`${type}Id`
    let { identifier, guid, sku } = this.state;
    let params = {
                    identifier,
                    [typeId]: parentIdentifier
                  }
    if (type === 'package') {
      params['guid'] = guid
      params['sku'] = sku
    }
    this.props.createAClone(params, type)
    this.props.closeClonePane(null, true);
  }

  packageFields(guid, sku) {
    if (this.props.type === 'package') {
      return (
        <div>
          <FormGroup className="col-sm-12">
            <div className="field">
              <input type="text" name="guid" id="guid" placeholder="GUID" onChange={(e) => this.handleChange('guid', e.target.value)} value={guid}/>
              <Label for="guid">GUID</Label>
            </div>
          </FormGroup>
          <FormGroup className="col-sm-12">
            <div className="field">
              <input type="text" name="sku" id="sku" placeholder="SKU" onChange={(e) => this.handleChange('sku', e.target.value)} value={sku}/>
              <Label for="sku">SKU</Label>
            </div>
          </FormGroup>
        </div>
      )
    }
  }

  render() {
    let { identifier, isPaneOpen, guid, sku } = this.state;
    let { type } = this.props;
    let title = `Clone a ${type}`

    return (
      <div>
        <div ref={ref => this.el = ref} className='clone-form'>
          <SlidingPane
            className='slinding-pane'
            overlayClassName='some-custom-overlay-class'
            isOpen={isPaneOpen}
            title={title}
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              this.setState({ isPaneOpen: false });
              this.props.closeClonePane()
            }}>
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleChange('identifier', e.target.value)} value={identifier}/>
                  <Label for="identifier">Identifier</Label>
                </div>
              </FormGroup>
              {this.packageFields(guid, sku)}
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
            <br />
          </SlidingPane>
        </div>
      </div>
    )
  }

}

const mapStateToProps = ({FlashMessageData}) => {
  return {
    statusCode: FlashMessageData.statusCode,
  };
}
export default connect(mapStateToProps, {
  createAClone,
})(CloneForm);
