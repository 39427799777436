import React, { Component } from 'react';
import Cookie from 'js-cookie';
import { connect } from 'react-redux';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import { FormGroup, Label, Input } from 'reactstrap';
import { fetchContentTagValidationDropDown  } from '../../../../scripts/redux/actions'
import { Accordion } from "semantic-ui-react";
import Loading from '../../Common/Loading/Loading.js';
import ReactJson from 'react-json-view'
import './ContentTagValidation.css';

class ContentTagValidation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingAnalysis: false,
      loadingMoreInfo: false,
      reqContextPlanIdentifier: '',
      reqContextLobIdentifier: '',
      reqContextPartnerPlatform: '',
      requestContextList: [],
      collapseBanner: '',
      showInfo: false,
      localLoader: false,
      showReset: false
    }

    this.createRequestContext = this.createRequestContext.bind(this);
    this.fetchMoreInfo = this.fetchMoreInfo.bind(this);
    this.backToContentTag = this.backToContentTag.bind(this);
    this.closeMoreInfo = this.closeMoreInfo.bind(this);
    this.refreshAnalysisResult = this.refreshAnalysisResult.bind(this);
    this.resetTheValidationPage = this.resetTheValidationPage.bind(this);
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  componentWillMount() {
    this.props.fetchContentTagValidationDropDown();
  }

  componentWillReceiveProps(nextProps) {
    const { planDropDown, lobDropDown, partnerPlatformDropDown } = nextProps;
    this.setState({ planDropDown, lobDropDown, partnerPlatformDropDown });
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.label}>{opt.label}</option>
      );
    });
  }

  closeMoreInfo() {
    this.setState({ showInfo: !this.state.showInfo, info: {} });
  }

  createRequestContext() {
    let { reqContextPlanIdentifier, reqContextLobIdentifier, reqContextPartnerPlatform } = this.state;
    if (!this.state.requestContextList.includes(`${reqContextPlanIdentifier}*_*${reqContextLobIdentifier}*_*${reqContextPartnerPlatform}`)) {
      if (this.state.requestContextList.length > 0) {
        let requestContextListCopy = [ ...this.state.requestContextList ];
        requestContextListCopy.push(`${reqContextPlanIdentifier}*_*${reqContextLobIdentifier}*_*${reqContextPartnerPlatform}`);
        this.setState({ requestContextList: requestContextListCopy, reqContextLobIdentifier: '' });
      } else if (this.state.requestContextList.length === 0) {
        this.setState({ requestContextList: [`${reqContextPlanIdentifier}*_*${reqContextLobIdentifier}*_*${reqContextPartnerPlatform}`], reqContextLobIdentifier: '' });
      }
    }
  }

  refreshAnalysisResult() {
    this.setState({ loadingAnalysis: true });
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag-validation`;
    fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify({ requestType: "refresh" }),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { result } = json.data;

      const AccordionContent = (content) => (
        <div className="indent">
          <a style={{ cursor: 'pointer', marginBottom: '50px' }} onClick={() => this.fetchMoreInfo(content)}><u>View Analysis Raw Data</u></a>
          <ReactJson src={content.jsonView} enableClipboard={false} displayDataTypes={false} indentWidth={2} displayObjectSize={false} />
        </div>
      )
  
      const AccordionPanels = [];
      let allResultHasSummary = [];
      
      if (!this.isEmpty(result)) {
        for (const echResult of result) {
          let resultObj = {};
          resultObj.title = `Plan: ${echResult.context.split('_')[0]}, Lob: ${echResult.context.split('_')[1]}, Partner: ${echResult.context.split('_')[2]}, Platform: ${echResult.context.split('_')[3]}`;
          let SubAccordionPanels = [];

          if (echResult.summaryData) {
            allResultHasSummary.push(true);
            for (const echCaseCondition of echResult.summaryData) {
              let echCaseConditionObj = {};
              echCaseConditionObj.title = `${echCaseCondition.caseName}: ${echCaseCondition.counts}`;
              if (!this.isEmpty(echCaseCondition.guids)) {
                let guidsAccordions = [];
                const SubAccordion1Content = (
                  <div className="indent">
                    <Accordion.Accordion
                      style={{marginLeft: "20px"}}
                      className="no-padding"
                      panels={guidsAccordions}
                    />
                  </div>
                )
                for (const echGuid of echCaseCondition.guids) {
                  let echGuidObj = {};
                  echGuidObj.title = `${Object.values(echGuid)[0].identifier} (${Object.values(echGuid)[0].packageType})`;
                  echGuidObj.content = { content: AccordionContent({ jsonView: Object.values(echGuid)[0], reqContentFileName: `${echResult.context.split('_')[0]}_${echResult.context.split('_')[1]}_${echResult.context.split('_')[2]}_${echResult.context.split('_')[3]}_analysisRawOutput.json` }) };
                  echGuidObj.key = `${Object.keys(echGuid)[0]}` + `${Math.floor((Math.random() * 100000) + 1)}`;
                  guidsAccordions.push(echGuidObj);
                }
                echCaseConditionObj.content = { content: SubAccordion1Content };
              }
              echCaseConditionObj.key = `${echCaseCondition.caseName}` + `${Math.floor((Math.random() * 100000) + 1)}`;
              SubAccordionPanels.push(echCaseConditionObj);
            }
          } else {
            allResultHasSummary.push(false);
            let echCaseConditionObj = {};
            echCaseConditionObj.title = `${echResult.status}`;
            echCaseConditionObj.key = `${echResult.context}` + `${Math.floor((Math.random() * 100000) + 1)}`;
            SubAccordionPanels.push(echCaseConditionObj);
          }
          
    
          const SubAccordions = (
            <div className="indent">
              <Accordion.Accordion
                className="no-padding"
                panels={SubAccordionPanels}
              />
            </div>
          )
          resultObj.content = {
            content: SubAccordions,
            key: 'sub-accordions'
          }
          AccordionPanels.push(resultObj);
        }
      }
      if (result.length === allResultHasSummary.length && !allResultHasSummary.includes(false)) {
        this.setState({ AccordionPanels, loadingAnalysis: false, showReset: true });
      } else {
        this.setState({ AccordionPanels, loadingAnalysis: false });
      }
    })
    .catch(error => {
      console.log('refreshAnalysis Error - ', error)
    });
  }

  submitRequest() {
    let { requestContextList } = this.state;
    let contextRequests = [];
    for (const echReqContext of requestContextList) {
      let paramObj = {
        planIdentifier: `${echReqContext.split('*_*')[0]}`,
        lobIdentifier: `${echReqContext.split('*_*')[1]}`,
        partnerName: `${echReqContext.split('*_*')[2].split('/')[0]}`,
        partnerPlatform: `${echReqContext.split('*_*')[2].split('/')[1]}`
      }
      contextRequests.push(paramObj);
    }
    let params = {
      requestType: "new",
      contextRequests
    }
    this.setState({ loadingAnalysis: true });
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag-validation`;
    fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { result } = json.data;

      const AccordionContent = (content) => (
        <div className="indent">
          <a style={{ cursor: 'pointer', marginBottom: '50px' }} onClick={() => this.fetchMoreInfo(content)}><u>View Analysis Raw Data</u></a>
          <ReactJson src={content.jsonView} enableClipboard={false} displayDataTypes={false} indentWidth={2} displayObjectSize={false} />
        </div>
      )
  
      const AccordionPanels = [];
      let allResultHasSummary = [];
      
      if (!this.isEmpty(result)) {
        for (const echResult of result) {
          let resultObj = {};
          resultObj.title = `Plan: ${echResult.context.split('_')[0]}, Lob: ${echResult.context.split('_')[1]}, Partner: ${echResult.context.split('_')[2]}, Platform: ${echResult.context.split('_')[3]}`;
          let SubAccordionPanels = [];

          if (echResult.summaryData) {
            allResultHasSummary.push(true);
            for (const echCaseCondition of echResult.summaryData) {
              let echCaseConditionObj = {};
              echCaseConditionObj.title = `${echCaseCondition.caseName}: ${echCaseCondition.counts}`;
              if (!this.isEmpty(echCaseCondition.guids)) {
                let guidsAccordions = [];
                const SubAccordion1Content = (
                  <div className="indent">
                    <Accordion.Accordion
                      style={{marginLeft: "20px"}}
                      className="no-padding"
                      panels={guidsAccordions}
                    />
                  </div>
                )
                for (const echGuid of echCaseCondition.guids) {
                  let echGuidObj = {};
                  echGuidObj.title = `${Object.values(echGuid)[0].identifier} (${Object.values(echGuid)[0].packageType})`;
                  echGuidObj.content = { content: AccordionContent({ jsonView: Object.values(echGuid)[0], reqContentFileName: `${echResult.context.split('_')[0]}_${echResult.context.split('_')[1]}_${echResult.context.split('_')[2]}_${echResult.context.split('_')[3]}_analysisRawOutput.json` }) };
                  echGuidObj.key = `${Object.keys(echGuid)[0]}` + `${Math.floor((Math.random() * 100000) + 1)}`;
                  guidsAccordions.push(echGuidObj);
                }
                echCaseConditionObj.content = { content: SubAccordion1Content };
              }
              echCaseConditionObj.key = `${echCaseCondition.caseName}` + `${Math.floor((Math.random() * 100000) + 1)}`;
              SubAccordionPanels.push(echCaseConditionObj);
            }
          } else {
            allResultHasSummary.push(false);
            let echCaseConditionObj = {};
            echCaseConditionObj.title = `${echResult.status}`;
            echCaseConditionObj.key = `${echResult.context}` + `${Math.floor((Math.random() * 100000) + 1)}`;
            SubAccordionPanels.push(echCaseConditionObj);
          }
          
    
          const SubAccordions = (
            <div className="indent">
              <Accordion.Accordion
                className="no-padding"
                panels={SubAccordionPanels}
              />
            </div>
          )
          resultObj.content = {
            content: SubAccordions,
            key: 'sub-accordions'
          }
          AccordionPanels.push(resultObj);
        }
      }
      if (result.length === allResultHasSummary.length && !allResultHasSummary.includes(false)) {
        this.setState({ AccordionPanels, loadingAnalysis: false, showReset: true });
      } else {
        this.setState({ AccordionPanels, loadingAnalysis: false });
      }
    })
    .catch(error => {
      console.log('submitRequest Error - ', error)
    });
  }

  fetchMoreInfo(content) {
    this.setState({ loadingMoreInfo: true });
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag-validation-more-info`;
    fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(content),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { info } = json.data;
      this.setState({ info, showInfo: true, loadingMoreInfo: false });
    })
    .catch(error => {
      console.log('fetchMoreInfo Error - ', error)
    });
  }

  backToContentTag() {
    this.props.history.push('/pc/content_tags');
  }

  resetTheValidationPage() {
    this.setState({ localLoader: true });
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}delete-content-tag-validation-analysis`;
    fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code } = json.status;
      if (code === "Success") {
        this.setState({ localLoader: false });
        window.location.reload(false);
      }
    })
    .catch(error => {
      console.log('resetTheValidationPage Error - ', error)
    });
  }

  render() {
    const { 
      planDropDown, lobDropDown, partnerPlatformDropDown, loadingAnalysis, loadingMoreInfo,
      reqContextPlanIdentifier, reqContextLobIdentifier, reqContextPartnerPlatform,
      requestContextList, info, showInfo, AccordionPanels, localLoader, showReset
    } = this.state;

    return (
      <div>
        {localLoader ? <div className="localLoading">Loading&#8230;</div> : '' }
        <FlashMessage />
        <div className="contentTag-header">
          <div className='new-contentTag-div'>
            <button className="btn btn-primary new-contentTag-btn"
                    onClick={this.backToContentTag}>
                      <i className="fa fa-solid fa-angle-left"></i> Back To ContentTag
            </button>
          </div>
        </div>
        <div className="reqContext-box">
          <div className="form-box">
          <p className="form-title"><strong><u>Configure The Request Context To Validate</u></strong></p>
          <FormGroup className="col-sm-12 validation-input">
            <Label for="partnerPlatform">Partner Platform</Label>
            <Input type="select" name="partnerPlatform" id="partnerPlatform" value={!this.isEmpty(reqContextPartnerPlatform) ? reqContextPartnerPlatform : 'Select Partner/Platform'} onChange={(e) => this.setState({ reqContextPartnerPlatform: e.target.value })}>
              {this.renderOptions(_.sortBy(partnerPlatformDropDown, 'label'))}
            </Input>
          </FormGroup>
          <FormGroup className="col-sm-12 validation-input">
            <Label for="planIdentifier" className='select-label'>Plan Identifier</Label>
            <Input type="select" name="planIdentifier" id="planIdentifier" value={!this.isEmpty(reqContextPlanIdentifier) ? reqContextPlanIdentifier : 'Select Plan'} onChange={(e) => this.setState({ reqContextPlanIdentifier: e.target.value })}>
              {this.renderOptions(_.sortBy(planDropDown, 'label'))}
            </Input>
          </FormGroup>
          <FormGroup className="col-sm-12 validation-input">
            <Label for="lobIdentifier" className='select-label'>Lines Of Business</Label>
            <Input type="select" name="lobIdentifier" id="lobIdentifier" value={!this.isEmpty(reqContextLobIdentifier) ? reqContextLobIdentifier : 'Select Lob'} onChange={(e) => this.setState({ reqContextLobIdentifier: e.target.value })}>
              {this.renderOptions(lobDropDown)}
            </Input>
          </FormGroup>
          {
            (!this.isEmpty(this.state.reqContextPlanIdentifier) && !this.isEmpty(this.state.reqContextLobIdentifier) && !this.isEmpty(this.state.reqContextPartnerPlatform)) ?
            <FormGroup className="col-sm-12 content-tag-validation-submit">
              <button className="btn btn-primary" type="button" onClick={(e) => this.createRequestContext(e)}>Create</button>
            </FormGroup> : null
          }
          </div>
          {
            requestContextList.length > 0 ? 
              <div className="selected-request-context">
                <p className="form-title"><strong><u>Request Contexts To Validate ({requestContextList.length})</u></strong></p>
                  <div className="scroller">
                    {requestContextList.map(rC => 
                      (
                        <li key={`${rC}`}><strong>Plan:</strong> {rC.split('*_*')[0]}, <strong>LOB:</strong> {rC.split('*_*')[1]}, <strong>Partner:</strong> {rC.split('*_*')[2].split('/')[0]}, <strong>Platform:</strong> {rC.split('*_*')[2].split('/')[1]}</li>
                      )
                    )}
                  </div>
                  <div>
                    <FormGroup className="col-sm-12 align-center">
                      { showReset ? <button className="btn btn-primary" style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px',marginLeft:'15px'}} type="button" onClick={this.resetTheValidationPage}>Reset Analysis</button> : null }
                      <button className="btn btn-primary" style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px',marginLeft:'15px'}} type="submit" onClick={(e) => this.submitRequest(e)}>Submit</button>
                    </FormGroup>
                  </div>
                  <p><strong>Note:</strong> Recommended number of request context would be 2 and not to go higher than 2 since it may timeout the server request.</p>
              </div> : null
          }
        </div>
        <hr style={{ align: 'center', width: '75%' }}/>
        <div className="result-div">
          {
            loadingAnalysis ?
              <div className="accordion-box"><Loading /> <h3 className="text-center">Please wait, while the validation analysis is running...</h3></div>
              : AccordionPanels && AccordionPanels.length > 0 ? <div className="accordion-box"><p className="form-title"><strong><u>Analysis Summary</u></strong><i className="fa fa-refresh black left-spacing" onClick={()=> this.refreshAnalysisResult()}></i></p><Accordion defaultActiveIndex={0} panels={AccordionPanels} /></div> : null
          }
          {
            loadingMoreInfo ?
              <div className="accordion-box-more-info"><Loading /> <h3 className="text-center">Please wait, while the more info is loading...</h3></div>
              : showInfo ? <div className="accordion-box-more-info"><p className="form-title"><strong><u>Analysis Details<i className='fa fa-times float-right margin-left-50' onClick={this.closeMoreInfo} /></u></strong></p><ReactJson src={info} enableClipboard={false} displayDataTypes={false} indentWidth={2} displayObjectSize={false} /></div> : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ContentTagsData, FlashMessageData}) => {
  return {
    planDropDown: ContentTagsData.planDropDown,
    lobDropDown: ContentTagsData.lobDropDown,
    partnerPlatformDropDown: ContentTagsData.partnerPlatformDropDown,
    contentTagValidationResult: ContentTagsData.contentTagValidationResult,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchContentTagValidationDropDown
})(ContentTagValidation);
