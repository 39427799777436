import Cookie from 'js-cookie';
import {
  SET_NOTIFICATION_PANEL,
} from '../ActionType';
import {fetchPackagesByClassification} from './Package';
import {signOut} from '../helper';

export const createAClone = (params, type) => {
  return (dispatch) => {
    const URL = `${['partner-platform', 'package', 'plan'].includes(type) ? process.env.REACT_APP_NODE_PC_ADMIN_URL : process.env.REACT_APP_PC_URL}clone-${type}`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      if (type === 'package') {
        dispatch(fetchPackagesByClassification(params.defaultClassificationId, [0, 1, 4].includes(parseInt(params.packageType)) ? parseInt(params.packageType) : 'all'))
      }
    })
    .catch(error => {
      console.log('createAClone Error - ', error)
    });
  }
}
