import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../../../scripts/redux/actions';
import './Login.css'
import logo from '../../../../assets/images/logo4.png';
import Cookie from 'js-cookie';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  componentWillMount() {
    if (Cookie.get('token')) {
      this.props.history.push('/pc')
    }
  }

  componentWillRecieve(nextProps) {
    if(!nextProps.isLoginPending) {
      this.setState({
        username: '',
        password: ''
      })
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  handleSubmit(e) {
    e.preventDefault();
    let { username, password } = this.state;
    this.props.login(username, password)
  }

  render() {
    let {username, password} = this.state;
    let {isLoginPending, isLoginSuccess, loginError} = this.props;
    let btn = isLoginPending ? 'loading' : 'Log In'
    return (
      <form className='login-form' onSubmit={(e) => {this.handleSubmit(e)}}>
        { isLoginSuccess && this.props.history.push('/pc') }
        <div className="logoImage">
          <img alt="logo" src={logo} style={{width: '100px'}}/>
        </div>
        { loginError && <div>
          <span>
            <div className={`notification notification-error notification-enter notification-enter-active`}>
              <div className="notification-message" role="alert">
                <i className="fa fa-times notification-icon"></i>
                <div className="message">{loginError.message}</div>
              </div>
            </div>
          </span></div>}
        <div className="white-box">
  				<input type='text' placeholder='Username' onChange={(e) => this.handleChange('username', e.target.value)} value={username}/>
  				<input type='password' placeholder='Password' onChange={(e) => this.handleChange('password', e.target.value)} value={password}/>
  			</div>
  			<input type='submit' disabled={isLoginPending} value={btn} />
  		</form>
    )
  }
}

const mapStateToProps = ({loginData}) => {
  return {
    isLoginPending: loginData.isLoginPending,
    isLoginSuccess: loginData.isLoginSuccess,
    loginError: loginData.loginError
  };
}

export default connect(mapStateToProps, {
  login,
})(Login);
