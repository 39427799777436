import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createAClone, fetchAPackagePartnerPlatform, fetchAllPartnerPlatformsForPackages, fetchPackageIauEnabledStatus, fetchPartnerPlatformIdsByPackageId, fetchAPackageAfter } from '../../../../scripts/redux/actions'
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Collapse, Form, FormGroup, Label, Input } from 'reactstrap';

const styles = {
  partnerPlatformsButton: {
    fontSize: '14px',
    border: '1px solid darkgray',
    borderRadius: '50px',
    marginBottom: '1rem',
  }
}

export class ClonePackageForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      identifier: '',
      guid: '',
      sku: '',
      disabledPartnerPlatform: [],
      validPartnerPlatform: [],
      showDeselectAll: true,
      partnerPlatformIdsByPackageId: [],
      selectAll: false,
      initalName: '',
      initialDescription: '',
      name: '',
      description: ''
    }
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.props.fetchAllPartnerPlatformsForPackages();
    this.props.fetchAPackagePartnerPlatform(this.props.parentIdentifier);
    this.props.fetchPackageIauEnabledStatus(this.props.parentIdentifier);
    this.props.fetchPartnerPlatformIdsByPackageId(this.props.parentIdentifier);
    this.props.fetchAPackageAfter(this.props.parentIdentifier);
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    const {name, description, iauEnabledStatusPackageExtra, iauEnabledStatusPlanPackage, partnerPackages, allPartnerPlatform, partnerPlatformIdsByPackageId, defaultClassificationId, packageType} = nextProps;
    let partnerPackageIds = Object.keys(partnerPackages).map(key => parseInt(key));
    let allDisablePartnerPlatform = allPartnerPlatform.filter(aPP => !partnerPlatformIdsByPackageId.includes(aPP.id)).map(aPP => aPP.id);
    let allValidPartnerPlatformIds = allPartnerPlatform.filter(aPP => partnerPackageIds.includes(aPP.id)).map(aPP => aPP.id);
    this.setState({initialName: name, initialDescription: description, defaultClassificationId, packageType, iauEnabledStatusPackageExtra, iauEnabledStatusPlanPackage, partnerPackages, allPartnerPlatform, disabledPartnerPlatform: allDisablePartnerPlatform, validPartnerPlatform: allValidPartnerPlatformIds, partnerPlatformIdsByPackageId});
    if (nextProps.statusCode === "Success") {
      this.props.closeClonePane(null, true)
    }
  }

  handlePartnerPlatformChange(id) {
    let partnerPackagesCopy = {...this.state.partnerPackages};
    partnerPackagesCopy[`${id}`] = !partnerPackagesCopy[`${id}`];
    this.setState({ partnerPackages: partnerPackagesCopy });
  }

  handleIauEnableChanges(key) {
    if (key === 'planPackage') {
      this.setState((prevState) => {
        return { iauEnabledStatusPlanPackage: !prevState.iauEnabledStatusPlanPackage }
      });
    } else if (key === 'packageExtra') {
      this.setState((prevState) => {
        return { iauEnabledStatusPackageExtra: !prevState.iauEnabledStatusPackageExtra }
      });
    }
  }

  selectAllHandler() {
    if (!this.state.selectAll) {
      this.setState((prevState) => {
        return { selectAll: !prevState.selectAll, partnerPackages: [ ...this.state.partnerPlatformIdsByPackageId, Object.keys(this.state.partnerPackages)].reduce((acc, key) => {acc[key] = true; return acc; }, {}) }
      });
    } else {
      this.setState((prevState) => {
        return { selectAll: !prevState.selectAll, partnerPackages: [ ...this.state.partnerPlatformIdsByPackageId, Object.keys(this.state.partnerPackages)].reduce((acc, key) => {acc[key] = false; return acc; }, {}) }
      });
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    let { parentIdentifier, type } = this.props;
    let typeId=`${type}Id`
    let { identifier, guid, sku, name, description } = this.state;
    let params = {
                    identifier,
                    [typeId]: parentIdentifier
                  }
    params['guid'] = guid
    params['sku'] = sku
    params['name'] = name
    params['description'] = description
    params['partnerPlatforms'] = this.state.partnerPackages
    params['iauEnabledStatusPlanPackage'] = this.state.iauEnabledStatusPlanPackage
    params['iauEnabledStatusPackageExtra'] = this.state.iauEnabledStatusPackageExtra
    params['defaultClassificationId'] = this.state.defaultClassificationId
    params['packageType'] = this.state.packageType

    this.props.createAClone(params, type)
    this.props.closeClonePane(null, true);
  }

  render() {
    let { initialName, initialDescription, identifier, isPaneOpen, guid, sku, allPartnerPlatform, iauEnabledStatusPlanPackage, iauEnabledStatusPackageExtra, partnerPlatformIdsByPackageId } = this.state;
    let { type } = this.props;
    let title = `Clone a ${type}`

    let noteText;
    if (partnerPlatformIdsByPackageId.length > 0) {
      noteText = (
        <p><strong>Note: The disabled partner platforms below cannot be added to this package because a valid partner_classification has not been established for classification <em>{this.state.classificationName}</em></strong></p>
      )
    } else if (this.state.partnerPlatformIdsByPackageId.length === 0) {
      noteText = (
        <p><strong>NOTE: No partner classifications exist for classification <em>{this.state.classificationName}</em>. To associate this package with partner platforms, create entries in the partner_classifications table for the platforms you want to support.</strong></p>
      )
    }

    return (
      <div>
        <div ref={ref => this.el = ref} className='clone-form'>
          <SlidingPane
            className='slinding-pane'
            overlayClassName='some-custom-overlay-class'
            isOpen={isPaneOpen}
            title={title}
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              this.setState({ isPaneOpen: false });
              this.props.closeClonePane()
            }}>
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="name" id="name" placeholder="Name" onChange={(e) => this.handleChange('name', e.target.value)} defaultValue={!this.isEmpty(initialName) ? initialName + ' - clone' : ''}/>
                  <Label for="name">Name</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="description" id="description" placeholder="Description" onChange={(e) => this.handleChange('description', e.target.value)} defaultValue={initialDescription}/>
                  <Label for="description">Description</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleChange('identifier', e.target.value)} value={identifier} required/>
                  <Label for="identifier">Identifier</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="iau-enable-plans" id="iau-enable-plans" checked={iauEnabledStatusPlanPackage} onChange={(e) => this.handleIauEnableChanges('planPackage')} />
                <Label check className="text-center">
                  IAU eligible for all associated plans.
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="iau-enable-extras" id="iau-enable-extras" checked={iauEnabledStatusPackageExtra} onChange={(e) => this.handleIauEnableChanges('packageExtra')} />
                <Label check className="text-center">
                  All associated extras are IAU eligible.
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="guid" id="guid" placeholder="GUID" onChange={(e) => this.handleChange('guid', e.target.value)} value={guid} required/>
                  <Label for="guid">GUID</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="sku" id="sku" placeholder="SKU" onChange={(e) => this.handleChange('sku', e.target.value)} value={sku} required/>
                  <Label for="sku">SKU</Label>
                </div>
              </FormGroup>
              <div>
                <button onClick={this.props.onTogglePartnerPlatforms} style={styles.partnerPlatformsButton}>
                  PartnerPlatforms
                </button>
                <Collapse isOpen={this.props.partnerPlatformsOpen}>
                  {noteText}
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="selectall-partner-platform" id="selectall-partner-platform" checked={this.state.selectAll} onChange={() => this.selectAllHandler()} />
                    <Label check className="text-center">
                      {this.state.selectAll ? "DeselectAll" : "SelectAll"}
                    </Label>
                  </FormGroup>
                  {!this.isEmpty(allPartnerPlatform) ? allPartnerPlatform.map(pP =>
                    (
                      <FormGroup key={pP.id} className="col-sm-12 form-checkbox">
                        <Input type="checkbox" disabled={this.state.disabledPartnerPlatform.includes(pP.id) ? true : false} name="partner-platform" id={pP.id} checked={this.state.partnerPackages[`${pP.id}`]} onChange={() => this.handlePartnerPlatformChange(pP.id)} />
                        <Label check className={this.state.disabledPartnerPlatform.includes(pP.id) ? "greyed-form-check-label" : "text-center"}>
                          {pP.partner}/{pP.platform}
                        </Label>
                      </FormGroup>
                    )
                  ) : null}
                </Collapse>
              </div>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
            <br />
          </SlidingPane>
        </div>
      </div>
    )
  }

}

const mapStateToProps = ({FlashMessageData, packagesData}) => {
  return {
    statusCode: FlashMessageData.statusCode,
    partnerPackages: packagesData.defaultPackagePartnerPackages,
    allPartnerPlatform: packagesData.allPartnerPlatform,
    iauEnabledStatusPlanPackage: packagesData.iauEnabledStatusPlanPackage,
    iauEnabledStatusPackageExtra: packagesData.iauEnabledStatusPackageExtra,
    partnerPlatformIdsByPackageId: packagesData.partnerPlatformIdsByPackageId,
    name: packagesData.name.value || '',
    description: packagesData.description.value || '',
    defaultClassificationId: packagesData.defaultClassificationId,
    packageType: packagesData.packageType,
  };
}
export default connect(mapStateToProps, {
  createAClone,
  fetchAPackagePartnerPlatform,
  fetchAllPartnerPlatformsForPackages,
  fetchPackageIauEnabledStatus,
  fetchPartnerPlatformIdsByPackageId,
  fetchAPackageAfter
})(ClonePackageForm);
