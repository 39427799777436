import { RESET_NOTIFICATION_PANEL, SET_NOTIFICATION_PANEL } from '../ActionType';

const INITIAL_STATE = {
  statusCode: '',
  statusMessage: '',
  showNotification: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_PANEL:
      let {code, message} = action.payload
      return {...state, statusCode: code, statusMessage: message, showNotification: true}
    case RESET_NOTIFICATION_PANEL:
      return {...state, ...INITIAL_STATE}
    default:
      return state;
  }
}
