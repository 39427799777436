import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchAllPartnerPlatforms,
  fetchPartnerPlatforms,
  togglePartnerPlatform,
  deletePartnerPlatform
} from '../../../../scripts/redux/actions'
import PartnerPlatformForm from './PartnerPlatformForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import ClonePartnerPlatformForm from '../ClonePartnerPlatformForm/ClonePartnerPlatformForm.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import { Card, CardFooter, CardBody, Row } from 'reactstrap';
import './PartnerPlatform.css';
import PartnerPlatformTabs from './PartnerPlatformTabs';

class PartnerPlatform extends Component {
  constructor (props) {
    super(props)
    this.state = {
      partnerPlatforms: [],
      displayingPartnerPlatforms: [],
      showPartnerPlatformForm: false,
      openedPartnerPlatform: null,
      loading: true,
      displayCardClass: '',
      showPartnerPlatformsTabs: false,
      partnerPlatformObj: {},
      tabTitle: ''
    }
    this.togglePartnerPlatformForm = this.togglePartnerPlatformForm.bind(this)
    this.togglePartnerPlatform = this.togglePartnerPlatform.bind(this)
    this.toggleCloneForm = this.toggleCloneForm.bind(this)
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  setPartnerPlatformTabsSectionClass() {
    let packageTabsSectionClass = 'package-tabs-section';
    let siCardBodyMd = '';
    let siCardMd = '';
    let tileSelected = 'tgc-tile-selected';
    if (window.innerWidth <= 1422) {
      packageTabsSectionClass = 'package-tabs-section'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      packageTabsSectionClass = 'package-tabs-section'
      siCardBodyMd = 'si-card-body-md'
      siCardMd = 'si-card-md'
      tileSelected = 'tgc-tile-selected tile-selected-md'
    } else if (window.innerWidth > 1440){
      packageTabsSectionClass = 'package-tabs-section package-tabs-section-lg'
    }
    this.setState({packageTabsSectionClass, siCardBodyMd, siCardMd, tileSelected})
  }

  togglePartnerPlatform(value) {
    let params = {
      partnerPlatformId: value.id,
      value: !value.enabled ? 1 : 0
    }
    this.props.togglePartnerPlatform(params);
  }

  componentWillMount() {
    this.setPartnerPlatformTabsSectionClass()
  }

  componentDidMount() {
    this.props.fetchAllPartnerPlatforms()
  }

  componentWillReceiveProps(nextProps) {
    const {partnerPlatforms, displayingPartnerPlatforms, loading} = nextProps
    this.setState({partnerPlatforms, displayingPartnerPlatforms, loading})
  }

  togglePartnerPlatformForm(id=null, getPartnerPlatforms=false) {
    if (id) {
      this.setState({
        showPartnerPlatformForm: true,
        openedPartnerPlatform: id
      });
    } else {
      this.setState({
        showPartnerPlatformForm: false
      })
    }
    if (getPartnerPlatforms) {
      this.props.fetchPartnerPlatforms()
    }
  }

  toggleCloneForm(id=null, getAllPartnerPlatform=false) {
    if (id) {
      const partnerPlatform = this.state.partnerPlatforms.find(pp => pp.id === id);
      this.setState({showCloneForm: true, partnerPlatformObj: partnerPlatform});
    } else {
      this.setState({showCloneForm: false});
    }
    if (getAllPartnerPlatform) {
      this.props.fetchAllPartnerPlatforms()
    }
  }

  displayPartnerPlatformTabs(openedPartnerPlatform, selectedDiv, title) {
    this.setState({
      showPartnerPlatformsTabs: true,
      openedPartnerPlatform,
      selectedDiv,
      tabTitle: title
    })
  }

  closePartnerPlatformTabs() {
    this.setState({
      showPartnerPlatformsTabs: false,
      selectedDiv: false
    })
  }

  search(val) {
    const {partnerPlatforms} = this.state
    let displayingPartnerPlatforms = partnerPlatforms.filter(c => (c.partner.toLowerCase().search(val.toLowerCase()) !== -1) || c.platform.toLowerCase().search(val.toLowerCase()) !== -1)
    this.setState({displayingPartnerPlatforms})
  }

  deleteRecord(id) {
    this.props.deletePartnerPlatform(id)
  }

  render() {
    const { openedPartnerPlatform, displayingPartnerPlatforms, loading,
            showPartnerPlatformForm, selectedDiv, tileSelected, siCardMd, siCardBodyMd,
            showPartnerPlatformsTabs, packageTabsSectionClass, showCloneForm, partnerPlatformObj,
            partnerPlatforms, tabTitle
          } = this.state

    let partnerList = ['Select Partner'];
    let sortedPartnerPlatforms = _.sortBy(partnerPlatforms, 'partner');
    for (const echPartnerPlatform of sortedPartnerPlatforms) {
      if (!this.isEmpty(echPartnerPlatform.partner.trim()) && !partnerList.includes(echPartnerPlatform.partner)) {
        partnerList.push(echPartnerPlatform.partner);
      }
    }
    partnerList.push('Other')
    let uniqPartnerList = partnerList.map(pL => {
      return { value: pL, label: pL }
    })

    const Item = ({value, name}) => {
      return (
        <div className='package-sortable-item'>
          <div className={`${name === selectedDiv ? tileSelected : ""}`}>
            <Card className={`margin-bottom-5 ${siCardMd}`}>
              <CardBody className={`si-card-body ${siCardBodyMd}`} onClick={() => this.displayPartnerPlatformTabs(value.id, name, `${value.partner} / ${value.platform}`)}>
                <span className="text-left font-size-18">{value.partner} / {value.platform}</span>
              </CardBody>
              <CardFooter className={`height-48 card-footer-color ${name === selectedDiv ? tileSelected : ""}`}>
                <div className="text-center si-card-footer">
                  {<i className="fa fa-clone" onClick={() => this.toggleCloneForm(value.id)}></i>}
                  {<i className="fa fa-pencil margin-left-50" onClick={() => this.togglePartnerPlatformForm && this.togglePartnerPlatformForm(value.id)}></i>}
                  { ((value.enabled || value.active) ?
                    <i className="fa fa-toggle-on margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.togglePartnerPlatform && this.togglePartnerPlatform(value) } } /> :
                    <i className="fa fa-toggle-off margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.togglePartnerPlatform && this.togglePartnerPlatform(value) } } />)
                  }
                  { value.partner.includes('-backup') ?
                    <i className='fa fa-trash margin-left-50' onClick={() => this.deleteRecord(value.id)} /> :
                    <i className='fa fa-trash margin-left-50' onClick={() => { if (window.confirm('Are you sure you wish to delete this?')) this.deleteRecord(value.id) } } />
                  }
                </div>
              </CardFooter>
            </Card>
          </div>
          { showPartnerPlatformsTabs && (name === selectedDiv) ?
          <div className={`${packageTabsSectionClass} ${name === selectedDiv ? "block" : ""}`}>
            <div onClick={() => this.closePartnerPlatformTabs()} className="wrapper">
              <a href className="close-button">
                <div className="in">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
                <div className="out">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
              </a>
            </div>
            <div><PartnerPlatformTabs openedPartnerPlatform={openedPartnerPlatform} tabTitle={tabTitle} /></div>
          </div> : null }
        </div>
      )
    };

    const List = ({items}) => {
      return (
        <Row>
          {
            items.map((value, index) => (
              <Item key={index} index={index} value={value} name={index}/>
            ))
          }
        </Row>
      );
    };

    return (
      <div>
        <FlashMessage />
        <div className='partnerPlatform'>
          <div className='partnerPlatform-header'>
            <div className="search-section partnerPlatform-search">
              <div className='aaa'>
                <input onKeyUp={(e)=>this.search(e.target.value)}
                       type="search" placeholder="What are you looking for?" />
              </div>
            </div>
            <div className='new-partnerPlatform-div'>
              <button className="btn btn-primary new-partnerPlatform-btn"
                      onClick={()=> this.setState({
                                                    showPartnerPlatformForm: true,
                                                    openedPartnerPlatform: null})} disabled>
              <i className="fa fa-plus"></i> New Partner Platform
              </button>
            </div>
          </div>
          <div className='new-partnerPlatform-content'>
            { loading && <Loading /> }
            {
              (!loading && displayingPartnerPlatforms.length === 0) ? <NoData /> :
              <List items={displayingPartnerPlatforms} />
            }
            {
              showPartnerPlatformForm ?
              <PartnerPlatformForm partnerPlatformId={openedPartnerPlatform}
                                  closeEditPartnerPlatformPane = {
                                    this.togglePartnerPlatformForm
                                  }
                                  />
              : null
            }
            {
              showCloneForm ?
              <ClonePartnerPlatformForm type='partnerPlatform'
                          parentIdentifier={partnerPlatformObj}
                          partnerList={uniqPartnerList}
                          closeClonePane={this.toggleCloneForm} />
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({PartnerPlatformData}) => {
  return {
    partnerPlatforms: PartnerPlatformData.partnerPlatforms,
    displayingPartnerPlatforms: PartnerPlatformData.displayingPartnerPlatforms,
    loading: PartnerPlatformData.loading,
  };
}

export {PartnerPlatform};

export default connect(mapStateToProps, {
  fetchAllPartnerPlatforms,
  fetchPartnerPlatforms,
  togglePartnerPlatform,
  deletePartnerPlatform
})(PartnerPlatform);
