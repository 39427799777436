import Cookie from 'js-cookie';
import {
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
} from '../ActionType';

function setLoginPending(isLoginPending) {
  return {
    type: SET_LOGIN_PENDING,
    isLoginPending
  };
}

function setLoginSuccess(isLoginSuccess) {
  return {
    type: SET_LOGIN_SUCCESS,
    isLoginSuccess
  };
}

function setLoginError(loginError) {
  return {
    type: SET_LOGIN_ERROR,
    loginError
  }
}

export const login = (username, password) => {
  return dispatch => {
    dispatch(setLoginPending(true));
    dispatch(setLoginSuccess(false));
    dispatch(setLoginError(null));
    let params = { username, password }
    callLoginApi(params, error => {
      dispatch(setLoginPending(false));
      if (!error) {
        dispatch(setLoginSuccess(true));
      } else {
        dispatch(setLoginError(error));
      }
    });
  }
}

function callLoginApi(params, callback) {
  var URL_JAVA = `${process.env.REACT_APP_PC_URL}login`;
  fetch(URL_JAVA, {
    method: 'POST',
    body: JSON.stringify(params),
  })
  .then(response => response)
  .then(response => {
    if (response.ok) {
      Cookie.set('token', response.headers.get('Authorization'));
      callback(null)
    } else {
      Cookie.remove('token');
      console.log( 'Login Failed -', response.statusText);
      callback(new Error('Invalid email or password'))
    }
  })
  .catch(error => {
    console.log('login Error to product-catalog-admin-service - ', error)
    callback(new Error('Invalid email or password to product-catalog-admin-service'))
  });

  var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}auth`;
  fetch(URL, {
    method: 'POST',
  })
  .then(response => response.json())
  .then(json => {
    Cookie.set('authtoken', `Bearer ${json.access_token}`);
  })
  .catch(error => {
    Cookie.remove('authtoken');
    console.log('auth Error to products-csa-admin - ', error)
    callback(new Error('Authorization failure to products-csa-admin'))
  });
}
