
import Cookie from 'js-cookie';

export const refreshToken = () => {
  return dispatch => {
    callNodeLoginApi(error => {
      if (error) {
        console.log('Error getting an refresh token')
      }
    });
  }
}


function callNodeLoginApi (callback) {
  var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}auth`;
  fetch(URL, {
    method: 'POST',
  })
  .then(response => response.json())
  .then(json => {
    Cookie.set('authtoken', `Bearer ${json.access_token}`);
  })
  .catch(error => {
    Cookie.remove('authtoken');
    console.log('auth Error to products-csa-admin - ', error)
    callback(new Error('Authorization failure to products-csa-admin'))
  });
}
