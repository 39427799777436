import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchAnLOBPlan,
  createAnLobPlan,
  fetchPartnerPlatforms,
  fetchDefaultPlans,
  resetLobPlanForm
} from '../../../redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

export class LineOfBusinessPlanForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingALobPlan: true
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    const {
      openedLobPlan,
      openedLob,
      fetchPartnerPlatforms,
      fetchAnLOBPlan,
      fetchDefaultPlans,
      resetLobPlanForm
    } = this.props;
    fetchPartnerPlatforms().then(() => {
      fetchDefaultPlans(null).then(() => {
        if (openedLobPlan) {
          fetchAnLOBPlan(openedLobPlan, openedLob)
        } else {
          resetLobPlanForm()
        }
          
      })
    })
  
    this.setState({ isPaneOpen: true })
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    const {
      partnerPlatformId, partnerPlatform, lineOfBusiness,
      lineOfBusinessId, planId, enabled, statusCode,
      loadingALobPlan, allPartnerPlatforms, defaultPlans
    } = nextProps

    this.setState({
      partnerPlatformId, partnerPlatform, lineOfBusiness,
      lineOfBusinessId, planId, enabled, statusCode,
      loadingALobPlan, allPartnerPlatforms, defaultPlans
    })

    if (nextProps.statusCode === "SUCCESS") {
      this.props.closeLobPlanFormPane(null, true)
    }
  }
  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const {
      partnerPlatformId, lineOfBusinessId, planId, enabled
    } = this.state
    var params = { partnerPlatformId, lineOfBusinessId, planId, enabled };
    this.props.createAnLobPlan(params)
  }

  render() {
    const {
      partnerPlatformId, planId, enabled, loadingALobPlan,
      allPartnerPlatforms, isPaneOpen, defaultPlans,
    } = this.state;
    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='LOB Plan Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closeLobPlanFormPane()
          }}>
          { loadingALobPlan ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="partnerPlatformId" id="partnerPlatformId"  value={partnerPlatformId} onChange={(e) => this.handleChange('partnerPlatformId', e.target.value)}>
                    {this.renderOptions(allPartnerPlatforms)}
                  </Input>
                  <Label for="partnerPlatformId" className='select-label'>Partner Platform</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="planId" id="planId"  value={planId} onChange={(e) => this.handleChange('planId', e.target.value)}>
                    {this.renderOptions(defaultPlans)}
                  </Input>
                  <Label for="planId" className='select-label'>Plan</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="enabled" id="enabled" checked={enabled} onChange={(e) => this.handleChange('enabled', !enabled)} />{' '}
                <Label check className="text-center"> Enabled </Label>
              </FormGroup>              
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({
    LineOfBusinessPlanData,
    FlashMessageData,
    DefaultDataCalls
  }) => {
  return {
    loadingALobPlan: LineOfBusinessPlanData.loadingALobPlan,
    partnerPlatformId: LineOfBusinessPlanData.partnerPlatformId,
    partnerPlatform: LineOfBusinessPlanData.partnerPlatform,
    lineOfBusiness: LineOfBusinessPlanData.lineOfBusiness,
    lineOfBusinessId: LineOfBusinessPlanData.lineOfBusinessId,
    planId: LineOfBusinessPlanData.planId,
    enabled: LineOfBusinessPlanData.enabled,
    statusCode: FlashMessageData.statusCode,
    allPartnerPlatforms: LineOfBusinessPlanData.partnerPlatforms,
    defaultPlans: DefaultDataCalls.defaultPlans,
  };
}

export default connect(mapStateToProps, {
  fetchAnLOBPlan,
  createAnLobPlan,
  fetchPartnerPlatforms,
  fetchDefaultPlans,
  resetLobPlanForm
})(LineOfBusinessPlanForm);
