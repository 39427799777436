import React from 'react';
import { Card, CardFooter, CardBody } from 'reactstrap';

const DisplayCard = (props) => {
  const { value, grandFatherToggle, toggleEditForm, displayPackageTabs, name,
          footerOpts, toggleCloneForm
        } = props;

  const renderToggle = (type, value) => {
    return <i style={{fontSize:'20px'}}
              className={`fa fa-toggle-${type} margin-left-50`}
              onClick={() => { if (window.confirm('Are you sure you wish to this action?')) grandFatherToggle && grandFatherToggle(value) } } />
  }

  return (
    <Card className='display-card'>
      <CardBody style={{height:'152px',background:'#5BC6FF',color:'#282c33'}} onClick={() => displayPackageTabs && displayPackageTabs(value.id, name, value.packageType)}>
          <span className="text-left" style={{fontSize:'18px'}}>{value.name || value.identifier}</span>
      </CardBody>
      <CardFooter style={{height: '48px'}} className='card-footer-color'>
        <div className="text-center" style={{'fontSize':'20px','color':'#282c33','paddingTop': '14px', 'display': 'flex', 'alignItems': 'left', 'justifyContent': 'center'}}>
          {footerOpts[0] && <i className="fa fa-clone" onClick={() => toggleCloneForm && toggleCloneForm(value.id)}></i>}
          {footerOpts[1] && <i className={"fa fa-pencil " +(footerOpts[0] ? 'margin-left-50' : '')} onClick={() => toggleEditForm && toggleEditForm(value.id)}></i>}
          {footerOpts[2] && ((value.enabled || value.active) ? renderToggle('on', value) : renderToggle('off', value))}
        </div>
      </CardFooter>
    </Card>
  )
}


export default DisplayCard;