import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchAFreemium,
  createAFreemium,
  fetchDWClassifications,
} from '../../../../scripts/redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

export class FreemiumForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      allClassifications: [],
      isPaneOpen: false,
      isPaneOpenLeft: false,
      dwClassifications: [],
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  handleAppSupportedChange(appName) {
    const {appsSupported} = this.state
    let app = _.find(appsSupported, {appName})
    app.isSupported = !app.isSupported
    this.setState(appsSupported)
  }

  componentDidMount() {
      Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.props.fetchAFreemium(this.props.openedPackage)
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    const {
      packageId, identifier, priority, startDate, endDate,
      previewHours, entitlementType, previewStartsAt, previewEndsAt, classificationId, previewType,
      previewStartTime, previewEndTime, previewTimeZone, editFreemium,
    } = nextProps

    this.setState({
      packageId, identifier, priority, startDate, endDate,
      previewHours, entitlementType, previewStartsAt, previewEndsAt, classificationId, previewType,
      previewStartTime, previewEndTime, previewTimeZone, editFreemium,
    })
    if (nextProps.statusCode === "Success") {
      this.props.closePackageFormPane(null, true)
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const {
      packageId, identifier, priority, startDate, endDate,
      previewHours, entitlementType, previewStartsAt, previewEndsAt, classificationId, previewType,
      previewStartTime, previewEndTime, previewTimeZone, dwClassification, packageName
    } = this.state

    var params = {
      packageId, identifier, priority, startDate, endDate,
      previewHours, entitlementType, previewStartsAt, previewEndsAt, classificationId, previewType,
      previewStartTime, previewEndTime, previewTimeZone, dwClassification, packageName
    };

    if (!this.props.editFreemium) {
      params['dwClassification'] = dwClassification
      params['packageName'] = packageName
    }
    this.props.createAFreemium(params)
  }

  renderAppsSupported() {
    return _.map(this.state.appsSupported, (app) => {
      return (
        <FormGroup check key={app.appName} className="form-checkbox" style={{padding:'0px'}}>
          <Input type="checkbox" name={app.appName} id={app.appName} checked={app.isSupported} onChange={(e) => this.handleAppSupportedChange(app.appName)} />{' '}
          <Label check className="text-center black">
            {app.appName}
          </Label>
        </FormGroup>
      );
    });
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    const {
      packageId, identifier, priority, startDate, endDate,
      previewHours, entitlementType, previewStartsAt, previewEndsAt, classificationId, previewType,
      previewStartTime, previewEndTime, previewTimeZone, isPaneOpen, loadingAPackage, editFreemium, packageName
    } = this.state;

    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Package Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closePackageFormPane()
          }}>
          { loadingAPackage ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="packageId" id="packageId" placeholder="Package Id" onChange={(e) => this.handleChange('packageId', e.target.value)} value={packageId}/>
                  <Label for="packageId">Package Id</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleChange('identifier', e.target.value)} value={identifier}/>
                  <Label for="identifier">Identifier</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="priority" id="priority" placeholder="Priority" onChange={(e) => this.handleChange('priority', e.target.value)} value={priority}/>
                  <Label for="priority">Priority</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="startDate" id="startDate" placeholder="Start Date" onChange={(e) => this.handleChange('startDate', e.target.value)} value={startDate}/>
                  <Label for="startDate">Start Date</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="endDate" id="endDate" placeholder="End Date" onChange={(e) => this.handleChange('endDate', e.target.value)} value={endDate}/>
                  <Label for="endDate">End Date</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="integer" name="previewHours" id="previewHours" placeholder="Preview Hours" onChange={(e) => this.handleChange('previewHours', e.target.value)} value={previewHours}/>
                  <Label for="previewHours">Preview Hours</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="entitlementType" id="entitlementType" placeholder="Entitlement Type" onChange={(e) => this.handleChange('entitlementType', e.target.value)} value={entitlementType}/>
                  <Label for="entitlementType">Entitlement Type</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="previewStartsAt" id="previewStartsAt" placeholder="Preview Starts At" onChange={(e) => this.handleChange('previewStartsAt', e.target.value)} value={previewStartsAt}/>
                  <Label for="previewStartsAt">Preview Starts At</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="previewEndsAt" id="previewEndsAt" placeholder="Preview Ends At" onChange={(e) => this.handleChange('previewEndsAt', e.target.value)} value={previewEndsAt}/>
                  <Label for="previewEndsAt">Preview Ends At</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="classificationId" id="classificationId" placeholder="Classification Id" onChange={(e) => this.handleChange('classificationId', e.target.value)} value={classificationId}/>
                  <Label for="classificationId">Classification Id</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="previewType" id="previewType" placeholder="Preview Type" onChange={(e) => this.handleChange('previewType', e.target.value)} value={previewType}/>
                  <Label for="previewType">Preview Type</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="previewStartTime" id="previewStartTime" placeholder="Preview Start Time" onChange={(e) => this.handleChange('previewStartTime', e.target.value)} value={previewStartTime}/>
                  <Label for="previewStartTime">Preview Start Time</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="previewEndTime" id="previewEndTime" placeholder="Preview End Time" onChange={(e) => this.handleChange('previewEndTime', e.target.value)} value={previewEndTime}/>
                  <Label for="previewEndTime">Preview End Time</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="previewTimeZone" id="previewTimeZone" placeholder="Preview Time Zone" onChange={(e) => this.handleChange('previewTimeZone', e.target.value)} value={previewTimeZone}/>
                  <Label for="previewTimeZone">Preview Time Zone</Label>
                </div>
              </FormGroup>

              {
                editFreemium ? '' :
                <div>
                  <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="packageName" id="packageName" placeholder="Package Name" onChange={(e) => this.handleChange('packageName', e.target.value)} value={packageName}/>
                    <Label for="packageName">Package Name</Label>
                  </div>
                </FormGroup>
                </div>
              }
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({freemiumsData, DefaultDataCalls, FlashMessageData}) => {
  return {
    packageId: freemiumsData.packageId || '',
    identifier: freemiumsData.identifier || '',
    priority: freemiumsData.priority || '',
    startDate: (freemiumsData.startDate != null ? freemiumsData.startDate.replace('T',' ').replace('.000Z', '') : ''),
    endDate: (freemiumsData.endDate != null ? freemiumsData.endDate.replace('T',' ').replace('.000Z', '') : ''),
    previewHours: freemiumsData.previewHours || '',
    entitlementType: freemiumsData.entitlementType || '',
    previewStartsAt: freemiumsData.previewStartsAt || '',
    previewEndsAt: freemiumsData.previewEndsAt || '',
    classificationId: freemiumsData.classificationId || '',
    previewType: freemiumsData.previewType || '',
    previewStartTime: freemiumsData.previewStartTime,
    previewEndTime: freemiumsData.previewEndTime,
    previewTimeZone: freemiumsData.previewTimeZone || '',
    extraType: freemiumsData.extraType || '',
    migratedTo: freemiumsData.migratedTo || '',
    editFreemium: freemiumsData.editFreemium,
    dwClassification: freemiumsData.dwClassification,
    packageName: freemiumsData.packageName || '',
    allClassifications: DefaultDataCalls.defaultClassifications,
    packageTypes: DefaultDataCalls.defaultPackageTypes,
    dwClassifications: DefaultDataCalls.dwClassifications,
    enabledPackages: DefaultDataCalls.enabledPackages,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchAFreemium,
  createAFreemium,
  fetchDWClassifications,
})(FreemiumForm);
