import Cookie from 'js-cookie';
import {
  FETCH_A_PLAN_PACKAGE,
  SET_NOTIFICATION_PANEL,
  FETCH_ALL_PACKAGES_BYPLANID
} from '../ActionType';
import {signOut} from '../helper';

export const fetchAPlanPackage = (packageId, planId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}package-plan/${packageId}/${planId}.json`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let { planPackagePrice, displayPrice, freeTrial, iauEnabled } = json;
      dispatch({
        type: FETCH_A_PLAN_PACKAGE,
        payload: {
          planPackagePrice, displayPrice, freeTrial, iauEnabled
        }
      })
    })
    .catch(error => {
        console.log('fetchPackagePlan Error - ', error);
    })
  }
}

export const fetchAllPackagesByPlanId = (planId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}plan_packages/${planId}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let {planPackageList, availablePackages: newPackages, planIdentifier} = json;
      let newPackagesList = _.sortBy(newPackages, 'label');
      dispatch({
        type: FETCH_ALL_PACKAGES_BYPLANID,
        payload: {planPackageList, newPackagesList, planIdentifier}
      });
    })
    .catch(error => {
        console.log('fetchAllPackagesByPlanId Error - ', error);
    })
  }
}

export const updateAPlanPackage = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}plan-packages`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('updateAPlanPackage Error - ', error)
    });
  }
}
