import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchFreemiumsByClassification, grandFatherToggle, fetchAllClassifications} from '../../../../scripts/redux/actions'
import FreemiumForm  from '../FreemiumForm/FreemiumForm.js';
import PackageTabs  from '../PackageTabs/PackageTabs.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import CloneForm from '../CloneForm/CloneForm.js';
import { Row } from 'reactstrap';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import 'react-select/dist/react-select.css';
import { Card, CardFooter, CardBody } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';
import './Freemium.css';
import Cookie from 'js-cookie';

class Freemium extends Component {
  constructor (props) {
    super(props)
    this.state = {
      packages: [],
      displayingPackages: [],
      dropdownOpen: false,
      showPackageTabs: false,
      openedPackage: null,
      classifications: [],
      showCloneForm: false,
      selectedPackageType: 'all',
      currentClassification: 17,
      showFreemiumForm: false,
      loading: true,
      showNotification: false,
      response: null,
      notificationMessage: null,
      footerOpts: {0: true, 1: true, 2: true},
      packageType: null,
      packageTabsSectionClass: ''
    }
    this.closePackageTabs = this.closePackageTabs.bind(this)
    this.grandFatherToggle = this.grandFatherToggle.bind(this)
    this.togglePackageForm = this.togglePackageForm.bind(this)
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
    this.toggleCloneForm = this.toggleCloneForm.bind(this)
  }

  setPackageTabsSectionClass() {
    let packageTabsSectionClass = 'package-tabs-section';
    let siCardBodyMd = '';
    let siCardMd = '';
    let tileSelected = 'tgc-tile-selected';
    if (window.innerWidth <= 1422) {
      packageTabsSectionClass = 'package-tabs-section'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      packageTabsSectionClass = 'package-tabs-section'
      siCardBodyMd = 'si-card-body-md'
      siCardMd = 'si-card-md'
      tileSelected = 'tgc-tile-selected tile-selected-md'
    } else if (window.innerWidth > 1440){
      packageTabsSectionClass = 'package-tabs-section package-tabs-section-lg'
    }
    this.setState({packageTabsSectionClass, siCardBodyMd, siCardMd, tileSelected})
  }

  onClassificationChange = (selectedClassification) => {
    this.setState({currentClassification: selectedClassification.value})
    this.props.fetchFreemiumsByClassification(selectedClassification.value, this.state.selectedPackageType)
  }

  onPackageTypeChange = (selectedPackageType) => {
    const {packages} = this.state
    let filteredPacks
    if (selectedPackageType.value === 'all') {
      filteredPacks = packages
    } else {
      filteredPacks = packages.filter(pack => pack.packageType === selectedPackageType.value)
    }
    this.setState({displayingPackages: filteredPacks, selectedPackageType: selectedPackageType.value})
  }

  togglePackageForm(packId=null, getPackages=false) {
    if (packId) {
      this.setState({ showFreemiumForm: true, openedPackage: packId });
    } else {
      this.setState({ showFreemiumForm: false})
    }
    if (getPackages) {
      this.props.fetchFreemiumsByClassification(this.state.currentClassification, this.state.selectedPackageType)
    }
  }

  toggleCloneForm(id=null, getPackages=false) {
    if (id) {
      this.setState({showCloneForm: true, openedPackage: id})
    } else {
      this.setState({showCloneForm: false})
    }
    if (getPackages) {
      this.props.fetchFreemiumsByClassification(this.state.currentClassification, this.state.selectedPackageType)
    }
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => {this.setState({showNotification: false})}, 3000);
  }

  closePackageTabs() {
    this.setState({showPackageTabs: false, selectedDiv: false})
  }

  grandFatherToggle(value) {
    const {currentClassification, selectedPackageType} = this.state
    let params = {
      packageId: value.id,
      enabled: !value.enabled ? true : false,
    }
    this.props.grandFatherToggle(params, 'pacakage', {classification: currentClassification, packageType: selectedPackageType})
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) {
      return;
    }
    let newPackagesObj = []
    let prioritizedPackages = arrayMove(this.state.displayingPackages, oldIndex, newIndex)
    prioritizedPackages.forEach((item, index) => {
      newPackagesObj.push({
        id: item.id,
        priority: index + 1,
      })
    })
    let packagesParams = {
      classificationId: this.state.currentClassification,
      packages: newPackagesObj
    }

    const URL = `${process.env.REACT_APP_PC_URL}update-package-classification-priority`;
    fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(packagesParams),
    })
    .then(response => response.json())
    .then(json => {
      this.setState({
        displayingPackages: arrayMove(this.state.displayingPackages, oldIndex, newIndex)
      });
      this.showNotificationPanel(true, json.status.code, json.status.message)
    })
    .catch(error => {
      console.log('Update Package Priority Error - ', error)
      // this.showNotificationPanel(true, json.status.code, json.status.message)
    });
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  componentWillMount() {
    this.setPackageTabsSectionClass()
    this.props.fetchAllClassifications()
    this.props.fetchFreemiumsByClassification(this.state.currentClassification)
  }

  componentWillReceiveProps(nextProps) {
    const {packages, displayingPackages, loading, classifications} = nextProps
    this.setState({packages, displayingPackages, loading, classifications})
  }

  search(val) {
    const {packages, selectedPackageType} = this.state
    let packs = packages.filter(pack => (selectedPackageType === 'all') ? (true) : (pack.packageType === selectedPackageType))
    let displayingPackages = packs.filter(pack => (pack.name.toLowerCase().search(val.toLowerCase()) !== -1) || (pack.identifier.toLowerCase().search(val.toLowerCase()) !== -1))
    this.setState({displayingPackages})
  }

  displayPackageTabs(openedPackage, selectedDiv, packageType) {
    this.setState({showPackageTabs: true, openedPackage, selectedDiv, packageType})
  }

  render() {
    const {
      openedPackage, currentClassification, loading,
      footerOpts, selectedDiv, showPackageTabs, displayingPackages,
      packageType, packageTabsSectionClass, siCardBodyMd,
      siCardMd, tileSelected, showCloneForm,
    } = this.state
    const SortableItem = SortableElement(({value, name}) =>
      <div>
        <div className='package-sortable-item'>
          <div className={`${name === selectedDiv ? tileSelected : ""}`}>
            <Card className={`margin-bottom-5 ${siCardMd}`}>
              <CardBody className={`si-card-body ${siCardBodyMd}`} onClick={() => this.displayPackageTabs(value.id, name, value.packageType)}>
                <span className="text-left font-size-18">{value.name || value.identifier}</span>
              </CardBody>
              <CardFooter className={`height-48 card-footer-color ${name === selectedDiv ? tileSelected : ""}`}>
                <div className="text-center si-card-footer">
                  {footerOpts[0] && <i className="fa fa-clone" onClick={() => this.toggleCloneForm(value.id)}></i>}
                  {footerOpts[1] && <i className="fa fa-pencil margin-left-50" onClick={() => this.togglePackageForm && this.togglePackageForm(value.id)}></i>}
                  { footerOpts[2] &&
                    ((value.enabled || value.active) ?
                    <i className="fa fa-toggle-on margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.grandFatherToggle && this.grandFatherToggle(value) } } /> :
                    <i className="fa fa-toggle-off margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.grandFatherToggle && this.grandFatherToggle(value) } } />)
                  }
                </div>
              </CardFooter>
            </Card>
          </div>
          { showPackageTabs && (name === selectedDiv) ?
          <div className={`${packageTabsSectionClass} ${name === selectedDiv ? "block" : ""}`}>
            <div onClick={() => this.closePackageTabs()} className="wrapper">
              <div className="close-button">
                <div className="in">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
                <div className="out">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
              </div>
            </div>
            <PackageTabs packageType={packageType}
                         showNotificationPanel={this.showNotificationPanel}
                         openedPackage={openedPackage}
                         currentClassification={currentClassification}/>
          </div> : null }
        </div>
      </div>
    );
    const SortableList = SortableContainer(({items}) => {
      return (
        <Row>
          {
            items.map((value, index) => (
              <SortableItem disabled={this.state.selectedPackageType !== 0}
                            key={index} index={index} value={value} name={index}/>
            ))
          }
        </Row>
      );
    });
    return (
        <div>
          <FlashMessage />
          <div className='package'>
              <div className='package-header'>
                <div className='package-search'>
                  <div className="search-section width-100">
                    <div className='aaa'>
                      <input onKeyUp={(e)=>this.search(e.target.value)}
                        type="search"
                        placeholder="What are you looking for?" />
                    </div>
                  </div>
                </div>
                <div className='new-package-div'>
                  <button className="btn btn-primary new-package-btn"
                          onClick={()=> this.setState({ showFreemiumForm: true,
                                                        openedPackage: null})}>
                  <i className="fa fa-plus"></i> New Freemium
                  </button>
                </div>
              </div>
              <div className='package-content'>
                {loading && <Loading />}
                {
                  (!loading && displayingPackages.length === 0) ? <NoData /> :
                  <SortableList items={displayingPackages} onSortEnd={this.onSortEnd} axis='xy' pressDelay={200}/>
                }
                {
                  this.state.showFreemiumForm ?
                  <FreemiumForm openedPackage={openedPackage}
                               closePackageFormPane={this.togglePackageForm}
                               showNotificationPanel={this.showNotificationPanel}/>
                  : null
                }
                {
                  showCloneForm ?
                  <CloneForm type='package'
                             parentIdentifier={openedPackage}
                             closeClonePane={this.toggleCloneForm}/>
                  : null
                }
              </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = ({freemiumsData, classificationsData}) => {
  return {
    packages: freemiumsData.packages,
    displayingPackages: freemiumsData.displayingPackages,
    loading: freemiumsData.loading,
    classifications: classificationsData.classifications,
  };
}

export {Freemium};

export default connect(mapStateToProps, {
  fetchFreemiumsByClassification,
  grandFatherToggle,
  fetchAllClassifications,
})(Freemium);
