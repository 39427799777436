import React, { Component } from 'react';
import "react-table/react-table.css";
import {
  Card,
  CardFooter,
  CardBody,
  Row,
  Nav,
  NavItem,
  NavLink,
  Col
} from 'reactstrap';
import "react-table/react-table.css";
import classnames from 'classnames';
import PartnerClassificationForm from './PartnerClassificationForm.js';
import PartnerLobPlanForm from './PartnerLobPlanForm.js';
import PartnerPackageForm from '../PartnerPackage/PartnerPackageForm.js';
import PartnerPackageExtraForm from '../PartnerPackage/PartnerPackageExtraForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import '../PackageTabs/PackageTabs.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Cookie from 'js-cookie';

class PartnerPlatformTabs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: 'classifications',
      partnerClassifications: [],
      displayingPartnerClassifications: [],
      displayingLobPlans: [],
      lobPlans: [],
      showNotification: '',
      statusCode: '',
      statusMessage: '',
      data:[],
      packageExtraOpen: false,
      packageListOpen: false,
      tabTitle: this.props.tabTitle || '',
    }
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
    this.togglePartnerClassificationForm = this.togglePartnerClassificationForm.bind(this)
    this.togglePartnerLobPlanForm = this.togglePartnerLobPlanForm.bind(this)
    this.togglePartnerPackageForm = this.togglePartnerPackageForm.bind(this)
    this.togglePartnerPackageExtraForm = this.togglePartnerPackageExtraForm.bind(this)
    this.updateLOB = this.updateLOB.bind(this)
    this.renderLOB = this.renderLOB.bind(this)
    this.renderPlans = this.renderPlans.bind(this)
    this.updatePC = this.updatePC.bind(this)
    this.renderClassifications = this.renderClassifications.bind(this)
    this.updatePP = this.updatePP.bind(this)
    this.renderPackages = this.renderPackages.bind(this)
    this.updateExtras = this.updateExtras.bind(this)
    this.renderExtras = this.renderExtras.bind(this)
    this.handleTogglePackageExtra = this.handleTogglePackageExtra.bind(this)
    this.handleToggleNewPackage = this.handleToggleNewPackage.bind(this)
  }

  setDisplayCardClass() {
    let displayCardClass = 'col-md-3';
    if (window.innerWidth <= 1422) {
      displayCardClass = 'col-md-3'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      displayCardClass = 'col-md-3'
    } else if (window.innerWidth > 1440){
      displayCardClass = 'col-lg-2'
    }
    this.setState({displayCardClass})
  }

  getPartnerClassifications() {
    const {openedPartnerPlatform} = this.props;
    if (openedPartnerPlatform) {
      this.setState({
        loading:true,
        activeTab: 'classifications',
      })
      const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-platform-data/${openedPartnerPlatform}`
      fetch(URL, {method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          // Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        this.setState({
          loading: false,
          data: json
        //   partnerClassifications: json,
        //   displayingPartnerClassifications: json,
        });
      })
      .catch(error => {
          console.log('getPartnerClassifications Error - ', error);
      })
    }
  }

  handleTogglePackageExtra(e) {
    if (e) {e.preventDefault();}
    this.setState((prevState) => {
      return { packageExtraOpen: !prevState.packageExtraOpen }
    });
  }

  handleToggleNewPackage(e) {
    if (e) {e.preventDefault();}
    this.setState((prevState) => {
      return { packageListOpen: !prevState.packageListOpen }
    });
  }

  getPartnerLobPlans() {
    const {openedPartnerPlatform} = this.props;
    if (openedPartnerPlatform) {
      this.setState(() => ({
        loading: true,
        activeTab: 'lobPlans',
      }))
      const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}line-of-business-plan-based-on-partner-platform/${openedPartnerPlatform}`
      fetch(URL, {method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        this.setState({
          loading: false,
          lobPlans: json,
          displayingLobPlans: json,
        });
      })
      .catch(error => {
          console.log('getPartnerLobPlans Error - ', error);
      })
    }
  }

  componentWillMount() {
    this.setDisplayCardClass()
    this.getPartnerClassifications()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      openedPartnerPlatform: nextProps.openedPartnerPlatform
    })
    this.getPartnerClassifications()
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => {this.setState({showNotification: false})}, 3000);
  }

  toggleTabs(tab) {
    switch (tab) {
      case 'classifications':
        this.getPartnerClassifications();
        break;
      case 'lobPlans':
        this.getPartnerLobPlans();
        break;
      default:
        this.getPartnerClassifications();
        break;
    }
  }

  search(key, val) {
    const obj = this.state[key]
    let displayingObj = obj.filter(o => (o.classification.toLowerCase().search(val.toLowerCase()) !== -1))
    switch (key) {
      case 'partnerClassifications':
        this.setState({displayingPartnerClassifications: displayingObj})
        break;
      default:
        console.log('default case')
        break;
    }
  }

  togglePartnerForms(openedPartnerData=null, getPartnerData=false) {
    switch (this.state.activeTab) {
      case 'classifications':
        this.togglePartnerClassificationForm(openedPartnerData, getPartnerData)
        break;
      case 'lobPlans':
        this.togglePartnerLobPlanForm(openedPartnerData, getPartnerData)
        break;
      default:
        this.togglePartnerClassificationForm(openedPartnerData, getPartnerData)
        break;
    }
  }

  togglePartnerClassificationForm(openedPartnerClassification=null, getPartnerClassifications=false) {
    if (openedPartnerClassification) {
      this.setState({ showPartnerClassificationForm: true, openedPartnerClassification });
    } else {
      this.getPartnerClassifications()
      this.setState({ showPartnerClassificationForm: false})
    }
    // if (getPartnerClassifications) {
    //   this.toggleTabs('classifications')
    // }
  }

  togglePartnerLobPlanForm(openedPartnerLobPlan=null, getPartnerLobPlans=false) {
    if (openedPartnerLobPlan) {
      this.setState({ showPartnerLobPlanForm: true, openedPartnerLobPlan });
    } else {
      this.getPartnerClassifications()
      this.setState({ showPartnerLobPlanForm: false})
    }
    // if (getPartnerLobPlans) {
    //   this.toggleTabs('lobPlans')
    // }
  }

  togglePartnerPackageForm(openedPartnerPackage=null, getPartnerLobPlans=false) {
    if (openedPartnerPackage) {
      this.setState({ showPartnerPackageForm: true, openedPartnerPackage });
    } else {
      this.getPartnerClassifications()
      this.setState({ showPartnerPackageForm: false})
    }
    // if (getPartnerLobPlans) {
    //   this.toggleTabs('lobPlans')
    // }
  }

  togglePartnerPackageExtraForm(openedPartnerPackageExtra=null, getPartnerPackageExtras=false) {
    if (openedPartnerPackageExtra) {
      this.setState({ showPartnerPackageExtraForm: true, openedPartnerPackageExtra });
    } else {
      this.setState({ showPartnerPackageExtraForm: false})
    }
    // if (getPartnerPackageExtras) {
    //   this.toggleTabs('packageExtras')
    // }
  }

  disablePartnerClassification(partnerClassification, value) {
    const URL = `${process.env.REACT_APP_PC_URL}disable-partner-classification/${partnerClassification}/${value}`
    fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token'),
      },
    })
    .then(response => response.json())
    .then(json => {
      this.showNotificationPanel(true, json.status.code, json.status.message)
      this.toggleTabs(this.state.activeTab)
    })
    .catch(error => {
        console.log('disablePartnerClassification - ', error);
    })
  }

  updateLOB(row){
    let params = {};
    params.enabled = row.lobplan_enabled;
    params.lineOfBusiness = row.lob_identifier;
    params.lineOfBusinessId = row.lob_id
    params.id = row.lob_id
    params.partnerPlatform = row.partner
    params.partnerPlatformId = row.pplatform_id
    params.plan = row.plan_identifier
    params.planId = row.lobplanId
    params.lobplanId = row.lobplan_id
    this.togglePartnerLobPlanForm(params)
  }

  updatePC(row){
    let params = {};
    params.id = row.pc_id;
    params.partnerPlatformId = row.pplatform_id;
    params.classificationId = row.classificationId;
    params.enabled = row.pc_enabled;
    params.priority = row.pc_priority;
    params.displayPackageCount = row.pc_display_package_count;
    this.togglePartnerClassificationForm(params)
  }

  updatePP(row){
    let params = {};
    params.openedPackageId = row.pp_package_id;
    params.partnerClassificationId = row.pc_id;
    this.togglePartnerPackageForm(params)
  }

  updateExtras(row){
    let params = {};
    params.openedPartnerPackageExtra = row.pp_id;
    params.partnerClassificationId = row.pc_id;
    this.togglePartnerPackageExtraForm(params)
  }


  renderLOB(cell,row) {
    return (
      <div className="text-left">
        <span className="text-edit"  onClick={(e) => {this.updateLOB(row)}}>{row.lob_identifier}</span>
      </div>
    )
  }

  renderPlans(cell, row) {
    return(
      <div className="text-left">
        <span onClick={(e) => {this.updateLOB(row)}} className={row.lobplan_enabled ? "text-edit text-green": "text-edit text-red"} >{row.plan_identifier}</span>
      </div>
    )
  }

  renderClassifications(cell, row) {
    return(
      <div className="text-left">
        <span onClick={(e) => {this.updatePC(row)}} className={row.pc_enabled ? "text-edit text-green": "text-edit text-red"} >{row.pc_identifier}</span>
      </div>
    )
  }

  renderPackages(cell, row) {
    return(
      <div className="text-left">
        <span onClick={(e) => {this.updatePP(row)}} className={row.pp_enabled ? "text-edit text-green": "text-edit text-red"} >{row.pp_identifier}</span>
      </div>
    )
  }

  renderExtras(cell, row) {
    return(
      <div className="text-left">
        <span onClick={(e) => {this.updateExtras(row)}} className="text-edit">{row.extras}</span>
      </div>
    )
  }


  render() {
    const {
      showNotification, statusCode, statusMessage, loading,
      activeTab, displayCardClass, showPartnerClassificationForm, openedPartnerPackageExtra,
      openedPartnerClassification, showPartnerLobPlanForm, openedPartnerLobPlan, showPartnerPackageForm, openedPartnerPackage, showPartnerPackageExtraForm,
      data, packageExtraOpen, packageListOpen, tabTitle
    } = this.state

    const Item = ({value}) => {
      return (
        <div className={displayCardClass} style={{padding:'0px 5px 10px', zIndex:'99'}}>
          <Card className='display-card' style={{'color': '#666666'}}>
            <CardBody style={{background:'#46464C',color:'white',height:'100px'}}>
              <span className="text-left" style={{fontSize:'18px'}}>{value.classification || value.lineOfBusiness}</span>
            </CardBody>
            <CardFooter style={{height: '30px'}} className='tabs-card-footer'>
                <div className="text-center" style={{background:'#46464C','fontSize':'20px','color':'white','display': 'flex', 'alignItems': 'left', 'justifyContent': 'center'}}>
                  <i onClick={() => this.togglePartnerForms(value)} className={classnames('fa', 'fa-pencil')}></i>
                  { (value.enabled ?
                    <i className="fa fa-toggle-on margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.disablePartnerClassification(value.id, false) } } /> :
                    <i className="fa fa-toggle-off margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.disablePartnerClassification(value.id, true) } } />)
                  }
                </div>
            </CardFooter>
          </Card>
        </div>
      )
    };

    const List = ({items}) => {
      if (items.length > 0) {
        return (
          <Row style={{padding:'10px'}}>
            {
              items.map((value, index) => (
                <Item key={`item-${index}`} index={index} value={value} />
              ))
            }
          </Row>
        );
      } else {
        return (<NoData />)
      }
    };

    const NavItems = ({items}) => {
      return (
        <Nav tabs>
          {
            items.map((item, index) => (
                <NavItem key={index}
                        className={classnames({ active: activeTab === item })}>
                  <NavLink onClick={() => { this.toggleTabs(item); }}>
                    {item}
                  </NavLink>
                </NavItem>
            ))
          }
        </Nav>
      )
    }

    // New Tabular Format of Data

    const columns = [
      {
        headerStyle: () => {
          return { width: '15vw', textAlign: 'left' };
        },
        text: "LOB",
        dataField: "lob_identifier",
        sort: true,
        filter: textFilter({ placeholder: 'Line of Business'}),
        formatter: this.renderLOB

        // filter: selectFilter({
        //   options: applications,
        //   placeholder: 'Show All',
        //   defaultValue: 'catalog'
        // })
      },
      {
        headerStyle: () => {
          return { width: '15vw', textAlign: 'left' };
        },
        text: "Plans",
        dataField: "plan_identifier",
        sort: true,
        filter: textFilter({ placeholder: 'Plans'}),
        formatter: this.renderPlans
        // filter: selectFilter({
        //   options: locales,
        //   placeholder: 'Show All',
        //   defaultValue: 'en',
        // })
      },
      {
        headerStyle: () => {
          return { width: '15vw', textAlign: 'left' };
        },
        text: "Classification",
        dataField: "pc_identifier",
        sort: true,
        filter: textFilter({ placeholder: 'Classification'}),
        formatter: this.renderClassifications
        // filter: textFilter({ placeholder: ' ', delay: 0 })
        // filter: textFilter({
        //   onFilter: this.search
        // })
      },

      {
        headerStyle: () => {
          return { width: '20vw', textAlign: 'left' };
        },
        text: "Package",
        dataField: "pp_identifier",
        sort: true,
        filter: textFilter({ placeholder: 'Package'}),
        formatter: this.renderPackages
        // formatter: this.renderStatus,
        // filter: selectFilter({
        //   options: () => translations,
        //   placeholder: "Show All"
        // }),
      },
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'center' };
        },
        text: "Extras",
        dataField: "extras",
        sort: true,
        formatter: this.renderExtras
      }
    ];
    return (
      <div className='package-tabs'>
        <p style={{textAlign:'center', fontSize:'30px'}}>{tabTitle}</p>
        {showNotification && <FlashMessage statusCode={statusCode} statusMessage={statusMessage} />}
            {/* <NavItems items={['classifications', 'lobPlans']} /> */}
            {
              loading ? <Loading color='orange'/> :
              <div>
                <BootstrapTable
              class="my-table"
              keyField='id'
              data={ data }
              columns={ columns }
              placeHolder={ false }
              filter={ filterFactory() }
              pagination={ paginationFactory(
                {
                  sizePerPageList: ([15, 20, 25, 50, 100]),
                  style: {
                    fontSize: '50px'
                  }
                }
              )}
            />

                {showPartnerClassificationForm ? <PartnerClassificationForm
                                        openedPartnerPlatform={this.props.openedPartnerPlatform}
                                        openedPartnerClassification={openedPartnerClassification}
                                        showNotificationPanel={this.showNotificationPanel}
                                        onToggleNewPackage={this.handleToggleNewPackage}
                                        packageListOpen={packageListOpen}
                                        closePartnerClassificationFormPane={this.togglePartnerClassificationForm}
                /> : null }
                { showPartnerLobPlanForm ? <PartnerLobPlanForm
                                            partnerPlatformId={this.props.openedPartnerPlatform}
                                            openedPartnerLobPlan={openedPartnerLobPlan}
                                            showNotificationPanel={this.showNotificationPanel}
                                            closePartnerLobPlanFormPane={this.togglePartnerLobPlanForm}
                                        /> : <></>}
                { showPartnerPackageForm ? <PartnerPackageForm
                                            partnerClassificationId={openedPartnerPackage.partnerClassificationId}
                                            openedPackageId={openedPartnerPackage.openedPackageId}
                                            closePartnerPackageFormPane={this.togglePartnerPackageForm}
                                            showNotificationPanel={this.showNotificationPanel}
                                            onTogglePackageExtra={this.handleTogglePackageExtra}
                                            packageExtraOpen={packageExtraOpen}
                                        /> : <></>}
                {showPartnerPackageExtraForm ? <PartnerPackageExtraForm
                                        openedPartnerPackage={openedPartnerPackageExtra}
                                        openedPartnerPackageExtra={openedPartnerPackageExtra}
                                        showNotificationPanel={this.showNotificationPanel}
                                        closePartnerPackageExtraFormPane={this.togglePartnerPackageExtraForm}
                                        /> : null}
              </div>
            }

      </div>
    );
  }
}

export default PartnerPlatformTabs;
