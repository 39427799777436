import Cookie from 'js-cookie';
import {
  FETCH_DEFAULT_PLANS,
  FETCH_DEFAULT_PACKAGES,
  FETCH_DEFAULT_CLASSIFICATIONS,
  LOADING_CLASSIFICATION_FORM,
  RESET_CLASSIFICATION_FORM,
  RESET_PACKAGE_FORM,
  LOADING_PACKAGE_FORM,
  RESET_PLAN_FORM,
  LOADING_PLAN_FORM,
  LOADING_CHANNEL_FORM,
  FETCH_DEFAULT_PACKAGE_TYPES,
  FETCH_DW_CLASSIFICATIONS,
  FETCH_ENABLED_PACKAGES,
  FETCH_DEFAULT_PACKAGE_EXTRAS,
  FETCH_DEFAULT_PACKAGE_BASE_PACKS,
  FETCH_SHIPPING_METHODS,
} from '../ActionType';
import { fetchAClassificationAfter } from './Classification';
import { fetchAPackageAfter } from './Package';
import { fetchAFreemiumAfter } from './Freemium';
import { fetchAPlanAfter } from './Plan';
import { fetchAChannelAfter } from './Channel';
import { fetchAnIapClassificationAfter } from './IapClassification';
import { fetchPackageToAdd } from "./PartnerPackage";
import {signOut} from '../helper';

export const fetchDefaultPlans = (id, typeOfCall) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}default-plans`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(defaultPlans => {
      if (defaultPlans.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      if (typeOfCall === 'planEdit') {
        dispatch(fetchAPlanAfter(id))
      } else if (typeOfCall === 'planCreate') {
        dispatch({type: RESET_PLAN_FORM})
        dispatch({type: LOADING_PLAN_FORM})
      }
      else {
        // dispatch(fetchDefaultPackages(id, typeOfCall))
      }
      dispatch({
        type: FETCH_DEFAULT_PLANS,
        payload: {defaultPlans}
      });
    })
  }
}

export const fetchDefaultPackages = (id, typeOfCall) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}default-packages`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(defaultPackages => {
      if (defaultPackages.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      if (typeOfCall === 'classificationEdit') {
        dispatch(fetchAClassificationAfter(id))
      } else {
        dispatch({type: RESET_CLASSIFICATION_FORM})
        dispatch({type: LOADING_CLASSIFICATION_FORM})
      }
      dispatch({
        type: FETCH_DEFAULT_PACKAGES,
        payload: {defaultPackages}
      });
    })
  }
}

export const fetchDefaultClassifications = (id, typeOfCall) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}default-classifications`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(defaultClassifications => {
      if (defaultClassifications.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      if (typeOfCall === 'packageEdit') {
        dispatch(fetchDefaultPackageTypes())
        dispatch(fetchShippingMethods())
        dispatch(fetchAPackageAfter(id))
      } else if (typeOfCall === 'freemiumEdit') {
        dispatch(fetchDefaultPackageTypes())
        dispatch(fetchAFreemiumAfter(id))
      } else if (typeOfCall === 'channelEdit') {
        dispatch(fetchAChannelAfter(id))
      } else if (typeOfCall === 'channelCreate') {
        dispatch({type: LOADING_CHANNEL_FORM, payload: false})
      } else if (typeOfCall === 'iapClassificationEdit') {
        dispatch(fetchAnIapClassificationAfter(id))
      } else if (typeOfCall === 'partnerClassification') {
        dispatch(fetchPackageToAdd(id))
      }
      else {
        dispatch(fetchDefaultPackageTypes())
        dispatch({type: RESET_PACKAGE_FORM})
        dispatch({type: LOADING_PACKAGE_FORM})
      }
      dispatch({
        type: FETCH_DEFAULT_CLASSIFICATIONS,
        payload: {defaultClassifications}
      })
    })
  }
}

export const fetchDefaultPackageTypes = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}package-types`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(defaultPackageTypes => {
      if (defaultPackageTypes.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      dispatch({
        type: FETCH_DEFAULT_PACKAGE_TYPES,
        payload: {defaultPackageTypes}
      });
    })
  }
}

export const fetchShippingMethods = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}packages/shipping-methods`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
        .then(response => response.json())
        .then(defaultShippingMethods => {
          if (defaultShippingMethods.exception === 'io.jsonwebtoken.ExpiredJwtException') {
            signOut()
          }
          dispatch({
            type: FETCH_SHIPPING_METHODS,
            payload: {defaultShippingMethods}
          });
        })
  }
}

export const fetchDWClassifications = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}enabled-classifications`;
    return fetch(URL, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(dwClassifications => {
        if (dwClassifications.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        dispatch({
          type: FETCH_DW_CLASSIFICATIONS,
          payload: {
            dwClassifications
          }
        });
      })
  }
}

export const fetchEnabledPackages = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}enabled-packages`;
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(enabledPackages => {
      if (enabledPackages.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      dispatch({
        type: FETCH_ENABLED_PACKAGES,
        payload: {
          enabledPackages
        }
      })
    })
  }
}

export const fetchDefaultPackageExtras = (packageId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}packages-extras/${packageId}`
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        // Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(defaultPackageExtrasInit => {
      if (defaultPackageExtrasInit.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let sortedData = _.sortBy(defaultPackageExtrasInit, 'priority', 'partnerPackageExtrasId');
      let defaultPackageExtras = [];
      for (const [i, val] of sortedData.entries()) {
        val.priority = i + 1;
        defaultPackageExtras.push(val)
      }
      dispatch({
        type: FETCH_DEFAULT_PACKAGE_EXTRAS,
        payload: {
          defaultPackageExtras
        }
      })
    })
  }
}

export const fetchPackageBase = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}default-packages`
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(defaultPackageBasePacks => {
      if (defaultPackageBasePacks.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      dispatch({
        type: FETCH_DEFAULT_PACKAGE_BASE_PACKS,
        payload: {
          defaultPackageBasePacks
        }
      })
    })
  }
}
