import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchMoreObjects, createMoreObjects} from '../../../../scripts/redux/actions';
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';
import Pagination from "react-js-pagination";
import { PACKAGE_TYPE_BASE, PACKAGE_TYPE_RSN } from "../Constants.js"

class MorePane extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      isPaneOpen: false,
      isPaneOpenLeft: false,
      objects: [],
      displayingObjects: [],
      selectedObjects: [],
      loading: true,
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: props.objects.length,
      pageRangeDisplayed: 10,
      from: 0,
      to: 20,
      pageSelect: {},
    }
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    const {existingObjects, apiPath} = this.props;
    this.props.fetchMoreObjects(existingObjects, apiPath)
    this.setState({ isPaneOpen: true });
  }

  componentWillReceiveProps(nextProps) {
    const {objects, displayingObjects, loading, statusCode} = nextProps;

    const totalItemsCount = objects.length;
    let {itemsCountPerPage, pageSelect} = this.state
    const numberOfPages = Math.ceil(totalItemsCount / itemsCountPerPage);

    Array.from(Array(numberOfPages)).forEach((x, i) => {
      pageSelect[i + 1] = false
    });
    this.setState({objects, displayingObjects, loading, totalItemsCount, pageSelect})

    if (statusCode === "Success" || statusCode === "SUCCESS") {
      this.props.closeMorePane(true)
    }
  }

  search(val) {
    const {objects} = this.state
    let displayingObjects = objects.filter(c => (c.identifier.toLowerCase().search(val.toLowerCase()) !== -1) || (c.name.toLowerCase().search(val.toLowerCase()) !== -1))
    this.setState({displayingObjects})
  }

  toggleCheckbox(val) {
    val.checked = !val.checked
    this.setState({ ...this.state.displayingObjects, val})
    this.setState({ ...this.state.objects, val})
  }

  submit(e) {
    e.preventDefault()
    let selectedObjects = this.state.objects.filter((obj) => obj.checked)
    let {type, openedPackage, packageType, currentClassification} = this.props
    let params = _.map(selectedObjects, _.partial(_.pick, _, ['id']));
    params = _.map(params, o => _.extend({packageId: openedPackage, classificationId: currentClassification, packageType}, o));
    if (type === 'package')  {
      if (packageType === PACKAGE_TYPE_BASE) {
        params = JSON.stringify(params).replace(/"id":/g, `"extraId":`)
      } else if (packageType === PACKAGE_TYPE_RSN) {
        params = JSON.stringify(params).replace(/"id":/g, `"requiredPackageId":`)
      } else {
        params = JSON.stringify(params).replace(/"packageId":/g, `"extraId":`)
        params = params.replace(/"id":/g, `"packageId":`)
      }
    } else {
      params = JSON.stringify(params).replace(/"id":/g, `"${type}Id":`);
    }
    this.props.createMoreObjects(params, type, packageType)
  }

  onPageChange = (pageNumber) => {
    let from = (pageNumber-1) * 20
    let to = from + 20
    this.setState({from, to, activePage: pageNumber})
  }

  selectAll = () => {
    let {activePage, pageSelect} = this.state
    pageSelect[activePage] = !pageSelect[activePage]
    this.setState({ pageSelect })
    const {displayingObjects, from, to} = this.state    
    displayingObjects.slice(from, to).map((value) => {
      return this.toggleCheckbox(value)
    })
  }

  render() {
    const { isPaneOpen, displayingObjects, loading, activePage, itemsCountPerPage,
            totalItemsCount, pageRangeDisplayed, from, to, pageSelect
    } = this.state;
    return (
      <div ref={ref => this.el = ref} className='more-pane'>
        <SlidingPane
          style={{marginLeft: '20%',zIndex: '99999'}}
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title={'Add more ' + this.props.type + 's'}
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closeMorePane();
          }}>
          <div className="search-section" style={{'zIndex': '0'}}>
            <div className='aaa'>
              <input onKeyUp={(e)=>this.search(e.target.value)}
                     type="search" placeholder="What are you looking for?"
                     style={{width:'35vw'}}/>
            </div>
          </div>
          {
            loading ? <Loading /> :
            <Form style={{marginTop:'50px'}}>
              <FormGroup check>
                <Input
                  checked={pageSelect[activePage]}
                  name="selectAll"
                  type="checkbox"
                  value='all'
                  onClick={()=> this.selectAll(activePage)}
                  style={{marginTop:'5px'}}
                />&nbsp;&nbsp;
                <Label check><span style={{fontSize:'15px'}}>Select All</span></Label>
              </FormGroup>
              {
                displayingObjects.slice(from, to).map((value, index) => (
                  <FormGroup check key={index}>
                    <Input
                      checked={value.checked}
                      name={value.identifier}
                      type="checkbox"
                      value={value.id}
                      onClick={()=> this.toggleCheckbox(value)}
                      style={{marginTop:'5px'}}
                    />&nbsp;&nbsp;
                    <Label check>
                      <span style={{fontSize:'15px'}}>{value.name}</span>
                      <span style={{color:'black',fontSize:'14px'}}> - {value.identifier}</span>
                    </Label>
                  </FormGroup>
                ))
              }
              <Pagination activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              pageRangeDisplayed={pageRangeDisplayed}
              onChange={this.onPageChange}
              />
              <button className="btn btn-primary"
                      style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}
                      onClick={(e) => this.submit(e)}>
                      Submit
              </button>
            </Form>
          }
        </SlidingPane>
      </div>);
  }
}

const mapStateToProps = ({MoreObjects, FlashMessageData}) => {
  return {
    objects: MoreObjects.objects,
    displayingObjects: MoreObjects.displayingObjects,
    loading: MoreObjects.loading,
    statusCode: FlashMessageData.statusCode,
  };
};

export {MorePane};

export default connect(mapStateToProps, {
  fetchMoreObjects,
  createMoreObjects,
})(MorePane);
