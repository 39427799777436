import Cookie from 'js-cookie';
import {
  FETCH_IAP_CLASSIFICATIONS,
  SET_NOTIFICATION_PANEL,
  LOADING_IAP_CLASSIFICATION_FORM,
  FETCH_AN_IAP_CLASSIFICATION,
  RESET_IAP_CLASSIFICATION_FORM,
} from '../ActionType';
import {fetchDefaultClassifications} from './DefaultDataCalls';
import {signOut} from '../helper';

export const fetchAllIapClassications = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}iap-classifications`;
    return fetch(URL, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let iapClassifications = json;
        dispatch({
          type: FETCH_IAP_CLASSIFICATIONS,
          payload: {
            iapClassifications
          }
        });
      })
      .catch(error => {
        console.log('fetchAllIapClassications Error - ', error);
      })
  }
}

export const fetchAnIapClassification = (id = null) => {
  let typeOfCall, editIapClassification = false;
  if (id) {
    typeOfCall = 'iapClassificationEdit'
    editIapClassification = true
  }
  return (dispatch) => {
    dispatch({type: LOADING_IAP_CLASSIFICATION_FORM})
    dispatch(fetchDefaultClassifications(id, typeOfCall))
    if (!editIapClassification) {
      dispatch({type: RESET_IAP_CLASSIFICATION_FORM})
      dispatch({type: LOADING_IAP_CLASSIFICATION_FORM})
    }
  }
}

export const fetchAnIapClassificationAfter = (id) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}iap-classifications/${id}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        identifier, classificationId, enabled, priority
      } = json;
      dispatch({
        type: FETCH_AN_IAP_CLASSIFICATION,
        payload: {
          identifier, classificationId, enabled, priority
        }
      })
    })
  }
}

export const createAnIapClassification = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-iap-classification`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllIapClassications())
    })
    .catch(error => {
      console.log('createAnIapClassification Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const toggleIapClassification = ({iapClassificationId, value}) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}disable-iap-classification/${iapClassificationId}/${value}`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllIapClassications())
    })
  }
}

