import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAPPVPackage, createUpdatePPVPackage } from '../../../../scripts/redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';

class PPVForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
      Modal.setAppElement(this.el);
  }

  componentWillReceiveProps(nextProps) {
    const {
      tvodGuid, startDate, endDate, imageSmUrl,
      imageLgUrl, loadingAPPV,
    } = nextProps
    const _startDate = startDate ? startDate.replace(/.000Z/,''): '';
    const _endDate = endDate ? endDate.replace(/.000Z/,''): '';
    
    this.setState({
      tvodGuid, startDate: _startDate, endDate: _endDate, imageSmUrl,
      imageLgUrl, loadingAPPV,
    });
    
    if (nextProps.statusCode === "SUCCESS") {
      this.props.closeEditPPVPane(null, true)
    }  
    if(nextProps.PPVId){
      this.props.fetchAPPVPackage(nextProps.PPVId)
    }
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    let {
      tvodGuid, startDate, endDate, imageSmUrl,
      imageLgUrl, modalEnabled,
    } = this.state
    let packageId = this.props.PPVId
    const _startDate = startDate.replace("T", " ").concat(":00")
    const _endDate = endDate.replace("T", " ").concat(":00")
    let params = {
      tvodGuid, startDate:_startDate, endDate:_endDate, imageSmUrl,
      imageLgUrl, modalEnabled, packageId,
    };
    this.props.createUpdatePPVPackage(params)
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    const {
      tvodGuid, startDate, endDate, imageSmUrl, imageLgUrl,
      modalEnabled
    } = this.state;
    
    return (
      <div ref={ref => this.el = ref} className='channel-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={this.props.showPPVPackageForm}
          title='Pay Per View Form'
          onRequestClose={() => {
            this.props.closeEditPPVPane(null, true)
          }}>
          <Form onSubmit={(e) => {this.handleSubmit(e)}}>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="tvodGuid" id="tvodGuid" placeholder="Tvod Guid" onChange={(e) => this.handleChange('tvodGuid', e.target.value)} value={tvodGuid || ''}/>
                <Label for="tvodGuid">Tvod Guid</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="datetime-local" name="startDate" id="startDate" placeholder="Start Date" onChange={(e) => this.handleChange('startDate', e.target.value)} value={startDate || ''}/>
        <Label for="startDate">Start Date</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="datetime-local" name="endDate" id="endDate" placeholder="End Date" onChange={(e) => this.handleChange('endDate', e.target.value)} value={endDate || ''}/>
                <Label for="endDate">End Date</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="imageSmUrl" id="imageSmUrl" placeholder="Image Small Url" onChange={(e) => this.handleChange('imageSmUrl', e.target.value)} value={imageSmUrl || ''}/>
                <Label for="imageSmUrl">Image Small Url</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="imageLgUrl" id="imageLgUrl" placeholder="Image Large Url" onChange={(e) => this.handleChange('imageLgUrl', e.target.value)} value={imageLgUrl || ''}/>
                <Label for="imageLgUrl">Image Large Url</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12 form-checkbox">
              <Input type="checkbox" name="modalEnabled" id="modalEnabled" checked={modalEnabled} onChange={(e) => this.handleChange('modalEnabled', !modalEnabled)} />{' '}
              <Label check className="text-center">
                <Label for="modalEnabled">Modal Enabled</Label>
              </Label>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <button className="btn btn-primary"
                      style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                      Submit
              </button>
            </FormGroup>
          </Form>
          <br />
        </SlidingPane>
      </div>);
  }

}


const mapStateToProps = ({payPerViewData, FlashMessageData}) => {
  return {
    tvodGuid: payPerViewData.tvodGuid,
    startDate: payPerViewData.startDate,
    endDate: payPerViewData.endDate,
    imageSmUrl: payPerViewData.imageSmUrl,
    imageLgUrl: payPerViewData.imageLgUrl,
    modalEnabled: payPerViewData.modalEnabled,
    loadingAPPV: payPerViewData.loadingAPPV,
    statusCode: FlashMessageData.statusCode,
  };
}

export {PPVForm};

export default connect(mapStateToProps, {
  fetchAPPVPackage,
  createUpdatePPVPackage,
})(PPVForm);
