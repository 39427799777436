import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logo4.png';
import Cookie from 'js-cookie';
import { refreshToken } from '../../../redux/actions';
import { connect } from 'react-redux';

const Sidebar = (props) => {
	const { sidebarItems, name } = props

	const isSelected = (navItem) => {
		return window.location.pathname === navItem.pathname ? 'selected' : '';
	}

	const signOut = () => {
		Cookie.remove('token');
		Cookie.remove('authtoken');
		window.location.reload();
	}
	const getToken = () => {
		props.refreshToken();
		let tokenExpire;
		clearTimeout(tokenExpire);
		tokenExpire = setTimeout(() => getToken(), 860000);
	}

	if (Cookie.get('token')) {
		getToken();
	}
	return (
		<div style={{ height: '100%' }}>
			<li className='al-sidebar-list-item' style={{ marginBottom: '25px' }}>
				<Link to={{ pathname: '/pc' }} className="al-logo clearfix"><img alt="logo" src={logo} className="profile" /></Link>
			</li>
			{
				_.map(sidebarItems, (navItem) => {
					return (
						<li className={`al-sidebar-list-item ${isSelected(navItem)} ${name ? "width-50" : ""}`} key={navItem.pathname}>
							<Link className="al-sidebar-list-link" to={{ pathname: navItem.pathname, query: navItem.query }}>
								<i className={`fa fa-${navItem.icon}`}></i>
								<span>{navItem.label}</span>
							</Link>
						</li>
					)
				})
			}
			<li className='al-sidebar-list-item' style={{ position: 'absolute', bottom: '0' }} onClick={() => signOut()}>
				<Link to='#' className="al-sidebar-list-link">
					<i className='fa fa-sign-out'></i>
					<span>Sign Out</span>
				</Link>
			</li>
		</div>
	)
}
const mapDispatchToProps = {
  refreshToken,
 };

export default connect(null, mapDispatchToProps)(Sidebar);


