import React from "react";
import {connect} from 'react-redux';
import {fetchAllNewTranlsations, fetchEnvStatus} from '../../../../scripts/redux/actions';
import "react-table/react-table.css";
import TranslationsMgrForm  from '../TranslationForm/TranslationsMgrForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {  selectFilter, textFilter  } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loading from '../../Common/Loading/Loading.js';
import './TranslationsMgr.css';


class TranslationsMgr extends React.Component {
  constructor() {
    super();
    this.state = {
      newTranslations: [],
			newDisplayingTranslations: [],
			envsToDisplay: [],
      showTranslationForm: false,
      showNotification: '',
      statusCode: '',
      statusMessage: '',
      loading: true,
      localLoader: false,
      key: '',
      locale: '',
      application: '',
      dev: {},
      qa: {},
      beta: {},
      prod: {},
      selectedBaseEnv:"",
      selected: [-1],
      localeFilterOptions:[],
    };
    this.searchKey = '';
    this.searchTranslation = '';
    this.renderEditable = this.renderEditable.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.search = this.search.bind(this);
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
    this.closeTranslationForm = this.closeTranslationForm.bind(this)
  }
  
  componentWillMount() {
    this.props.fetchAllNewTranlsations();
    this.props.fetchEnvStatus();
	}

  refresh(){
    this.setState({localLoader: true});
    this.props.fetchAllNewTranlsations().then(res =>  this.setState({localLoader: false}))
  }

  componentWillReceiveProps(nextProps) {
    const {newTranslations, newDisplayingTranslations, loading, envsToDisplay} = nextProps
    this.setState({newTranslations, newDisplayingTranslations, loading, envsToDisplay});
    let localeFilterOptions = [...new Set(newTranslations.map(item => item.locale))];
    localeFilterOptions = localeFilterOptions.map(filter => { return {"value": filter, "label": filter} });
    localeFilterOptions.splice(0, 0, {"value": "all", "label": "Show All"});
    this.setState({localeFilterOptions});
  }

  async reconcileUpdatedTranslations(params){
    let { newDisplayingTranslations } = this.state;
    let updatedTranslation = {
      "dev": {},
      "qa": {},
      "beta": {},
      "prod": {}
    };
    setTimeout(500);
    if(params.duplicateKey){
      this.setState({localLoader: true})
      let myParams = {};
      myParams.key = params.key;
      myParams.application = params.application;
      myParams.locale = params.locale;
      let data = await this.props.fetchAllNewTranlsations(myParams)
      let foundRecords = newDisplayingTranslations.filter(translation => (params.key).toLowerCase() === (translation.key).toLowerCase() && params.locale === translation.locale && params.application === translation.application);
      let targetRecordsToBeDeleted = foundRecords.filter(rec => rec.key !== params.key);
      for(const rec of targetRecordsToBeDeleted){
        let targetDeletionRecordIndex = newDisplayingTranslations.findIndex(translation => rec.key === translation.key && params.locale === translation.locale && params.application === translation.application);
        targetDeletionRecordIndex !== -1 && newDisplayingTranslations.splice(targetDeletionRecordIndex , 1);
      }
      for(const rec of data){
        let impactedTargetDeletionRecordIndex1 = newDisplayingTranslations.findIndex(translation => rec.key === translation.key && params.locale === translation.locale && params.application === translation.application);
        impactedTargetDeletionRecordIndex1 !== -1 && newDisplayingTranslations.splice(impactedTargetDeletionRecordIndex1 , 1);
        newDisplayingTranslations.push(rec);
      }
      this.setState({localLoader: false})
    } else {
    let foundIndex = newDisplayingTranslations.findIndex(translation => params.key === translation.key && params.locale === translation.locale && params.application === translation.application);
    console.log(foundIndex,'foundIndex')
    if( foundIndex === -1 ) {
      // Create a new record
      foundIndex = newDisplayingTranslations.length + 1;
    } 
      updatedTranslation.dev.id = params.devId;
      updatedTranslation.qa.id = params.qaId;
      updatedTranslation.beta.id = params.betaId;
      updatedTranslation.prod.id = params.prodId;
      updatedTranslation.key = params.key;
      updatedTranslation.locale = params.locale;
      updatedTranslation.value = params.prodValue;
      updatedTranslation.application = params.application;
      updatedTranslation.dev.value = params.devValue;
      updatedTranslation.dev.updated_at = params.devUpdatedAt;
      updatedTranslation.qa.value = params.qaValue;
      updatedTranslation.qa.updated_at = params.qaUpdatedAt;
      updatedTranslation.beta.value = params.betaValue;
      updatedTranslation.beta.updated_at = params.betaUpdatedAt;
      updatedTranslation.prod.value = params.prodValue;
      updatedTranslation.prod.updated_at = params.prodUpdatedAt;
      if((new Set([params.devValue,params.qaValue,params.betaValue, params.prodValue])).size === 1){
        updatedTranslation.sync = true;
      } else {
        updatedTranslation.sync = false;
      }
      newDisplayingTranslations[foundIndex] = updatedTranslation;
      console.log(newDisplayingTranslations[foundIndex],'newDisplayingTranslations[foundIndex]')
    }
      
      this.setState({newDisplayingTranslations});
      var el = document.querySelector("#text-filter-column-key");
      this.search(el.value, newDisplayingTranslations)
  }

  renderEditable(cellInfo) {
    return (
      <div className="translation-input text-left">
        {this.state.newDisplayingTranslations[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  updateState(cellInfo, value) {
    const {newDisplayingTranslations} = this.state;
    newDisplayingTranslations[cellInfo.index][cellInfo.column.id] = value;
    newDisplayingTranslations[cellInfo.index]['edited'] = true
    this.setState({ newDisplayingTranslations });
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => {this.setState({showNotification: false})}, 3000);
  }

  updateTranslation(params, selectedEnv) {
    if(selectedEnv){
      this.setState({selectedBaseEnv:selectedEnv})
    }
    let {dev, qa, beta, prod, locale, key, application} = params
    this.setState({ showTranslationForm: true, dev, qa, beta, prod, key, locale, application})
  }

  closeTranslationForm(getTranslations=false, params) {
    if(getTranslations){
      // this.setState({localLoader: true})
      // this.props.fetchAllNewTranlsations().then(res =>  this.setState({localLoader: false}))
      this.reconcileUpdatedTranslations(params);
    }
    this.setState({showTranslationForm: false, key: '', locale: '', application: '', selectedBaseEnv: '', dev: {},qa: {},beta: {}, prod: {}})
    this.searchKey = '';
    this.searchTranslation = '';
  }

  renderButton(cell, row) {
    return (
      <div className="text-center">
        <i className="fa fa-pencil edit-icon" onClick={(e) => {this.updateTranslation(row)}}></i>
      </div>
    )
  }

  renderStatus(cell, row) {
    return(
      <div className="text-left">
        <button  disabled={this.state.envsToDisplay.includes("dev") ? true:false} onClick={(e) => this.updateTranslation(row,"dev")} className={row.dev && row.dev.value ? "env-indication-btn" : this.state.envsToDisplay.includes("dev") ? "env-indication-btn grey down" : "env-indication-btn grey"}>Dev</button>
        <button  disabled={this.state.envsToDisplay.includes("qa") ? true:false}  onClick={(e) => this.updateTranslation(row,"qa")} className={row.qa && row.qa.value ?  "env-indication-btn" : this.state.envsToDisplay.includes("qa") ? "env-indication-btn grey down" : "env-indication-btn grey"}>Qa</button>
        <button  disabled={this.state.envsToDisplay.includes("beta") ? true:false} onClick={(e) => this.updateTranslation(row,"beta")} className={row.beta && row.beta.value ? "env-indication-btn" : this.state.envsToDisplay.includes("beta") ? "env-indication-btn grey down" : "env-indication-btn grey" }>Beta</button>
        <button  disabled={this.state.envsToDisplay.includes("prod") ? true:false} onClick={(e) => this.updateTranslation(row,"prod")} className={row.prod && row.prod.value ? "env-indication-btn" : this.state.envsToDisplay.includes("prod") ? "env-indication-btn grey down" : "env-indication-btn grey"}>Prod</button>
      </div>
    )
  }

  onChange(key, value){
    // this.setState({locale:value})
  }

  
	search(filterVal, data) {
		// let {newTranslations} = this.state;
    // let newDisplayingTranslations = newTranslations.filter(translation => translation.key.search(val.toLowerCase()) !== -1)
    // console.log(newDisplayingTranslations,'DISPLAYING TRANSLATIONS')
		// this.setState({newDisplayingTranslations});
    if (filterVal) {
      return data.filter(translation => (translation.key).toLowerCase().search(filterVal.toLowerCase()) !== -1);
    }
    return data;
  }

  render() {
    const {
      showNotification, statusCode, statusMessage, newDisplayingTranslations, envsToDisplay, showTranslationForm, locale, key, dev, qa, beta, prod, application, selectedBaseEnv, loading, localLoader
    } = this.state;
    const applications = {
      'catalog': 'catalog',
      'ottweb': 'ottweb'
    };
    const locales = {
      'en': 'en',
      'es': 'es',
      'pt-BR': 'pt-BR',
      'hi': 'hi',
      'pa': 'pa',
      'gu': 'gu'
    };
    const translations = {
      false: 'differences'
    }
    const columns = [
      {
        headerStyle: () => {
          return { width: '6vw', textAlign: 'left' };
        },
        text: "App",
        dataField: "application",
        sort: true,
        filter: selectFilter({
          options: applications,
          placeholder: 'Show All',
          defaultValue: 'catalog'
        })
      },
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'left' };
        },
        text: "Locale",
        dataField: "locale",
        sort: true,
        filter: selectFilter({
          options: locales,
          placeholder: 'Show All',
          defaultValue: 'en',
        })
      },
      {
        headerStyle: () => {
          return { width: '30vw', textAlign: 'left' };
        },
        text: "Key",
        dataField: "key",
        sort: true,
        // filter: textFilter({ placeholder: ' ', delay: 0 })
        filter: textFilter({
          onFilter: this.search
        })
      },

      {
        headerStyle: () => {
          return { width: '35vw', textAlign: 'left' };
        },
        text: "Translations",
        dataField: "sync",
        sort: true,
        formatter: this.renderStatus,
        filter: selectFilter({
          options: () => translations,
          placeholder: "Show All"
        }),
      },
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'center' };
        },
        text: "Edit",
        dataField: " ",
        formatter: this.renderButton
      }
    ];
    return (
      <div>
        {showNotification && <FlashMessage statusCode={statusCode} statusMessage={statusMessage} />}
        <div className='translation-header'>
          {!loading
          ?<div className='new-translation-div'>
            <button className="btn btn-primary new-translation-btn"
                    onClick={()=> this.setState({ showTranslationForm: true, key: this.searchKey})}>
            <i className="fa fa-plus"></i> New Translation
            </button>
            <div className="text-center refresh" onClick={()=> this.refresh()}>
              <i className="fa fa-refresh black"></i>
            </div>
          </div>
          : ''}
        </div>
        <div className='translation-content'>
          {
          loading ?
          <div className="loading"><Loading/><h3 className="text-center">Please wait, translations are loading...</h3> </div>
          :
          <div>
            <div>
              {envsToDisplay.length > 0 ? envsToDisplay.map(env => {return <p className="env-display">{env} is down</p>}) : ""}
            </div>
            <BootstrapTable
              class="my-table"
              keyField='id'
              data={ newDisplayingTranslations }
              columns={ columns }
              placeHolder={ false }
              filter={ filterFactory() }
              pagination={ paginationFactory(
                {
                  sizePerPageList: ([15, 20, 25, 50, 100]),
                  style: {
                    fontSize: '50px'
                  }
                }
              )}
            />
             {localLoader ? <div className="localLoading">Loading&#8230;</div> : '' }
            <br />
            { showTranslationForm
            ? <TranslationsMgrForm
                locale={locale}
                tkey={key}
                devValue={dev}
                qaValue={qa}
                betaValue={beta}
                prodValue={prod}
                application={application}
                closeTranslationFormPane={this.closeTranslationForm}
                showNotificationPanel={this.showNotificationPanel}
                baseEnv={selectedBaseEnv}
                downEnvs={envsToDisplay}/>
            : null }
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({translationsData, FlashMessageData}) => {
  return {
    newTranslations: translationsData.newTranslations,
    newDisplayingTranslations: translationsData.newDisplayingTranslations,
    envsToDisplay: translationsData.envsToDisplay,
    loading: translationsData.loading,
    showNotificationPanel: FlashMessageData
  }
}
export default connect(mapStateToProps, {
  fetchAllNewTranlsations,
  fetchEnvStatus
})(TranslationsMgr);
