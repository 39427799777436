import {
  FETCH_DMACODESZIPCODES,
  RESET_DMACODESZIPCODE_FORM,
  FETCH_A_DMACODESZIPCODE,
  EDIT_DMACODESZIPCODE,
  LOADING_DMACODESZIPCODE_FORM,
  FETCH_DMACODES,
  FETCH_ZIPCODES,
  UPDATE_DMACODESZIPCODES_STATE
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  dmaCodesZipcodes: [],
  displayingDmaCodesZipcodes: [],
  dmaCodes: [],
  zipCodes: [],
  loading: true,
  // Particular DmaCodesZipcode
  dmaCode: {},
  zipcode: {},
  id: null,
  ota_qual: 'indoor',
  ontech_qual: false,
  locast_qual: false,
  loadingADmaCodesZipcode: false,
  editDmaCodesZipcode: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DMACODESZIPCODES:
      let {dmaCodesZipcodes} = action.payload
      return {...state, dmaCodesZipcodes, displayingDmaCodesZipcodes: dmaCodesZipcodes, loading: false};
    case FETCH_A_DMACODESZIPCODE:
      let { id, otaQual, ontechQual, locastQual, dmaCode, zipcode } = action.payload
      return { ...state, id, otaQual, ontechQual, locastQual, dmaCode, zipcode, loadingAChannel: false };
    case LOADING_DMACODESZIPCODE_FORM:
      return {...state, loadingADmaCodesZipcode: action.payload};
    case RESET_DMACODESZIPCODE_FORM:
      let emptyDmaCodesZipcodeForm = _.omit(INITIAL_STATE, ['dmaCodesZipcodes', 'displayingDmaCodesZipcodes','loading', 'loadingADmaCodesZipcode']);
      return {...state, ...emptyDmaCodesZipcodeForm};
    case EDIT_DMACODESZIPCODE:
      let {editDmaCodesZipcode} = action.payload;
      return {...state, editDmaCodesZipcode};
    case FETCH_DMACODES:
      let {dmaCodes} = action.payload;
      return {...state, dmaCodes};
    case FETCH_ZIPCODES:
      let {zipCodes} = action.payload;
      return {...state, zipCodes};
    case UPDATE_DMACODESZIPCODES_STATE:
      let { dma_code_zipcode } = action.payload;
      let dmaCodesZipcodesCopy = state.dmaCodesZipcodes.find(
        (dcz) => dcz.id === dma_code_zipcode.id
      );
      dmaCodesZipcodesCopy.otaQual = dma_code_zipcode.ota_qual === 'no_qual' ? 'none' : dma_code_zipcode.ota_qual;
      dmaCodesZipcodesCopy.ontechQual = dma_code_zipcode.ontech_qual;
      dmaCodesZipcodesCopy.locastQual = dma_code_zipcode.locast_qual;
      let updatedDmaCodesZipcodes = [
        ...state.dmaCodesZipcodes,
        dmaCodesZipcodesCopy,
      ];
      return { ...state, dmaCodesZipcodes: updatedDmaCodesZipcodes };
    default:
      return state;
  }
}
