import {
  FETCH_PACKAGES_BY_CLASSIFICATION,
  LOADING_PACKAGE_FORM,
  FETCH_A_PACKAGE,
  RESET_PACKAGE_FORM,
  EDIT_PACKAGE,
  PACKAGE_PARTNER_PACKAGES,
  FETCH_ALL_PARTNER_PLATFORM,
  FETCH_PACKAGE_IAU_ENABLE_STATUS,
  FETCH_PARTNER_PLATFORM_IDS_BY_PACKAGEID,
  FETCH_DEFAULT_SHIPPING_METHODS
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  packages: [],
  displayingPackages: [],
  loading: true,
  // Particular Package
  identifier: '',
  name: {},
  description: {},
  description_1: {},
  description_2: {},
  description_3: {},
  guid: '',
  sku: '',
  priority: 1,
  defaultClassificationId: 17,
  packageType: 0,
  shippingMethods: [],
  amount: 0,
  promo: false,
  status: '',
  enabled: false,
  extraType: '',
  migratedTo: 0,
  dvrRequired: false,
  appsSupported: [
                  {appName: "icepak", isSupported: false},
                  {appName: "ottweb", isSupported: false},
                  {appName: "fakeblock", isSupported: false}
                ],
  dwClassification: 17,
  dwlob: 'domestic',
  packageName: '',
  loadingAPackage: false,
  editPackage: false,
  defaultPackagePartnerPackages: [],
  allPartnerPlatform: [],
  partnerPlatformIdsByPackageId: [],
  iauEnabledStatusPlanPackage: false,
  iauEnabledStatusPackageExtra: false,
  otaQualOutdoor: false,
  otaQualIndoor: false,
  otaQualNone: false,
  imageUrls: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PACKAGES_BY_CLASSIFICATION:
      let {packages, displayingPackages} = action.payload
      return {...state, packages, displayingPackages, loading: false};
    case FETCH_A_PACKAGE:
      let {
        identifier, guid, sku, priority, defaultClassificationId,
        packageType, amount, promo, status, enabled, migratedTo,
        appsSupported, name, extraType, description, dvrRequired,
        otaQualOutdoor, otaQualIndoor, otaQualNone, description_1,
        description_2, description_3, imageUrls
      } = action.payload
      return {
        ...state, identifier, guid, sku, priority, defaultClassificationId,
        packageType, amount, promo, status, enabled, dvrRequired, migratedTo,
        appsSupported, name, extraType, description, loadingAPackage: false,
        otaQualOutdoor, otaQualIndoor, otaQualNone, description_1,
        description_2, description_3, imageUrls
      };
    case FETCH_DEFAULT_SHIPPING_METHODS:
      let { shippingMethods } = action.payload
      return {...state, shippingMethods }
    case LOADING_PACKAGE_FORM:
      return {...state, loadingAPackage: !state.loadingAPackage};
    case RESET_PACKAGE_FORM:
      let emptyPackageForm = _.omit(INITIAL_STATE, ['packages', 'displayingPackages','loading', 'loadingAPackage']);
      return {...state, ...emptyPackageForm, loadingAPackage: true };
    case EDIT_PACKAGE:
      let {editPackage} = action.payload;
      return {...state, editPackage};
    case PACKAGE_PARTNER_PACKAGES:
      let { defaultPackagePartnerPackages } = action.payload
      return {...state, defaultPackagePartnerPackages}
    case FETCH_ALL_PARTNER_PLATFORM:
      let { allPartnerPlatform } = action.payload
      return {...state, allPartnerPlatform}
    case FETCH_PACKAGE_IAU_ENABLE_STATUS:
      let { iauEnabledStatusPlanPackage, iauEnabledStatusPackageExtra } = action.payload.iauEnabledStatus;
      return {...state, iauEnabledStatusPlanPackage, iauEnabledStatusPackageExtra}
    case FETCH_PARTNER_PLATFORM_IDS_BY_PACKAGEID:
      let { partnerPlatformIdsByPackageId } = action.payload;
      return {...state, partnerPlatformIdsByPackageId}
    default:
      return state;
  }
}
