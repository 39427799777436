import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createOrUpdateAnOldTranslation, deleteAnOldTranslation } from '../../../../scripts/redux/actions'
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label } from 'reactstrap';

export class TranslationForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      locale: this.props.locale || "en",
      key: this.props.tkey,
      value: this.props.value,
      application: this.props.application || "catalog",
      id: this.props.id || undefined,
      newTranslation: this.props.newTranslation || false
    }
  }

  handleChange(key, value) {
    console.log(`Key - ${key}, val - ${value}`);
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.setState({
      isPaneOpen: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.statusCode === "SUCCESS") {
      this.props.closeTranslationFormPane(true)
      this.setState({ isPaneOpen: false })
    }
  }

  handleDelete(e) {
    if (e) {
      e.preventDefault();
    }
    const { id } = this.state
    var params = { id };
    this.props.deleteAnOldTranslation(params);
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const { locale, key, value, application, id } = this.state
    var params = { locale, key, value, application, id };
    this.props.createOrUpdateAnOldTranslation(params)
  }

  render() {
    const { locale, key, value, isPaneOpen, application, id, newTranslation } = this.state;
    const applications = [
      'catalog',
      'ottweb'
    ];
    const locales = [
      'en',
      'es',
      'pt-BR',
      'hi',
      'pa',
      'gu'
    ];

    return (
      <div ref={ref => this.el = ref} className='translation-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Translation Form'
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ isPaneOpen: false });
            this.props.closeTranslationFormPane()
          }}>
          <Form onSubmit={(e) => {this.handleSubmit(e)}}>
            <FormGroup className="col-sm-12">
              <div className="field">
                <select
                  key={id}
                  style={{ width: "20%", height: "20px" }}
                  defaultValue={application}
                  onChange={(e) => this.handleChange('application', e.target.value)}
                >
                  {applications.map(application =>
                    <option key={application} value={application}>{application}</option>
                  )};
                </select>
                <Label for="application">Application</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <select
                  key={id}
                  style={{ width: "20%", height: "20px" }}
                  defaultValue={locale}
                  onChange={(e) => this.handleChange('locale', e.target.value)}
                >
                  {locales.map(locale =>
                    <option key={locale} value={locale}>{locale}</option>
                  )};
                </select>
                <Label for="locale">Locale</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="key" id="key" placeholder="Key" onChange={(e) => this.handleChange('key', e.target.value)} value={key} required/>
                <Label for="key">Key</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="value" id="value" placeholder="Value" onChange={(e) => this.handleChange('value', e.target.value)} value={value} required/>
                <Label for="value">Value</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <button className="btn btn-primary"
                      style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                      Save
              </button>
              {!newTranslation && <button className="btn btn-danger"
                      type="button"
                      style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px', marginLeft: '10px'}}
                      onClick={(e) => {this.handleDelete()}}>
                      Delete
              </button>}
            </FormGroup>
          </Form>
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({FlashMessageData}) => {
  return {
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  createOrUpdateAnOldTranslation,
  deleteAnOldTranslation
})(TranslationForm);
