import Cookie from 'js-cookie';
import {
  FETCH_ALL_PARTNER_PLATFORMS,
  SET_NOTIFICATION_PANEL,
  LOADING_PARTNER_PLATFORM_FORM,
  FETCH_A_PARTNER_PLATFORM,
  RESET_PARTNER_PLATFORM_FORM,
  FETCH_A_PARTNER_CLASSIFICATION,
  RESET_PARTNER_CLASSIFICATION_FORM,
  LOADING_PARTNER_CLASSIFICATION_FORM,
} from '../ActionType';
// import _ from 'lodash';
import {
  signOut
} from '../helper';
import {
  fetchPartnerClassifications
} from './PartnerPackage';

export const fetchAllPartnerPlatforms = (forPPDropdown=false) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-platforms`;
    return fetch(URL, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          // Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(partnerPlatforms => {
        if (partnerPlatforms.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        dispatch({
          type: FETCH_ALL_PARTNER_PLATFORMS,
          payload: {
            partnerPlatforms
          }
        });
        if (forPPDropdown) {
          dispatch(fetchPartnerClassifications(partnerPlatforms[0].id, 'fromPPDropdown'))
        }
      })
      .catch(error => {
        console.log('fetchAllLOBs Error - ', error);
      })
  }
}

export const togglePartnerPlatform = ({
    partnerPlatformId,
    value
  }) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}disable-partner-platform/${partnerPlatformId}/${value}`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllPartnerPlatforms())
    })
  }
}

export const fetchAPartnerPlatform = (id) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PARTNER_PLATFORM_FORM })
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-platform/${id}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        id, partner, platform, enabled, hasDoorways, hasPartnerSkus
      } = json.data.partner_platforms;
      dispatch({
        type: FETCH_A_PARTNER_PLATFORM,
        payload: {
          id, partner, platform, enabled, hasDoorways, hasPartnerSkus
        }
      })
    })
  }
}

export const createAPartnerLobPlan = (params) => {
return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-line-of-business-plans`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('createAPartnerPlatform Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const updateAPartnerLobPlan = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}line-of-business-plans`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('updateAPartnerLobPlan Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const createAPartnerPlatform = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-partner-platform`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllPartnerPlatforms())
    })
    .catch(error => {
      console.log('createAPartnerPlatform Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const resetPartnerPlatformForm = () => {
  return (dispatch) => {
    return dispatch({
      type: RESET_PARTNER_PLATFORM_FORM
    })
  }
}

export const fetchAPartnerClassification = (partnerPlatformId, classificationId) => {
  return (dispatch) => {
    dispatch(resetPartnerClassificationForm())
    dispatch(loadingPartnerClassificationForm())
    const URL = `${process.env.REACT_APP_PC_URL}/partner-classification/${partnerPlatformId}/${classificationId}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        partnerPlatformId,
        classification,
        enabled,
        priority,
        displayPackageCount,
      } = json;
      dispatch({
        type: FETCH_A_PARTNER_CLASSIFICATION,
        payload: {
          partnerPlatformId,
          classification,
          enabled,
          priority,
          displayPackageCount,
        }
      })
    })
  }
}

export const resetPartnerClassificationForm = () => {
  return (dispatch) => {
    return dispatch({
      type: RESET_PARTNER_CLASSIFICATION_FORM
    })
  }
}

export const loadingPartnerClassificationForm = () => {
  return (dispatch) => {
    return dispatch({
      type: LOADING_PARTNER_CLASSIFICATION_FORM
    })
  }
}

export const createAPartnerClassification = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-partner-classification`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('createAPartnerClassification Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const deletePartnerPlatform = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-platform/${params}`;
    return fetch(URL, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllPartnerPlatforms());
    })
    .catch(error => {
      console.log('createAPartnerClassification Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const updatePartnerClassification = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-classification`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('updatePartnerClassification Error - ', error)
    });
  }
}

export const updateAPartnerPlatform = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-platform`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('updateAPartnerPlatform Error - ', error)
    });
  }
}
