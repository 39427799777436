import Cookie from 'js-cookie';
import {
  FETCH_OFFERS_COMPLETE,
  FETCHING_OFFERS,
  OFFER_POST_STATUS,
  SET_NOTIFICATION_PANEL
} from '../ActionType';
import {signOut} from '../helper';

export const fetchOffers = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}offers`;
    dispatch({
      type: FETCHING_OFFERS,
      payload: true
    });
    return fetch(URL, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let offers = json;
        dispatch({
          type: FETCH_OFFERS_COMPLETE,
          payload: {
            offers
          }
        });
      })
      .catch(error => {
        console.log('fetchOffers Error - ', error);
      })
  }
}

export const postOffer = (offer) => {
  return (dispatch) => {
    dispatch({
      type: OFFER_POST_STATUS,
      payload: 'posting'
    });
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}update-offer`;
    let status;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(offer),
    })
    .then(response => {
      status = response.status
      return response.json()
    })
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let code;
      let message;
      let offerPostStatus;
      if (status === 200) {
        code = 'SUCCESS';
        message = 'Successful Offer Post.';
        offerPostStatus = 'success'
      } else {
        code = 'ERROR';
        message = json.errors[0].message;
        offerPostStatus = 'failed'
      }
      dispatch(fetchOffers())
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch({
        type: OFFER_POST_STATUS,
        payload: offerPostStatus
      });
    })
    .catch(error => {
      console.log('Offer Post Error - ', error)
      dispatch({
        type: OFFER_POST_STATUS,
        payload: 'failed'
      });
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code: 'ERROR', message: error.toString()}
      })
    });
  }
}
