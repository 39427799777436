import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postOffer } from '../../../redux/actions'
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Collapse, Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

const styles = {
  dontEditContainer: {
    fontSize: '14px',
    borderBottom: '1px solid lightgray',
    paddingBottom: '5px'
  },
  dontEditKey: {
    color: '#faa31e'
  },
  dontEditValue: {
    color: 'gray'
  },
  translationsButton: {
    fontSize: '14px',
    border: '1px solid darkgray',
    marginBottom: '1rem',
  }
}
Modal.setAppElement('body');
export class OfferForm extends Component {
  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.postOffer(this.props.offer)
  }

  render() {
    const { offer } = this.props;

    const active = (offer && offer.active) || false;
    const confirmation_headline = (offer && offer.confirmation_headline) || '';
    const device_headline = (offer && offer.device_headline) || '';
    const duration_in_months = (offer && offer.duration_in_months) || '';
    const disclaimer = (offer && offer.disclaimer) || '';
    const end_date = (offer && offer.end_date) || '';
    const heading = (offer && offer.heading) || '';
    const id = (offer && offer.id) || '';
    const identifier = (offer && offer.identifier) || '';
    const name = (offer && offer.name) || '';
    const offerCta = (offer && offer.offerCta) || '';
    const offer_actions = (offer && offer.offer_actions) || '';
    const offer_description = (offer && offer.offer_description) || '';
    const offer_rule_ids = (offer && offer.offer_rule_ids) || [];
    const offer_type = (offer && offer.offer_type) || '';
    const preview_headline = (offer && offer.preview_headline) || '';
    const priority = (offer && offer.priority) || '';
    const start_date = (offer && offer.start_date) || '';
    const subHeading = (offer && offer.subHeading) || '';
    const subText = (offer && offer.subText) || '';

    return (
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={this.props.showOfferForm}
          title='Offer Form'
          onRequestClose={() => {
            this.props.closeEditOfferPane(false, null, false)
          }}>
            {
              this.props.offerPostStatus === 'posting' ?
              <Loading />
              :
              <Form onSubmit={(e) => {this.handleSubmit(e)}}>
                {/*We probably don't want to edit id. (Ternary hides id for creating new offer) */}
                { id ?
                    <FormGroup className="col-sm-12">
                      <div style={styles.dontEditContainer}>
                        <span style={styles.dontEditKey}>id: </span><span style={styles.dontEditValue}>{id}</span>
                      </div>
                    </FormGroup>
                  : null
                }
                {/* TO MAKE ANY FIELD EDITABLE, remove "disabled" attribute from <input/> */}
                <FormGroup className="col-sm-12">
                    <div className="field">
                      <input disabled type="text" name="identifier" id="identifier" placeholder="(identifier empty)" onChange={(e) => this.props.onOfferFormChange('identifier', e.target.value)} value={identifier}/>
                      <Label for="identifier">identifier</Label>
                    </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                    <div className="field">
                      <input disabled type="text" name="offer_type" id="offer_type" placeholder="(offer_type empty)" onChange={(e) => this.props.onOfferFormChange('offer_type', e.target.value)} value={offer_type}/>
                      <Label for="offer_type">offer_type</Label>
                    </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                    <div className="field">
                      <input disabled type="text" name="start_date" id="start_date" placeholder="(start_date empty)" onChange={(e) => this.props.onOfferFormChange('start_date', e.target.value)} value={start_date}/>
                      <Label for="start_date">start_date</Label>
                    </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                    <div className="field">
                      <input disabled type="text" name="end_date" id="end_date" placeholder="(end_date empty)" onChange={(e) => this.props.onOfferFormChange('end_date', e.target.value)} value={end_date}/>
                      <Label for="end_date">end_date</Label>
                    </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                    <div className="field">
                      <input disabled type="text" name="duration_in_months" id="duration_in_months" placeholder="(duration_in_months empty)" onChange={(e) => this.props.onOfferFormChange('duration_in_months', e.target.value)} value={duration_in_months}/>
                      <Label for="duration_in_months">duration_in_months</Label>
                    </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                    <div className="field">
                      <input disabled type="text" name="priority" id="priority" placeholder="(priority empty)" onChange={(e) => this.props.onOfferFormChange('priority', e.target.value)} value={priority}/>
                      <Label for="priority">priority</Label>
                    </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                    <div className="field">
                      <input disabled type="text" name="offer_rule_ids" id="offer_rule_ids" placeholder="(offer_rule_ids empty)" onChange={(e) => this.props.onOfferFormChange('offer_rule_ids', e.target.value)} value={offer_rule_ids}/>
                      <Label for="offer_rule_ids">offer_rule_ids</Label>
                    </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                    <div className="field">
                      <input disabled type="text" name="offer_actions" id="offer_actions" placeholder="(offer_actions empty)" onChange={(e) => this.props.onOfferFormChange('offer_actions', e.target.value)} value={JSON.stringify(offer_actions)}/>
                      <Label for="offer_actions">offer_actions</Label>
                    </div>
                </FormGroup>
                {/* Translations Collapse */}
                <div>
                  <button
                    onClick={this.props.onToggleTranslations}
                    style={styles.translationsButton}
                  >
                    Translations
                  </button>
                  <Collapse isOpen={this.props.translationsOpen}>
                    <FormGroup className="col-sm-12">
                      <div className="field">
                        <input disabled type="text" name="name" id="name" placeholder="(name empty)" onChange={(e) => this.props.onOfferFormChange('name', e.target.value)} value={name}/>
                        <Label for="name">name</Label>
                      </div>
                    </FormGroup>
                    <FormGroup className="col-sm-12">
                      <div className="field">
                        <input disabled type="text" name="heading" id="heading" placeholder="(heading empty)" onChange={(e) => this.props.onOfferFormChange('heading', e.target.value)} value={heading}/>
                        <Label for="heading">heading</Label>
                      </div>
                    </FormGroup>
                    <FormGroup className="col-sm-12">
                        <div className="field">
                          <input disabled type="text" name="subHeading" id="subHeading" placeholder="(subHeading empty)" onChange={(e) => this.props.onOfferFormChange('subHeading', e.target.value)} value={subHeading}/>
                          <Label for="subHeading">subHeading</Label>
                        </div>
                    </FormGroup>
                    <FormGroup className="col-sm-12">
                        <div className="field">
                          <input disabled type="text" name="offer_description" id="offer_description" placeholder="(offer_description empty)" onChange={(e) => this.props.onOfferFormChange('offer_description', e.target.value)} value={offer_description}/>
                          <Label for="offer_description">offer_description</Label>
                        </div>
                    </FormGroup>
                    <FormGroup className="col-sm-12">
                        <div className="field">
                          <input disabled type="text" name="disclaimer" id="disclaimer" placeholder="(disclaimer empty)" onChange={(e) => this.props.onOfferFormChange('disclaimer', e.target.value)} value={disclaimer}/>
                          <Label for="disclaimer">disclaimer</Label>
                        </div>
                    </FormGroup>
                    <FormGroup className="col-sm-12">
                        <div className="field">
                          <input disabled type="text" name="confirmation_headline" id="confirmation_headline" placeholder="(confirmation_headline empty)" onChange={(e) => this.props.onOfferFormChange('confirmation_headline', e.target.value)} value={JSON.stringify(confirmation_headline)}/>
                          <Label for="confirmation_headline">confirmation_headline</Label>
                        </div>
                    </FormGroup>
                    <FormGroup className="col-sm-12">
                        <div className="field">
                          <input disabled type="text" name="preview_headline" id="preview_headline" placeholder="(preview_headline empty)" onChange={(e) => this.props.onOfferFormChange('preview_headline', e.target.value)} value={JSON.stringify(preview_headline)}/>
                          <Label for="preview_headline">preview_headline</Label>
                        </div>
                    </FormGroup>
                    <FormGroup className="col-sm-12">
                        <div className="field">
                          <input disabled type="text" name="device_headline" id="device_headline" placeholder="(device_headline empty)" onChange={(e) => this.props.onOfferFormChange('device_headline', e.target.value)} value={JSON.stringify(device_headline)}/>
                          <Label for="device_headline">device_headline</Label>
                        </div>
                    </FormGroup>
                    <FormGroup className="col-sm-12">
                        <div className="field">
                          <input disabled type="text" name="offerCta" id="offerCta" placeholder="(offerCta empty)" onChange={(e) => this.props.onOfferFormChange('offerCta', e.target.value)} value={offerCta}/>
                          <Label for="offerCta">offerCta</Label>
                        </div>
                    </FormGroup>
                    <FormGroup className="col-sm-12">
                        <div className="field">
                          <input disabled type="text" name="subText" id="subText" placeholder="(subText empty)" onChange={(e) => this.props.onOfferFormChange('subText', e.target.value)} value={subText}/>
                          <Label for="subText">subText</Label>
                        </div>
                    </FormGroup>
                  </Collapse>
                </div>
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="active" id="active" checked={active} onChange={(e) => this.props.onOfferFormChange('active', e.target.checked)} />
                  <Label check className="text-center">active</Label>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <button
                    className="btn btn-primary"
                    style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                    Submit
                  </button>
                </FormGroup>
              </Form>
            }
          <br />
        </SlidingPane>
    );
  }
}

export default connect(null, {
  postOffer
})(OfferForm);
