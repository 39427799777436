import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-table/react-table.css";
import _ from 'lodash';
import { fetchAllPackagesByPlanId, updateAPlanPackage } from '../../../../scripts/redux/actions';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';
import AddDeletePlanPackageForm from './AddDeletePlanPackageForm';
import './PlanTabs.css';

class PlanTabs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedPackage: this.props.openedPackage,
      loading: true,
      openedPlan: this.props.openedPlan,
      planPackageIdsToBeDeleted: [],
      planPackagesToBeUpdated: [],
      AddDeletePlanPackageForm: false,
      showAddDeletePlanPackageForm: false,
      allFreeTrial: false,
      allIauEnabled: true
    }

    this.renderDeleteButton = this.renderDeleteButton.bind(this)
    this.deletePlanPackage = this.deletePlanPackage.bind(this)
    this.renderPlanPackagePrice = this.renderPlanPackagePrice.bind(this)
    this.renderDisplayPrice = this.renderDisplayPrice.bind(this)
    this.renderFreeTrial = this.renderFreeTrial.bind(this)
    this.renderIauEnabled = this.renderIauEnabled.bind(this)
    this.updatePrice = this.updatePrice.bind(this)
    this.updateFreeTrial = this.updateFreeTrial.bind(this)
    this.updateIauEnabled = this.updateIauEnabled.bind(this)
    this.togglePlanPackageForm = this.togglePlanPackageForm.bind(this)
    this.enabledAllHandler = this.enabledAllHandler.bind(this)
  }

  componentWillMount() {
    this.props.fetchAllPackagesByPlanId(this.props.openedPlan)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({openedPackage: nextProps.openedPackage, data: nextProps.data, loading: nextProps.loading, planIdentifier: nextProps.planIdentifier, allPackageList: nextProps.allPackageList})

    if (nextProps.statusCode === "Success") {
      this.props.closeTab()
    }
  }

  renderPlanPackagePrice(cell, row) {
    return (
      <div className="col-sm-12">
        <div className="field">
          <span>{row.planPackagePrice}</span>
        </div>
      </div>
    )
  }

  renderDisplayPrice(cell, row) {
    return (
      <div className="col-sm-12">
        <div className="field">
          <span>{row.displayPrice}</span>
        </div>
      </div>
    )
  }

  renderFreeTrial(cell, row) {
    return (
      <div>
        <i className={row.freeTrial ? "fa fa-toggle-on text-green" : "fa fa-toggle-off text-red"} onClick={() => this.updateFreeTrial(row.planPackageId)}/>
      </div>
    )
  }

  renderIauEnabled(cell, row) {
    return (
      <div>
        <i className={row.iauEnabled ? "fa fa-toggle-on text-green" : "fa fa-toggle-off text-red"} onClick={() => this.updateIauEnabled(row.planPackageId)}/>
      </div>
    )
  }

  updatePrice(e, planPackageId) {
    let filteredData = this.state.data.filter(pP => pP.planPackageId !== planPackageId);
    let filteredPlanPackagesToBeUpdated = this.state.planPackagesToBeUpdated.filter(pP => pP.planPackageId !== planPackageId);
    let newDataCopy = this.state.data.find(pP => pP.planPackageId === planPackageId);

    switch (e.target.name) {
      case 'planPackagePrice':
        newDataCopy.planPackagePrice = e.target.value;
        break;
      case 'displayPrice':
        newDataCopy.displayPrice = e.target.value;
        break;
    }

    this.setState({ data: [ ...filteredData, newDataCopy ], planPackagesToBeUpdated: [ ...filteredPlanPackagesToBeUpdated, newDataCopy ] });
  }

  updateFreeTrial(planPackageId) {
    let filteredData = this.state.data.filter(pP => pP.planPackageId !== planPackageId);
    let filteredPlanPackagesToBeUpdated = this.state.planPackagesToBeUpdated.filter(pP => pP.planPackageId !== planPackageId);
    let newDataCopy = this.state.data.find(pP => pP.planPackageId === planPackageId);
    newDataCopy.freeTrial = !newDataCopy.freeTrial;

    this.setState({ data: [ ...filteredData, newDataCopy ], planPackagesToBeUpdated: [ ...filteredPlanPackagesToBeUpdated, newDataCopy ] });
  }

  updateIauEnabled(planPackageId) {
    let filteredData = this.state.data.filter(pP => pP.planPackageId !== planPackageId);
    let filteredPlanPackagesToBeUpdated = this.state.planPackagesToBeUpdated.filter(pP => pP.planPackageId !== planPackageId);
    let newDataCopy = this.state.data.find(pP => pP.planPackageId === planPackageId);
    newDataCopy.iauEnabled = !newDataCopy.iauEnabled;

    this.setState({ data: [ ...filteredData, newDataCopy ], planPackagesToBeUpdated: [ ...filteredPlanPackagesToBeUpdated, newDataCopy ] });
  }

  renderDeleteButton(cell, row) {
    return (
      <div>
        <i className='fa fa-trash' onClick={() => this.deletePlanPackage(row.planPackageId)} />
      </div>
    )
  }

  deletePlanPackage(planPackageId) {
    let filteredData = this.state.data.filter(pP => pP.planPackageId !== planPackageId);
    let idsToBeDeleted = [...this.state.planPackageIdsToBeDeleted];
    if (this.state.data.find(dt => dt.planPackageId === planPackageId).isNew === false) {
      idsToBeDeleted.push(planPackageId);
    }

    if (this.state.planPackagesToBeUpdated.map(pP => pP.planPackageId).includes(planPackageId)) {
      let filteredPlanPackagesToBeUpdated = this.state.planPackagesToBeUpdated.filter(pP => pP.planPackageId !== planPackageId);
      this.setState({ data: filteredData, planPackageIdsToBeDeleted: idsToBeDeleted, planPackagesToBeUpdated: filteredPlanPackagesToBeUpdated });
    } else {
      this.setState({ data: filteredData, planPackageIdsToBeDeleted: idsToBeDeleted });
    }
  }

  togglePlanPackageForm() {
    this.props.fetchAllPackagesByPlanId(this.props.openedPlan);
    this.setState({ showAddDeletePlanPackageForm: false})
  }

  enabledAllHandler(type) {
    switch (type) {
      case 'freeTrial':
        let freeTrialDataCopy = this.state.data.map(dat => {
          dat.freeTrial = this.state.allFreeTrial ? false : true;
          return dat;
        });
        this.setState({ data: freeTrialDataCopy, planPackagesToBeUpdated: freeTrialDataCopy, allFreeTrial: !this.state.allFreeTrial });
        break;
      case 'iauEnabled':
        let iauEnabledDataCopy = this.state.data.map(dat => {
          dat.iauEnabled = this.state.allIauEnabled ? false : true;
          return dat;
        });
        this.setState({ data: iauEnabledDataCopy, planPackagesToBeUpdated: iauEnabledDataCopy, allIauEnabled: !this.state.allIauEnabled });
        break;
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    let { planPackagesToBeUpdated, planPackageIdsToBeDeleted } = this.state;

    let params = {
      planPackagesToBeUpdated, planPackageIdsToBeDeleted, planId: this.props.openedPlan, addDeletePlanPackageForm: false
    }
    this.props.updateAPlanPackage(params)
  }

  render() {
    const {
      loading, data, planIdentifier, allPackageList, allFreeTrial, allIauEnabled
    } = this.state

    let sortedData = _.orderBy(data, ['planPackageId'], ['desc']);

    const columns = [
      {
        headerStyle: () => {
          return { width: '3vw', textAlign: 'center' };
        },
        style: { textAlign: 'center'},
        text: "Package",
        dataField: "packageIdentifier",
        sort: true,
        filter: textFilter({ placeholder: 'Package Identifier'}),
      },
      {
        headerStyle: () => {
          return { width: '3vw', textAlign: 'center' };
        },
        style: { textAlign: 'center'},
        text: "Plan Package Price",
        dataField: "planPackagePrice",
        sort: true,
        filter: textFilter({ placeholder: 'Plan Package Price'}),
        formatter: this.renderPlanPackagePrice
      },
      {
        headerStyle: () => {
          return { width: '3vw', textAlign: 'center' };
        },
        style: { textAlign: 'center'},
        text: "Display Price",
        dataField: "displayPrice",
        sort: true,
        filter: textFilter({ placeholder: 'Display Price'}),
        formatter: this.renderDisplayPrice
      },
      {
        headerStyle: () => {
          return { width: '2vw', textAlign: 'center' };
        },
        style: { textAlign: 'center'},
        text: "Free Trial",
        dataField: "freeTrial",
        sort: true,
        formatter: this.renderFreeTrial,
      },
      {
        headerStyle: () => {
          return { width: '2vw', textAlign: 'center' };
        },
        style: { textAlign: 'center'},
        text: "IAU Enabled",
        dataField: "iauEnabled",
        sort: true,
        formatter: this.renderIauEnabled,
      },
      {
        headerStyle: () => {
          return { width: '1vw', textAlign: 'center' };
        },
        style: { textAlign: 'center'},
        text: "Delete",
        dataField: " ",
        formatter: this.renderDeleteButton,
      }
    ];

    return (
      <div>
        <p style={{textAlign:'center', fontSize:'30px'}}>{planIdentifier}</p>
        <div className='new-plan-package-div'>
          <button className="btn btn-primary new-plan-package-btn"
                  onClick={()=> this.setState({ showAddDeletePlanPackageForm: true })}>
          <i className="fa fa-plus"></i> Add/Edit Plan Packages
          </button>
          <FormGroup className="col-sm-12 form-checkbox set-input-width">
            <Input type="checkbox" name="allIauEnabled" id="allIauEnabled" checked={allIauEnabled} onChange={() => this.enabledAllHandler('iauEnabled')} />
            <Label check className="text-center">
              {allIauEnabled ? "Disable All IAU Enabled" : "Enable All IAU Enabled"}
            </Label>
          </FormGroup>
          <FormGroup className="col-sm-12 form-checkbox set-input-width">
            <Input type="checkbox" name="allFreeTrial" id="allFreeTrial" checked={allFreeTrial} onChange={() => this.enabledAllHandler('freeTrial')} />
            <Label check className="text-center">
              {allFreeTrial ? "Disable All Free Trial" : "Enable All Free Trial"}
            </Label>
          </FormGroup>
        </div>
        {
          loading ? <Loading color='orange'/> :
          <div>
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <BootstrapTable
                class="my-table"
                keyField='id'
                data={ sortedData }
                columns={ columns }
                placeHolder={ false }
                filter={ filterFactory() }
                pagination={ paginationFactory({
                    sizePerPageList: ([15, 20, 25, 50, 100]),
                    style: {
                      fontSize: '50px'
                    }
                  })
                }
              />
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
                <button className="btn btn-primary discard-box"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}} onClick={() => this.props.closeTab()} >
                        Discard Changes
                </button>
              </FormGroup>
            </Form>
          </div>
        }
        {
          this.state.showAddDeletePlanPackageForm ?
          <AddDeletePlanPackageForm
            allPackageList={allPackageList}
            planId={this.props.openedPlan}
            closePlanPackageFormPane={this.togglePlanPackageForm} />
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = ({plansData, FlashMessageData}) => {
  return {
    data: plansData.planPackages,
    allPackageList: plansData.allPackageList,
    planIdentifier: plansData.planIdentifier,
    loading: plansData.loading,
    statusCode: FlashMessageData.statusCode,
  }
}
export default connect(mapStateToProps, {
  fetchAllPackagesByPlanId,
  updateAPlanPackage
})(PlanTabs);
