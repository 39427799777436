import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchAllClassifications, grandFatherToggle} from '../../../../scripts/redux/actions'
import ClassificationForm from '../ClassificationForm/ClassificationForm.js';
import CloneForm from '../CloneForm/CloneForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import { Card, CardFooter, CardBody, Row } from 'reactstrap';
import './Classification.css';
import ClassificationTabs from './ClassificationTabs';

class NewClassification extends Component {
  constructor (props) {
    super(props)
    this.state = {
      classifications: [],
      displayingClassifications: [],
      showClassificationForm: false,
      showCloneForm: false,
      openedClassification: null,
      loading: true,
      footerOpts: {0: true, 1: true, 2: true},
      displayCardClass: '',
    }
    this.toggleClassificationForm = this.toggleClassificationForm.bind(this)
    this.grandFatherToggle = this.grandFatherToggle.bind(this)
    this.toggleCloneForm = this.toggleCloneForm.bind(this)
  }

  setClassificationTabsSectionClass() {
    let packageTabsSectionClass = 'package-tabs-section';
    let siCardBodyMd = '';
    let siCardMd = '';
    let tileSelected = 'tgc-tile-selected';
    if (window.innerWidth <= 1422) {
      packageTabsSectionClass = 'package-tabs-section'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      packageTabsSectionClass = 'package-tabs-section'
      siCardBodyMd = 'si-card-body-md'
      siCardMd = 'si-card-md'
      tileSelected = 'tgc-tile-selected tile-selected-md'
    } else if (window.innerWidth > 1440){
      packageTabsSectionClass = 'package-tabs-section package-tabs-section-lg'
    }
    this.setState({packageTabsSectionClass, siCardBodyMd, siCardMd, tileSelected})
  }

  grandFatherToggle(value) {
    let params = {
      classificationId: value.id,
      enabled: !value.enabled ? 1 : 0
    }
  this.props.grandFatherToggle(params, 'classification');
  }

  componentWillMount() {
    this.setClassificationTabsSectionClass()
    this.props.fetchAllClassifications()
  }

  componentWillReceiveProps(nextProps) {
    const {classifications, displayingClassifications, loading} = nextProps
    this.setState({classifications, displayingClassifications, loading})
  }

  toggleClassificationForm(id=null, getClassifications=false) {
    if (id) {
      this.setState({ showClassificationForm: true, openedClassification: id });
    } else {
      this.setState({ showClassificationForm: false})
    }
    if (getClassifications) {
      this.props.fetchAllClassifications()
    }
  }

  toggleCloneForm(id=null, getClassifications=false) {
    if (id) {
      this.setState({showCloneForm: true, openedClassification: id})
    } else {
      this.setState({showCloneForm: false})
    }
    if (getClassifications) {
      this.props.fetchAllClassifications()
    }
  }

  displayClassificationTabs(openedClassification, selectedDiv) {
    this.setState({showClassificationTabs: true, openedClassification, selectedDiv})
  }

  closeClassificationTabs() {
    this.setState({showPackageTabs: false, selectedDiv: false})
  }

  search(val) {
    const {classifications} = this.state
    let displayingClassifications = classifications.filter(c => (c.name.toLowerCase().search(val.toLowerCase()) !== -1) || c.identifier.toLowerCase().search(val.toLowerCase()) !== -1)
    this.setState({displayingClassifications})
  }

  render() {
    const { openedClassification, displayingClassifications, loading,
            footerOpts, showClassificationForm, showCloneForm, selectedDiv,
            tileSelected, siCardMd, siCardBodyMd, showClassificationTabs,
            packageTabsSectionClass,
          } = this.state

    const Item = ({value, name}) => {
      return (
        <div className='package-sortable-item'>
          <div className={`${name === selectedDiv ? tileSelected : ""}`}>
            <Card className={`margin-bottom-5 ${siCardMd}`}>
              <CardBody className={`si-card-body ${siCardBodyMd}`} onClick={() => this.displayClassificationTabs(value.id, name)}>
                <span className="text-left font-size-18">{value.name || value.identifier}</span>
              </CardBody>
              <CardFooter className={`height-48 card-footer-color ${name === selectedDiv ? tileSelected : ""}`}>
                <div className="text-center si-card-footer">
                  {footerOpts[0] && <i className="fa fa-clone" onClick={() => this.toggleCloneForm(value.id)}></i>}
                  {footerOpts[1] && <i className="fa fa-pencil margin-left-50" onClick={() => this.toggleClassificationForm && this.toggleClassificationForm(value.id)}></i>}
                  { footerOpts[2] &&
                    ((value.enabled || value.active) ?
                    <i className="fa fa-toggle-on margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.grandFatherToggle && this.grandFatherToggle(value) } } /> :
                    <i className="fa fa-toggle-off margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.grandFatherToggle && this.grandFatherToggle(value) } } />)
                  }
                </div>
              </CardFooter>
            </Card>
          </div>
          { showClassificationTabs && (name === selectedDiv) ?
          <div className={`${packageTabsSectionClass} ${name === selectedDiv ? "block" : ""}`}>
            <div onClick={() => this.closeClassificationTabs()} className="wrapper">
              <a href className="close-button">
                <div className="in">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
                <div className="out">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
              </a>
            </div>
            <div><ClassificationTabs showNotificationPanel={this.showNotificationPanel}
                         openedClassification={openedClassification}/></div>
          </div> : null }
        </div>
      )
    };

    const List = ({items}) => {
      return (
        <Row>
          {
            items.map((value, index) => (
              <Item key={index} index={index} value={value} name={index}/>
            ))
          }
        </Row>
      );
    };

    return (
      <div>
        <FlashMessage />
        <div className='classification'>
          <div className='classification-header'>
            <div className="search-section classification-search">
              <div className='aaa'>
                <input onKeyUp={(e)=>this.search(e.target.value)}
                       type="search" placeholder="What are you looking for?" />
              </div>
            </div>
            <div className='new-classification-div'>
              <button className="btn btn-primary new-classification-btn"
                      onClick={()=> this.setState({
                                                    showClassificationForm: true,
                                                    openedClassification: null})}>
              <i className="fa fa-plus"></i> New Classification
              </button>
            </div>
          </div>
          <div className='new-classification-content'>
            { loading && <Loading /> }
            {
              (!loading && displayingClassifications.length === 0) ? <NoData></NoData> :
              <List items={displayingClassifications} />
            }
            {
              showClassificationForm ?
              <ClassificationForm classificationId={openedClassification}
                                  closeEditClassificationPane={this.toggleClassificationForm}/>
              : null
            }
            {
              showCloneForm ?
              <CloneForm type='classification'
                         parentIdentifier={openedClassification}
                         closeClonePane={this.toggleCloneForm}/>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({classificationsData}) => {
  return {
    classifications: classificationsData.classifications,
    displayingClassifications: classificationsData.displayingClassifications,
    loading: classificationsData.loading,
  };
}

export {NewClassification};

export default connect(mapStateToProps, {
  fetchAllClassifications,
  grandFatherToggle,
})(NewClassification);
