import Cookie from 'js-cookie';
import {
  FETCH_FREEMIUMS_BY_CLASSIFICATION,
  SET_NOTIFICATION_PANEL,
  FETCH_A_FREEMIUM,
  LOADING_FREEMIUM_FORM,
  EDIT_FREEMIUM,
  RESET_FREEMIUM_FORM,
} from '../ActionType';
import _ from 'lodash';
import {
  fetchDefaultClassifications,
  fetchDWClassifications,
  fetchEnabledPackages
} from './DefaultDataCalls';
// import { arrayMove } from 'react-sortable-hoc'; // Used in WIP sortBasePackages below
import {signOut} from '../helper';

export const fetchFreemiumsByClassification = (classification=17, selectedPackageType='all') => {
  return (dispatch) => {
    const PACKAGES_URL = `${process.env.REACT_APP_PC_URL}freemiums`;
    return fetch(PACKAGES_URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let packages = json;
      packages = _.sortBy(packages, 'classPackagePriority');
      let displayingPackages = Object.assign([], packages);
      if (selectedPackageType !== 'all') {
        displayingPackages = displayingPackages.filter(pack => pack.packageType === selectedPackageType)
      }
      dispatch({
        type: FETCH_FREEMIUMS_BY_CLASSIFICATION,
        payload: {packages, displayingPackages}
      })
    })
    .catch(error => {
        console.log('Package Error 2 - ', error);
    })
  }
}

export const fetchAFreemiumAfter = (id) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}freemium/${id}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        packageId, identifier, priority, startDate, endDate,
        previewHours, entitlementType, previewStartsAt, previewEndsAt, classificationId, previewType,
        previewStartTime, previewEndTime, previewTimeZone,
      } = json;
      dispatch({
        type: FETCH_A_FREEMIUM,
        payload: {
          packageId, identifier, priority, startDate, endDate,
          previewHours, entitlementType, previewStartsAt, previewEndsAt, classificationId, previewType,
          previewStartTime, previewEndTime, previewTimeZone,
        }
      })
    })
  }
}

export const fetchAFreemium = (id = null) => {
  let typeOfCall, editPackage = false;
  if (id) {
    typeOfCall = 'freemiumEdit'
    editPackage = true
  }
  return (dispatch) => {
    if (!editPackage) {
      dispatch({type: RESET_FREEMIUM_FORM})
      dispatch(fetchDWClassifications())
    }
    dispatch({
      type: EDIT_FREEMIUM,
      payload: {editPackage}
    })
    dispatch({type: LOADING_FREEMIUM_FORM})
    dispatch(fetchDefaultClassifications(id, typeOfCall))
    dispatch(fetchEnabledPackages())
  }
}

export const createAFreemium = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-update-freemium`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchFreemiumsByClassification())
      // this.props.showNotificationPanel(true, json.status.code, json.status.message)
      // this.props.closePackageFormPane(null, true)
    })
    .catch(error => {
      console.log('createAPackage Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

// ToDo
// export const sortBasePackages = (params, oldIndex, newIndex) => {
//   const URL = `${process.env.REACT_APP_PC_URL}update-package-priority`;
//   return fetch(URL, {
//     method: 'PUT',
//     headers: new Headers({
//       'Content-Type': 'application/json',
//       Authorization: Cookie.get('token')
//     }),
//     credentials: 'same-origin',
//     body: JSON.stringify(params),
//   })
//   .then(response => response.json())
//   .then(json => {
//     if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
//       signOut()
//     }
//     this.setState({
//       displayingPackages: arrayMove(this.state.displayingPackages, oldIndex, newIndex)
//     });
//     this.showNotificationPanel(true, json.status.code, json.status.message)
//   })
//   .catch(error => {
//     console.log('Update Package Priority Error - ', error)
//     // this.showNotificationPanel(true, json.status.code, json.status.message)
//   });
// }
