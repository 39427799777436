import React, { Component } from 'react';
import "react-table/react-table.css";
// import _ from 'lodash';
import {
  TabContent,
  TabPane,
  Card,
  CardFooter,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import LineOfBusinessPlanForm from './LineOfBusinessPlanForm.js'
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import '../PackageTabs/PackageTabs.css';
import Cookie from 'js-cookie';

class LineOfBusinessTabs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedLob: this.props.openedLob,
      lobPlans: [],
      displayingLobPlans: [],
      showLobPlanForm: false,
      openedLobPlan: null,
    }
    // this.closeMorePane = this.closeMorePane.bind(this)
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
    this.toggleLobPlanForm = this.toggleLobPlanForm.bind(this)
  }

  setDisplayCardClass() {
    let displayCardClass = 'col-md-3';
    if (window.innerWidth <= 1422) {
      displayCardClass = 'col-md-3';
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      displayCardClass = 'col-md-3';
    } else if (window.innerWidth > 1440){
      displayCardClass = 'col-lg-2';
    }
    this.setState({displayCardClass})
  }

  // closeMorePane(updated = false) {
  //   this.setState({ morePaneSection: '' });
  //   if (updated) {
  //     this.toggleTabs(this.state.activeTab)
  //   }
  // }

  getLobPlans() {
    const {openedLob} = this.props
    if (openedLob) {
      this.setState({
        loading:true,
        activeTab: 'packages',
      })
      const URL = `${process.env.REACT_APP_PC_URL}line-of-business-plan-based-on-lob/${openedLob}`
      fetch(URL, {method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        this.setState({
          loading: false,
          lobPlans: json,
          displayingLobPlans: json,
        });
      })
      .catch(error => {
          console.log('PackageTabs Error 1 - ', error);
      })
    }
  }

  componentWillMount() {
    this.setDisplayCardClass()
    this.getLobPlans()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({openedLob: nextProps.openedLob})
    this.getLobPlans()
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => {this.setState({showNotification: false})}, 3000);
  }

  toggleTabs(tab) {
    switch (tab) {
      default:
      this.getLobPlans();
      break;
    }
  }

  search(val) {
    const {lobPlans} = this.state
    let displayingLobPlans = lobPlans.filter(c => (c.partnerPlatform.toLowerCase().search(val.toLowerCase()) !== -1))
    this.setState({displayingLobPlans})
  }

  toggleLobPlanForm(openedLobPlan=null, getLobPlans=false) {
    if (openedLobPlan) {
      this.setState({ showLobPlanForm: true, openedLobPlan });
    } else {
      this.setState({ showLobPlanForm: false });
    }
    if (getLobPlans) {
      this.toggleTabs('package')
    }
  }

  toggleLobPlan(lobpId, lobpEnabled) {
    const URL = `${process.env.REACT_APP_PC_URL}disable-line-of-business-plan/${lobpId}/${lobpEnabled}`
    fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token'),
      },
    })
    .then(response => response.json())
    .then(json => {
      this.showNotificationPanel(true, json.status.code, json.status.message)
      this.toggleTabs(this.state.activeTab)
    })
    .catch(error => {
        console.log('PackageTabs Error 3 - ', error);
    })
  }

  render() {
    const {
      showNotification, statusCode, statusMessage, loading, openedLobPlan,
      activeTab, displayCardClass, openedLob, showLobPlanForm,
    } = this.state
    const Item = ({value}) => {
      return (
        <div className={displayCardClass} style={{padding:'0px 5px 10px', zIndex:'99'}}>
          <Card className='display-card' style={{'color': '#666666'}}>
            <CardBody style={{background:'#46464C',color:'white',height:'100px'}}>
              <span className="text-left" style={{fontSize:'18px'}}>{value.partnerPlatform}</span>
            </CardBody>
            <CardFooter style={{height: '30px'}} className='tabs-card-footer'>
                <div className="text-center" style={{background:'#46464C','fontSize':'20px','color':'white','display': 'flex', 'alignItems': 'left', 'justifyContent': 'center'}}>
                  <i onClick={() => this.toggleLobPlanForm(value.id)} className={classnames('fa', 'fa-pencil')}></i>
                  { (value.enabled ?
                    <i className="fa fa-toggle-on margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.toggleLobPlan(value.id, false) } } /> :
                    <i className="fa fa-toggle-off margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.toggleLobPlan(value.id, true) } } />)
                  }
                </div>
            </CardFooter>
          </Card>
        </div>
      )
    };

    const List = ({items}) => {
      if (items.length > 0) {
        return (
          <Row style={{padding:'10px'}}>
            {
              items.map((value, index) => (
                <Item key={`item-${index}`} index={index} value={value} />
              ))
            }
          </Row>
        );
      } else {
        return (<NoData />)
      }
    };

    const NavItems = ({items}) => {
      return (
        <Nav tabs>
          {
            items.map((item, index) => (
                <NavItem key={index}
                        className={classnames({ active: activeTab === item })}>
                  <NavLink onClick={() => { this.toggleTabs(item); }}>
                    {item}
                  </NavLink>
                </NavItem>
            ))
          }
        </Nav>
      )
    }

    return (
      <div className='package-tabs'>

        {showNotification && <FlashMessage statusCode={statusCode} statusMessage={statusMessage} />}
            <NavItems items={['packages']} />
            {
              loading ? <Loading color='orange'/> :
              <div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="packages">
                    <div>
                      <Row style={{'paddingTop': '20px'}}>
                        <div className="search-section" style={{marginLeft:'15px',paddingBottom:'15px'}}>
                          <div className='aaa'>
                            <input onKeyUp={(e)=>this.search(e.target.value)}
                                   type="search" placeholder="What are you looking for?" />
                          </div>
                        </div>
                        <Col sm='1'>
                          <button className="btn btn-primary"
                                  onClick = {
                                    () => this.setState({
                                      showLobPlanForm: true,
                                      openedLobPlan: null
                                    })
                                  }
                                  style={{'height': '35px','fontSize': '14px'}}>
                          <i className="fa fa-plus"></i> New LOB Plan
                          </button>
                        </Col>
                      </Row>
                      <List items={this.state.displayingLobPlans} itemType='packages' />
                    </div>
                  </TabPane>
                </TabContent> 
                {showLobPlanForm ? <LineOfBusinessPlanForm
                                      showNotificationPanel={this.showNotificationPanel}
                                      closeLobPlanFormPane={this.toggleLobPlanForm}
                                      openedLob={openedLob}
                                      openedLobPlan={openedLobPlan}
                                      /> : null}
              </div>
            }
      </div>
    );
  }
}

export default LineOfBusinessTabs;
