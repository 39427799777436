import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchOffers} from '../../../../scripts/redux/actions'
import OfferForm from './OfferForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import { Card, CardFooter, CardBody, Row } from 'reactstrap';
import Select from 'react-select';
import './Offer.css';

class Offer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchString: '',
      selectActiveOffers: null,
      showOfferForm: false,
      selectedOffer: null,
      translationsOpen: false
    }

    this.onSelectActiveOffersChange = this.onSelectActiveOffersChange.bind(this)
    this.toggleOfferForm = this.toggleOfferForm.bind(this)
    this.handleOfferFormChange = this.handleOfferFormChange.bind(this)
    this.handleToggleTranslations = this.handleToggleTranslations.bind(this)
  }

  componentDidMount() {
    this.props.fetchOffers()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.offerPostStatus === 'posting' && nextProps.offerPostStatus === 'success') {
      this.toggleOfferForm(false, null, true)
    }
  }

  onSelectActiveOffersChange(selectActiveOffers) {
    this.setState({
      selectActiveOffers: selectActiveOffers ? selectActiveOffers.value : null
    })
  }

  toggleOfferForm(showOfferForm, selectedOffer, getOffers=false) {
    this.setState({
      showOfferForm,
      selectedOffer,
      translationsOpen: false
    });
    if (getOffers) {
      this.props.fetchOffers()
    }
  }

  handleOfferFormChange(key, value) {
    const selectedOffer = {
      ...this.state.selectedOffer,
      [key]: value
    }
    this.setState({
      selectedOffer
    });
  }

  handleToggleTranslations(e) {
    if (e) {e.preventDefault();}
    this.setState({translationsOpen: !this.state.translationsOpen})
  }

  search(val) {
    this.setState({searchString: val})
  }

  render() {
    const { selectActiveOffers, selectedOffer, showOfferForm, searchString, translationsOpen } = this.state
    const { fetchingOffers, offers } = this.props;
    const activeFiltered = selectActiveOffers === null ? offers : offers.filter(o => (o.active === selectActiveOffers))
    const displayingOffers = activeFiltered.filter(o => ((o.name && o.name.toLowerCase().search(searchString.toLowerCase()) !== -1) || o.identifier.toLowerCase().search(searchString.toLowerCase()) !== -1))

    const Item = ({value}) => {
      return (
        <div className='offer-sortable-item'>
          <div>
            <Card className={'margin-bottom-5'}>
              <CardBody className={'si-card-body'} style={{cursor: 'auto'}}>
                <span className="text-left font-size-18">{value.name || value.identifier}</span>
              </CardBody>
              <CardFooter className="height-48 card-footer-color">
                <div className="text-center si-card-footer">
                  <i className="fa fa-pencil" onClick={() => this.toggleOfferForm(true, value, false)}></i>
                  { value.active ?
                    <i className="fa fa-toggle-on margin-left-50" style={{color: 'black', cursor: 'auto'}} />
                    :
                    <i className="fa fa-toggle-off margin-left-50" style={{color: 'gray', cursor: 'auto'}} />
                  }
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>
      )
    };

    const List = ({items}) => {
      return (
        <Row>
          {
            items.map((value, index) => (
              <Item key={index} index={index} value={value} name={index}/>
            ))
          }
        </Row>
      );
    };

    let offerContent;
    if (fetchingOffers) {
      offerContent = <Loading />;
    } else if (displayingOffers.length === 0) {
      offerContent = <NoData />
    } else {
      offerContent = <List items={displayingOffers} />
    }

    return (
      <div>
        <FlashMessage />
        <div className='offer'>
          <div className='offer-header'>
            <div className='select'>
              <Select
                isSearchable={false}
                placeholder="Status"
                name="form-field-name"
                value={selectActiveOffers}
                onChange={this.onSelectActiveOffersChange}
                options={[
                  {value: null, label: 'All'},
                  {value: true, label: 'Active'},
                  {value: false, label: 'Inactive'},
                ]}
              />
            </div>
            <div className="search-section offer-search">
              <div>
                <input
                  onKeyUp={(e)=>this.search(e.target.value)}
                  type="search" placeholder="What are you looking for?" />
              </div>
            </div>
            <div className='new-offer-div'>
              <button
                disabled // TODO: REMOVE this when new offer feature is ready
                className="btn btn-primary new-offer-btn"
                onClick={() => this.toggleOfferForm(true, null, false)}
              >
              <i className="fa fa-plus"></i> New Offer
              </button>
            </div>
          </div>
          <div className='new-offer-content'>
            {offerContent}
            <OfferForm 
              showOfferForm={showOfferForm}
              offer={selectedOffer}
              offerPostStatus={this.props.offerPostStatus}
              onOfferFormChange={this.handleOfferFormChange}
              closeEditOfferPane={this.toggleOfferForm}
              translationsOpen={translationsOpen}
              onToggleTranslations={this.handleToggleTranslations}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({offersData}) => {
  const { offers, fetchingOffers, offerPostStatus } = offersData;
  return {
    fetchingOffers,
    offerPostStatus,
    offers
  };
}

export default connect(mapStateToProps, {
  fetchOffers,
})(Offer);
