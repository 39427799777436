import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAPlan, createAPlan, updateAPlan } from '../../../../scripts/redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js'

export class PlanForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      allPlans: [],
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingAPlan: false,
    }
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  handleAppSupportedChange(appName) {
    const {appsSupported} = this.state
    let app = _.find(appsSupported, {appName})
    app.isSupported = !app.isSupported
    this.setState(appsSupported)
  }

  componentDidMount() {
      Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.props.fetchAPlan(this.props.planId)
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    const {
      name, identifier, planCode, trialDays,
      additionalAddOnsAmount, amount, baseLinearAddOnsAmount,
      baseLinearMultiplier, baseLinearIntroMonths, billingCycleLengthInMonths,
      concurrencyMultiplier, downgradable, extraLinearAddOnsAmount,
      extrasMultiplier, planIntervalLength,
      planIntervalUnit, totalBillingCycles, upsell, futurePlanId, restartPlanId,
      webUpsell, certificateRequired, additionalDays,
      priority, planType, appsSupported, loadingAPlan, allPlans, bundleOffer,
      immutable, nonRefundable, editPlan
    } = nextProps

    this.setState({
      name, identifier, planCode, trialDays,
      additionalAddOnsAmount, amount, baseLinearAddOnsAmount,
      baseLinearMultiplier, baseLinearIntroMonths, billingCycleLengthInMonths,
      concurrencyMultiplier, downgradable, extraLinearAddOnsAmount,
      extrasMultiplier, planIntervalLength,
      planIntervalUnit, totalBillingCycles, upsell, futurePlanId, restartPlanId,
      webUpsell, certificateRequired, additionalDays,
      priority, planType, appsSupported, loadingAPlan, allPlans, bundleOffer,
      immutable, nonRefundable, name, editPlan
    })

    if (nextProps.statusCode === "Success") {
      this.props.closePlanFormPane(null, true)
    }
  }

  handleTranslationChange(value) {
    if (Object.keys(this.state.name).length === 0) {
      let newNameObj = {}
      newNameObj['key'] = `model.plan.${this.state.identifier}.name`;
      newNameObj['value'] = value;
      newNameObj['interpolations'] = null;
      newNameObj['is_proc'] = false;
      newNameObj['translatable_type'] = "Plan";
      newNameObj['translatable_id'] = this.props.planId;
      newNameObj['application'] = "catalog";
      this.setState({name: newNameObj});
    }
    if (!this.isEmpty(this.state.name)) {
      let nameCopy = {...this.state.name};
      if (this.isEmpty(nameCopy.translatable_type)) {
        nameCopy.translatable_type = "Plan";
      }
      if (this.isEmpty(nameCopy.translatable_id)) {
        nameCopy.translatable_id = this.props.planId;
      }
      nameCopy.value = value;
      this.setState({name: nameCopy});
    }
  }

  handleIdentifierChange(value) {
    if (!this.isEmpty(this.state.name)) {
      let nameCopy = {...this.state.name};
      nameCopy.key = `model.plan.${value}.name`;
      this.setState({name: nameCopy, identifier: value});
    } else {
      this.setState({identifier: value});
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const {
      name, identifier, planCode, trialDays,
      additionalAddOnsAmount, amount, baseLinearAddOnsAmount,
      baseLinearMultiplier, baseLinearIntroMonths, billingCycleLengthInMonths,
      concurrencyMultiplier, downgradable, extraLinearAddOnsAmount,
      extrasMultiplier, planIntervalLength,
      planIntervalUnit, totalBillingCycles, upsell, futurePlanId, restartPlanId,
      webUpsell, certificateRequired, additionalDays,
      priority, planType, appsSupported, bundleOffer,
      immutable, nonRefundable
    } = this.state
    var params = {
      name, identifier, planCode, trialDays,
      additionalAddOnsAmount, amount, baseLinearAddOnsAmount,
      baseLinearMultiplier, baseLinearIntroMonths, billingCycleLengthInMonths,
      concurrencyMultiplier, downgradable, extraLinearAddOnsAmount,
      extrasMultiplier, planIntervalLength,
      planIntervalUnit, totalBillingCycles, upsell, futurePlanId, restartPlanId,
      webUpsell, certificateRequired, additionalDays,
      priority, planType, appsSupported, bundleOffer,
      immutable, nonRefundable
    };

    if (this.isEmpty(params.name.key)) {
      params.name.key = `model.plan.${identifier}.name`;
    }
    if (this.isEmpty(params.name.value)) {
      params.name.value = name.value;
    }
    if (this.isEmpty(params.name.is_proc)) {
      params.name.is_proc = false;
    }
    if (this.isEmpty(params.name.translatable_type)) {
      params.name.translatable_type = "Plan";
    }
    if (this.isEmpty(params.name.translatable_id)) {
      params.name.translatable_id = this.props.planId;
    }
    if (this.isEmpty(params.name.application)) {
      params.name.application = "catalog";
    }

    if (this.props.editPlan) {
      params.id = this.props.planId;
      this.props.updateAPlan(params)
    } else {
      this.props.createAPlan(params)
    }
  }

  renderAppsSupported() {
    return _.map(this.state.appsSupported, (app) => {
      return (
        <FormGroup check key={app.appName} className="form-checkbox" style={{padding:'0px'}}>
          <Input type="checkbox" name={app.appName} id={app.appName} checked={app.isSupported} onChange={(e) => this.handleAppSupportedChange(app.appName)} />{' '}
          <Label check className="text-center black">
            {app.appName}
          </Label>
        </FormGroup>
      );
    });
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    const {
      name, identifier, planCode, trialDays, bundleOffer,
      additionalAddOnsAmount, amount, baseLinearAddOnsAmount,
      baseLinearMultiplier, baseLinearIntroMonths, billingCycleLengthInMonths,
      concurrencyMultiplier, downgradable, extraLinearAddOnsAmount,
      extrasMultiplier, planIntervalLength,
      planIntervalUnit, totalBillingCycles, upsell, futurePlanId, restartPlanId,
      webUpsell, certificateRequired, additionalDays, priority, planType,
      isPaneOpen, allPlans, loadingAPlan,
      immutable, nonRefundable
    } = this.state;

    return (
      <div ref={ref => this.el = ref} className='plan-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Plan Form'
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ isPaneOpen: false });
            this.props.closePlanFormPane()
          }}>
          {
            loadingAPlan ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="name" id="name" placeholder="Name" onChange={(e) => this.handleTranslationChange(e.target.value)} value={name ? name.value : ' '}/>
                  <Label for="name">Name</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleIdentifierChange(e.target.value)} value={identifier} required/>
                  <Label for="identifier">Identifier</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="planCode" id="planCode" placeholder="Plan Code" onChange={(e) => this.handleChange('planCode', e.target.value)} value={planCode}/>
                  <Label for="planCode">Plan Code</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="trialDays" id="trialDays" placeholder="Trial Days" onChange={(e) => this.handleChange('trialDays', e.target.value)} value={trialDays}/>
                  <Label for="trialDays">Trial Days</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="additionalAddOnsAmount" id="additionalAddOnsAmount" placeholder="Additional AddOns Amount" onChange={(e) => this.handleChange('additionalAddOnsAmount', e.target.value)} value={additionalAddOnsAmount}/>
                  <Label for="additionalAddOnsAmount">Additional AddOns Amount</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="amount" id="amount" placeholder="Amount" onChange={(e) => this.handleChange('amount', e.target.value)} value={amount}/>
                  <Label for="amount">Amount</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="baseLinearAddOnsAmount" id="baseLinearAddOnsAmount" placeholder="Base Linear AddOns Amount" onChange={(e) => this.handleChange('baseLinearAddOnsAmount', e.target.value)} value={baseLinearAddOnsAmount}/>
                  <Label for="baseLinearAddOnsAmount">Base Linear AddOns Amount</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="baseLinearMultiplier" id="baseLinearMultiplier" placeholder="Base Linear Multiplier" onChange={(e) => this.handleChange('baseLinearMultiplier', e.target.value)} value={baseLinearMultiplier}/>
                  <Label for="baseLinearMultiplier">Base Linear Multiplier</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="baseLinearIntroMonths" id="baseLinearIntroMonths" placeholder="Base Linear Intro Months" onChange={(e) => this.handleChange('baseLinearIntroMonths', e.target.value)} value={baseLinearIntroMonths}/>
                  <Label for="baseLinearIntroMonths">Base Linear Intro Months</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="billingCycleLengthInMonths" id="billingCycleLengthInMonths" placeholder="Base Linear Length In Months" onChange={(e) => this.handleChange('billingCycleLengthInMonths', e.target.value)} value={billingCycleLengthInMonths}/>
                  <Label for="billingCycleLengthInMonths">Base Linear Length In Months</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="concurrencyMultiplier" id="concurrencyMultiplier" placeholder="Concurrency Multiplier" onChange={(e) => this.handleChange('concurrencyMultiplier', e.target.value)} value={concurrencyMultiplier}/>
                  <Label for="concurrencyMultiplier">Concurrency Multiplier</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="downgradable" id="downgradable" checked={downgradable} placeholder="Downgradable" onChange={(e) => this.handleChange('downgradable', !downgradable)}/>{' '}
                <Label check className="text-center">
                  Downgradable
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="extraLinearAddOnsAmount" id="extraLinearAddOnsAmount" placeholder="Extra Linear AddOns Amount" onChange={(e) => this.handleChange('extraLinearAddOnsAmount', e.target.value)} value={extraLinearAddOnsAmount}/>
                  <Label for="extraLinearAddOnsAmount">Extra Linear AddOns Amount</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="extrasMultiplier" id="extrasMultiplier" placeholder="Extras Multiplier" onChange={(e) => this.handleChange('extrasMultiplier', e.target.value)} value={extrasMultiplier}/>
                  <Label for="extrasMultiplier">Extras Multiplier</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="immutable" id="immutable" checked={immutable} onChange={(e) => this.handleChange('immutable', !immutable)} />{' '}
                <Label check className="text-center">
                  Mutated
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="nonRefundable" id="nonRefundable" checked={nonRefundable} onChange={(e) => this.handleChange('nonRefundable', !nonRefundable)} />{' '}
                <Label check className="text-center">
                  Cannot Be Refunded
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="planIntervalLength" id="planIntervalLength" placeholder="Plan Interval Length" onChange={(e) => this.handleChange('planIntervalLength', e.target.value)} value={planIntervalLength}/>
                  <Label for="planIntervalLength">Plan Interval Length</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="planIntervalUnit" id="planIntervalUnit"  value={planIntervalUnit} onChange={(e) => this.handleChange('planIntervalUnit', e.target.value)}>
                    <option value={0}>Day</option>
                    <option value={1}>Week</option>
                    <option value={2}>Month</option>
                  </Input>
                  <Label for="planIntervalUnit">Plan Interval Unit</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="totalBillingCycles" id="totalBillingCycles" placeholder="Total Billing Cycles" onChange={(e) => this.handleChange('totalBillingCycles', e.target.value)} value={totalBillingCycles}/>
                  <Label for="totalBillingCycles">Total Billing Cycles</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="upsell" id="upsell" checked={upsell}  onChange={(e) => this.handleChange('upsell', !upsell)} />{' '}
                <Label check className="text-center">
                  Upsell
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="futurePlanId" id="futurePlanId"  value={futurePlanId} onChange={(e) => this.handleChange('futurePlanId', e.target.value)}>
                    {this.renderOptions(allPlans)}
                  </Input>
                  <Label for="futurePlanId" className='select-label'>Future Plan</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="restartPlanId" id="restartPlanId"  value={restartPlanId} onChange={(e) => this.handleChange('restartPlanId', e.target.value)}>
                    {this.renderOptions(allPlans)}
                  </Input>
                  <Label for="restartPlanId" className='select-label'>Restart Plan</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="webUpsell" id="webUpsell" checked={webUpsell} onChange={(e) => this.handleChange('webUpsell', !webUpsell)} />{' '}
                <Label check className="text-center">
                  Web Upsell
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="certificateRequired" id="certificateRequired" checked={certificateRequired} onChange={(e) => this.handleChange('certificateRequired', !certificateRequired)} />{' '}
                <Label check className="text-center">
                  Certificate Required
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="additionalDays" id="additionalDays" placeholder="Additional Days" onChange={(e) => this.handleChange('additionalDays', e.target.value)} value={additionalDays}/>
                  <Label for="additionalDays">Additional Days</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="priority" id="priority" placeholder="Priority" onChange={(e) => this.handleChange('priority', e.target.value)} value={priority}/>
                  <Label for="priority">Priority</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="planType" id="planType"  value={planType} onChange={(e) => this.handleChange('planType', e.target.value)}>
                    <option>grandfathered</option>
                    <option>promotional</option>
                    <option>retailer</option>
                    <option>standard</option>
                    <option>winback</option>
                  </Input>
                  <Label for="planType">Plan Type</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <Label className="select-label">Apps Supported</Label>
                {this.renderAppsSupported()}
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="bundleOffer" id="bundleOffer" checked={bundleOffer} onChange={(e) => this.handleChange('bundleOffer', !bundleOffer)} />{' '}
                <Label check className="text-center">
                  Bundle Offer
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
          }
          <br />
        </SlidingPane>
      </div>
    );
  }

}

const mapStateToProps = ({plansData, DefaultDataCalls, FlashMessageData}) => {
  return {
    name: plansData.name,
    identifier: plansData.identifier,
    planCode: plansData.planCode,
    trialDays: plansData.trialDays,
    additionalAddOnsAmount: plansData.additionalAddOnsAmount,
    amount: plansData.amount,
    baseLinearAddOnsAmount: plansData.baseLinearAddOnsAmount,
    baseLinearMultiplier: plansData.baseLinearMultiplier,
    baseLinearIntroMonths: plansData.baseLinearIntroMonths,
    billingCycleLengthInMonths: plansData.billingCycleLengthInMonths,
    concurrencyMultiplier: plansData.concurrencyMultiplier,
    downgradable: plansData.downgradable,
    extraLinearAddOnsAmount: plansData.extraLinearAddOnsAmount,
    extrasMultiplier: plansData.extrasMultiplier,
    immutable: plansData.immutable,
    nonRefundable: plansData.nonRefundable,
    planIntervalLength : plansData.planIntervalLength,
    planIntervalUnit: plansData.planIntervalUnit,
    totalBillingCycles: plansData.totalBillingCycles,
    upsell: plansData.upsell,
    futurePlanId: plansData.futurePlanId,
    restartPlanId: plansData.restartPlanId,
    bundleOffer: plansData.bundleOffer,
    webUpsell: plansData.webUpsell,
    certificateRequired: plansData.certificateRequired,
    additionalDays: plansData.additionalDays,
    priority: plansData.priority,
    planType: plansData.planType,
    appsSupported: plansData.appsSupported,
    loadingAPlan: plansData.loadingAPlan,
    editPlan: plansData.editPlan,
    allPlans: DefaultDataCalls.defaultPlans,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchAPlan,
  createAPlan,
  updateAPlan
})(PlanForm);
