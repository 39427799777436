import {
  FETCH_A_PLAN_PACKAGE,
} from '../ActionType';

const INITIAL_STATE = {
  planPackagePrice: 0,
  displayPrice: 0,
  freeTrial: false,
  iauEnabled: false,
  loadingAPlanPackage: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_A_PLAN_PACKAGE:
      let {
        planPackagePrice, displayPrice, freeTrial, iauEnabled
      } = action.payload
      return {
        ...state, planPackagePrice, displayPrice, freeTrial, iauEnabled, loadingAPlanPackage: false
      };
    default:
      return state;
  }
}
