import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchPackagePlan, fetchAllPlans} from '../../../../scripts/redux/actions';
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Cookie from 'js-cookie';

class PackagePlanForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      planPackagePrice: '',
      displayPrice: '',
      freeTrial: false,
      iauEnabled: false,
      otherPlans: [],
      id: '',
      plan_id: this.props.planId,
      packageId: this.props.packageId
    }
  }

  handleChange(key, value) {
    console.log(`Key - ${key}, val - ${value}`);
    this.setState({[key]: value})
  }

  componentDidMount() {
      Modal.setAppElement(this.el);
  }

  PackagePlanForm() {
    let {plan_id, packageId} = this.state
    this.props.fetchPackagePlan(plan_id, packageId).then(() => {
      this.setState({
        isPaneOpen: true,
      });
    })
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.props.fetchAllPlans().then((json)=> {
      console.log('json - ', json);
      let otherPlans = _.differenceBy(json, this.props.existingObjects, 'id')
      console.log('otherPlans = ', otherPlans);
      this.setState({otherPlans})
      this.PackagePlanForm()
    })
  }

  componentWillReceiveProps(nextProps) {
    const {
      planPackagePrice, displayPrice, freeTrial, iauEnabled, id, plan_id
    } = nextProps
    this.setState({planPackagePrice, displayPrice, freeTrial, iauEnabled, id, plan_id})
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const { planPackagePrice, displayPrice, freeTrial, iauEnabled, plan_id, id, packageId } = this.state
    var URL = `${process.env.REACT_APP_PC_URL}update-package-plan`;
    var params = { planPackagePrice, displayPrice, freeTrial, iauEnabled, plan_id, id, packageId }
    console.log('call api with params - ', params );
    fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      this.props.showNotificationPanel(true, json.status.code, json.status.message)
      this.props.closePackagePlanFormPane(null, true)
    })
    .catch(error => {
      console.log('PlanForm Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.id} value={opt.id}>{opt.identifier}</option>
      );
    });
  }

  render() {
    const { planPackagePrice, displayPrice, freeTrial, iauEnabled, otherPlans,
      isPaneOpen, plan_id
    } = this.state;

    return (
      <div ref={ref => this.el = ref} className='plan-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Package Plan Form'
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ isPaneOpen: false });
            this.props.closePackagePlanFormPane()
          }}>
          <Form onSubmit={(e) => {this.handleSubmit(e)}}>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="number" name="planPackagePrice" id="planPackagePrice" placeholder="Plan Package Price" onChange={(e) => this.handleChange('planPackagePrice', e.target.value)} value={planPackagePrice}/>
                <Label for="planPackagePrice">Plan Package Price</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="displayPrice" id="displayPrice" placeholder="Display Price" onChange={(e) => this.handleChange('displayPrice', e.target.value)} value={displayPrice}/>
                <Label for="displayPrice">Display Price</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <Label check className="text-center" style={{paddingLeft: '12px'}}>
                <Input type="checkbox" name="freeTrial" id="freeTrial" checked={freeTrial} onChange={(e) => this.handleChange('freeTrial', !freeTrial)} />{' '}
                Free Trial
              </Label>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <Label check className="text-center" style={{paddingLeft: '12px'}}>
                <Input type="checkbox" name="iauEnabled" id="iauEnabled" checked={iauEnabled} onChange={(e) => this.handleChange('iauEnabled', !iauEnabled)} />{' '}
                IAU Enabled
              </Label>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <Input type="select" name="plan_id" id="plan_id" value={plan_id} onChange={(e) => this.handleChange('plan_id', e.target.value)}>
                  {this.renderOptions(otherPlans)}
                </Input>
                <Label for="otherPlans">Plan</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <button className="btn btn-primary"
                      style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                      Submit
              </button>
            </FormGroup>
          </Form>
          <br />
        </SlidingPane>
      </div>
    );
  }

}

const mapStateToProps = ({PackagePlanData}) => {
  return {
    planPackagePrice: PackagePlanData.planPackagePrice,
    displayPrice: PackagePlanData.displayPrice,
    freeTrial: PackagePlanData.freeTrial,
    iauEnabled: PackagePlanData.iauEnabled,
    id: PackagePlanData.id,
    plan_id: PackagePlanData.plan_id,
  };
};

export default connect(mapStateToProps, {
  fetchPackagePlan,
  fetchAllPlans
})(PackagePlanForm);
