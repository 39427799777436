import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PC from './PC/PC.js';
import Login from './Common/Login/Login.js';
import PrivateRoute from './Common/PrivateRoute/PrivateRoute.js';

const Main = () => (
  <Switch>
    <PrivateRoute path='/' exact={true} component={PC} />
    <Route path='/pc' component={PC} />
    <Route path='/login' component={Login} />
  </Switch>
);

export default Main
