import Cookie from 'js-cookie';

export function authHeader() {
  // return authorization header with jwt token
  let token = JSON.parse(localStorage.getItem('token'));

  if (token) {
      return { 'Authorization': token, 'Content-Type': 'application/json' };
  } else {
      return {};
  }
}

export function signOut() {
  Cookie.remove('token');
  Cookie.remove('authtoken');
  window.location.reload();
  return;
}

export function fetchAPI(URL, params) {
  let targetURL = URL;
  if(window.location.protocol === "http:"){
    targetURL = targetURL.replace('https:','https:');
  } else {
    targetURL = targetURL.replace('http:','https:');
  }
  return fetch(targetURL, params)
};

export async function callApi(url, requestMethod, requestHeaders, requestBody) {
  const timeout = 60000;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const headers =  new Headers({
    'Content-Type': 'application/json',
  });
  const method = requestMethod["method"];
  const body = requestBody && JSON.stringify(requestBody);
  const response = await fetchAPI(url, {
    method,
    headers,
    body,
    signal: controller.signal  
  });
  clearTimeout(id);
  return response;
}
