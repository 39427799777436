import Cookie from 'js-cookie';
import {
  FETCH_CLASSIFICATIONS,
  SET_NOTIFICATION_PANEL,
  RESET_NOTIFICATION_PANEL,
  FETCH_A_CLASSIFICATION,
  LOADING_CLASSIFICATION_FORM,
} from '../ActionType';
import _ from 'lodash';
import {fetchDefaultPlans} from './DefaultDataCalls';
import {fetchPackagesByClassification} from './Package';
import {fetchAllChannels} from './Channel';
import {fetchAllPPVPackages} from './PayPerView';
import {signOut} from '../helper';

export const fetchAllClassifications = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}classifications`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let classifications = json;
        classifications = _.sortBy(classifications, 'priority');
        dispatch({
          type: FETCH_CLASSIFICATIONS,
          payload: {classifications}
        });
      })
      .catch(error => {
          console.log('fetchAllClassifications Error - ', error);
      })
  }
}

// ToDo - Move it to appropriate location
export const grandFatherToggle = (params, type, filters={}) => {
  return (dispatch) => {
    const URL = `${['channel', 'package'].includes(type) ? process.env.REACT_APP_NODE_PC_ADMIN_URL : process.env.REACT_APP_PC_URL}disable-${type}`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      switch (type) {
        case 'classification':
          dispatch(fetchAllClassifications())
          return;
        case 'package':
          (filters.packageType === 'ppv') ? dispatch(fetchAllPPVPackages()) : dispatch(fetchPackagesByClassification(filters.classification, filters.packageType))
          return;
        case 'channel':
          dispatch(fetchAllChannels())
          return;
        default:
          return;
      }

    })
    .catch(error => {
        console.log('grandFatherToggle Error - ', error);
        // this.showNotificationPanel(true, json.status.code, json.status.message)
    })
  }
}

export const fetchAClassification = (id = null) => {
  let typeOfCall;
  if (id) {
    typeOfCall = 'classificationEdit'
  }
  return (dispatch) => {
    dispatch({type: LOADING_CLASSIFICATION_FORM})
    dispatch(fetchDefaultPlans(id, typeOfCall))
  }
}

export const fetchAClassificationAfter = (id) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}classification/${id}`;
    return fetch(URL, {method: 'GET',headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        identifier, priority, iapPriority, featuredImagePath,
        featuredShowLogoPath, customerCategoryId, defaultPackageId,
        defaultPlanId, appsSupported, salesNumber, supportNumber,
        enabled
      } = json;
      dispatch({
        type: FETCH_A_CLASSIFICATION,
        payload: {
          identifier, priority, iapPriority, featuredImagePath,
          featuredShowLogoPath, customerCategoryId, defaultPackageId,
          defaultPlanId, appsSupported, salesNumber, supportNumber,
          enabled
        }
      })
    })
  }
}

export const createAClassification = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-update-classification`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllClassifications())
    })
    .catch(error => {
      console.log('createAClassification Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

//  ToDo - Move it to appropriate location
export const resetNotificationPanel = () => {
  return (dispatch) => {
      dispatch({
        type: RESET_NOTIFICATION_PANEL,
      })
  }
}
