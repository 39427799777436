import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchPackageBase,
  resetPartnerPackageForm,
  fetchAPartnerPackage,
  loadingPartnerPackageForm,
  updateAPartnerPackage,
} from '../../../redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Collapse, Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

const styles = {
  partnerPlatformsButton: {
    fontSize: '14px',
    border: '1px solid darkgray',
    borderRadius: '50px',
    marginBottom: '1rem',
  }
}

export class PartnerPackageForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingAPartnerPackage: true,
      selectAll: false,
      displayExtra: [],
      searchActive: false,
      searchVal: '',
      showAllSelected: false
    }
    this.clearSearch = this.clearSearch.bind(this)
    this.showAllHandler = this.showAllHandler.bind(this)
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    const {
      openedPackageId,
      fetchAPartnerPackage,
      resetPartnerPackageForm,
      partnerClassificationId,
      fetchPackageBase
    } = this.props;

    fetchPackageBase().then(() => {
      if (openedPackageId) {
        this.setState({ loadingAPartnerPackage: true })
        fetchAPartnerPackage(partnerClassificationId, openedPackageId).then(() => {
          this.setState({ loadingAPartnerPackage: false })
        })
      } else {
        resetPartnerPackageForm()
        this.setState({
          loadingAPartnerPackage: false
        })
      }
    })
    this.setState({ isPaneOpen: true })
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    const {
      packageId, enabled, priority, loadingAPartnerPackage,
      statusCode, defaultPackages, id, allPackageExtras
    } = nextProps

    this.setState({
      packageId, enabled, priority, loadingAPartnerPackage,
      statusCode, defaultPackages, id, allPackageExtras, displayExtra: allPackageExtras
    })

    if (nextProps.statusCode === "Success") {
      this.props.closePartnerPackageFormPane(null, true)
    }
  }

  handlePackageExtraChange(packageId) {
    let allFilteredExtraCopy = this.state.displayExtra.filter(pE => pE.packageId !== packageId);
    let extraCopy = this.state.displayExtra.find(pE => pE.packageId === packageId);
    extraCopy.isExtra = !extraCopy.isExtra;
    let newUpdatedExtras = [ ...allFilteredExtraCopy, extraCopy ];
    this.setState({ displayExtra: this.state.showAllSelected ? this.state.displayExtra.filter(n => n.isExtra === true) : newUpdatedExtras });
  }

  handleEnabledChange(packageId) {
    let filteredData = this.state.displayExtra.filter(pE => pE.packageId !== packageId);
    let extraCopy = this.state.displayExtra.find(pE => pE.packageId === packageId);
    extraCopy.enabled = !extraCopy.enabled;
    let newUpdatedVal = [ ...filteredData, extraCopy ];
    this.setState({ displayExtra: newUpdatedVal });
  }

  handlePriorityChange(e, packageId) {
    let filteredData = this.state.displayExtra.filter(pE => pE.packageId !== packageId);
    let extraCopy = this.state.displayExtra.find(pE => pE.packageId === packageId);
    extraCopy.priority = e.target.value;
    let newUpdatedVal = [ ...filteredData, extraCopy ];
    this.setState({ displayExtra: newUpdatedVal });
  }

  selectAllHandler() {
    let newExtras = this.state.displayExtra.map(ex => {
      if (!this.state.selectAll) {
        ex.isExtra = true;
        ex.priority = this.isEmpty(ex.priority) ? 0 : ex.priority;
        ex.enabled = true;
      } else {
        ex.isExtra = false;
      }
      return ex
    });
    this.setState((prevState) => {
      return { selectAll: !prevState.selectAll, displayExtra: newExtras }
    });
  }

  searchPackageExtra(searchVal) {
    let checkEmpty = !this.isEmpty(searchVal);
    let newFilteredData = this.state.allPackageExtras.filter(pE => pE.identifier.includes(searchVal) || pE.packageType.includes(searchVal));
    this.setState({ displayExtra: newFilteredData, searchActive: checkEmpty, searchVal });
  }

  clearSearch() {
    this.searchPackageExtra("")
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      if (this.state.packageId) {
        return (
          <option key={opt.value} value={opt.value} selected>{opt.label}</option>
        )
      } else {
        return (
          <option key={opt.value} value={opt.value}>{opt.label}</option>
        )
      }
    });
  }

  showAllHandler() {
    this.setState((prevState) => {
      return { showAllSelected: !prevState.showAllSelected, displayExtra: this.state.showAllSelected ? this.state.allPackageExtras : this.state.displayExtra.filter(n => n.isExtra === true) }
    });
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const {
      packageId, enabled, priority, id, displayExtra
    } = this.state
    let newExtras = displayExtra.filter(pE => pE.isExtra);
    var params = {
      packageId, enabled, priority, id, allPackageExtras: newExtras
    };
    this.props.updateAPartnerPackage(params)
  }

  render() {
    const {
     enabled, priority, loadingAPartnerPackage, searchVal,
     defaultPackages, isPaneOpen, packageId, displayExtra, searchActive,
     showAllSelected
    } = this.state;
    let sortedExtras = _.sortBy(displayExtra, 'identifier');
    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Partner Package Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closePartnerPackageFormPane()
          }}>
          { loadingAPartnerPackage ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="packageId" id="packageId"  value={packageId} onChange={(e) => this.handleChange('packageId', e.target.value)}>
                    {this.renderOptions(defaultPackages)}
                  </Input>
                  <Label for="packageId" className='select-label'>Partner Package</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="priority" id="priority" placeholder="Priority" onChange={(e) => this.handleChange('priority', e.target.value)} value={priority} required/>
                  <Label for="priority">Priority</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="enabled" id="enabled" checked={enabled} onChange={(e) => this.handleChange('enabled', !enabled)} />{' '}
                <Label check className="text-center"> Enabled </Label>
              </FormGroup>
              <div>
                <button onClick={this.props.onTogglePackageExtra} style={styles.partnerPlatformsButton}>
                  Package Extras And One Time Purchase Items
                </button>
                <Collapse isOpen={this.props.packageExtraOpen}>
                  <div className="search-section" style={{marginLeft:'15px', paddingBottom:'55px', width: '50%'}}>
                    <div className='aaa'>
                      <input onChange={(e)=>this.searchPackageExtra(e.target.value)} value={searchVal}
                              type="search" placeholder="What are you looking for?" />
                    </div>
                  </div>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="selectall-package-extra" id="selectall-package-extra" checked={this.state.selectAll} onChange={() => this.selectAllHandler()} />
                    <Label check className="text-center">
                      {this.state.selectAll ? "Deselect All" : "Select All"}
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="showall-selected" id="showall-selected" checked={showAllSelected} onChange={(e) => this.showAllHandler(e)} />
                    <Label check className="text-center">
                      {showAllSelected ? "Show All" : "Show All Selected"}
                    </Label>
                  </FormGroup>
                  {
                    searchActive ?
                    <div>
                      <span className="note-section">Note: Clear the search to enable the submit button</span>
                      <FormGroup className="col-sm-12">
                        <button className="btn btn-primary"
                                onClick={this.clearSearch}
                                style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                                Clear Search
                        </button>
                      </FormGroup>
                    </div>
                      :
                    <FormGroup className="col-sm-12">
                      <button type="submit"
                              className="btn btn-primary"
                              disabled={searchActive}
                              style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                              Submit
                      </button>
                    </FormGroup>
                  }
                  {!this.isEmpty(sortedExtras) ? sortedExtras.map(pP =>
                    (
                      <FormGroup key={pP.packageId} className="col-sm-12 form-checkbox">
                        <Input type="checkbox" name="package-extra" id={pP.packageId} checked={pP.isExtra} onChange={() => this.handlePackageExtraChange(pP.packageId)} />
                        <span className="check-css">
                          {pP.identifier} ({pP.packageType})
                          {
                            pP.isExtra ?
                            <span style={{ float: 'right' }}>
                              <input type="number" name="priority" id={`priority-${pP.packageId}`} placeholder="Priority" value={pP.priority} onChange={(e) => this.handlePriorityChange(e, pP.packageId)} />
                              <i className={pP.enabled ? "fa fa-toggle-on text-green" : "fa fa-toggle-off float-right text-red"} onClick={() => this.handleEnabledChange(pP.packageId)}/>
                            </span>
                            : null
                          }
                        </span>
                      </FormGroup>
                    )
                  ) : null}
                </Collapse>
              </div>
              {
                searchActive ?
                <div>
                  <span className="note-section">Note: Clear the search to enable the submit button</span>
                  <FormGroup className="col-sm-12">
                    <button className="btn btn-primary"
                            onClick={this.clearSearch}
                            style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                            Clear Search
                    </button>
                  </FormGroup>
                </div>
                 :
                <FormGroup className="col-sm-12">
                  <button type="submit"
                          className="btn btn-primary"
                          disabled={searchActive}
                          style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                          Submit
                  </button>
                </FormGroup>
              }
            </Form>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({
    PartnerPackageData,
    FlashMessageData,
    DefaultDataCalls,
  }) => {
  return {
    packageId: PartnerPackageData.packageId,
    pack: PartnerPackageData.pack,
    id: PartnerPackageData.id,
    loadingAPartnerPackage: PartnerPackageData.loadingAPartnerPackage,
    enabled: PartnerPackageData.enabled,
    priority: PartnerPackageData.priority,
    allPackageExtras: PartnerPackageData.allPackageExtras,
    statusCode: FlashMessageData.statusCode,
    defaultPackages: DefaultDataCalls.defaultPackageBasePacks,
  };
}

export default connect(mapStateToProps, {
  fetchPackageBase,
  resetPartnerPackageForm,
  fetchAPartnerPackage,
  loadingPartnerPackageForm,
  updateAPartnerPackage,
})(PartnerPackageForm);
