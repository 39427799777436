import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchAllChannels, grandFatherToggle} from '../../../../scripts/redux/actions'
import ChannelForm from '../ChannelForm/ChannelForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import { Card, CardFooter, CardBody, Row } from 'reactstrap';
import './Channel.css';
import ChannelTabs from './ChannelTabs';

class NewChannel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      channels: [],
      displayingChannels: [],
      showChannelForm: false,
      openedChannel: null,
      loading: true,
      footerOpts: {0: true, 1: true},
      displayCardClass: '',
    }
    this.toggleChannelForm = this.toggleChannelForm.bind(this)
    this.grandFatherToggle = this.grandFatherToggle.bind(this)
  }

  setChannelTabsSectionClass() {
    let packageTabsSectionClass = 'package-tabs-section';
    let siCardBodyMd = '';
    let siCardMd = '';
    let tileSelected = 'tgc-tile-selected';
    if (window.innerWidth <= 1422) {
      packageTabsSectionClass = 'package-tabs-section'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      packageTabsSectionClass = 'package-tabs-section'
      siCardBodyMd = 'si-card-body-md'
      siCardMd = 'si-card-md'
      tileSelected = 'tgc-tile-selected tile-selected-md'
    } else if (window.innerWidth > 1440){
      packageTabsSectionClass = 'package-tabs-section package-tabs-section-lg'
    }
    this.setState({packageTabsSectionClass, siCardBodyMd, siCardMd, tileSelected})
  }

  grandFatherToggle(value) {
    let enabledValue;
    if (value.active) {
      enabledValue = 0;
    } else if (!value.active) {
      enabledValue = 1;
    }
    let params = {
      channelId: value.id,
      enabled: enabledValue
    }
    this.props.grandFatherToggle(params, 'channel');
  }

  componentWillMount() {
    this.setChannelTabsSectionClass()
    this.props.fetchAllChannels()
  }

  componentWillReceiveProps(nextProps) {
    const {channels, displayingChannels, loading} = nextProps
    this.setState({channels, displayingChannels, loading})
  }

  toggleChannelForm(id=null, getChannels=false) {
    if (id) {
      this.setState({ showChannelForm: true, openedChannel: id });
    } else {
      this.setState({ showChannelForm: false})
    }
    if (getChannels) {
      this.props.fetchAllChannels()
    }
  }

  displayChannelTabs(openedChannel, selectedDiv) {
    this.setState({showChannelTabs: true, openedChannel, selectedDiv})
  }

  closeChannelTabs() {
    this.setState({showPackageTabs: false, selectedDiv: false})
  }

  search(val) {
    const {channels} = this.state
    let displayingChannels = channels.filter(c => (c.name.toLowerCase().search(val.toLowerCase()) !== -1) || c.identifier.toLowerCase().search(val.toLowerCase()) !== -1)
    this.setState({displayingChannels})
  }

  render() {
    const { openedChannel, displayingChannels, loading,
            footerOpts, showChannelForm, selectedDiv,
            tileSelected, siCardMd, siCardBodyMd, showChannelTabs,
            packageTabsSectionClass,
          } = this.state

    const Item = ({value, name}) => {
      return (
        <div className='package-sortable-item'>
          <div className={`${name === selectedDiv ? tileSelected : ""}`}>
            <Card className={`margin-bottom-5 ${siCardMd}`}>
              <CardBody className={`si-card-body ${siCardBodyMd}`} onClick={() => this.displayChannelTabs(value.id, name)}>
                <span className="text-left font-size-18">{value.name || value.identifier}</span>
              </CardBody>
              <CardFooter className={`height-48 card-footer-color ${name === selectedDiv ? tileSelected : ""}`}>
                <div className="text-center si-card-footer">
                  {footerOpts[0] && <i className="fa fa-pencil" onClick={() => this.toggleChannelForm && this.toggleChannelForm(value.id)}></i>}
                  { footerOpts[1] &&
                    ((value.enabled || value.active) ?
                    <i className="fa fa-toggle-on margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.grandFatherToggle && this.grandFatherToggle(value) } } /> :
                    <i className="fa fa-toggle-off margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.grandFatherToggle && this.grandFatherToggle(value) } } />)
                  }
                </div>
              </CardFooter>
            </Card>
          </div>
          { showChannelTabs && (name === selectedDiv) ?
          <div className={`${packageTabsSectionClass} ${name === selectedDiv ? "block" : ""}`}>
            <div onClick={() => this.closeChannelTabs()} className="wrapper">
              <a href className="close-button">
                <div className="in">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
                <div className="out">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
              </a>
            </div>
            <div><ChannelTabs showNotificationPanel={this.showNotificationPanel}
                         openedChannel={openedChannel}/></div>
          </div> : null }
        </div>
      )
    };

    const List = ({items}) => {
      return (
        <Row>
          {
            items.map((value, index) => (
              <Item key={index} index={index} value={value} name={index}/>
            ))
          }
        </Row>
      );
    };

    return (
      <div>
        <FlashMessage />
        <div className='channel'>
          <div className='channel-header'>
            <div className="search-section channel-search">
              <div className='aaa'>
                <input onKeyUp={(e)=>this.search(e.target.value)}
                       type="search" placeholder="What are you looking for?" />
              </div>
            </div>
            <div className='new-channel-div'>
              <button className="btn btn-primary new-channel-btn"
                      onClick={()=> this.setState({
                                                    showChannelForm: true,
                                                    openedChannel: null})}>
              <i className="fa fa-plus"></i> New Channel
              </button>
            </div>
          </div>
          <div className='new-channel-content'>
            { loading && <Loading /> }
            {
              (!loading && displayingChannels.length === 0) ? <NoData></NoData> :
              <List items={displayingChannels} />
            }
            {
              showChannelForm ?
              <ChannelForm channelId={openedChannel}
                           closeEditChannelPane={this.toggleChannelForm}/>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({channelsData}) => {
  return {
    channels: channelsData.channels,
    displayingChannels: channelsData.displayingChannels,
    loading: channelsData.loading,
  };
}

export {NewChannel};

export default connect(mapStateToProps, {
  fetchAllChannels,
  grandFatherToggle,
})(NewChannel);
