import {
  FETCH_CLASSIFICATIONS,
  LOADING_CLASSIFICATION_FORM,
  FETCH_A_CLASSIFICATION,
  RESET_CLASSIFICATION_FORM,
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  classifications: [],
  displayingClassifications: [],
  loading: true,
  // Particular Classification
  identifier: '',
  priority: '',
  iapPriority: '',
  featuredImagePath: '',
  featuredShowLogoPath: '',
  customerCategoryId: 0,
  defaultPackageId: 0,
  defaultPlanId: 0,
  appsSupported: [
                  {appName: "icepak", isSupported: false},
                  {appName: "ottweb", isSupported: false},
                  {appName: "fakeblock", isSupported: false}
                ],
  salesNumber: '',
  supportNumber: '',
  enabled: 0,
  loadingAClassification: false,
  name: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CLASSIFICATIONS:
      let {classifications} = action.payload
      return {...state, classifications, displayingClassifications: classifications, loading: false};
    case FETCH_A_CLASSIFICATION:
      let {
        identifier, priority, iapPriority, featuredImagePath,
        featuredShowLogoPath, customerCategoryId, defaultPackageId,
        defaultPlanId, appsSupported, salesNumber, supportNumber,
        enabled, name
      } = action.payload
      return {
        ...state, identifier, priority, iapPriority, featuredImagePath,
        featuredShowLogoPath, customerCategoryId, defaultPackageId,
        defaultPlanId, appsSupported, salesNumber, supportNumber,
        enabled, name, loadingAClassification: false
      };
    case LOADING_CLASSIFICATION_FORM:
      return {...state, loadingAClassification: !state.loadingAClassification};
    case RESET_CLASSIFICATION_FORM:
      let emptyClassificationForm = _.omit(INITIAL_STATE, ['classifications', 'displayingClassifications','loading', 'loadingAClassification']);
      return {...state, ...emptyClassificationForm, loadingAClassification: true };
    default:
      return state;
  }
}
