import { FETCH_MORE_OBJECTS  } from '../ActionType';

const INITIAL_STATE = {
  objects: [],
  displayingObjects: [],
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MORE_OBJECTS:
      let {objects, displayingObjects} = action.payload
      return {...state, objects, displayingObjects, loading: false};
    default:
      return state;
  }
}
