import React, { Component } from 'react';
import { connect } from 'react-redux';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import { Button, Input } from 'semantic-ui-react';
import { fetchTagTree, fetchAContentTag, deleteTagChildrens } from '../../../../scripts/redux/actions'
import ContentTagForm from '../ContentTagForm/ContentTagForm';
import ContentTagDetailsPane from '../ContentTagDetailsPane/ContentTagDetailsPane';
import Loading from '../../Common/Loading/Loading.js';
import 'react-sortable-tree/style.css';
import './ContentTag.css';

class ContentTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
      searchFocusIndex: 0,
      treeData: [],
      contentCategoryTagId: '',
      tagCategoryId: '',
      tagId: '',
      showAddDeleteContentTagForm: false,
      showContentTagDetailsPane: false,
      loading: true
    }

    this.toggleContentTagForm = this.toggleContentTagForm.bind(this)
    this.toggleContentTagDetailsPane = this.toggleContentTagDetailsPane.bind(this)
    this.deleteNode = this.deleteNode.bind(this)
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  componentWillMount() {
    this.props.fetchTagTree();
  }

  componentWillReceiveProps(nextProps) {
    const { treeData, loading } = nextProps;
    this.setState({ treeData, loading });
    if (nextProps.statusCode === "Success") {
      this.setState({ loading: true });
      this.props.fetchTagTree()
    }
  }

  expandAndCollapse = (expanded) => {
    this.setState({
      treeData: toggleExpandedForAll({
        treeData: this.state.treeData,
        expanded,
      }),
    });
  };

  updateTreeData(treeData) {
    this.setState({ treeData });
  }

  contentTagDetails(node) {
    let { tag_id, content_tag_id, tag_category_id: tagCategoryId, content_category_tag_id: contentCategoryTagId } = node;
    let tagId = !this.isEmpty(tag_id) ? tag_id : content_tag_id;
    this.setState({ showContentTagDetailsPane: true, tagId, tagCategoryId, contentCategoryTagId });
  }

  toggleContentTagForm() {
    this.props.fetchTagTree();
    this.setState({ showAddDeleteContentTagForm: false, loading: true })
  }

  toggleContentTagDetailsPane() {
    this.props.fetchTagTree();
    this.setState({ showContentTagDetailsPane: false, tagId: '', tagCategoryId: '', contentCategoryTagId: '', loading: true });
  }

  deleteNode(node) {
    let { tag_id, tag_category_id, content_tag_id, content_category_tag_id } = node;
    let params;
    if (!this.isEmpty(tag_id) && !this.isEmpty(tag_category_id)) {
      params = {
        tagId: tag_id,
        tagCategoryId: tag_category_id,
        contentCategoryTagId: content_category_tag_id,
        masterDelete: false
      }
    } else if (this.isEmpty(tag_id) && this.isEmpty(tag_category_id)) {
      params = {
        tagId: content_tag_id,
        masterDelete: true
      }
    }

    this.props.deleteTagChildrens(params);
  }

  render() {
    const { 
      treeData, searchString, searchFocusIndex, showAddDeleteContentTagForm,
      contentCategoryTagId, tagCategoryId, tagId, showContentTagDetailsPane,
      loading
    } = this.state
    return (
      <div>
        <FlashMessage />
        <div className="contentTag-header">
          <div className='new-contentTag-div'>
            <button className="btn btn-primary new-contentTag-btn"
                    onClick={()=> this.setState({ showAddDeleteContentTagForm: true })}>
            <i className="fa fa-plus"></i> Add/Edit/Delete Content Tag
            </button>
          </div>
          <div className='new-contentTag-div'>
            <button className="btn btn-primary new-contentTag-btn"
                    onClick={()=>{this.props.history.push('/pc/content_tag_validation')}}>Content Tag Validation</button>
          </div>
        </div>
        <div style={{ height: 10000 }}>
          <div style={{ flex: '0 0 auto', padding: '0 15px' }}>
            <Button size='mini' color='blue' onClick={() => { this.expandAndCollapse(true); }}>Expand all</Button>
            <Button size='mini' color='blue' onClick={() => { this.expandAndCollapse(false); }}>Collapse all</Button>
            <Input size='mini' placeholder='Search' value={searchString}
              onChange={event => this.setState({ searchString: event.target.value })}/>
          </div>
          { loading ? <div><Loading /> <h3 className="text-center">Please wait, while tag tree is loading...</h3></div>
          : <SortableTree
              searchQuery={searchString}
              onChange={this.updateTreeData}
              searchFocusOffset={searchFocusIndex}
              treeData={treeData}
              canDrag={false}
              onChange={treeData => this.setState({ treeData })}
              generateNodeProps={({ node, path }) => ({
                title: (
                  <div className="tree-form">
                    <ul className="main-lists">
                      <li className="list-item">
                        <span>{node.title} ({node.type})</span>
                      </li>
                      {
                        ["packages", "lines_of_business", "channels", "plans"].includes(node.type) ? "" :   
                          (<span><li className="list-item">
                            <span><i className='fa fa-pencil' onClick={() => this.contentTagDetails(node)} /></span>
                          </li>
                          <li className="list-item">
                            <span><i className='fa fa-trash' onClick={() => { if (window.confirm('Are you sure you wish to do this action?')) this.deleteNode(node)} } /></span>
                          </li></span>)
                      }
                    </ul>
                  </div>
                )
              })}
            /> }
        </div>
        {
          showAddDeleteContentTagForm ? 
            <ContentTagForm closeContentTagFormPane={this.toggleContentTagForm} />
          : null
        }
        {
          showContentTagDetailsPane ? 
            <ContentTagDetailsPane 
              closeContentTagDetailsPane={this.toggleContentTagDetailsPane}
              contentCategoryTagId={contentCategoryTagId}
              tagCategoryId={tagCategoryId}
              tagId={tagId}
            />
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = ({ContentTagsData, FlashMessageData}) => {
  return {
    treeData: ContentTagsData.treeData,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchTagTree,
  fetchAContentTag,
  deleteTagChildrens
})(ContentTag);
