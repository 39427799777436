import Cookie from 'js-cookie';
import {
  FETCH_DMACODESZIPCODES,
  SET_NOTIFICATION_PANEL,
  FETCH_A_DMACODESZIPCODE,
  LOADING_DMACODESZIPCODE_FORM,
  RESET_DMACODESZIPCODE_FORM,
  EDIT_DMACODESZIPCODE,
  FETCH_DMACODES,
  FETCH_ZIPCODES,
  UPDATE_DMACODESZIPCODES_STATE
} from '../ActionType';
import _ from 'lodash';
import {fetchDefaultClassifications} from './DefaultDataCalls';
import {signOut} from '../helper';

export const fetchAllDmaCodesZipcodes = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}dma-codes-zipcodes`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let dmaCodesWithName = json.map(dcN => {
          let dmaCodeVal = `${dcN.dmaCode.name}`;
          return { ...dcN, dmaCodeVal }
        });
        let dmaCodesSortByName = _.sortBy(dmaCodesWithName, 'dmaCodeVal');
        let dmaCodesZipcodes = dmaCodesSortByName.map(dc => {
          let dmaCodeVal = `${dc.dmaCode.dmaCode} (${dc.dmaCode.name})`;
          let zipCodeVal = dc.zipcode ? dc.zipcode.zipcode : null;
          return { ...dc, dmaCodeVal, zipCodeVal }
        });
        dispatch({
          type: FETCH_DMACODESZIPCODES,
          payload: {dmaCodesZipcodes}
        });
      })
      .catch(error => {
          console.log('fetchAllDmaCodesZipcodes Error - ', error);
      })
  }
}

export const fetchAllDmaCodes = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}dma-codes`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let sortedData = _.sortBy(json, 'name');
        let dmaCodes = [{ id: null, name: 'Select DMA Code', dmaCode: null }, ...sortedData];
        dispatch({
          type: FETCH_DMACODES,
          payload: {dmaCodes}
        });
      })
      .catch(error => {
          console.log('fetchAllDmaCodes Error - ', error);
      })
  }
}

export const fetchAllZipCodesByDmaCodeId = (params) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}all-zipcodes/${parseInt(params)}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let zipCodes = json;
        dispatch({
          type: FETCH_ZIPCODES,
          payload: {zipCodes}
        });
      })
      .catch(error => {
          console.log('fetchAllZipCodes Error - ', error);
      })
  }
}

export const fetchADmaCodesZipcode = (id = null) => {
  let editDmaCodesZipcode = false;
  const typeOfCall = id ? 'dmaCodesZipcodeEdit' : 'dmaCodesZipcodeCreate';
  if (id) { editDmaCodesZipcode = true }
  return (dispatch) => {
    dispatch({type: LOADING_DMACODESZIPCODE_FORM, payload: true}) // Even if we're creating a new channel, we're still waiting for fresh default classification info
    dispatch({type: RESET_DMACODESZIPCODE_FORM})
    dispatch({type: EDIT_DMACODESZIPCODE, payload: {editDmaCodesZipcode}})
    dispatch(fetchDefaultClassifications(id, typeOfCall))
  }
}

export const fetchADmaCodesZipcodeAfter = (id) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}dma-codes-zipcode/${id}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        id,
        ota_qual: otaQual,
        ontech_qual: ontechQual,
        locast_qual: locastQual
      } = json.data.dma_codes_zipcodes;
      let dmaCode = json.data.dma_codes;
      let zipcode = json.data.zipcodes;
      dispatch({
        type: FETCH_A_DMACODESZIPCODE,
        payload: {
          id,
          otaQual,
          ontechQual,
          locastQual,
          dmaCode,
          zipcode
        }
      })
    })
  }
}

export const createADmaCodesZipcode = (params) => {
  let statusCode;
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}create-update-dma-code-zipcode`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => {
      statusCode = response.status;
      return response.json()
    })
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllDmaCodesZipcodes())
    })
    .catch(error => {
      console.log('createADmaCodesZipcode Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const updateADmaCodesZipcode = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}create-update-dma-code-zipcode`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => {
      return response.json()
    })
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      const { dma_code_zipcode } = json.data
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch({
        type: UPDATE_DMACODESZIPCODES_STATE,
        payload: {dma_code_zipcode}
      });
    })
    .catch(error => {
      console.log('updateADmaCodesZipcode Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}
