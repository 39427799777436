import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateAPlanPackage } from '../../../../scripts/redux/actions';
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { CardHeader, Form, FormGroup, Label, Input } from 'reactstrap';
import './AddDeletePlanPackageForm.css';

export class AddDeletePlanPackageForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      partnerPlatformIdsByPackageId: [],
      selectAll: false,
      allPackageList: this.props.allPackageList,
      displayPackageList: this.props.allPackageList,
      searchActive: false,
      planId: this.props.planId,
      searchVal: ''
    }
    this.handleBooleanChanges = this.handleBooleanChanges.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.setState({ isPaneOpen: true });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.statusCode === "Success") {
      this.props.closePlanPackageFormPane(null, true)
    }
  }

  selectAllHandler() {
    let newExtras = this.state.displayPackageList.map(ex => {
      ex.isNew = this.state.selectAll ? false : true;
      return ex
    });
    this.setState((prevState) => {
      return { selectAll: !prevState.selectAll, displayPackageList: newExtras }
    });
  }

  handleBooleanChanges(type, packageId) {
    let allFilteredExtraCopy = this.state.displayPackageList.filter(pE => pE.packageId !== packageId);
    let extraCopy = this.state.displayPackageList.find(pE => pE.packageId === packageId);
    switch (type) {
      case 'isNew':
        extraCopy.isNew = !extraCopy.isNew;
        break;
      case 'freeTrial':
        extraCopy.freeTrial = !extraCopy.freeTrial;
        break;
      case 'iauEnabled':
        extraCopy.iauEnabled = !extraCopy.iauEnabled;
        break;
    }
    let newUpdatedExtras = [ ...allFilteredExtraCopy, extraCopy ];
    this.setState({ displayPackageList: newUpdatedExtras });
  }

  handlePriceChange(e, packageId) {
    let allFilteredExtraCopy = this.state.displayPackageList.filter(pE => pE.packageId !== packageId);
    let extraCopy = this.state.displayPackageList.find(pE => pE.packageId === packageId);
    switch (e.target.name) {
      case 'planPackagePrice':
        extraCopy.planPackagePrice = e.target.value;
        break;
      case 'displayPrice':
        extraCopy.displayPrice = e.target.value;
        break;
    }
    let newUpdatedExtras = [ ...allFilteredExtraCopy, extraCopy ];
    this.setState({ displayPackageList: newUpdatedExtras });
  }

  searchNewPackage(searchVal) {
    let checkEmpty = !this.isEmpty(searchVal);
    let newFilteredData = this.state.allPackageList.filter(pE => pE.packageIdentifier.includes(searchVal));
    this.setState({ displayPackageList: newFilteredData, searchActive: checkEmpty, searchVal });
  }

  clearSearch() {
    this.searchNewPackage("");
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const {
      displayPackageList, planId
    } = this.state
    let filteredPackage = displayPackageList.filter(pE => pE.isNew);
    var params = {
      planId, displayPackageList: filteredPackage, addDeletePlanPackageForm: true
    };

    this.props.updateAPlanPackage(params);
  }

  render() {
    const { displayPackageList, isPaneOpen, searchActive, searchVal } = this.state;
    let sortedData = _.orderBy(displayPackageList, ['packageIdentifier'])
    return (
      <SlidingPane
        className='slinding-pane'
        overlayClassName='some-custom-overlay-class'
        isOpen={ isPaneOpen }
        title='Edit PlanPackage'
        onRequestClose={() => {
          this.setState({ isPaneOpen: false });
          this.props.closePlanPackageFormPane();
        }}>
        <Form onSubmit={(e) => {this.handleSubmit(e)}}>
          <div>
            <div className="search-section" style={{marginLeft:'15px', paddingBottom:'55px', width: '50%'}}>
              <div className='aaa'>
                <input onChange={(e)=>this.searchNewPackage(e.target.value)} type="search" value={searchVal} placeholder="What are you looking for?" />
              </div>
            </div>
            <div>
              <span className="note-section"><strong>NOTE: Fields are add/delete(checkbox), plan-plackage-price, display-price, free-trial, iau-enabled</strong></span>
            </div>
            <FormGroup className="col-sm-12 form-checkbox">
              <Input type="checkbox" name="selectall-package" id="selectall-package" checked={this.state.selectAll} onChange={() => this.selectAllHandler()} />
              <Label check className="text-center">
                {this.state.selectAll ? "Deselect All" : "Select All"}
              </Label>
            </FormGroup>
            {!this.isEmpty(sortedData) ? sortedData.map(pP =>
              (
                <CardHeader className="header-class">
                  <div className="col-sm-12 form-checkbox set-display">
                    <Input type="checkbox" name="new-package" id={pP.packageId} checked={pP.isNew} onChange={() => this.handleBooleanChanges('isNew', pP.packageId)} />
                    <Label className="form-check-label" for="new-package">{pP.packageIdentifier}</Label>
                  </div>
                  <div className="set-display">
                    <i className={pP.freeTrial ? "fa fa-toggle-on text-green float-right" : "fa fa-toggle-off text-red float-right"} onClick={() => this.handleBooleanChanges('freeTrial', pP.packageId)} />
                    <i className={pP.iauEnabled ? "fa fa-toggle-on text-green float-right" : "fa fa-toggle-off text-red float-right"} onClick={() => this.handleBooleanChanges('iauEnabled', pP.packageId)} />
                    <div className="col-sm-12 set-input-width">
                      <Input className="col-sm-12" type="number" name="displayPrice" id={`displayPrice-${pP.packageId}`} value={pP.displayPrice} onChange={(e) => this.handlePriceChange(e, pP.packageId)} />
                    </div>
                    <div className="col-sm-12 set-input-width">
                      <Input className="col-sm-12" type="number" name="planPackagePrice" id={`planPackagePrice-${pP.packageId}`} value={pP.planPackagePrice} onChange={(e) => this.handlePriceChange(e, pP.packageId)} />
                    </div>
                  </div>
                </CardHeader>
              )
            ) : null}
          </div>
          {
            searchActive ? 
            <div>
              <span className="note-section">NOTE: Clear the search to enable the submit button</span>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        onClick={this.clearSearch}
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Clear Search
                </button>
              </FormGroup>
            </div>
              : 
            <FormGroup className="col-sm-12">
              <button type="submit"
                      className="btn btn-primary"
                      disabled={searchActive}
                      style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                      Submit
              </button>
            </FormGroup>
          }
        </Form>
      </SlidingPane>
    );
  }
}

const mapStateToProps = ({FlashMessageData}) => {
  return {
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  updateAPlanPackage
})(AddDeletePlanPackageForm);
