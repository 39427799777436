import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchAllPlans} from '../../../../scripts/redux/actions'
import PlanForm from '../PlanForm/PlanForm.js';
import ClonePlanForm from '../ClonePlanForm/ClonePlanForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import { Card, CardFooter, CardBody, Row } from 'reactstrap';
import './Plan.css';
import PlanTabs from './PlanTabs';

class NewPlan extends Component {
  constructor (props) {
    super(props)
    this.state = {
      plans: [],
      displayingPlans: [],
      showPlanForm: false,
      showCloneForm: false,
      openedPlan: null,
      loading: true,
      footerOpts: {0: true, 1: true, 2: false},
      displayCardClass: '',
    }
    this.togglePlanForm = this.togglePlanForm.bind(this)
    this.toggleCloneForm = this.toggleCloneForm.bind(this)
  }

  setClassificationTabsSectionClass() {
    let packageTabsSectionClass = 'package-tabs-section';
    let siCardBodyMd = '';
    let siCardMd = '';
    let tileSelected = 'tgc-tile-selected';
    if (window.innerWidth <= 1422) {
      packageTabsSectionClass = 'package-tabs-section'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      packageTabsSectionClass = 'package-tabs-section'
      siCardBodyMd = 'si-card-body-md'
      siCardMd = 'si-card-md'
      tileSelected = 'tgc-tile-selected tile-selected-md'
    } else if (window.innerWidth > 1440){
      packageTabsSectionClass = 'package-tabs-section package-tabs-section-lg'
    }
    this.setState({packageTabsSectionClass, siCardBodyMd, siCardMd, tileSelected})
  }

  componentWillMount() {
    this.setClassificationTabsSectionClass()
    this.props.fetchAllPlans()
  }

  componentWillReceiveProps(nextProps) {
    const {plans, displayingPlans, loading} = nextProps
    this.setState({plans, displayingPlans, loading})
  }

  togglePlanForm(id=null, getPlans=false) {
    if (id) {
      this.setState({ showPlanForm: true, openedPlan: id });
    } else {
      this.setState({ showPlanForm: false})
    }
    if (getPlans) {
      this.props.fetchAllPlans()
    }
  }

  toggleCloneForm(id=null, getPlans=false) {
    if (id) {
      this.setState({showCloneForm: true, openedPlan: id})
    } else {
      this.setState({showCloneForm: false})
    }
    if (getPlans) {
      this.props.fetchAllPlans()
    }
  }

  displayPlanTabs(openedPlan, selectedDiv) {
    this.setState({showPlanTabs: true, openedPlan, selectedDiv})
  }

  closePlanTabs = () => {
    this.setState({showPackageTabs: false, selectedDiv: false})
  }

  search(val) {
    const {plans} = this.state
    let displayingPlans = plans.filter(c => (c.name.toLowerCase().search(val.toLowerCase()) !== -1) || c.identifier.toLowerCase().search(val.toLowerCase()) !== -1)
    this.setState({displayingPlans})
  }

  render() {
    const { openedPlan, displayingPlans, loading,
            footerOpts, showPlanForm, showCloneForm, selectedDiv,
            tileSelected, siCardMd, siCardBodyMd, showPlanTabs,
            packageTabsSectionClass,
          } = this.state

    const Item = ({value, name}) => {
      return (
        <div className='package-sortable-item'>
          <div className={`${name === selectedDiv ? tileSelected : ""}`}>
            <Card className={`margin-bottom-5 ${siCardMd}`}>
              <CardBody className={`si-card-body ${siCardBodyMd}`} onClick={() => this.displayPlanTabs(value.id, name)}>
                <span className="text-left font-size-18">{value.name || value.identifier}</span>
              </CardBody>
              <CardFooter className={`height-48 card-footer-color ${name === selectedDiv ? tileSelected : ""}`}>
                <div className="text-center si-card-footer">
                  {footerOpts[0] && <i className="fa fa-clone" onClick={() => this.toggleCloneForm(value.id)}></i>}
                  {footerOpts[1] && <i className="fa fa-pencil margin-left-50" onClick={() => this.togglePlanForm && this.togglePlanForm(value.id)}></i>}
                  { footerOpts[2] &&
                    ((value.enabled || value.active) ?
                    <i className="fa fa-toggle-on margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.grandFatherToggle && this.grandFatherToggle(value) } } /> :
                    <i className="fa fa-toggle-off margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.grandFatherToggle && this.grandFatherToggle(value) } } />)
                  }
                </div>
              </CardFooter>
            </Card>
          </div>
          { showPlanTabs && (name === selectedDiv) ?
          <div className={`${packageTabsSectionClass} ${name === selectedDiv ? "block" : ""}`}>
            <div onClick={() => this.closePlanTabs()} className="wrapper">
              <a href className="close-button">
                <div className="in">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
                <div className="out">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
              </a>
            </div>
            <div><PlanTabs closeTab={this.closePlanTabs}
                         openedPlan={openedPlan}/></div>
          </div> : null }
        </div>
      )
    };

    const List = ({items}) => {
      return (
        <Row>
          {
            items.map((value, index) => (
              <Item key={index} index={index} value={value} name={index}/>
            ))
          }
        </Row>
      );
    };

    return (
      <div>
        <FlashMessage />
        <div className='plan'>
          <div className='plan-header'>
            <div className="search-section plan-search">
              <div className='aaa'>
                <input onKeyUp={(e)=>this.search(e.target.value)}
                       type="search" placeholder="What are you looking for?" />
              </div>
            </div>
            <div className='new-plan-div'>
              <button className="btn btn-primary new-plan-btn"
                      onClick={()=> this.setState({
                                                    showPlanForm: true,
                                                    openedPlan: null})}>
              <i className="fa fa-plus"></i> New Plan
              </button>
            </div>
          </div>
          <div className='new-plan-content'>
            { loading && <Loading /> }
            {
              (!loading && displayingPlans.length === 0) ? <NoData></NoData> :
              <List items={displayingPlans} />
            }
            {
              showPlanForm ?
              <PlanForm planId={openedPlan}
                                  closePlanFormPane={this.togglePlanForm}/>
              : null
            }
            {
              showCloneForm ?
              <ClonePlanForm type='plan'
                         parentIdentifier={openedPlan}
                         closeClonePane={this.toggleCloneForm}/>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({plansData}) => {
  return {
    plans: plansData.plans,
    displayingPlans: plansData.displayingPlans,
    loading: plansData.loading,
  };
}

export {NewPlan};

export default connect(mapStateToProps, {
  fetchAllPlans,
})(NewPlan);
