import {
  FETCH_PPV_PACKAGES,
  FETCH_A_PPV_PACKAGE,
  LOADING_PPV_FORM,
  RESET_PPV_FORM,
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  ppvPackages: [],
  displayingPPVPackages: [],
  loading: true,
  tvodGuid: '',
  startDate: '',
  endDate: '',
  imageSmUrl: '',
  imageLgUrl: '',
  modalEnabled: false,
  loadingAPPV: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PPV_PACKAGES:
      let {ppvPackages} = action.payload
      return {
        ...state, ppvPackages, displayingPPVPackages: ppvPackages, loading: false
      };
    case FETCH_A_PPV_PACKAGE:
      let {
        tvodGuid, startDate, endDate, imageSmUrl, imageLgUrl,
      } = action.payload
      return {
        ...state, tvodGuid, startDate, endDate, imageSmUrl, imageLgUrl, loadingAPPV: false
      };
    case LOADING_PPV_FORM:
      return {...state, loadingAPPV: !state.loadingAPPV};
    case RESET_PPV_FORM:
      let emptyPPVForm = _.omit(INITIAL_STATE, ['ppvPackages', 'displayingPPVPackages', 'loading', 'loadingAPPV']);
      return {...state, ...emptyPPVForm, loadingAPPV: true };
    default:
      return state;
  }
}
