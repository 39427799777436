import { FETCH_TRANSLATIONS, FETCH_NEW_TRANSLATIONS, FETCH_ENV_STATUS, SET_TRANSLATION_RESPONSE_DATA} from '../ActionType';

const INITIAL_STATE = {
  translations: [],
  newTranslations:[],
  displayingTranslations: [],
  newDisplayingTranslations: [],
  envsToDisplay: [],
  setTranslations:[],
  loading: true,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRANSLATIONS:
      let {translations} = action.payload
      return {...state, translations, displayingTranslations: translations, loading: false};
    case FETCH_NEW_TRANSLATIONS:
      let {newTranslations} = action.payload
      return {...state, newTranslations, newDisplayingTranslations: newTranslations, loading: false};
    case SET_TRANSLATION_RESPONSE_DATA:
      let {setTranslations} = action.payload
      return {...state, setTranslations};
    case FETCH_ENV_STATUS:
      let {notReachableEnvs} = action.payload
      return {...state, envsToDisplay: notReachableEnvs, loading: false};
    default:
      return state;
  }
}
