import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createAClone } from '../../../../scripts/redux/actions'
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label } from 'reactstrap';

export class ClonePlanForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      identifier: '',
      planCode: '',
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.statusCode === "Success") {
      this.props.closeClonePane(null, true)
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    let { parentIdentifier, type } = this.props;
    let typeId=`${type}Id`
    let { identifier, planCode } = this.state;
    let params = { identifier, [typeId]: parentIdentifier, planCode }
    this.props.createAClone(params, type)
  }

  render() {
    let { planCode, identifier, isPaneOpen } = this.state;
    let { type } = this.props;
    let title = `Clone a ${type}`

    return (
      <div>
        <div ref={ref => this.el = ref} className='clone-form'>
          <SlidingPane
            className='slinding-pane'
            overlayClassName='some-custom-overlay-class'
            isOpen={isPaneOpen}
            title={title}
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              this.setState({ isPaneOpen: false });
              this.props.closeClonePane()
            }}>
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="planCode" id="planCode" placeholder="Plan Code" onChange={(e) => this.handleChange('planCode', e.target.value)} value={planCode} required/>
                  <Label for="planCode">Plan Code</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleChange('identifier', e.target.value)} value={identifier} required/>
                  <Label for="identifier">Identifier</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
            <br />
          </SlidingPane>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({FlashMessageData}) => {
  return {
    statusCode: FlashMessageData.statusCode
  };
}
export default connect(mapStateToProps, {
  createAClone
})(ClonePlanForm);
