import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Package from './Package/Package.js';
import Freemium from './Freemium/Freemium.js';
// import Classification from './Classification/Classification.js';
import NewClassification from './Classification/NewClassification.js';
// import Channel from './Channel/Channel.js';
import NewChannel from './Channel/NewChannel.js';
import Offer from './Offer/Offer.js';
// import Plan from './Plan/Plan.js';
import NewPlan from './Plan/NewPlan.js';
import PayPerView from './PayPerView/PayPerView.js';
import Translations from './Translations/Translations.js'
import TranslationsMgr from './Translations/TranslationsMgr';
import OttTranslations from './Translations/OttTranslations.js'
import ClearCache from './ClearCache/ClearCache.js'
import PrivateRoute from '../Common/PrivateRoute/PrivateRoute.js';
import LineOfBusiness from './LineOfBusiness/LineOfBusiness.js';
import IapClassification from './IapClassification/IapClassification.js';
import PartnerPlatform from './PartnerPlatform/PartnerPlatform.js';
import DmaCodesZipcode from './DmaCodesZipcode/DmaCodesZipcode.js';
import ContentTag from './ContentTag/ContentTag.js';
import ContentTagValidation from './ContentTagValidation/ContentTagValidation.js';

const PC = () => (
  <div>
    {
      ['/', '/pc'].includes(window.location.pathname) ? <Redirect to = "/pc/packages" /> : null
    }
    <Switch>
      <PrivateRoute exact path='/pc/packages' component={Package}/>
      <PrivateRoute exact path='/pc/freemiums' component={Freemium}/>
      <PrivateRoute exact path='/pc/classifications' component={NewClassification}/>
      <PrivateRoute exact path='/pc/channels' component={NewChannel}/>
      <PrivateRoute exact path='/pc/dma_codes_zipcode' component={DmaCodesZipcode}/>
      <PrivateRoute exact path='/pc/offers' component={Offer}/>
      <PrivateRoute exact path='/pc/plans' component={NewPlan}/>
      <PrivateRoute exact path='/pc/translations' component={Translations}/>
      <PrivateRoute exact path='/pc/translations-mgr' component={TranslationsMgr}/>
      <PrivateRoute exact path='/pc/ott_translations' component={OttTranslations}/>
      <PrivateRoute exact path='/pc/ppv' component={PayPerView}/>
      <PrivateRoute exact path='/pc/clear_cache' component={ClearCache}/>
      <PrivateRoute exact path='/pc/line_of_business' component={LineOfBusiness}/>
      <PrivateRoute exact path='/pc/iap_classifications' component={IapClassification}/>
      <PrivateRoute exact path='/pc/partner_platforms' component={PartnerPlatform}/>
      <PrivateRoute exact path='/pc/content_tags' component={ContentTag}/>
      <PrivateRoute exact path='/pc/content_tag_validation' component={ContentTagValidation}/>
    </Switch>
  </div>
)

export default PC;
