import Cookie from 'js-cookie';
import {
  FETCH_PACKAGES_BY_CLASSIFICATION,
  SET_NOTIFICATION_PANEL,
  FETCH_A_PACKAGE,
  FETCH_DEFAULT_SHIPPING_METHODS,
  LOADING_PACKAGE_FORM,
  EDIT_PACKAGE,
  FETCH_ALL_PARTNER_PLATFORM,
  PACKAGE_PARTNER_PACKAGES,
  FETCH_PACKAGE_IAU_ENABLE_STATUS,
  FETCH_PARTNER_PLATFORM_IDS_BY_PACKAGEID
} from '../ActionType';
import _ from 'lodash';
import {
  fetchDefaultClassifications,
  fetchDWClassifications,
  fetchEnabledPackages
} from './DefaultDataCalls';
// import { arrayMove } from 'react-sortable-hoc'; // Used in WIP sortBasePackages below
import {signOut} from '../helper';

export const fetchPackagesByClassification = (classification=17, selectedPackageType='all') => {
  return (dispatch) => {
    const PACKAGES_URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}packages-by-classification/${classification}`;
    return fetch(PACKAGES_URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let packages = json;
      packages = _.sortBy(packages, 'classPackagePriority');
      let displayingPackages = Object.assign([], packages);
      if (selectedPackageType !== 'all') {
        displayingPackages = displayingPackages.filter(pack => pack.packageType === selectedPackageType)
      }
      dispatch({
        type: FETCH_PACKAGES_BY_CLASSIFICATION,
        payload: {packages, displayingPackages}
      })
    })
    .catch(error => {
        console.log('Package Error 2 - ', error);
    })
  }
}

export const fetchAPackageAfter = (id) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}package/${id}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const shippingMethods = []
      json.data.device_shipping_methods?.forEach(item => shippingMethods.push(item.shipping_id))
      const {
        identifier, guid, sku, priority, default_classification_id: defaultClassificationId,
        package_type: packageType, amount, promo, status, enabled, dvr_required: dvrRequired,
        apps_supported: appsSupported, extra_type: extraType, migrated_to: migratedTo,
        ota_qual_outdoor: otaQualOutdoor, ota_qual_indoor: otaQualIndoor, ota_qual_none: otaQualNone
      } = json.data.packages;
      let name = json.data.translations.find(tr => tr.key === `model.package.${identifier}.name`) ? json.data.translations.find(tr => tr.key === `model.package.${identifier}.name`) : {};
      let description = json.data.translations.find(tr => tr.key === `model.package.${identifier}.description`) ? json.data.translations.find(tr => tr.key === `model.package.${identifier}.description`) : {};
      let description_1 = json.data.translations.find(tr => tr.key === `model.package.${identifier}.description_1`) ? json.data.translations.find(tr => tr.key === `model.package.${identifier}.description_1`) : {};
      let description_2 = json.data.translations.find(tr => tr.key === `model.package.${identifier}.description_2`) ? json.data.translations.find(tr => tr.key === `model.package.${identifier}.description_2`) : {};
      let description_3 = json.data.translations.find(tr => tr.key === `model.package.${identifier}.description_3`) ? json.data.translations.find(tr => tr.key === `model.package.${identifier}.description_3`) : {};
      let imageUrls = json.data.image_urls;
      dispatch({
        type: FETCH_A_PACKAGE,
        payload: {
          identifier, guid, sku, priority, defaultClassificationId,
          packageType, amount, promo, status, enabled, dvrRequired,
          appsSupported, extraType, migratedTo,
          description_1, description_2, description_3,
          name, description, otaQualOutdoor, otaQualIndoor,
          otaQualNone, imageUrls
        }
      })
      dispatch({
        type: FETCH_DEFAULT_SHIPPING_METHODS,
        payload: { shippingMethods }
      })
    })
  }
}

export const fetchAPackage = (id = null) => {
  let typeOfCall, editPackage = false;
  if (id) {
    typeOfCall = 'packageEdit'
    editPackage = true
  }
  return (dispatch) => {
    if (!editPackage) {
      dispatch(fetchDWClassifications())
    } else {
      dispatch(fetchAPackagePartnerPlatform(id))
      dispatch(fetchPackageIauEnabledStatus(id))
      dispatch(fetchAllPartnerPlatformsForPackages())
      dispatch(fetchPartnerPlatformIdsByPackageId(id))
    }
    dispatch({
      type: EDIT_PACKAGE,
      payload: {editPackage}
    })
    dispatch({type: LOADING_PACKAGE_FORM})
    dispatch(fetchDefaultClassifications(id, typeOfCall))
    dispatch(fetchEnabledPackages())
  }
}

export const createAPackage = (params) => {
  if (params.otaQualIndoor === false && params.otaQualOutdoor === false && params.otaQualNone === false) {
    params = (params.packageType === "4") ? { ...params, otaQualIndoor: true } : { ...params, otaQualNone: true }
  }
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}create-update-package`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchPackagesByClassification(params.defaultClassificationId, [0, 1, 4].includes(parseInt(params.packageType)) ? parseInt(params.packageType) : 'all'))
      // this.props.showNotificationPanel(true, json.status.code, json.status.message)
      // this.props.closePackageFormPane(null, true)
    })
    .catch(error => {
      console.log('createAPackage Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const updateAPackage = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}create-update-package`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchPackagesByClassification(params.defaultClassificationId, [0, 1, 4].includes(parseInt(params.packageType)) ? parseInt(params.packageType) : 'all'))
      // this.props.showNotificationPanel(true, json.status.code, json.status.message)
      // this.props.closePackageFormPane(null, true)
    })
    .catch(error => {
      console.log('updateAPackage Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const fetchAPackagePartnerPlatform = (packageId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner_packages/${packageId}`
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(defaultPackagePartnerPackages => {
      if (defaultPackagePartnerPackages.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      dispatch({
        type: PACKAGE_PARTNER_PACKAGES,
        payload: {defaultPackagePartnerPackages}
      });
    });
  }
}

export const fetchAllPartnerPlatformsForPackages = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-platforms`
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(allPartnerPlatform => {
      if (allPartnerPlatform.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      dispatch({
        type: FETCH_ALL_PARTNER_PLATFORM,
        payload: {allPartnerPlatform}
      });
    });
  }
}

export const fetchPackageIauEnabledStatus = (packageId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}iau_enabled_status/${packageId}`
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(iauEnabledStatus => {
      if (iauEnabledStatus.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      dispatch({
        type: FETCH_PACKAGE_IAU_ENABLE_STATUS,
        payload: {iauEnabledStatus}
      });
    });
  }
}

export const fetchPartnerPlatformIdsByPackageId = (packageId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner_classifications/${packageId}`
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(partnerPlatformIdsByPackageId => {
      if (partnerPlatformIdsByPackageId.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      dispatch({
        type: FETCH_PARTNER_PLATFORM_IDS_BY_PACKAGEID,
        payload: {partnerPlatformIdsByPackageId}
      });
    });
  }
}

// ToDo
// export const sortBasePackages = (params, oldIndex, newIndex) => {
//   const URL = `${process.env.REACT_APP_PC_URL}update-package-priority`;
//   return fetch(URL, {
//     method: 'PUT',
//     headers: new Headers({
//       'Content-Type': 'application/json',
//       Authorization: Cookie.get('token')
//     }),
//     credentials: 'same-origin',
//     body: JSON.stringify(params),
//   })
//   .then(response => response.json())
//   .then(json => {
//     if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
//       signOut()
//     }
//     this.setState({
//       displayingPackages: arrayMove(this.state.displayingPackages, oldIndex, newIndex)
//     });
//     this.showNotificationPanel(true, json.status.code, json.status.message)
//   })
//   .catch(error => {
//     console.log('Update Package Priority Error - ', error)
//     // this.showNotificationPanel(true, json.status.code, json.status.message)
//   });
// }
