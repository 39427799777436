import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAllContentTags, updateContentTag, deleteContentTag } from '../../../../scripts/redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Input } from 'reactstrap';
import './ContentTagForm.css';

export class ContentTagForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      searchVal: '',
      updatedData: [],
      showAddNewForm: false,
      newTagName: '',
      newTagType: '',
      newEnabled: false,
      newPriority: 0,
      newData: [],
      displayContentTag: [],
      searchActive: false
    }
    this.handleEnableChanges = this.handleEnableChanges.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addNew = this.addNew.bind(this);
    this.addNewRecord = this.addNewRecord.bind(this);
    this.searchContentTag = this.searchContentTag.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.deleteContentTagHandler = this.deleteContentTagHandler.bind(this);
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  handleChange(e, id) {
    let filteredData = this.state.data.filter(pE => pE.id !== id);
    let dtCopy = this.state.data.find(pE => pE.id === id);

    let filteredUpdatedData = this.state.updatedData.filter(pE => pE.id !== id);
    let updatedDtCopy = this.state.updatedData.find(pE => pE.id === id);

    let filteredNewData = this.state.newData.filter(pE => pE.id !== id);
    let newDtCopy = {...this.state.newData.find(pE => pE.id === id)};

    switch (e.target.name) {
      case 'tagName': {
        dtCopy.tag_name = e.target.value;
        if (!this.isEmpty(updatedDtCopy)) {
          updatedDtCopy.tag_name = e.target.value;
          this.setState({ data: [ ...filteredData, dtCopy ], updatedData: [ ...filteredUpdatedData, updatedDtCopy ] });
        } else if (!this.isEmpty(newDtCopy)) {
          newDtCopy.tag_name = e.target.value;
          this.setState({ data: [ ...filteredData, dtCopy ], newData: [ ...filteredNewData, newDtCopy ] });
        } else {
          this.setState({ data: [ ...filteredData, dtCopy ], updatedData: [ ...filteredUpdatedData, dtCopy ] });
        }
        break;
      }
      case 'tagType': {
        dtCopy.tag_type = e.target.value;
        if (!this.isEmpty(updatedDtCopy)) {
          updatedDtCopy.tag_type = e.target.value;
          this.setState({ data: [ ...filteredData, dtCopy ], updatedData: [ ...filteredUpdatedData, updatedDtCopy ] });
        } else if (!this.isEmpty(newDtCopy)) {
          newDtCopy.tag_type = e.target.value;
          this.setState({ data: [ ...filteredData, dtCopy ], newData: [ ...filteredNewData, newDtCopy ] });
        } else {
          this.setState({ data: [ ...filteredData, dtCopy ], updatedData: [ ...filteredUpdatedData, dtCopy ] });
        }
        break;
      }
      case 'priority': {
        dtCopy.priority = e.target.value;
        if (!this.isEmpty(updatedDtCopy)) {
          updatedDtCopy.priority = e.target.value;
          this.setState({ data: [ ...filteredData, dtCopy ], updatedData: [ ...filteredUpdatedData, updatedDtCopy ] });
        } else if (!this.isEmpty(newDtCopy)) {
          newDtCopy.priority = e.target.value;
          this.setState({ data: [ ...filteredData, dtCopy ], newData: [ ...filteredNewData, newDtCopy ] });
        } else {
          this.setState({ data: [ ...filteredData, dtCopy ], updatedData: [ ...filteredUpdatedData, dtCopy ] });
        }
        break;
      }
    }
  }

  deleteContentTagHandler(obj) {
    this.props.deleteContentTag(obj.id);
  }

  addNewRecord() {
    if (!this.isEmpty(this.state.newTagName) && !this.isEmpty(this.state.newTagType)) {
      let lastDataRecord = [ ..._.sortBy(this.state.data, 'id') ];
      let { newTagName: tag_name, newTagType: tag_type, newEnabled: enabled, newPriority: priority } = this.state;
      let record = { tag_name, tag_type, enabled, priority, id: lastDataRecord[lastDataRecord.length - 1].id + 1 };
      this.setState({ data: [ ...this.state.data, record ], showAddNewForm: false, newTagName: '', newTagType: '', newEnabled: false, newPriority: 0, newData: [ ...this.state.newData, record ] });
    } else {
      this.setState({ showAddNewForm: false });
    }
  }

  handleEnableChanges(id) {
    let filteredData = this.state.data.filter(pE => pE.id !== id);
    let dtCopy = {...this.state.data.find(pE => pE.id === id)};
    dtCopy.enabled = !dtCopy.enabled;

    let filteredUpdatedData = this.state.updatedData.filter(pE => pE.id !== id);
    let updatedDtCopy = {...this.state.updatedData.find(pE => pE.id === id)};

    let filteredNewData = this.state.newData.filter(pE => pE.id !== id);
    let newDtCopy = {...this.state.newData.find(pE => pE.id === id)};

    if (!this.isEmpty(updatedDtCopy)) {
      updatedDtCopy.enabled = !updatedDtCopy.enabled;
      this.setState({ data: [ ...filteredData, dtCopy ], updatedData: [ ...filteredUpdatedData, updatedDtCopy ] });
    } else if (!this.isEmpty(newDtCopy)) {
      newDtCopy.enabled = !newDtCopy.enabled;
      this.setState({ data: [ ...filteredData, dtCopy ], newData: [ ...filteredNewData, newDtCopy ] });
    } else {
      this.setState({ data: [ ...filteredData, dtCopy ], updatedData: [ ...filteredUpdatedData, dtCopy ] });
    }
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.props.fetchAllContentTags()
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
    if (nextProps.statusCode === "Success") {
      this.props.closeContentTagFormPane()
    }
  }

  addNew(e) {
    switch(e.target.name) {
      case 'new-tagName': {
        this.setState({ newTagName: e.target.value });
        break;
      }
      case 'new-tagType': {
        this.setState({ newTagType: e.target.value });
        break;
      }
      case 'new-priority': {
        this.setState({ newPriority: e.target.value });
        break;
      }
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const {
      updatedData, newData
    } = this.state;

    var params = {
      updatedData, newData
    };

    this.props.updateContentTag(params);
  }

  searchContentTag(searchVal) {
    let checkEmpty = !this.isEmpty(searchVal);
    let newFilteredData = this.state.data.filter(dt => dt.tag_name.includes(searchVal) || dt.tag_type.includes(searchVal));
    this.setState({ displayContentTag: newFilteredData, searchActive: checkEmpty, searchVal });
  }

  clearSearch() {
    this.searchContentTag("")
  }

  render() {
    const {
      isPaneOpen, data, searchVal, showAddNewForm, searchActive,
      newTagName, newTagType, newEnabled, newPriority, displayContentTag
    } = this.state;

    let sortedData = this.isEmpty(displayContentTag) ? _.sortBy(data, 'id') : _.sortBy(displayContentTag, 'id');

    return (
      <div ref={ref => this.el = ref} className='contentTag-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Content Category Tag Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closeContentTagFormPane();
          }}>
          <Form onSubmit={(e) => {this.handleSubmit(e)}}>
            <div className="search-section" style={{marginLeft:'15px', paddingBottom:'55px', width: '50%'}}>
              <div className='aaa'>
                <input onChange={(e)=>this.searchContentTag(e.target.value)}
                        type="search" value={searchVal} placeholder="What are you looking for?" />
              </div>
              { searchActive ? null : <i className='fa fa-plus-circle' style={{ marginLeft: '10px' }} onClick={(e) => { this.setState({ showAddNewForm: !showAddNewForm })}} /> }
            </div>
            { showAddNewForm ? 
              (
                <div>
                  <Input className="form-input" type="text" name="new-tagName" id="new-tagName" onChange={(e) => this.addNew(e)} value={newTagName} />
                  <Input className="form-input" type="text" name="new-tagType" id="new-tagType" onChange={(e) => this.addNew(e)} value={newTagType} />
                  <Input className="form-input" type="number" name="new-priority" id="new-priority" onChange={(e) => this.addNew(e)} value={newPriority} />
                  <i className={newEnabled ? "fa fa-toggle-on text-green" : "fa fa-toggle-off text-red"} onClick={() => { this.setState({ newEnabled: !newEnabled })}} newPriority={newEnabled} />
                  <i className='fa fa-trash' style={{ marginLeft: '10px', color: '#ccc' }} />
                  { showAddNewForm ? <i className='fa fa-check-circle' style={{ marginLeft: '10px' }} onClick={this.addNewRecord} /> : null }
                </div>
              ) : null
            }
            {!this.isEmpty(sortedData) ? sortedData.map(pP =>
              (
                <div style={{ marginBottom: '1rem' }}>
                  <Input className="form-input" type="text" name="tagName" id={`tagName-${pP.id}`} onChange={(e) => this.handleChange(e, pP.id)} value={pP.tag_name} />
                  <Input className="form-input" type="text" name="tagType" id={`tagType-${pP.id}`} onChange={(e) => this.handleChange(e, pP.id)} value={pP.tag_type}/>
                  <Input className="form-input" type="number" name="priority" id={`priority-${pP.id}`} onChange={(e) => this.handleChange(e, pP.id)} value={pP.priority} />
                  <i className={pP.enabled ? "fa fa-toggle-on text-green" : "fa fa-toggle-off text-red"} onClick={(e) => this.handleEnableChanges(pP.id)}/>
                  <i className='fa fa-trash' style={{ marginLeft: '10px' }} onClick={() => { if (window.confirm('Are you sure? This will purge the tag and all of its association from the model.')) this.deleteContentTagHandler(pP)} } />
                </div>
              )
            ) : null}
            {
              searchActive ?
              <div>
                <span className="note-section">Note: Clear the search to enable the submit button</span>
                <FormGroup className="col-sm-12">
                  <button className="btn btn-primary"
                          onClick={this.clearSearch}
                          style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                          Clear Search
                  </button>
                </FormGroup>
              </div>
                :
              <FormGroup className="col-sm-12">
                <button type="submit"
                        className="btn btn-primary"
                        disabled={searchActive}
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            }
          </Form>
          <br />
        </SlidingPane>
      </div>);
  }
}

const mapStateToProps = ({ContentTagsData, FlashMessageData}) => {
  return {
    data: ContentTagsData.data,
    statusCode: FlashMessageData.statusCode
  };
}

export default connect(mapStateToProps, {
  fetchAllContentTags,
  updateContentTag,
  deleteContentTag
})(ContentTagForm);
