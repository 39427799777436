import {
  FETCH_CHANNELS,
  LOADING_CHANNEL_FORM,
  FETCH_A_CHANNEL,
  RESET_CHANNEL_FORM,
  EDIT_CHANNEL
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  channels: [],
  displayingChannels: [],
  loading: true,
  // Particular Channel
  description: '',
  name: '',
  id: null,
  identifier: '',
  classificationId: '0',
  callSign: '',
  imageName: '',
  imageUrls: [],
  priority: '',
  cmsExternalId: '',
  title: '',
  businessName: '',
  isFree: false,
  active: false,
  loadingAChannel: false,
  editChannel: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHANNELS:
      let {channels} = action.payload
      return {...state, channels, displayingChannels: channels, loading: false};
    case FETCH_A_CHANNEL:
      let {
        id, identifier, classificationId, callSign, description, imageName, imageUrls, name, priority,
        cmsExternalId, title, businessName, isFree, active,
      } = action.payload
      return {
        ...state,
        id,
        identifier,
        classificationId,
        description,
        callSign,
        imageName,
        imageUrls,
        name,
        priority,
        cmsExternalId,
        title,
        businessName,
        isFree,
        active,
        loadingAChannel: false
      };
    case LOADING_CHANNEL_FORM:
      return {...state, loadingAChannel: action.payload};
    case RESET_CHANNEL_FORM:
      let emptyChannelForm = _.omit(INITIAL_STATE, ['channels', 'displayingChannels','loading', 'loadingAChannel']);
      return {...state, ...emptyChannelForm};
    case EDIT_CHANNEL:
      let {editChannel} = action.payload;
      return {...state, editChannel};
    default:
      return state;
  }
}
