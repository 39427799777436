import Cookie from 'js-cookie';
import {
  FETCH_CONTENT_TAG_TREE,
  FETCH_ALL_CONTENT_TAG,
  FETCH_A_CONTENT_TAG,
  FETCH_CONTENT_TAG_CHILDRENS,
  FETCH_CONTENT_TAG_DROPDOWN,
  SET_NOTIFICATION_PANEL,
  FETCH_CONTENT_TAG_VALIDATION_DROPDOWN,
  CONTENT_TAG_VALIDATION_RESULT,
  RESET_CONTENT_TAG_VALIDATION_RESULT
} from '../ActionType';
import {signOut} from '../helper';

export const fetchTagTree = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag-tree`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { treeData } = json.data;
      dispatch({ 
        type: FETCH_CONTENT_TAG_TREE,
        payload: { treeData }
      });
    })
    .catch(error => {
      console.log('fetchTagTree Error - ', error)
    });
  }
}

export const fetchAContentTag = (id) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag/${id}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { tag_name: tagName, tag_type: tagType, enabled, priority } = json.data.content_tags;
      const displayText = json.data.translations[0];
      dispatch({
        type: FETCH_A_CONTENT_TAG,
        payload: {
          tagName, tagType, enabled, priority, displayText
        }
      });
    });
  }
}

export const fetchAllContentTags = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tags`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { content_tags } = json.data;
      dispatch({ 
        type: FETCH_ALL_CONTENT_TAG,
        payload: { content_tags }
      })
    })
    .catch(error => {
      console.log('fetchAllContentTags Error - ', error)
    });
  }
}

export const updateContentTag = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('updateContentTag Error - ', error)
    });
  }
}

export const fetchChildren = (tagCategoryId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag-children/${tagCategoryId}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { packageList, linesOfBusinessList, planList, channelList, contentTagList } = json.data.childrenList;

      dispatch({
        type: FETCH_CONTENT_TAG_CHILDRENS,
        payload: { packageList, linesOfBusinessList, planList, channelList, contentTagList }
      })
    })
    .catch(error => {
      console.log('fetchChildren Error - ', error)
    });
  }
}

export const fetchContentTagsDropDown = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag-dropdown`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { parentDropDown } = json.data;
      dispatch({
        type: FETCH_CONTENT_TAG_DROPDOWN,
        payload: { parentDropDown }
      })
    })
    .catch(error => {
      console.log('fetchContentTagsDropDown Error - ', error)
    });
  }
}

export const updateContentTagDetails = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag-details`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('updateContentTagDetails Error - ', error)
    });
  }
}

export const deleteContentTag = (tagId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag/${tagId}`;
    return fetch(URL, {method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('deleteContentTag Error - ', error)
    });
  }
}

export const deleteTagChildrens = (params) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag-tree`;
    return fetch(URL, {method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('deleteTagChildrens Error - ', error)
    });
  }
}

export const fetchContentTagValidationDropDown = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}content-tag-validation-dropdown`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { planDropDown, lobDropDown, partnerPlatformDropDown } = json.data;
      dispatch({
        type: FETCH_CONTENT_TAG_VALIDATION_DROPDOWN,
        payload: { planDropDown, lobDropDown, partnerPlatformDropDown }
      })
    })
    .catch(error => {
      console.log('fetchContentTagValidationDropDown Error - ', error)
    });
  }
}
