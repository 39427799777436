import {
  FETCH_TRANSLATIONS,
  FETCH_NEW_TRANSLATIONS,
  FETCH_ENV_STATUS,
  SET_NOTIFICATION_PANEL,
  SET_TRANSLATION_RESPONSE_DATA,
} from '../ActionType';
import _ from 'lodash';
import { callApi } from '../helper';

export const fetchAllOttTranslations = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}translations?application=ottweb`;
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      })
    })
      .then(response => response.json())
      .then(json => {
        const translations = _.map(json, o => _.extend({ edited: false }, o));
        dispatch({
          type: FETCH_TRANSLATIONS,
          payload: { translations }
        });
      })
      .catch(error => {
        console.log('fetchAllOttTranlsations Error - ', error);
      })
  }
}

export const fetchAllTranlsations = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}translations?application=catalog`;
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      })
    })
      .then(response => response.json())
      .then(json => {
        const translations = _.map(json, o => _.extend({ edited: false }, o));
        dispatch({
          type: FETCH_TRANSLATIONS,
          payload: { translations }
        });
      })
      .catch(error => {
        console.log('fetchAllTranlsations Error - ', error);
      })
  }
}

export const fetchAllTranslationsAllApps = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}translations`;
    return fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      })
    })
      .then(response => response.json())
      .then(json => {
        const translations = _.map(json, o => _.extend({ edited: false }, o));
        dispatch({
          type: FETCH_TRANSLATIONS,
          payload: { translations }
        });
      })
      .catch(error => {
        console.log('fetchAllTranslationsAllApps Error - ', error);
      })
  }
}

function unique(arr, keyProps) {
  const kvArray = arr.map(entry => {
    const key = keyProps.map(k => entry[k]).join('|');
    // eslint-disable-next-line
    entry.application === null ? entry.application = "" : entry.application = entry.application;
    return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
}

const filterValue = (obj, key, locale, application) => obj.find(v => ((v['key'] === key) && (v['locale'] === locale) && (v['application'] === application)));

export const fetchAllNewTranlsations = (params) => {
  return async (dispatch) => {
    let networkErrorFlag = false;
    let devData = params ? callApi(`${process.env.REACT_APP_NODE_PC_ADMIN_DEV_URL}translations?key=${params.key}&application=${params.application}&locale=${params.locale}`, "GET") : callApi(`${process.env.REACT_APP_NODE_PC_ADMIN_DEV_URL}translations`, "GET");
    let qaData = params ? callApi(`${process.env.REACT_APP_NODE_PC_ADMIN_QA_URL}translations?key=${params.key}&application=${params.application}&locale=${params.locale}`, "GET") : callApi(`${process.env.REACT_APP_NODE_PC_ADMIN_QA_URL}translations`, "GET");
    let betaData = params ? callApi(`${process.env.REACT_APP_NODE_PC_ADMIN_BETA_URL}translations?key=${params.key}&application=${params.application}&locale=${params.locale}`, "GET") : callApi(`${process.env.REACT_APP_NODE_PC_ADMIN_BETA_URL}translations`, "GET");
    let prodData = params ? callApi(`${process.env.REACT_APP_NODE_PC_ADMIN_PROD_URL}translations?key=${params.key}&application=${params.application}&locale=${params.locale}`, "GET") : callApi(`${process.env.REACT_APP_NODE_PC_ADMIN_PROD_URL}translations`, "GET");
    const results = await Promise.all([devData, qaData, betaData, prodData].map(p => p.catch(e => e)));
    console.log(results, 'results');
    let count = 0;
    for (let i = 0; i < 4; i++) {
      if ((results[i].name === "AbortError" || results[i].message === "Failed to fetch") && results[i].status !== 200) {
        count++
      }
      networkErrorFlag = (count === 4) ? true : false;
    }
    const response = results.map(result => { return (result instanceof Error) ? [] : result })
    const devTranslationsData = response[0].status === 200 ? await response[0].json() : [];
    const qaTranslationsData = response[1].status === 200 ? await response[1].json() : [];
    const betaTranslationsData = response[2].status === 200 ? await response[2].json() : [];
    const prodTranslationsData = response[3].status === 200 ? await response[3].json() : [];
    let array = _.union(qaTranslationsData, prodTranslationsData, devTranslationsData, betaTranslationsData);
    let uniqueArray = unique(array, ['key', 'locale', 'application']);
    let newTranslations = _.map(uniqueArray, o => {
      delete o.id;
      const devValue = filterValue(devTranslationsData, `${o.key}`, `${o.locale}`, `${o.application}`) ? filterValue(devTranslationsData, `${o.key}`, `${o.locale}`, `${o.application}`) : undefined;
      const qaValue = filterValue(qaTranslationsData, `${o.key}`, `${o.locale}`, `${o.application}`) ? filterValue(qaTranslationsData, `${o.key}`, `${o.locale}`, `${o.application}`) : undefined;
      const betaValue = filterValue(betaTranslationsData, `${o.key}`, `${o.locale}`, `${o.application}`) ? filterValue(betaTranslationsData, `${o.key}`, `${o.locale}`, `${o.application}`) : undefined;
      const prodValue = filterValue(prodTranslationsData, `${o.key}`, `${o.locale}`, `${o.application}`) ? filterValue(prodTranslationsData, `${o.key}`, `${o.locale}`, `${o.application}`) : undefined;
      let syncFlag, envValues;
      envValues = [
        devValue && devValue.value,
        qaValue && qaValue.value,
        betaValue && betaValue.value,
        prodValue && prodValue.value
      ];
      // Set the syncFlag to true if the translations are identical across all environments.
      syncFlag = envValues.every(function (v, i, a) {
        return i === 0 || v === a[i - 1];
      });
      return _.extend(
        {
          "dev": {
            "id": devValue !== undefined ? devValue.id : "",
            "value": devValue !== undefined ? devValue.value : "",
            "updated_at": devValue !== undefined ? devValue.updated_at : ""
          },
          "qa": {
            "id": qaValue !== undefined ? qaValue.id : "",
            "value": qaValue !== undefined ? qaValue.value : "",
            "updated_at": qaValue !== undefined ? qaValue.updated_at : ""
          },
          "beta": {
            "id": betaValue !== undefined ? betaValue.id : "",
            "value": betaValue !== undefined ? betaValue.value : "",
            "updated_at": betaValue !== undefined ? betaValue.updated_at : ""
          },
          "prod": {
            "id": prodValue !== undefined ? prodValue.id : "",
            "value": prodValue !== undefined ? prodValue.value : "",
            "updated_at": prodValue !== undefined ? prodValue.updated_at : ""
          },
          edited: false,
          sync: syncFlag
        },
        o
      )
    });
    if (networkErrorFlag) {
      newTranslations = ["NETWORK ERROR, PLEASE TRY AFTER SOMETIME"]
    }
    console.log(newTranslations, 'TRANSLATION')
    console.log(devTranslationsData.length, "DEV COUNT")
    console.log(qaTranslationsData.length, "QA COUNT")
    console.log(betaTranslationsData.length, "BETA COUNT")
    console.log(prodTranslationsData.length, "PROD COUNT")
    if(params){
      return newTranslations
    } else {
      dispatch({
        type: FETCH_NEW_TRANSLATIONS,
        payload: { newTranslations }
      });
    }
  }
}

export const createATranslation = (params) => {
  return async (dispatch) => {
    const devURL = `${process.env.REACT_APP_NODE_PC_ADMIN_DEV_URL}translationsGenerator`;
    const qaURL = `${process.env.REACT_APP_NODE_PC_ADMIN_QA_URL}translationsGenerator`;
    const betaURL = `${process.env.REACT_APP_NODE_PC_ADMIN_BETA_URL}translationsGenerator`;
    const prodURL = `${process.env.REACT_APP_NODE_PC_ADMIN_PROD_URL}translationsGenerator`;
    let { baseEnv, destinationEnvs, devId, devValue, qaValue, betaValue, prodValue, qaId, betaId, prodId, locale, key, application } = params;
    application = application === "" ? null : application;
    let translationObj = {
      locale,
      key,
      application,
      "value": `${params[`${baseEnv}Value`]}`,
    }
    console.log(`${params[`${baseEnv}Value`]}`, 'PARAMS')
    console.log(baseEnv, 'PARAMS BASE ENV')
    console.log(translationObj, "TRANSLATIONObj")
    let dataSetters = [];
    // eslint-disable-next-line
    destinationEnvs.map(env => {
      switch (env.value) {
        case "dev":
          translationObj = Object.assign(translationObj, { id: devId, env: 'dev' });
          dataSetters.push(callApi(devURL, {
            method: devId ? 'PUT' : 'POST',
            credentials: 'same-origin',
          },
            "",
            [translationObj],
          ));
          break;
        case "qa":
          translationObj = Object.assign(translationObj, { id: qaId, env: 'qa' });
          dataSetters.push(callApi(qaURL, {
            method: qaId ? 'PUT' : 'POST',
            credentials: 'same-origin',
          },
            "",
            [translationObj],
          ));
          break;
        case "beta":
          translationObj = Object.assign(translationObj, { id: betaId, env: 'beta' });
          dataSetters.push(callApi(betaURL, {
            method: betaId ? "PUT" : "POST",
            credentials: 'same-origin',
          },
            "",
            [translationObj]
          ));
          break;
        case "prod":
          translationObj = Object.assign(translationObj, { id: prodId, env: 'prod' });
          dataSetters.push(callApi(prodURL, {
            method: prodId ? 'PUT' : 'POST',
            credentials: 'same-origin'
          },
            "",
            [translationObj],
          ));
          break;
        default: break;
      }
    });
    const results = await Promise.all(dataSetters.map(p => p.catch(e => {
      const code = "Error";
      const message = "created/updated translation failed.";
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: { code, message }
      });
    })))
    .catch(error => {
      console.log('createATranslation Error - ', error)
      this.props.showNotificationPanel(true, "ERROR", "Failure in connection");
    });

    let setTranslations = []
    let responseStatus = results.map(result => result.status);
    if(!(responseStatus.includes(400))){
      results.forEach(async res => {
        let response = await res.json();
        response.data.translations[0].shouldUpdate = true;
          setTranslations.push(response.data.translations[0])
      })
      dispatch({
        type: SET_TRANSLATION_RESPONSE_DATA,
        payload: { setTranslations }
      })
    }

    let code;
    let message;
    responseStatus.forEach(statuscode => {
      if ((statuscode === 200) || (statuscode === 201)) {
        code = "SUCCESS";
        message = "Successfully created/updated translation."
      } else {
         code = "Error";
         message = "created/updated translation failed.";
      }
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: { code, message }
      });
    })
  }
}

export const createOrUpdateAnOldTranslation = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}translationsGenerator`;
    let body = [params];
    return fetch(URL, {
      method: params && params.id ? 'PUT' : 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      credentials: 'same-origin',
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(json => {
        const { code, message } = json.status
        dispatch({
          type: SET_NOTIFICATION_PANEL,
          payload: { code, message }
        });
      })
      .catch(error => {
        console.log('createAnOldTranslation Error - ', error)
        this.props.showNotificationPanel(true, "ERROR", "Failure in connection");
      });
  }
}

export const deleteAnOldTranslation = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}translationsGenerator`;
    let body = [params];
    return fetch(URL, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      credentials: 'same-origin',
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(json => {
        const { code, message } = json.status
        dispatch({
          type: SET_NOTIFICATION_PANEL,
          payload: { code, message }
        });
      })
      .catch(error => {
        console.log('Delete Translations Error - ', error)
        this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage);
      });
  }
}

export const fetchTranslationWithKey = (params) => {
  return (dispatch) => {
    const devURL = `${process.env.REACT_APP_NODE_PC_ADMIN_DEV_URL}translations?key=${params.key}&application=${params.application}&locale=${params.locale}`;
    const qaURL = `${process.env.REACT_APP_NODE_PC_ADMIN_QA_URL}translations?key=${params.key}&application=${params.application}&locale=${params.locale}`;
    const betaURL = `${process.env.REACT_APP_NODE_PC_ADMIN_BETA_URL}translations?key=${params.key}&application=${params.application}&locale=${params.locale}`;
    const prodURL = `${process.env.REACT_APP_NODE_PC_ADMIN_PROD_URL}translations?key=${params.key}&application=${params.application}&locale=${params.locale}`;

    if(params.env === "dev"){
      const localEnv = "dev";
      callApi(devURL,"GET").then(response => response.json())
      .then(json => {
        if(json.length !== 0){
          let setTranslations = [];
          json[0].env = localEnv;
          json[0].shouldUpdate = false;
          setTranslations.push(json[0]);
          dispatch({
            type: SET_TRANSLATION_RESPONSE_DATA,
            payload: { setTranslations }
          })
      }
      });
    } else if(params.env === "qa") {
      const localEnv = "qa";
      callApi(qaURL,"GET").then(response => response.json())
      .then(json => {
        if(json.length !== 0){
          let setTranslations = [];
          json[0].env = localEnv;
          json[0].shouldUpdate = false;
          setTranslations.push(json[0]);
          dispatch({
            type: SET_TRANSLATION_RESPONSE_DATA,
            payload: { setTranslations }
          })
        }
      });
    } else if(params.env === "beta") {
      const localEnv = "beta";
      callApi(betaURL, "GET").then(response => response.json())
      .then(json => {
        if(json.length !== 0){
          let setTranslations = [];
          json[0].env = localEnv;
          json[0].shouldUpdate = false;
          setTranslations.push(json[0]);
          dispatch({
            type: SET_TRANSLATION_RESPONSE_DATA,
            payload: { setTranslations }
          })
        }
      });
    } else {
      const localEnv = "prod";
      callApi(prodURL, "GET").then(response => response.json())
      .then(json => {
        if(json.length !== 0){
          let setTranslations = [];
          json[0].env = localEnv;
          json[0].shouldUpdate = false;
          setTranslations.push(json[0]);
          dispatch({
            type: SET_TRANSLATION_RESPONSE_DATA,
            payload: { setTranslations }
          })
        }
      });
    }
  }
}
export const fetchEnvStatus = () => {
  return async (dispatch) => {
    const devURL = `${process.env.REACT_APP_NODE_PC_ADMIN_DEV_URL}health_check`;
    const qaURL = `${process.env.REACT_APP_NODE_PC_ADMIN_QA_URL}health_check`;
    const betaURL = `${process.env.REACT_APP_NODE_PC_ADMIN_BETA_URL}health_check`;
    const prodURL = `${process.env.REACT_APP_NODE_PC_ADMIN_PROD_URL}health_check`;
    let devData = callApi(devURL, "GET");
    let qaData = callApi(qaURL, "GET");
    let betaData = callApi(betaURL, "GET");
    let prodData = callApi(prodURL, "GET");
    const results = await Promise.all([devData, qaData, betaData, prodData].map(p => p.catch(e => e)))
    const response = results.map(result => { return (result instanceof Error) ? [] : result })
    let notReachableEnvs = [];
    console.log(response, "ENV RESPONSE")
    if (response[0].status !== 200) notReachableEnvs.push("dev");
    if (response[1].status !== 200) notReachableEnvs.push("qa");
    if (response[2].status !== 200) notReachableEnvs.push("beta");
    if (response[3].status !== 200) notReachableEnvs.push("prod");
    dispatch({
      type: FETCH_ENV_STATUS,
      payload: { notReachableEnvs }
    });
  }
}
