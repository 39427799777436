import Cookie from 'js-cookie';
import {
  FETCH_MORE_OBJECTS,
  SET_NOTIFICATION_PANEL,
} from '../ActionType';
import _ from 'lodash';
import {signOut} from '../helper';
import { PACKAGE_TYPE_BASE, PACKAGE_TYPE_RSN } from "../../components/PC/Constants.js"


export const fetchMoreObjects = (existingObjects, apiPath) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}${apiPath}`
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let objects = _.differenceBy(json, existingObjects, 'id')
      objects = _.map(objects, o => _.extend({checked: false}, o));
      let displayingObjects = Object.assign([], objects)
      dispatch({
        type: FETCH_MORE_OBJECTS,
        payload: {objects, displayingObjects}
      });
    })
    .catch(error => {
        console.log('fetchMoreObjects - ', error);
    })
  }
}

export const createMoreObjects = (params, type, packageType) => {
  return (dispatch) => {
    let URL;
    if (type === 'package') {
      if (packageType === PACKAGE_TYPE_RSN) {
        URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}create-package-prereqs`
      } else {
        URL = `${process.env.REACT_APP_PC_URL}create-package-${type}s`;
      }
    } else {
      URL = `${process.env.REACT_APP_PC_URL}create-package-${type}s`;
    }
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: params,
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
        console.log('createMoreObjects - ', error);
    })
  }
}

export const clearCache = ({URL, msg}) => {
  return (dispatch) => {
    let params = {client: 'studio', secret: process.env.REACT_APP_PC_SECRET_KEY};
    if (msg === 'Cleared PPV cache successfully') {
      params['cache_type'] = 'PPV'
    }

    if (msg === 'Cleared OTT cache successfully') {
      params['cache_type'] = 'OTT'
    }

    fetch(URL, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })
    .then(response => response.json())
		.then(json => {
      if (json.status_code === 'OK') {
        let code = 'success'
        dispatch({
          type: SET_NOTIFICATION_PANEL,
          payload: {code, msg}
        });
      }
    })
    .catch(error => console.error('Error:', error))
  }
}

export const pcNodeJSCacheClear = ({URL, msg}) => {

  return (dispatch) => {
    const message = msg;
    fetch(URL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'x-apicache-bypass': true,
        Authorization: Cookie.get('authtoken')
      })
    })
		.then(json => {
      if (json.statusText === 'OK' || json.ok === true) {
        let code = 'SUCCESS'
        dispatch({
          type: SET_NOTIFICATION_PANEL,
          payload: {code, message}
        });
      }
    })
    .catch(error => console.error('Error:', error))
  }
}

// export const clearCache = () => {
//   return (dispatch) => {
//     let URL = `${process.env.REACT_APP_OLD_PC_URL}/cache/clear`;
//     var data = {client: 'studio', secret: process.env.REACT_APP_PC_SECRET_KEY};
//     fetch(URL, {
//       method: 'POST',
//       body: JSON.stringify(data),
//       headers: new Headers({
//         'Content-Type': 'application/json'
//       })
//     })
//     .then(response => response.json())
// 		.then(json => {
//       if (json.status_code === 'OK') {
//         let code = 'success'
//         let message = 'Cache Cleared Successfully'
//         dispatch({
//           type: SET_NOTIFICATION_PANEL,
//           payload: {code, message}
//         });
//       }
//     })
//     .catch(error => console.error('Error:', error))
//   }
// }
