import Cookie from 'js-cookie';
import {
  FETCH_CHANNELS,
  SET_NOTIFICATION_PANEL,
  FETCH_A_CHANNEL,
  LOADING_CHANNEL_FORM,
  RESET_CHANNEL_FORM,
  EDIT_CHANNEL
} from '../ActionType';
import _ from 'lodash';
import {fetchDefaultClassifications} from './DefaultDataCalls';
import {signOut} from '../helper';

export const fetchAllChannels = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}channels`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let channels = json;
        channels = _.sortBy(channels, 'priority');
        dispatch({
          type: FETCH_CHANNELS,
          payload: {channels}
        });
      })
      .catch(error => {
          console.log('fetchAllChannels Error - ', error);
      })
  }
}

export const fetchAChannel = (id = null) => {
  let editChannel = false;
  const typeOfCall = id ? 'channelEdit' : 'channelCreate';
  if (id) { editChannel = true }
  return (dispatch) => {
    dispatch({type: LOADING_CHANNEL_FORM, payload: true}) // Even if we're creating a new channel, we're still waiting for fresh default classification info
    dispatch({type: RESET_CHANNEL_FORM})
    dispatch({type: EDIT_CHANNEL, payload: {editChannel}})
    dispatch(fetchDefaultClassifications(id, typeOfCall))
  }
}

export const fetchAChannelAfter = (id) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}channel/${id}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        active,
        business_name: businessName,
        cms_external_id: cmsExternalId,
        call_sign: callSign,
        classification_id: classificationId,
        id,
        identifier,
        image_name: imageName,
        is_free: isFree,
        priority,
        title,
      } = json.data.channels;
      const { image_urls: imageUrls } = json.data
      let description = json.data.translations.find(tr => tr.key === `model.channel.${identifier}.description`);
      let name = json.data.translations.find(tr => tr.key === `model.channel.${identifier}.name`);
      dispatch({
        type: FETCH_A_CHANNEL,
        payload: {
          active,
          businessName,
          cmsExternalId,
          callSign,
          classificationId,
          description,
          id,
          identifier,
          imageName,
          imageUrls,
          isFree,
          name,
          priority,
          title,
        }
      })
    })
  }
}

export const createAChannel = (params) => {
  let statusCode;
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}create-update-channel`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => {
      statusCode = response.status;
      return response.json()
    })
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllChannels())
    })
    .catch(error => {
      console.log('createAChannel Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const updateAChannel = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}create-update-channel`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => {
      return response.json()
    })
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllChannels())
    })
    .catch(error => {
      console.log('updateAChannel Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}
