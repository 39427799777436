import {
  FETCH_PLANS,
  LOADING_PLAN_FORM,
  FETCH_A_PLAN,
  RESET_PLAN_FORM,
  FETCH_ALL_PACKAGES_BYPLANID,
  EDIT_PLAN
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  plans: [],
  displayingPlans: [],
  loading: true,
  // Particular Plan
  identifier: '',
  planCode: '',
  trialDays: 0,
  additionalAddOnsAmount: '',
  amount: '',
  baseLinearAddOnsAmount: '',
  baseLinearMultiplier: '',
  baseLinearIntroMonths: '',
  billingCycleLengthInMonths: '',
  concurrencyMultiplier: '',
  downgradable: false,
  extraLinearAddOnsAmount: '',
  extrasMultiplier: '',
  immutable: false,
  nonRefundable: false,
  planIntervalLength : '',
  planIntervalUnit: 0,
  totalBillingCycles: '',
  upsell: false,
  futurePlanId: 0,
  restartPlanId: 0,
  webUpsell: false,
  certificateRequired: false,
  additionalDays: '',
  priority: '',
  planType: 'grandfathered',
  bundleOffer: false,
  appsSupported: [
                  {appName: "icepak", isSupported: false},
                  {appName: "ottweb", isSupported: false},
                  {appName: "fakeblock", isSupported: false}
                ],
  loadingAPlan: false,
  planPackages: [],
  newPackagesList: [],
  planIdentifier: '',
  name: {},
  editPlan: false,
  allPackageList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PLANS:
      let {plans} = action.payload
      return {...state, plans, displayingPlans: plans, loading: false};
    case FETCH_A_PLAN:
      let {
        identifier, planCode, trialDays, additionalAddOnsAmount, amount,
        baseLinearAddOnsAmount, baseLinearMultiplier, baseLinearIntroMonths,
        billingCycleLengthInMonths, concurrencyMultiplier, downgradable,
        extraLinearAddOnsAmount, extrasMultiplier, immutable, nonRefundable,
        planIntervalLength , planIntervalUnit, totalBillingCycles, upsell,
        futurePlanId, restartPlanId, webUpsell, bundleOffer,
        certificateRequired, additionalDays, priority, planType, appsSupported,
        name
      } = action.payload
      return {
        ...state, identifier, planCode, trialDays, additionalAddOnsAmount, amount,
        baseLinearAddOnsAmount, baseLinearMultiplier, baseLinearIntroMonths,
        billingCycleLengthInMonths, concurrencyMultiplier, downgradable,
        extraLinearAddOnsAmount, extrasMultiplier, immutable, nonRefundable,
        planIntervalLength , planIntervalUnit, totalBillingCycles, upsell,
        futurePlanId, restartPlanId, webUpsell, bundleOffer,
        certificateRequired, additionalDays, priority, planType, appsSupported, name,
        loadingAPlan: false
      };
    case LOADING_PLAN_FORM:
      return {...state, loadingAPlan: !state.loadingAPlan};
    case EDIT_PLAN:
      let {editPlan} = action.payload;
      return {...state, editPlan};
    case RESET_PLAN_FORM:
      let emptyPlanForm = _.omit(INITIAL_STATE, ['plans', 'displayingPlans','loading', 'loadingAPlan']);
      return {...state, ...emptyPlanForm, loadingAPlan: true };
    case FETCH_ALL_PACKAGES_BYPLANID:
      let {planPackageList, newPackagesList, planIdentifier} = action.payload
      let allPackageListInit = [];
      for (const echPkg of newPackagesList) {
        let pkgObj = {
          planPackageId: null,
          packageId: echPkg.value,
          packageIdentifier: echPkg.label,
          planPackagePrice: 0,
          displayPrice: 0,
          freeTrial: false,
          iauEnabled: true,
          isNew: false
        };
        allPackageListInit.push(pkgObj);
      }
      return { ...state, planPackages: planPackageList, planIdentifier, loading: false, allPackageList: [ ...allPackageListInit, ...planPackageList ] };
    default:
      return state;
  }
}
