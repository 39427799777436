export const PC_ITEMS = [
  { pathname: '/pc/plans', label: 'Plans', icon: 'play'},
  { pathname: '/pc/packages', label: 'Packages', icon: 'get-pocket' },
  { pathname: '/pc/freemiums', label: 'Freemiums', icon: 'get-pocket' },
  { pathname: '/pc/channels', label: 'Channels', icon: 'bullhorn'},
  { pathname: '/pc/dma_codes_zipcode', label: 'DMA Codes-Zipcodes', icon: 'dot-circle-o'},
  { pathname: '/pc/offers', label: 'Offers', icon: 'get-pocket'},
  { pathname: '/pc/ppv', label: 'PPV', icon: 'wpexplorer'},
  { pathname: '/pc/partner_platforms', label: 'Partner Platforms', icon: 'podcast'},
  // Tab removed pending refactoring of partner packages display within partner classifications
  // Components and redux/actions can be removed for PartnerPackage
  // { pathname: '/pc/partner_packages', label: 'Partner Packages', icon: 'themeisle'},
  { pathname: '/pc/classifications', label: 'Classifications', icon: 'table' },
  { pathname: '/pc/line_of_business', label: 'Line of Business', icon: 'bandcamp'},
  { pathname: '/pc/iap_classifications', label: 'IAP Classifications', icon: 'grav'},
  { pathname: '/pc/content_tags', label: 'Content Tagging', icon: 'tags'},
  { pathname: '/pc/translations', label: 'Translations', icon: 'dot-circle-o' },
  { pathname: '/pc/translations-mgr', label: 'Translations Manager', icon: 'dot-circle-o' },
  // { pathname: '/pc/ott_translations', label: 'OTT Translations', icon: 'language'},
  { pathname: '/pc/clear_cache', label: 'Clear Cache', icon: 'creative-commons'},
];

export const PACKAGE_TYPES = [
  {value: 0, label: 'Base Linear'},
  {value: 1, label: 'Extra Linear'},
  {value: 4, label: 'Device Packages'},
]

export const CUSTOMER_CATEGORY_ID = [
  {value: 1, label: 'Domestic'},
  {value: 2, label: 'Latino'},
  {value: 3, label: 'International'},
]

export const DWLOB = [
  {value: 'domestic', label: 'Domestic'},
  {value: 'equipment', label: 'Equipment'},
  {value: 'international', label: 'International'},
  {value: 'latino', label: 'Latino'},
  {value: 'shipping', label: 'Shipping'},
  {value: 'stream', label: 'Stream'},
  {value: 'taxes', label: 'Taxes'},
]
export const PACKAGE_TABS_BASE_NAV_ITEMS = [
  {tabId: 'channels', label: 'CHANNELS'},
  {tabId: 'classifications', label: 'CLASSIFICATIONS'},
  {tabId: 'plans', label: 'PLANS'},
  {tabId: 'packages', label: 'EXTRA PACKAGES'}
];
export const PACKAGE_TABS_EXTRA_NAV_ITEMS = [
  {tabId: 'channels', label: 'CHANNELS'},
  {tabId: 'classifications', label: 'CLASSIFICATIONS'},
  {tabId: 'plans', label: 'PLANS'},
  {tabId: 'packages', label: 'BASE PACKAGES'}
];
export const PACKAGE_TABS_RSN_NAV_ITEMS = [
  {tabId: 'channels', label: 'CHANNELS'},
  {tabId: 'classifications', label: 'CLASSIFICATIONS'},
  {tabId: 'plans', label: 'PLANS'},
  {tabId: 'packages', label: 'REQUIRED PACKAGES'}
];

export const PACKAGE_TYPE_BASE = 0;
export const PACKAGE_TYPE_EXTRA = 1;
export const PACKAGE_TYPE_RSN = 7;
