import {
  FETCH_IAP_CLASSIFICATIONS,
  FETCH_AN_IAP_CLASSIFICATION,
  LOADING_IAP_CLASSIFICATION_FORM,
  RESET_IAP_CLASSIFICATION_FORM,
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
    iapClassifications: [],
    displayingIapClassifications: [],
    loading: true,
    loadingAnIapClassication: false,
    identifier: null,
    classificationId: null,
    enabled: false,
    priority: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_IAP_CLASSIFICATIONS:
      let {iapClassifications} = action.payload
      return {...state, iapClassifications, displayingIapClassifications: iapClassifications, loading: false};
    case FETCH_AN_IAP_CLASSIFICATION:
      let {
        identifier, classificationId, enabled, priority
      } = action.payload
      return {
        ...state, identifier, classificationId, enabled, priority, loadingAnIapClassication: false
      }
    case LOADING_IAP_CLASSIFICATION_FORM:
      return {
        ...state,
        loadingAnIapClassication: !state.loadingAnIapClassication
      };
    case RESET_IAP_CLASSIFICATION_FORM:
      let emptyIapClassicationForm = _.omit(INITIAL_STATE, ['iapClassifications', 'displayingIapClassifications', 'loading', 'loadingAnIapClassication']);
    return {
      ...state,
      ...emptyIapClassicationForm,
      loadingAnIapClassication: true
    };
    default:
    return state;
  }
}