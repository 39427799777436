import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAnIapClassification, createAnIapClassification } from '../../../redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

export class IapClassificationForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingAnIapClassication: true
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    const {iapClassificationId}  = this.props;
    this.props.fetchAnIapClassification(iapClassificationId)
    this.setState({ isPaneOpen: true })
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    const { identifier, classificationId, allClassifications, enabled, priority, loadingAnIapClassication } = nextProps

    this.setState({ identifier, classificationId, allClassifications, enabled, priority, loadingAnIapClassication })

    if (nextProps.statusCode === "SUCCESS") {
      this.props.closeEditIapClassificationPane(null, true)
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const { identifier, classificationId, enabled, priority } = this.state

    let params = { identifier, classificationId, enabled, priority };
    this.props.createAnIapClassification(params)
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    const {
      enabled, isPaneOpen, loadingAnIapClassication, priority, 
      identifier, allClassifications, classificationId
    } = this.state;
    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='IAP Classification Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closeEditIapClassificationPane()
          }}>
          { loadingAnIapClassication ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleChange('identifier', e.target.value)} value={identifier}/>
                    <Label for="identifier">Identifier</Label>
                  </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="classificationId" id="classificationId"  value={classificationId} onChange={(e) => this.handleChange('classificationId', e.target.value)}>
                    {this.renderOptions(allClassifications)}
                  </Input>
                  <Label for="classificationId" className='select-label'>Classification</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="priority" id="priority" placeholder="Priority" onChange={(e) => this.handleChange('priority', e.target.value)} value={priority}/>
                    <Label for="priority">Priority</Label>
                  </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="enabled" id="enabled" checked={enabled} onChange={(e) => this.handleChange('enabled', !enabled)} />{' '}
                <Label check className="text-center"> This IAP Classification is currently active. </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({IapClassificationData, DefaultDataCalls, FlashMessageData}) => {
  return {
    loadingAnIapClassication: IapClassificationData.loadingAnIapClassication,
    identifier: IapClassificationData.identifier,
    classificationId: IapClassificationData.classificationId,
    priority: IapClassificationData.priority,
    enabled: IapClassificationData.enabled,
    statusCode: FlashMessageData.statusCode,
    allClassifications: DefaultDataCalls.defaultClassifications,
  };
}

export default connect(mapStateToProps, {
  fetchAnIapClassification,
  createAnIapClassification
})(IapClassificationForm);
