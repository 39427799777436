import Cookie from 'js-cookie';
import {
  FETCH_A_PACKAGE_CLASSIFICATION,
  SET_NOTIFICATION_PANEL,
} from '../ActionType';
import {signOut} from '../helper';

export const fetchAPackageClassification = (packageId, classificationId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}package-classification/${packageId}/${classificationId}.json`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let { appSupported, classPackagePriority } = json;
      dispatch({
        type: FETCH_A_PACKAGE_CLASSIFICATION,
        payload: {
          appSupported, classPackagePriority
        }
      })
    })
    .catch(error => {
        console.log('fetchPackagePlan Error - ', error);
    })
  }
}

export const updateAPackageClassification = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}update-package-classification`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      // dispatch(fetchPackagesByClassification())
      // this.props.showNotificationPanel(true, json.status.code, json.status.message)
      // this.props.closePackageFormPane(null, true)
    })
    .catch(error => {
      console.log('createAPackage Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}