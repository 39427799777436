import React, { Component } from 'react';
import './FlashMessage.css';

import { connect } from 'react-redux';
import {resetNotificationPanel} from '../../../../scripts/redux/actions'

class FlashMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      closeMessage: '',
    }
  }

  componentWillMount() {
    this.setState({closeMessage: this.props.statusMessage});
  }

  closeMessage() {
    this.setState({showNotification: false});
    this.props.resetNotificationPanel()
  }

  componentWillReceiveProps(nextProps) {
    const {statusCode, statusMessage, showNotification} = nextProps
    this.setState({statusCode, statusMessage, showNotification})
    if (showNotification) {
      if(statusCode.toLowerCase() === "success"){
        setTimeout(() => {this.closeMessage()}, 1500);
      } else {
        setTimeout(() => {this.closeMessage()}, 2000);
      }
    }
  }

  render() {
    const { showNotification } = this.state;
    return(
      <div>
        { showNotification ? <div className="notification-container" onClick={()=>this.closeMessage()}>
          <span>
            <div className={`notification notification-${this.props.statusCode.toLowerCase()} notification-enter notification-enter-active`}>
              <div className="notification-message" role="alert">
                <i className="fa fa-check-circle notification-icon"></i>
                <div className="message">{this.props.statusMessage}</div>
              </div>
            </div>
          </span></div> : '' }
      </div>
    )
  }
}

const mapStateToProps = ({ FlashMessageData }) => {
  return {
    statusCode: FlashMessageData.statusCode,
    statusMessage: FlashMessageData.statusMessage,
    showNotification: FlashMessageData.showNotification,
  }
}

export default connect(mapStateToProps, {resetNotificationPanel})(FlashMessage);
