import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createAClone } from '../../../../scripts/redux/actions'
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import './ClonePartnerPlatformForm.css';

export class ClonePartnerPlatformForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      localLoader: false,
      isPaneOpen: false,
      partnerPlatform: this.props.parentIdentifier || {},
      partner: '',
      platform: '',
      partnerList: this.props.partnerList || [],
      showPartnerInput: false,
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  handlePartnerChange(value) {
    if (value === 'Other') {
      this.setState((prevState) => {
        return { showPartnerInput: !prevState.showPartnerInput }
      })
    } else if (value === 'Select Partner') {
      this.setState({partner: '', showPartnerInput: false})
    } else {
      this.setState({showPartnerInput: false, partner: value})
    }
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.statusCode === "Success") {
      this.setState({localLoader: false})
      this.props.closeClonePane(null, true)
    }
    if (nextProps.statusCode === "ERROR") {
      this.setState({localLoader: false})
      this.props.closeClonePane()
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    let { parentIdentifier } = this.props;
    let { partner, platform } = this.state;

    let params = {};
    params['partnerPlatformId'] = parentIdentifier.id
    params['partner'] = partner
    params['platform'] = platform
    this.setState({localLoader: true})
    this.props.createAClone(params, 'partner-platform')
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    let { isPaneOpen, partnerList, showPartnerInput, localLoader } = this.state;
    
    return (
      <div>
        <div ref={ref => this.el = ref} className='clone-form'>
          <SlidingPane
            className='slinding-pane'
            overlayClassName='some-custom-overlay-class'
            isOpen={isPaneOpen}
            title='Clone To'
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              this.setState({ isPaneOpen: false });
              this.props.closeClonePane()
            }}>
            {localLoader ? <div className="localLoading">Loading&#8230;</div> : '' }
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="partner" id="partner" onChange={(e) => this.handlePartnerChange(e.target.value)}>
                    {this.renderOptions(partnerList)}
                  </Input>
                  <Label for="partner" className='select-label'>Partner</Label>
                </div>
              </FormGroup>
              { showPartnerInput ? 
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="partner" id="platform" placeholder="Please Specify Partner Name" onChange={(e) => this.handleChange('partner', e.target.value)} required />
                  <Label for="partner">Partner</Label>
                </div>
              </FormGroup> : null }
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="platform" id="platform" placeholder="Platform" onChange={(e) => this.handleChange('platform', e.target.value)} required />
                  <Label for="platform">Platform</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
            <br />
          </SlidingPane>
        </div>
      </div>
    )
  }

}

const mapStateToProps = ({FlashMessageData}) => {
  return {
    statusCode: FlashMessageData.statusCode
  };
}
export default connect(mapStateToProps, {
  createAClone,
})(ClonePartnerPlatformForm);
