export const FETCH_CLASSIFICATIONS = 'fetch_classifications';
export const FETCH_A_CLASSIFICATION =  'fetch_a_classification';
export const LOADING_CLASSIFICATION_FORM = 'loading_classification_form';
export const RESET_CLASSIFICATION_FORM = 'reset_classification_form';
export const FETCH_DW_CLASSIFICATIONS = 'fetch_dw_classifications';

export const FETCH_PACKAGES_BY_CLASSIFICATION = 'fetch_packages_by_classification';
export const FETCH_PARTNER_PLATFORM_IDS_BY_PACKAGEID = 'fetch_partner_platform_ids_by_packageid';
export const FETCH_A_PACKAGE =  'fetch_a_package';
export const LOADING_PACKAGE_FORM = 'loading_package_form';
export const RESET_PACKAGE_FORM = 'reset_package_form';
export const EDIT_PACKAGE = 'edit_package';
export const FETCH_FREEMIUMS_BY_CLASSIFICATION = 'fetch_freemiums_by_classification';
export const FETCH_A_FREEMIUM =  'fetch_a_freemium';
export const LOADING_FREEMIUM_FORM = 'loading_freemium_form';
export const RESET_FREEMIUM_FORM = 'reset_freemium_form';
export const EDIT_FREEMIUM = 'edit_freemium';
export const SET_NOTIFICATION_PANEL =  'set_notification_panel';
export const RESET_NOTIFICATION_PANEL =  'reset_notification_panel';

export const FETCH_DEFAULT_PLANS =  'fetch_default_plans';
export const FETCH_DEFAULT_PACKAGES =  'fetch_default_packages';
export const FETCH_DEFAULT_CLASSIFICATIONS =  'fetch_default_classifications';
export const FETCH_DEFAULT_PACKAGE_TYPES = 'fetch_default_package_types';
export const FETCH_SHIPPING_METHODS = 'fetch_shipping_methods';
export const FETCH_DEFAULT_SHIPPING_METHODS = 'fetch_default_shipping_methods';
export const FETCH_DEFAULT_PACKAGE_EXTRAS = 'FETCH_DEFAULT_PACKAGE_EXTRAS'
export const FETCH_DEFAULT_PACKAGE_BASE_PACKS = 'FETCH_DEFAULT_PACKAGE_BASE_PACKS'

export const FETCH_PLANS = 'fetch_plans';
export const FETCH_A_PLAN =  'fetch_a_plan';
export const LOADING_PLAN_FORM = 'loading_plan_form';
export const RESET_PLAN_FORM = 'reset_plan_form';
export const EDIT_PLAN = 'edit_plan';

export const FETCH_CHANNELS = 'fetch_channels';
export const FETCH_A_CHANNEL =  'fetch_a_channel';
export const EDIT_CHANNEL = 'edit_channel';
export const LOADING_CHANNEL_FORM = 'loading_channel_form';
export const RESET_CHANNEL_FORM = 'reset_channel_form';

export const FETCH_DMACODESZIPCODES = 'fetch_dmacodeszipcodes';
export const FETCH_DMACODES = 'fetch_dmacodes';
export const FETCH_ZIPCODES = 'fetch_zipcodes';
export const FETCH_A_DMACODESZIPCODE =  'fetch_a_dmacodeszipcode';
export const EDIT_DMACODESZIPCODE = 'edit_dmacodeszipcode';
export const LOADING_DMACODESZIPCODE_FORM = 'loading_dmacodeszipcode_form';
export const RESET_DMACODESZIPCODE_FORM = 'reset_dmacodeszipcode_form';
export const UPDATE_DMACODESZIPCODES_STATE = 'update_dmacodeszipcodes_state'

export const FETCH_TRANSLATIONS = 'fetch_translations';
export const FETCH_NEW_TRANSLATIONS = 'fetch_new_translations';
export const FETCH_ENV_STATUS = 'fetch_env_status';
export const FETCH_A_TRANSLATION =  'fetch_a_translation';
export const LOADING_TRANSLATION_FORM = 'loading_translation_form';
export const RESET_TRANSLATION_FORM = 'reset_translation_form';
export const SET_TRANSLATION_RESPONSE_DATA = 'set_translation_form';


export const FETCH_MORE_OBJECTS = 'fetch_more_objects';
export const FETCH_A_PACKAGE_PLAN = 'fetch_a_package_plan';

export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';

export const FETCH_A_PACKAGE_CLASSIFICATION = 'FETCH_A_PACKAGE_CLASSIFICATION';
export const FETCH_A_PLAN_PACKAGE = 'FETCH_A_PLAN_PACKAGE';
export const FETCH_PPV_PACKAGES = 'FETCH_PPV_PACKAGES';
export const FETCH_A_PPV_PACKAGE = 'FETCH_A_PPV_PACKAGE';
export const LOADING_PPV_FORM = 'LOADING_PPV_FORM';
export const RESET_PPV_FORM = 'RESET_PPV_FORM';
export const FETCH_ENABLED_PACKAGES = 'FETCH_ENABLED_PACKAGES';

export const FETCH_LOBS = 'FETCH_LOBS';
export const FETCH_AN_LOB = 'FETCH_AN_LOB';
export const LOADING_LOB_FORM = 'LOADING_LOB_FORM';
export const RESET_LOB_FORM = 'RESET_LOB_FORM';
export const FETCH_LOB_PLANS = 'FETCH_LOB_PLANS';
export const FETCH_AN_LOB_PLAN = 'FETCH_AN_LOB_PLAN';
export const FETCH_PARTNER_PLATFORMS = 'FETCH_PARTNER_PLATFORMS';
export const RESET_LOB_PLAN_FORM = 'RESET_LOB_PLAN_FORM';

export const FETCH_IAP_CLASSIFICATIONS = 'FETCH_IAP_CLASSIFICATIONS';
export const FETCH_OFFERS_COMPLETE = 'FETCH_OFFERS_COMPLETE';
export const FETCHING_OFFERS = 'FETCHING_OFFERS';
export const OFFER_POST_STATUS = 'OFFER_POST_STATUS';
export const FETCH_AN_IAP_CLASSIFICATION = 'FETCH_AN_IAP_CLASSIFICATION';
export const LOADING_IAP_CLASSIFICATION_FORM = 'LOADING_IAP_CLASSIFICATION_FORM';
export const RESET_IAP_CLASSIFICATION_FORM = 'RESET_IAP_CLASSIFICATION_FORM';

export const FETCH_ALL_PARTNER_PLATFORMS = 'FETCH_ALL_PARTNER_PLATFORMS';
export const LOADING_PARTNER_PLATFORM_FORM = 'LOADING_PARTNER_PLATFORM_FORM';
export const FETCH_A_PARTNER_PLATFORM = 'FETCH_A_PARTNER_PLATFORM';
export const RESET_PARTNER_PLATFORM_FORM = 'RESET_PARTNER_PLATFORM_FORM';
export const FETCH_A_PARTNER_CLASSIFICATION = 'FETCH_A_PARTNER_CLASSIFICATION';
export const RESET_PARTNER_CLASSIFICATION_FORM = 'RESET_PARTNER_CLASSIFICATION_FORM';
export const LOADING_PARTNER_CLASSIFICATION_FORM = 'LOADING_PARTNER_CLASSIFICATION_FORM';

export const FETCH_PARTNER_CLASSIFICATIONS = 'FETCH_PARTNER_CLASSIFICATIONS';
export const LOADING_PARTNER_PACKAGES = 'LOADING_PARTNER_PACKAGES';
export const LOADING_PARTNER_PACKAGE_FORM = 'LOADING_PARTNER_PACKAGE_FORM';
export const RESET_PARTNER_PACKAGE_FORM = 'RESET_PARTNER_PACKAGE_FORM';
export const FETCH_A_PARTNER_PACKAGE = 'FETCH_A_PARTNER_PACKAGE';
export const FETCH_A_PARTNER_PACKAGE_EXTRA = 'FETCH_A_PARTNER_PACKAGE_EXTRA';
export const PACKAGE_PARTNER_PACKAGES = 'PACKAGE_PARTNER_PACKAGES';
export const FETCH_ALL_PARTNER_PLATFORM = 'FETCH_ALL_PARTNER_PLATFORM';
export const FETCH_PACKAGE_IAU_ENABLE_STATUS = 'FETCH_PACKAGE_IAU_ENABLE_STATUS';

export const FETCH_ALL_PACKAGES_BYPLANID = 'fetch_all_packages_byplanid';
export const FETCH_A_PACKAGE_LIST_TO_ADD = 'fetch_a_package_list_to_add';
export const FETCH_ALL_CONTENT_TAG = 'fetch_all_content_tag';
export const FETCH_A_CONTENT_TAG = 'fetch_a_content_tag';
export const FETCH_CONTENT_TAG_TREE = 'fetch_content_tag_tree';
export const FETCH_CONTENT_TAG_CHILDRENS = 'fetch_content_tag_childrens';
export const FETCH_CONTENT_TAG_DROPDOWN = 'fetch_content_tag_dropdown';
export const FETCH_CONTENT_TAG_VALIDATION_DROPDOWN = 'fetch_content_tag_validation_dropdown';
