import {
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
} from '../ActionType';

const INITIAL_STATE = {
  isLoginSuccess: false,
  isLoginPending: false,
  loginError: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOGIN_PENDING:
      return {...state, isLoginPending: action.isLoginPending};
    case SET_LOGIN_SUCCESS:
      return {...state, isLoginSuccess: action.isLoginSuccess};
    case SET_LOGIN_ERROR:
      return {...state, loginError: action.loginError};
    default:
      return state;
  }
}
