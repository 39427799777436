import {
  FETCH_PARTNER_CLASSIFICATIONS,
  LOADING_PARTNER_PACKAGES,
  FETCH_A_PARTNER_PACKAGE,
  RESET_PARTNER_PACKAGE_FORM,
  FETCH_A_PACKAGE_LIST_TO_ADD
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  partnerClassifications: [],
  partnerPackages: [],
  loading: false,
  id: null,
  partnerClassificationId: null,
  packageId: '',
  pack: '',
  enabled: false,
  priority: '',
  allPackageExtras: [],
  partnerPackagesList: []
  }

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PARTNER_CLASSIFICATIONS:
      let {
        partnerClassifications
      } = action.payload
      return {
        ...state,
        partnerClassifications,
      };
    case FETCH_A_PARTNER_PACKAGE:
      let {
        id, partnerClassificationId, packageId, pack, enabled, priority, allPackageExtras
      } = action.payload
      return {
        ...state,
        id, partnerClassificationId, packageId, pack, enabled, priority, allPackageExtras
      }
    case RESET_PARTNER_PACKAGE_FORM:
      let emptyPartnerPackageForm = _.omit(INITIAL_STATE, ['partnerClassifications', 'partnerPackages', 'loading', 'loadingAnPartnerPlatform']);
      return {
        ...state,
        ...emptyPartnerPackageForm,
        loadingAnPartnerPlatform: false
      };
    case LOADING_PARTNER_PACKAGES:
      return {
        ...state, loading: !state.loading
      }
    case FETCH_A_PACKAGE_LIST_TO_ADD:
      let { partnerPackagesList } = action.payload;
      return {
        ...state, partnerPackagesList
      }
    default:
      return state;
  }
}
