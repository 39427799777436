import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAChannel, createAChannel, updateAChannel } from '../../../../scripts/redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';

const styles = {
  imageUrl: {
    overflowWrap: 'break-word',
    paddingBottom: '1rem'
  },
  imageUrlContainer: {
    fontSize:'14px',
    margin: '1rem 0'
  },
  logoContainer: {
    backgroundImage:
    `linear-gradient(45deg, #e0e0e0 25%, transparent 25%, transparent 75%, #e0e0e0 75%, #e0e0e0),
    linear-gradient(45deg, #e0e0e0 25%, #d4d4d4 25%, #d4d4d4 75%, #e0e0e0 75%, #e0e0e0)`,
    backgroundSize: '50px 50px', /* Must be a square */
    backgroundPosition: '0 0, 25px 25px', /* Must be half of the square */
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem 0'
  },
  submitButton: {
    height:'35px',
    fontSize:'14px',
    width:'25%',
    marginTop:'10px'
  }
}
class ChannelForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      allClassifications: [],
      isPaneOpen: false,
      isPaneOpenLeft: false
    }
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  handleTranslationChange(key, value) {
    if (key === 'name'){
      if (Object.keys(this.state.name).length === 0) {
        let newNameObj = {}
        newNameObj['key'] = `model.channel.${this.state.identifier}.name`;
        newNameObj['value'] = value;
        newNameObj['interpolations'] = null;
        newNameObj['is_proc'] = false;
        newNameObj['translatable_type'] = "Channel";
        newNameObj['translatable_id'] = this.props.channelId;
        newNameObj['application'] = "catalog";
        this.setState({name: newNameObj});
      }
      if (!this.isEmpty(this.state.name)) {
        let nameCopy = {...this.state.name};
        if (this.isEmpty(nameCopy.translatable_type)) {
          nameCopy.translatable_type = "Channel";
        }
        if (this.isEmpty(nameCopy.translatable_id)) {
          nameCopy.translatable_id = this.props.channelId;
        }
        nameCopy.value = value;
        this.setState({name: nameCopy});
      }
    } else if (key === 'description') {
      if (Object.keys(this.state.description).length === 0) {
        let newDescriptionObj = {}
        newDescriptionObj['key'] = `model.channel.${this.state.identifier}.description`;
        newDescriptionObj['value'] = value;
        newDescriptionObj['interpolations'] = null;
        newDescriptionObj['is_proc'] = false;
        newDescriptionObj['translatable_type'] = "Channel";
        newDescriptionObj['translatable_id'] = this.props.channelId;
        newDescriptionObj['application'] = "catalog";
        this.setState({description: newDescriptionObj});
      }
      if (!this.isEmpty(this.state.description)) {
        let descriptionCopy = {...this.state.description};
        if (this.isEmpty(descriptionCopy.translatable_type)) {
          descriptionCopy.translatable_type = "Channel";
        }
        if (this.isEmpty(descriptionCopy.translatable_id)) {
          descriptionCopy.translatable_id = this.props.channelId;
        }
        descriptionCopy.value = value;
        this.setState({description: descriptionCopy});
      }
    }
  }

  componentDidMount() {
      Modal.setAppElement(this.el);
  }

  componentWillReceiveProps(nextProps) {
    const {
      name, description, id, identifier, classificationId, callSign, imageName, imageUrls,
      priority, cmsExternalId, title, businessName, isFree, active,
      loadingAChannel, allClassifications, editChannel
    } = nextProps

    this.setState({
      name, description, id, identifier, classificationId, callSign, imageName, imageUrls,
      priority, cmsExternalId, title, businessName, isFree, active,
      loadingAChannel, allClassifications, editChannel
    })

    if (nextProps.statusCode === "Success") {
      this.props.closeEditChannelPane(null, true)
    }
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.props.fetchAChannel(this.props.channelId)
    this.setState({ isPaneOpen: true })
  }

  handleIdentifierChange(value) {
    if (!this.isEmpty(this.state.name)) {
      let nameCopy = {...this.state.name};
      nameCopy.key = `model.channel.${value}.name`;
      this.setState({name: nameCopy});
    }
    if (!this.isEmpty(this.state.description)) {
      let descriptionCopy = {...this.state.description};
      descriptionCopy.key = `model.channel.${value}.description`;
      this.setState({description: descriptionCopy});
    }
    this.setState({identifier: value});
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const {
      description, id, identifier, classificationId, callSign, imageName,
      priority, cmsExternalId, title, businessName, isFree, name
    } = this.state

    const active = this.state.active === true ? 1 : 0;

    let params = {
      description, id, identifier, classificationId, callSign, imageName,
      priority, cmsExternalId, title, businessName, isFree, active, name
    };

    if (this.isEmpty(params.name.key)) {
      params.name.key = `model.channel.${identifier}.name`;
    }
    if (this.isEmpty(params.name.value)) {
      params.name.value = name.value;
    }
    if (this.isEmpty(params.name.is_proc)) {
      params.name.is_proc = false;
    }
    if (this.isEmpty(params.name.translatable_type)) {
      params.name.translatable_type = "Channel";
    }
    if (this.isEmpty(params.name.translatable_id)) {
      params.name.translatable_id = this.props.channelId;
    }
    if (this.isEmpty(params.name.application)) {
      params.name.application = "catalog";
    }

    if (this.isEmpty(params.description.key)) {
      params.description.key = `model.channel.${identifier}.description`;
    }
    if (this.isEmpty(params.description.value)) {
      params.description.value = description.value;
    }
    if (this.isEmpty(params.description.is_proc)) {
      params.description.is_proc = false;
    }
    if (this.isEmpty(params.description.translatable_type)) {
      params.description.translatable_type = "Channel";
    }
    if (this.isEmpty(params.description.translatable_id)) {
      params.description.translatable_id = this.props.channelId;
    }
    if (this.isEmpty(params.description.application)) {
      params.description.application = "catalog";
    }

    if (!id) {delete params.id}

    if (this.props.editChannel) {
      params.id = this.props.channelId;
      this.props.updateAChannel(params)
    } else {
      this.props.createAChannel(params)
    }
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    const {
      name, description, identifier, classificationId, callSign, imageName, imageUrls,
      priority, cmsExternalId, title, businessName, isFree, active,
      isPaneOpen, allClassifications
    } = this.state;
    let renderUrls = null;
    if (imageUrls && imageUrls.length > 0) {
      const urls = imageUrls.map((imageData) => {
        const url = `${imageData.commonPath}/${imageData.subPath}/${imageData.type}/${imageData.theme}/${imageData.name}.${imageData.format}`
        return (
          <div key={imageData.id} style={styles.imageUrlContainer}>
            <p style={styles.imageUrl}>{url}</p>
            <div style={styles.logoContainer}>
              <img
                src={url}
                alt="Logo currently unavailable at this URL"
                style={{width: '16rem'}}
              />
            </div>
            <hr/>
          </div>
        )
      });
      renderUrls = (
        <div>
          <p style={{fontSize:'16px'}}>Image URLs:</p>
          {urls}
        </div>
      );
    } else {renderUrls = null}

    return (
      <div ref={ref => this.el = ref} className='channel-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Channel Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closeEditChannelPane()
          }}>
          <Form onSubmit={(e) => {this.handleSubmit(e)}}>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="name" id="name" placeholder="Name" onChange={(e) => this.handleTranslationChange('name', e.target.value)} defaultValue={name ? name.value : ''}/>
                <Label for="name">Name</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="description" id="description" placeholder="Description" onChange={(e) => this.handleTranslationChange('description', e.target.value)} defaultValue={description ? description.value : ''}/>
                <Label for="description">Description</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleIdentifierChange(e.target.value)} value={identifier}/>
                <Label for="identifier">Identifier</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <Input type="select" name="classificationId" id="classificationId" value={classificationId} onChange={(e) => this.handleChange('classificationId', e.target.value)}>
                  {this.renderOptions(allClassifications)}
                </Input>
                <Label for="classificationId" className='select-label'>Classification Id</Label>
              </div>
            </FormGroup>

            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="callSign" id="callSign" placeholder="Call Sign" onChange={(e) => this.handleChange('callSign', e.target.value)} value={callSign}/>
                <Label for="callSign">Call Sign</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="imageName" id="imageName" placeholder="Image Name" onChange={(e) => this.handleChange('imageName', e.target.value)} value={imageName}/>
                <Label for="imageName">Image Name</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="priority" id="priority" placeholder="Priority" onChange={(e) => this.handleChange('priority', e.target.value)} value={priority}/>
                <Label for="priority">Priority</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="cmsExternalId" id="cmsExternalId" placeholder="CMS External" onChange={(e) => this.handleChange('cmsExternalId', e.target.value)} value={cmsExternalId}/>
                <Label for="cmsExternalId">CMS External</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="title" id="title" placeholder="Title" onChange={(e) => this.handleChange('title', e.target.value)} value={title}/>
                <Label for="title">Title</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="businessName" id="businessName" placeholder="Business Name" onChange={(e) => this.handleChange('businessName', e.target.value)} value={businessName}/>
                <Label for="businessName">Business Name</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12 form-checkbox">
              <Input type="checkbox" name="isFree" id="isFree" checked={isFree} onChange={(e) => this.handleChange('isFree', !isFree)} />{' '}
              <Label check className="text-center">
                <Label for="isFree">Free</Label>
              </Label>
            </FormGroup>
            <FormGroup className="col-sm-12 form-checkbox">
              <Input type="checkbox" name="active" id="active" checked={active} onChange={(e) => this.handleChange('active', !active)} />{' '}
              <Label check className="text-center">
                <Label for="active">Active</Label>
              </Label>
            </FormGroup>
            {renderUrls}
            <FormGroup className="col-sm-12">
              <button className="btn btn-primary"
                style={styles.submitButton}>
                Submit
              </button>
            </FormGroup>
          </Form>
          <br />
        </SlidingPane>
      </div>);
  }

}


const mapStateToProps = ({channelsData, DefaultDataCalls, FlashMessageData}) => {
  return {
    name: channelsData.name || {},
    description: channelsData.description || {},
    id:  channelsData.id,
    identifier: channelsData.identifier,
    classificationId: channelsData.classificationId,
    callSign: channelsData.callSign,
    imageName: channelsData.imageName,
    imageUrls: channelsData.imageUrls,
    priority: channelsData.priority,
    cmsExternalId: channelsData.cmsExternalId,
    title: channelsData.title,
    businessName: channelsData.businessName,
    isFree: channelsData.isFree,
    active: channelsData.active,
    loadingAChannel: channelsData.loadingAChannel,
    editChannel: channelsData.editChannel,
    allClassifications: DefaultDataCalls.defaultClassifications,
    statusCode: FlashMessageData.statusCode,
  };
}

export {ChannelForm};

export default connect(mapStateToProps, {
  fetchAChannel,
  createAChannel,
  updateAChannel
})(ChannelForm);
