import Cookie from 'js-cookie';
import {
  FETCH_PLANS,
  FETCH_A_PLAN,
  LOADING_PLAN_FORM,
  SET_NOTIFICATION_PANEL,
  EDIT_PLAN
} from '../ActionType';
import _ from 'lodash';
import {fetchDefaultPlans} from './DefaultDataCalls';
import {signOut} from '../helper';

export const fetchAllPlans = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}plans`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let plans = json;
      plans = _.sortBy(plans, 'priority');
      dispatch({
        type: FETCH_PLANS,
        payload: {plans}
      });
    })
    .catch(error => {
        console.log('fetchAllPlans Error - ', error);
    })
  }
}

export const fetchAPlan = (id = null) => {
  let typeOfCall;
  if (id) {
    typeOfCall = 'planEdit'
  } else {
    typeOfCall = 'planCreate'
  }
  return (dispatch) => {
    if (typeOfCall === 'planEdit') {
      let editPlan = true;
      dispatch({
        type: EDIT_PLAN,
        payload: {editPlan}
      })
    }
    dispatch({type: LOADING_PLAN_FORM})
    dispatch(fetchDefaultPlans(id, typeOfCall))
  }
}

export const fetchAPlanAfter = (id) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}plan/${id}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        identifier, plan_code: planCode, trial_days: trialDays, additional_add_ons_amount: additionalAddOnsAmount,
        amount, base_linear_add_ons_amount: baseLinearAddOnsAmount, base_linear_multiplier: baseLinearMultiplier,
        base_linear_intro_months: baseLinearIntroMonths, billing_cycle_length_in_months: billingCycleLengthInMonths,
        concurrency_multiplier: concurrencyMultiplier, downgradable,
        extra_linear_add_ons_amount: extraLinearAddOnsAmount, extras_multiplier: extrasMultiplier,
        plan_interval_length: planIntervalLength , plan_interval_unit: planIntervalUnit, total_billing_cycles: totalBillingCycles,
        upsell, future_plan_id: futurePlanId, restart_plan_id: restartPlanId, web_upsell: webUpsell, bundle_offer: bundleOffer,
        certificate_required: certificateRequired, additional_days: additionalDays, priority, plan_type: planType, apps_supported: appsSupported,
        non_refundable: nonRefundable, immutable
      } = json.data.plans;
      let name = json.data.translations.find(tr => tr.key === `model.plan.${identifier}.name`) ? json.data.translations.find(tr => tr.key === `model.plan.${identifier}.name`) : {};
      dispatch({
        type: FETCH_A_PLAN,
        payload: {
          identifier, planCode, trialDays, additionalAddOnsAmount, amount,
          baseLinearAddOnsAmount, baseLinearMultiplier, baseLinearIntroMonths,
          billingCycleLengthInMonths, concurrencyMultiplier, downgradable,
          extraLinearAddOnsAmount, extrasMultiplier,
          planIntervalLength, planIntervalUnit, totalBillingCycles, upsell,
          futurePlanId, restartPlanId, webUpsell, bundleOffer,
          certificateRequired, additionalDays, priority, planType, appsSupported,
          nonRefundable, immutable, name
        }
      })
    })
  }
}

export const createAPlan = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}plan`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllPlans())
    })
    .catch(error => {
      console.log('createAPlan Error - ', error)
    });
  }
}

export const updateAPlan = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}plan`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllPlans())
    })
    .catch(error => {
      console.log('updateAPlan Error - ', error)
    });
  }
}
