import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createATranslation, fetchTranslationWithKey } from '../../../../scripts/redux/actions'
import Modal from 'react-modal';
import Select from "react-select";
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './TranslationForm.css';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import { Form, FormGroup, Label } from 'reactstrap';

export class TranslationsMgrForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: [{ "devValue": '', "qaValue": '', "betaValue": '', "prodValue": '' }],
      translationsMgrDirtyFlag: false,
      duplicateKey: false,
      devDuplicateKey: false,
      qaDuplicateKey: false,
      betaDuplicateKey: false,
      prodDuplicateKey: false,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isPaneFullWidth: false,
      locale: '',
      key: this.props.tkey,
      application: '',
      showNotification: '',
      statusCode: '',
      statusMessage: '',
      devValue: '',
      devId: '',
      devDirtyFlag: false,
      qaValue: '',
      qaId: '',
      qaDirtyFlag: false,
      betaValue: '',
      betaId: '',
      betaDirtyFlag: false,
      prodValue: '',
      prodId: '',
      prodDirtyFlag: false,
      destinationEnvs: [],
      baseEnv: '',
      devUpdatedAt: '',
      qaUpdatedAt: '',
      betaUpdatedAt: '',
      prodUpdatedAt: '',
      env: '',
      baseEnvFilterOptions: [],
      setUpdatedDataTranslations: [],
      updatedResponseFlag: false,
      filterOptions: [
        { value: "dev", label: "Dev" },
        { value: "qa", label: "Qa" },
        { value: "beta", label: "Beta" },
        { value: "prod", label: "Prod" }
      ]
    }
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleBaseEnvChange = this.handleBaseEnvChange.bind(this);
    this.togglePaneWidth = this.togglePaneWidth.bind(this);
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
  }

  getEnvironment() {
    const URL = window.location.hostname;
    let env = "";
    switch (URL) {
      case "admin.d.sling.com":
        env = "development";
        break;
      case "admin.q.sling.com":
        env = "qa";
        break;
      case "admin.b.sling.com":
        env = "beta";
        break;
      case "admin.p.sling.com":
        env = "production";
        break;
      default: env = "development"
    }
    return env;
  }

  handleMultiChange(option) {
    this.setState(state => {
      return {
        destinationEnvs: option
      };
    });
  }
  handleChange(key, value) {
    // console.log(`Key - ${key}, val - ${value}`);
    this.setState({ [key]: value })

    if (key === "devValue" || key === "qaValue" || key === "betaValue" || key === "prodValue") {
      const initialState = this.state.initialValues[0];
      switch(key){
        case "devValue":
          if (value !== initialState.devValue){
            this.setState({ devDirtyFlag: true });
          } 
          else {
            this.setState({ devDirtyFlag: false })
          } 
          break;
        case "qaValue":
          if (value !== initialState.qaValue) {
          this.setState({ qaDirtyFlag: true })
          }
          else {
            this.setState({ qaDirtyFlag: false })
          } 
          break;
        case "betaValue":
          if (value !== initialState.betaValue) {
            this.setState({ betaDirtyFlag: true })
          } 
          else {
            this.setState({ betaDirtyFlag: false })
          }
          break;
        case "prodValue":
          if (key === 'prodValue' && value !== initialState.prodValue) {
            this.setState({ prodDirtyFlag: true })
          }
          else {
            this.setState({ prodDirtyFlag: false })
          }
          break;
        default: 
          console.log('No Value is being updated') 
          break;
      }
    }
  }
  handleBaseEnvChange(option) {
    this.setState(state => {
      return {
        baseEnv: option !== null ? option.value : ''
      };
    });
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    let params = {};
    const { key, application, locale } = this.state;
    params.key = key;
    params.application = application;
    params.locale = locale;
    if(this.state.devId === ""){
      console.log('dev is empty')
      params.env = "dev";
      this.props.fetchTranslationWithKey(params)
    }
    if(this.state.qaId === ""){
      params.env = "qa";
      this.props.fetchTranslationWithKey(params)
      console.log('qa is empty')
    }
    if(this.state.betaId === ""){
      params.env = "beta";
      this.props.fetchTranslationWithKey(params)
      console.log('beta is empty')
    }
    if(this.state.prodId === ""){
      params.env = "prod";
      this.props.fetchTranslationWithKey(params)
      console.log('prod is empty')
    }
  }

  componentWillMount() {
    Modal.setAppElement('body');
    const env = this.getEnvironment();
    this.setState({
      isPaneOpen: true,
      env
    });
    if (env !== 'production') {
      let filterOpts = this.state.filterOptions;
      filterOpts = filterOpts.filter(o => o.value !== 'prod');
      this.setState({ filterOptions: filterOpts });
    }
    if (this.props.devValue || this.props.qaValue || this.props.betaValue || this.props.prodValue) {
      this.setState({
        devValue: this.props.devValue.value,
        devId: this.props.devValue.id,
        qaValue: this.props.qaValue.value,
        qaId: this.props.qaValue.id,
        betaValue: this.props.betaValue.value,
        betaId: this.props.betaValue.id,
        prodValue: this.props.prodValue.value,
        prodId: this.props.prodValue.id,
        locale: this.props.locale || "en",
        application: this.props.application || "catalog",
        devUpdatedAt: this.props.devValue.updated_at,
        qaUpdatedAt: this.props.qaValue.updated_at,
        betaUpdatedAt: this.props.betaValue.updated_at,
        prodUpdatedAt: this.props.prodValue.updated_at,
        initialValues: [{
          devValue: this.props.devValue.value,
          qaValue: this.props.qaValue.value,
          betaValue: this.props.betaValue.value,
          prodValue: this.props.prodValue.value
        }]
      });
      // this.setState({initialValues:[{"devValue": this.props.devValue.value,"qaValue": this.props.qaValue.value, "betaValue": this.props.betaValue.value,"prodValue": this.props.prodValue.value}]});
    }
    if (this.props.tkey) {
      this.setState({ key: this.props.tkey })
    }
    if (this.props.baseEnv) {
      this.setState({ baseEnv: this.props.baseEnv });
    }
    const baseFilters = [];
    if (this.props.downEnvs.length > 0) {
      if (!this.props.downEnvs.includes("dev")) {
        baseFilters.push({ value: "dev", label: "Dev" });
      }
      if (!this.props.downEnvs.includes("qa")) {
        baseFilters.push({ value: "qa", label: "Qa" });
      }
      if (!this.props.downEnvs.includes("beta")) {
        baseFilters.push({ value: "beta", label: "Beta" });
      }
      if (!this.props.downEnvs.includes("prod")) {
        baseFilters.push({ value: "prod", label: "Prod" });
      }
      this.setState({ baseEnvFilterOptions: baseFilters });
    } else {
      this.setState({ baseEnvFilterOptions: this.state.filterOptions });
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.setUpdatedDataTranslations,'nextProps.setUpdatedDataTranslations')
    if(nextProps.setUpdatedDataTranslations.length){
      nextProps.setUpdatedDataTranslations.forEach(updateTranslation => {
        if (updateTranslation.env === "dev") {
          let newinitialValues = this.state.initialValues;
          if(!updateTranslation.shouldUpdate){
            this.setState({ devId: updateTranslation.id });  
            this.setState({ devDirtyFlag: false});
            this.setState({ duplicateKey: true});
            this.setState({devDuplicateKey: true})
            let { destinationEnvs }= this.state;
            destinationEnvs.push( { value: "dev", label: "Dev" });
            this.setState({destinationEnvs});
          } else {
          newinitialValues[0].devValue = updateTranslation.value
          this.setState({ devValue: updateTranslation.value });
          this.setState({ devId: updateTranslation.id });
          this.setState({ devUpdatedAt: updateTranslation.updated_at });
          this.setState({ initialValues: newinitialValues });
          this.setState({ devDirtyFlag: false});
          this.setState({devDuplicateKey: false})
          }
        }
        if (updateTranslation.env === "qa") {
          let newinitialValues = this.state.initialValues;
          if(!updateTranslation.shouldUpdate){
            let { destinationEnvs }= this.state;
            this.setState({ qaId: updateTranslation.id });  
            this.setState({ qaDirtyFlag: false});
            this.setState({ duplicateKey: true});
            this.setState({qaDuplicateKey: true})
            destinationEnvs.push( { value: "qa", label: "Qa" });
            this.setState({destinationEnvs});
          } else {
          newinitialValues[0].qaValue = updateTranslation.value
          this.setState({ qaValue: updateTranslation.value });
          this.setState({ qaId: updateTranslation.id });
          this.setState({ qaUpdatedAt: updateTranslation.updated_at });
          this.setState({ initialValues: newinitialValues });
          this.setState({ qaDirtyFlag: false});
          this.setState({qaDuplicateKey: false})
          }
        }
        if (updateTranslation.env === "beta") {
          let newinitialValues = this.state.initialValues;
          if(!updateTranslation.shouldUpdate){
            let { destinationEnvs }= this.state;
            this.setState({ betaId: updateTranslation.id });  
            this.setState({ betaDirtyFlag: false});
            this.setState({ duplicateKey: true});
            this.setState({betaDuplicateKey: true})
            destinationEnvs.push( { value: "beta", label: "Beta" });
            this.setState({destinationEnvs});
          } else {
            newinitialValues[0].betaValue = updateTranslation.value
            this.setState({ betaValue: updateTranslation.value});
            this.setState({ betaId: updateTranslation.id });
            this.setState({ betaUpdatedAt: updateTranslation.updated_at });
            this.setState({initialValues: newinitialValues });
            this.setState({ betaDirtyFlag: false});
            this.setState({betaDuplicateKey: false})
          }
        }
        if (updateTranslation.env === "prod") {
          let newinitialValues = this.state.initialValues;
          if(!updateTranslation.shouldUpdate){
            this.setState({ prodId: updateTranslation.id });  
            this.setState({ prodDirtyFlag: false});
            this.setState({ duplicateKey: true});
            this.setState({prodDuplicateKey: true});
            const env = this.getEnvironment();
            if (env === 'production') {
              let { destinationEnvs }= this.state;
              destinationEnvs.push( { value: "prod", label: "Prod" });
              this.setState({destinationEnvs});
            }
          } else {
            newinitialValues[0].prodValue = updateTranslation.value
            this.setState({ prodValue: updateTranslation.value});
            this.setState({prodId: updateTranslation.id});
            this.setState({prodUpdatedAt: updateTranslation.updated_at });
            this.setState({ initialValues: newinitialValues });
            this.setState({ prodDirtyFlag: false});
            this.setState({prodDuplicateKey: false})
          }
        }
      });
      this.setState({ updatedResponseFlag: true});
    }
  }
  closePane() {
    const initialState = this.state.initialValues[0];
    const { application, locale, key, devValue, qaValue, betaValue, prodValue, devId, qaId, betaId, prodId, devUpdatedAt, qaUpdatedAt, betaUpdatedAt, prodUpdatedAt, devDirtyFlag, qaDirtyFlag, betaDirtyFlag, prodDirtyFlag, translationsMgrDirtyFlag, duplicateKey } = this.state
    let params = {
      application,
      locale,
      key,
      devValue: !devDirtyFlag ? devValue : initialState.devValue,
      qaValue: !qaDirtyFlag ? qaValue : initialState.qaValue,
      betaValue: !betaDirtyFlag ? betaValue : initialState.betaValue,
      prodValue: !prodDirtyFlag ? prodValue : initialState.prodValue,
      devId,
      qaId,
      betaId,
      prodId,
      devUpdatedAt,
      qaUpdatedAt,
      betaUpdatedAt,
      prodUpdatedAt,
      duplicateKey,
      devDirtyFlag,
      qaDirtyFlag,
      betaDirtyFlag,
      prodDirtyFlag
    };
    this.props.closeTranslationFormPane(translationsMgrDirtyFlag, params)
  }

  togglePaneWidth() {
    const currentPanelFullWidthState = this.state.isPaneFullWidth;
    this.setState({ isPaneFullWidth: !currentPanelFullWidthState })
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => { this.setState({ showNotification: false }) }, 3000);
  }

  handleSave(saveToEnv, e) {
    if (e) {
      e.preventDefault();
    }

    const _destinationEnvs = [{ value: `${saveToEnv}` }];
    const { application, locale, key, devValue, qaValue, betaValue, prodValue, devId, qaId, betaId, prodId } = this.state

    let params = {
      application,
      locale,
      key,
      devValue,
      qaValue,
      betaValue,
      prodValue,
      baseEnv: saveToEnv,
      destinationEnvs: _destinationEnvs,
      devId,
      qaId,
      betaId,
      prodId
    };
    this.props.createATranslation(params);
    this.setState({ translationsMgrDirtyFlag: true })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const { application, locale, key, devValue, qaValue, betaValue, prodValue, baseEnv, destinationEnvs, devId, qaId, betaId, prodId } = this.state
    let params = {
      application,
      locale,
      key,
      devValue,
      qaValue,
      betaValue,
      prodValue,
      baseEnv,
      destinationEnvs,
      devId,
      qaId,
      betaId,
      prodId
    };
    this.props.createATranslation(params);
    this.setState({ devDirtyFlag: false, qaDirtyFlag: false, betaDirtyFlag: false, prodDirtyFlag: false, translationsMgrDirtyFlag: true });
  }

  render() {
    const { locale, key, application, id, devValue, qaValue, betaValue, prodValue, isPaneOpen, isPaneFullWidth, baseEnv, destinationEnvs, env, devDirtyFlag, qaDirtyFlag, betaDirtyFlag, prodDirtyFlag, devUpdatedAt, qaUpdatedAt, betaUpdatedAt, prodUpdatedAt, devDuplicateKey, qaDuplicateKey, betaDuplicateKey, prodDuplicateKey } = this.state;
    const applications = [
      'catalog',
      'ottweb'
    ];
    const locales = [
      'en',
      'es',
      'pt-BR',
      'hi',
      'pa',
      'gu'
    ];

    return (
      <div ref={ref => this.el = ref} className='translation-form'>
        <FlashMessage />
        <SlidingPane
          className={isPaneFullWidth ? 'slinding-pane-full' : 'slinding-pane'}
          overlayClassName='some-custom-overlay-class'
          isOpen={isPaneOpen}
          title='Translation Form'
          width='600px'
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.closePane();
            this.setState({ isPaneOpen: false });
          }}>
          <Form onSubmit={(e) => { this.handleSubmit(e) }}>

            <i onClick={() => this.togglePaneWidth()} className="expand-widget fa fa-2x fa-expand" aria-hidden="true"></i>
            <FormGroup className="col-sm-12">
              <div className="field">
                <select
                  key={id}
                  style={{ width: "20%", height: "20px" }}
                  defaultValue={application}
                  disabled={true}
                  onChange={(e) => this.handleChange('application', e.target.value)}
                >
                  {applications.map(application =>
                    <option key={application} value={application}>{application}</option>
                  )};
                </select>
                <Label for="application">App</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <select
                  disabled={true}
                  key={id}
                  style={{ width: "20%", height: "20px" }}
                  defaultValue={locale}
                  onChange={(e) => this.handleChange('locale', e.target.value)}
                >
                  {locales.map(locale =>
                    <option key={locale} value={locale}>{locale}</option>
                  )};
                </select>
                <Label for="locale">Locale *</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="key" id="key" placeholder="Key" onChange={(e) => this.handleChange('key', e.target.value)} value={key} required disabled/>
                <Label for="key">Key *</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <textarea className="translationValue" name="devValue" id="devValue" placeholder="Value" onChange={(e) => this.handleChange('devValue', e.target.value)} value={devValue} />
                <Label for="devValue">Dev-Value {devUpdatedAt ? `| updated_at - `:''} <span className="updated-at">{devUpdatedAt}</span> <span className="blink">{devDuplicateKey ? `Duplicate Key`:''}</span> {devDirtyFlag ? <button type="button" className="btn btn-primary saveTranslationsBtn" onClick={(e) => this.handleSave("dev")}>Save</button> : ''}</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <textarea className="translationValue" name="qaValue" id="qaValue" placeholder="Value" onChange={(e) => this.handleChange('qaValue', e.target.value)} value={qaValue} />
                <Label for="qaValue">Qa-Value {qaUpdatedAt ? `| updated_at - `:''}<span className="updated-at">{qaUpdatedAt}</span><span className="blink">{qaDuplicateKey ? ` Duplicate Key`:''}</span> {qaDirtyFlag ? <button type="button" className="btn btn-primary saveTranslationsBtn" onClick={(e) => this.handleSave("qa")}>Save</button> : ''}</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <textarea className="translationValue" name="betaValue" id="betaValue" placeholder="Value" onChange={(e) => this.handleChange('betaValue', e.target.value)} value={betaValue} />
                <Label for="betaValue">Beta-Value {betaUpdatedAt ? `| updated_at - `:''}<span className="updated-at">{betaUpdatedAt}</span><span className="blink"> {betaDuplicateKey ? ` Duplicate Key`:''} </span>{betaDirtyFlag ? <button type="button" className="btn btn-primary saveTranslationsBtn" onClick={(e) => this.handleSave("beta")}>Save</button> : ''}</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <textarea className="translationValue" name="prodValue" id="prodValue" placeholder="Value" onChange={(e) => this.handleChange('prodValue', e.target.value)} value={prodValue} disabled={env === 'production' ? false : true} />
                <Label for="prodValue">Prod-Value {prodUpdatedAt ? `| updated_at - `:''}<span className="updated-at">{prodUpdatedAt}</span><span className="blink">{prodDuplicateKey ? ` Duplicate Key`:''}</span> {prodDirtyFlag ? <button type="button" className="btn btn-primary saveTranslationsBtn" onClick={(e) => this.handleSave("prod")}>Save</button> : ''}</Label>
              </div>
            </FormGroup>
            <hr />
            <h4>Copy</h4>
            <FormGroup className="col-sm-12">
              <div className="field">
                <Select
                  name="baseEnv"
                  onChange={this.handleBaseEnvChange}
                  style={{ width: "100%" }}
                  defaultValue={baseEnv}
                  value={baseEnv}
                  options={this.state.baseEnvFilterOptions}
                  multi={false}
                >
                  {/* {this.props.downEnvs.includes("qa") ? "" : <option value="qa">Qa</option>}
                    {this.props.downEnvs.includes("dev") ? "" :<option value="dev">Dev</option>}
                    {this.props.downEnvs.includes("beta") ? "" :<option value="beta">Beta</option>}
                    {this.props.downEnvs.includes("prod") ? "" :<option value="prod">Prod</option>} */}
                </Select>
                <span>FROM</span>
              </div>
              <div className="field">
                <Select
                  name="Environment"
                  placeholder="Environment"
                  value={destinationEnvs}
                  options={this.state.filterOptions}
                  onChange={this.handleMultiChange}
                  multi
                  required={true}
                />
                <span>TO</span>
              </div>
            </FormGroup>

            <FormGroup className="col-sm-12">
              <button className="btn btn-primary"
                style={{ height: '35px', fontSize: '14px', width: '25%', marginTop: '10px' }}
                disabled={baseEnv && destinationEnvs.length > 0 ? false : true}>
                Copy
              </button>
            </FormGroup>
          </Form>
          <br />
        </SlidingPane>
      </div>);
  }

}


const mapStateToProps = ({ FlashMessageData, translationsData }) => {
  return {
    setUpdatedDataTranslations: translationsData.setTranslations,
    showNotificationPanel: FlashMessageData,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  createATranslation,
  fetchTranslationWithKey
})(TranslationsMgrForm);
