import {
  FETCH_LOB_PLANS,
  FETCH_AN_LOB_PLAN,
  FETCH_PARTNER_PLATFORMS,
  RESET_LOB_PLAN_FORM,
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
    lobPlans: [],
    displayingLobPlans: [],
    loadingALobPlan: true,
    partnerPlatformId: null,
    partnerPlatform: '',
    lineOfBusinessId: null,
    lineOfBusiness: '',
    planId: null,
    plan: '',
    enabled: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LOB_PLANS:
      let {lobPlans} = action.payload
      return {...state, lobPlans, displayingLobPlans: lobPlans, loading: false};
    case FETCH_AN_LOB_PLAN:
      let {partnerPlatformId, partnerPlatform, lineOfBusinessId, lineOfBusiness, planId, enabled} = action.payload
      return {
        ...state, partnerPlatformId, partnerPlatform, lineOfBusinessId, lineOfBusiness, planId, enabled, loadingALobPlan: false
      }
    case FETCH_PARTNER_PLATFORMS:
      let {partnerPlatforms} = action.payload
      return {
        ...state,
        partnerPlatforms,
        loadingALobPlan: true
      }
    case RESET_LOB_PLAN_FORM:
      let emptyLobPlanForm = _.omit(INITIAL_STATE, ['lobPlans', 'displayingLobPlans', 'loadingALobPlan']);
      return {
        ...state,
        ...emptyLobPlanForm,
        loadingALobPlan: false
      };
      
    default:
    return state;
  }
}