import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchAllLOBs, toggleLinesOfBusiness} from '../../../../scripts/redux/actions'
import LineOfBusinessForm from './LineOfBusinessForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import { Card, CardFooter, CardBody, Row } from 'reactstrap';
import '../Classification/Classification.css';
import LineOfBusinessTabs from './LineOfBusinessTabs';

class LineOfBusiness extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lobs: [],
      displayingLobs: [],
      showLobForm: false,
      openedLob: null,
      loading: true,
      displayCardClass: '',
      showLobTabs: false,
    }
    this.toggleLobForm = this.toggleLobForm.bind(this)
    this.toggleLinesOfBusiness = this.toggleLinesOfBusiness.bind(this)
  }

  setLobTabsSectionClass() {
    let packageTabsSectionClass = 'package-tabs-section';
    let siCardBodyMd = '';
    let siCardMd = '';
    let tileSelected = 'tgc-tile-selected';
    if (window.innerWidth <= 1422) {
      packageTabsSectionClass = 'package-tabs-section'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      packageTabsSectionClass = 'package-tabs-section'
      siCardBodyMd = 'si-card-body-md'
      siCardMd = 'si-card-md'
      tileSelected = 'tgc-tile-selected tile-selected-md'
    } else if (window.innerWidth > 1440){
      packageTabsSectionClass = 'package-tabs-section package-tabs-section-lg'
    }
    this.setState({packageTabsSectionClass, siCardBodyMd, siCardMd, tileSelected})
  }

  toggleLinesOfBusiness(value) {
    let params = {
      lobId: value.id,
      value: !value.enabled ? 1 : 0
    }
    this.props.toggleLinesOfBusiness(params);
  }
  componentWillMount() {
    this.setLobTabsSectionClass()
  }

  componentDidMount() {
    this.props.fetchAllLOBs()
  }

  componentWillReceiveProps(nextProps) {
    const {lobs, displayingLobs, loading} = nextProps
    this.setState({lobs, displayingLobs, loading})
  }

  toggleLobForm(id=null, getLobs=false) {
    if (id) {
      this.setState({ showLobForm: true, openedLob: id });
    } else {
      this.setState({ showLobForm: false})
    }
    if (getLobs) {
      this.props.fetchAllLOBs()
    }
  }

  displayLobTabs(openedLob, selectedDiv) {
    this.setState({showLobTabs: true, openedLob, selectedDiv})
  }

  closeLobTabs() {
    this.setState({showLobTabs: false, selectedDiv: false})
  }

  search(val) {
    const {lobs} = this.state
    let displayingLobs = lobs.filter(c => (c.name.toLowerCase().search(val.toLowerCase()) !== -1) || c.identifier.toLowerCase().search(val.toLowerCase()) !== -1)
    this.setState({displayingLobs})
  }

  render() {
    const { openedLob, displayingLobs, loading,
            showLobForm, selectedDiv,
            tileSelected, siCardMd, siCardBodyMd, showLobTabs,
            packageTabsSectionClass,
          } = this.state

    const Item = ({value, name}) => {
      return (
        <div className='package-sortable-item'>
          <div className={`${name === selectedDiv ? tileSelected : ""}`}>
            <Card className={`margin-bottom-5 ${siCardMd}`}>
              <CardBody className={`si-card-body ${siCardBodyMd}`} onClick={() => this.displayLobTabs(value.id, name)}>
                <span className="text-left font-size-18">{value.name || value.identifier}</span>
              </CardBody>
              <CardFooter className={`height-48 card-footer-color ${name === selectedDiv ? tileSelected : ""}`}>
                <div className="text-center si-card-footer">
                  {<i className="fa fa-pencil" onClick={() => this.toggleLobForm && this.toggleLobForm(value.identifier)}></i>}
                  { ((value.enabled || value.active) ?
                    <i className="fa fa-toggle-on margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.toggleLinesOfBusiness && this.toggleLinesOfBusiness(value) } } /> :
                    <i className="fa fa-toggle-off margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.toggleLinesOfBusiness && this.toggleLinesOfBusiness(value) } } />)
                  }
                </div>
              </CardFooter>
            </Card>
          </div>
          { showLobTabs && (name === selectedDiv) ?
          <div className={`${packageTabsSectionClass} ${name === selectedDiv ? "block" : ""}`}>
            <div onClick={() => this.closeLobTabs()} className="wrapper">
              <a href className="close-button">
                <div className="in">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
                <div className="out">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
              </a>
            </div>
            <div><LineOfBusinessTabs showNotificationPanel={this.showNotificationPanel}
                         lobId={openedLob} openedLob={openedLob}/></div>
          </div> : null }
        </div>
      )
    };

    const List = ({items}) => {
      return (
        <Row>
          {
            items.map((value, index) => (
              <Item key={index} index={index} value={value} name={index}/>
            ))
          }
        </Row>
      );
    };

    return (
      <div>
        <FlashMessage />
        <div className='classification'>
          <div className='classification-header'>
            <div className="search-section classification-search">
              <div className='aaa'>
                <input onKeyUp={(e)=>this.search(e.target.value)}
                       type="search" placeholder="What are you looking for?" />
              </div>
            </div>
            <div className='new-classification-div'>
              <button className="btn btn-primary new-classification-btn"
                      onClick={()=> this.setState({
                                                    showLobForm: true,
                                                    openedLob: null})}>
              <i className="fa fa-plus"></i> New Line of Business
              </button>
            </div>
          </div>
          <div className='new-classification-content'>
            { loading && <Loading /> }
            {
              (!loading && displayingLobs.length === 0) ? <NoData></NoData> :
              <List items={displayingLobs} />
            }
            {
              showLobForm ?
              <LineOfBusinessForm lobId={openedLob}
                                  closeEditLobPane={this.toggleLobForm}/>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({LineOfBusinessData}) => {
  return {
    lobs: LineOfBusinessData.lobs,
    displayingLobs: LineOfBusinessData.displayingLobs,
    loading: LineOfBusinessData.loading,
  };
}

export {LineOfBusiness};

export default connect(mapStateToProps, {
  fetchAllLOBs,
  toggleLinesOfBusiness,
})(LineOfBusiness);
