import React, { Component } from 'react';
import "react-table/react-table.css";
import _ from 'lodash';
import {TabContent, TabPane, Card, CardFooter, CardBody, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import classnames from 'classnames';
import MorePane from '../MorePane/MorePane.js'
import PackagePlanForm from '../PackagePlanForm/PackagePlanForm.js'
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import {PACKAGE_TABS_BASE_NAV_ITEMS, PACKAGE_TABS_EXTRA_NAV_ITEMS, PACKAGE_TABS_RSN_NAV_ITEMS, PACKAGE_TYPE_BASE, PACKAGE_TYPE_EXTRA, PACKAGE_TYPE_RSN} from '../Constants.js';
import './PackageTabs.css';
import Cookie from 'js-cookie';

class PackageTabs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      baseLinearPacks: [],
      activeTab: 'channels',
      openedPackage: this.props.openedPackage,
      packageType: this.props.packageType,
      channels: [],
      displayingChannels: [],
      classifications: [],
      displayingClassifications: [],
      plans: [],
      displayingPlans: [],
      packages: [],
      displayingPackages: [],
      morePaneSection: '',
      existingObjects: [],
      loading: true,
      showNotification: '',
      statusCode: '',
      statusMessage: '',
      showPackagePlanForm: false,
      openedPlan: '',
      displayCardClass: '',
      currentClassification: this.props.currentClassification,
    }
    this.closeMorePane = this.closeMorePane.bind(this)
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
    this.togglePackagePlanForm = this.togglePackagePlanForm.bind(this)
  }

  setDisplayCardClass() {
    let displayCardClass = 'col-md-3';
    if (window.innerWidth <= 1422) {
      displayCardClass = 'col-md-3'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      displayCardClass = 'col-md-3'
    } else if (window.innerWidth > 1440){
      displayCardClass = 'col-lg-2'
    }
    this.setState({displayCardClass})
  }

  closeMorePane(updated = false) {
    this.setState({ morePaneSection: '' });
    if (updated) {
      this.toggleTabs(this.state.activeTab)
    }
  }

  // ToDo - Move to common method
  getChannels() {
    if (this.props.openedPackage) {
      this.setState({
        loading:true,
        activeTab: 'channels',
      })
      const URL = `${process.env.REACT_APP_PC_URL}package-channels/${this.props.openedPackage}`
      fetch(URL, {method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        json = _.sortBy(json, 'channelPriority');
        this.setState({
          loading: false,
          channels: json,
          displayingChannels: json,
        });
      })
      .catch(error => {
          console.log('PackageTabs Error 1 - ', error);
      })
    }
  }

  getClassifications() {
    if (this.props.openedPackage) {
      this.setState({
        loading: true,
        activeTab: 'classifications',
      })
      const URL = `${process.env.REACT_APP_PC_URL}package-classifications/${this.props.openedPackage}`
      fetch(URL, {method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token'),
        })
      })
      .then(response => response.json())
      .then(json => {
        this.setState({
          loading: false,
          classifications: json,
          displayingClassifications: json,
        });
      })
      .catch(error => {
          console.log('PackageTabs Error 2 - ', error);
      })
    }
  }

  getPlans() {
    if (this.props.openedPackage) {
      this.setState({
        loading: true,
        activeTab: 'plans',
      })
      const URL = `${process.env.REACT_APP_PC_URL}package-plans/${this.props.openedPackage}`
      fetch(URL, {method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token'),
        })
      })
      .then(response => response.json())
      .then(json => {
        this.setState({
          loading: false,
          plans: json,
          displayingPlans: json,
        });
      })
      .catch(error => {
          console.log('PackageTabs Error 3 - ', error);
      })
    }
  }

  getPackages() {
    if (this.props.openedPackage) {
      this.setState({
        loading: true,
        activeTab: 'packages',
      })
      const {openedPackage, packageType} = this.props
      const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}packages-by-packages/${openedPackage}/package-type/${packageType}`
      fetch(URL, {method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token'),
        })
      })
      .then(response => response.json())
      .then(json => {
        json = _.sortBy(json, 'extraPriority');
        if (!_.isObject(json[0])) {
          json = []
        }
        this.setState({
          loading: false,
          packages: json,
          displayingPackages: json,
        });
      })
      .catch(error => {
          console.log('PackageTabs Error 4 - ', error);
      })
    }
  }

  componentWillMount() {
    this.setDisplayCardClass()
    this.getChannels()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({openedPackage: nextProps.openedPackage})
    this.getChannels()
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => {this.setState({showNotification: false})}, 3000);
  }

  toggleTabs(tab) {
    switch (tab) {
      case 'plans':
      this.getPlans();
      break;
      case 'channels':
      this.getChannels();
      break;
      case 'classifications':
      this.getClassifications();
      break;
      case 'packages':
      this.getPackages();
      break;
      default:
      this.getChannels();
      break;
    }
  }

  onSortEnd = (section, {oldIndex, newIndex}) => {
    switch (section) {
      case 'plans':
        this.setState({
          displayingPlans: arrayMove(this.state.displayingPlans, oldIndex, newIndex)
        });
        break;
      case 'classifications':
        this.setState({
          displayingClassifications: arrayMove(this.state.displayingClassifications, oldIndex, newIndex)
        });
        break;
      case 'channels':
        if (oldIndex === newIndex) {
          break;
        }
        let newChannelsObj = []
        let prioritizedChannels = arrayMove(this.state.displayingChannels, oldIndex, newIndex)
        prioritizedChannels.forEach((item, index) => {
          newChannelsObj.push({
            id: item.id,
            priority: index + 1,
          })
        })
        let params = {
          packageId: this.props.openedPackage,
          channels: newChannelsObj
        }

        const URL = `${process.env.REACT_APP_PC_URL}/update-package-channel-priority`;
        fetch(URL, {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: Cookie.get('token'),
          }),
          credentials: 'same-origin',
          body: JSON.stringify(params),
        })
        .then(response => response.json())
        .then(json => {
          this.getChannels()
        })
        .catch(error => {
          console.log('Update Package Priority Error - ', error)
          // this.showNotificationPanel(true, json.status.code, json.status.message)
        });
        break;
      case 'packages':
        if (oldIndex === newIndex) {
          break;
        }
        let newPackagesObj = []
        let prioritizedPackages = arrayMove(this.state.displayingPackages, oldIndex, newIndex)
        prioritizedPackages.forEach((item, index) => {
          newPackagesObj.push({
            id: item.id,
            priority: index + 1,
          })
        })
        let packagesParams = {
          packageId: this.props.openedPackage,
          packages: newPackagesObj
        }

        let packageURL = `${process.env.REACT_APP_PC_URL}/update-package-extra-priority`;
        fetch(packageURL, {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: Cookie.get('token'),
          }),
          credentials: 'same-origin',
          body: JSON.stringify(packagesParams),
        })
        .then(response => response.json())
        .then(json => {
          this.getPackages()
        })
        .catch(error => {
          console.log('Update Package Priority Error - ', error)
          // this.showNotificationPanel(true, json.status.code, json.status.message)
        });
        break;
      default:
        console.log('PackageTabs - onSortEnd - default');
    }
  }

  search(key, val) {
    const obj = this.state[key]
    let displayingObj = obj.filter(o => o.identifier.toLowerCase().includes(val.toLowerCase()))
    switch (key) {
      case 'channels':
        this.setState({displayingChannels: displayingObj})
        break;
      case 'plans':
        this.setState({displayingPlans: displayingObj})
        break;
      case 'classifications':
        this.setState({displayingClassifications: displayingObj})
        break;
      case 'packages':
        this.setState({displayingPackages: displayingObj})
        break;
      default:
        console.log('default case')
        break;
    }
  }

  setMorePaneSection(morePaneSection) {
    const existingObjects = this.state[morePaneSection]
    this.setState({morePaneSection, existingObjects});
  }

  togglePackagePlanForm(openedPlan=null, getPackagePlans=false) {
    if (openedPlan) {
      this.setState({ showPackagePlanForm: true, openedPlan });
    } else {
      this.setState({ showPackagePlanForm: false})
    }
    if (getPackagePlans) {
      this.toggleTabs('plans')
    }
  }

  deleteRecord(typeId, type, openPkgPackageType) {
    let params = {packageId: this.props.openedPackage}

    if (type === 'package') {
      if (openPkgPackageType === PACKAGE_TYPE_RSN) {
        params['requiredPackageId'] = typeId
      } else {
        params['extraId'] = typeId
      }
    } else {
      params[`${type}Id`] = typeId
    }

    if (type === 'package') {
      if (openPkgPackageType === PACKAGE_TYPE_RSN) {
        URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}delete-package-prereq`
      } else {
        URL = `${process.env.REACT_APP_PC_URL}delete-package-${type}`;
      }
    } else {
      URL = `${process.env.REACT_APP_PC_URL}delete-package-${type}`;
    }
    fetch(URL, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token'),
      },
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      this.showNotificationPanel(true, json.status.code, json.status.message)
      this.toggleTabs(this.state.activeTab)
    })
    .catch(error => {
        console.log('PackageTabs Error 3 - ', error);
    })
  }

  render() {
    const {
      showNotification, statusCode, statusMessage, loading,
      activeTab, existingObjects, openedPackage, morePaneSection,
      showPackagePlanForm, openedPlan, displayCardClass, packageType,
      currentClassification,
    } = this.state

    const SortableItem = SortableElement(({value}) =>
      <div className={displayCardClass} style={{padding:'0px 5px 10px', zIndex:'99'}}>
        <Card className='display-card' style={{'color': '#666666'}}>
          <CardBody style={{background:'#46464C',color:'white',height:'100px'}}>
            <span className="text-left" style={{fontSize:'18px'}}>{value.name}</span>
            <br />
            <span style={{fontSize:'14px'}}>ID - {value.identifier}</span>
          </CardBody>
          <CardFooter style={{height: '30px'}} className='tabs-card-footer'>
              <div className="text-center" style={{background:'#46464C','fontSize':'20px','color':'white', 'display': 'flex', 'alignItems': 'left', 'justifyContent': 'center'}}>
                <i className={classnames('fa', 'fa-clone', {'icon-disabled': _.includes(['channels', 'classifications'], this.state.activeTab)})}></i>
                <i onClick={() => this.togglePackagePlanForm(value.id)} className={classnames('fa', 'fa-pencil', 'margin-left-50', {'icon-disabled': !_.includes(['plans'], this.state.activeTab)})}></i>
                {/* <i onClick={() => this.deleteRecord(value.id, this.state.activeTab.slice(0, -1))} className="fa fa-trash margin-left-50"></i> */}
                <i onClick={() => { if (window.confirm('Are you sure you wish to delete this?')) this.deleteRecord(value.id, this.state.activeTab.slice(0, -1), packageType) } }
                  className='fa fa-trash margin-left-50'/>

              </div>
          </CardFooter>
        </Card>
      </div>
    );

    const SortableList = SortableContainer(({items, itemType}) => {
      let disabled = !['channels', 'packages'].includes(itemType)
      if (items.length > 0) {
        return (
          <Row style={{padding:'10px'}}>
            {
              items.map((value, index) => (
                <SortableItem disabled={disabled} key={`item-${index}`} index={index} value={value} />
              ))
            }
          </Row>
        );
      } else {
        return (<NoData />)
      }
    });

    const NavItems = ({items}) => {
      return (
        <Nav tabs>
          {
            items.map((item, index) => (
                <NavItem key={index}
                        className={classnames({ active: activeTab === item.tabId })}>
                  <NavLink onClick={() => { this.toggleTabs(item.tabId); }}>
                    {item.label}
                  </NavLink>
                </NavItem>
            ))
          }
        </Nav>
      )
    }

    // const TabContents = ({items}) => {
    //   return (
    //     <TabContent activeTab={activeTab}>
    //       {
    //         items.map((item, index) => (
    //           <TabPane key={index} tabId={item.obj}>
    //             <div>
    //               <Row style={{'paddingTop': '20px'}}>
    //                 <section className="search-section col-sm-5" style={{'paddingBottom': '15px'}}>
    //                   <form>
    //                     <input onKeyUp={(e)=>this.search(item.obj, e.target.value)} type="search" placeholder="What are you looking for?" />
    //                   </form>
    //                 </section>
    //                 <Col sm='1'>
    //                   <Button className="btn btn-warning" onClick={() => this.setMorePaneSection(item.obj)} style={{'height': '35px','fontSize': '14px'}}>Add More</Button>
    //                 </Col>
    //               </Row>
    //               <SortableList items={this.state[item.displayingObj]} itemType={item.obj} onSortEnd={this.onSortEnd.bind(this, item.obj)} axis='xy' pressDelay={200}/>
    //             </div>
    //           </TabPane>
    //         ))
    //       }
    //     </TabContent>
    //   )
    // }

    return (
      <div className='package-tabs'>

        {showNotification && <FlashMessage statusCode={statusCode} statusMessage={statusMessage} />}
        {![PACKAGE_TYPE_BASE, PACKAGE_TYPE_RSN].includes(packageType) ? <NavItems items={PACKAGE_TABS_EXTRA_NAV_ITEMS} />: ''}
        {packageType === PACKAGE_TYPE_BASE ? <NavItems items={PACKAGE_TABS_BASE_NAV_ITEMS} />:''}
        {packageType === PACKAGE_TYPE_RSN ? <NavItems items={PACKAGE_TABS_RSN_NAV_ITEMS} />:''}
            {
              loading ? <Loading color='orange'/> :
              <div>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="channels">
                    <div>
                      <Row style={{'paddingTop': '20px'}}>
                        <div className="search-section" style={{marginLeft:'15px',paddingBottom:'15px'}}>
                          <div className='aaa'>
                            <input onKeyUp={(e)=>this.search('channels', e.target.value)}
                                   type="search" placeholder="What are you looking for?" />
                          </div>
                        </div>
                        <Col sm='1'>
                          <button className="btn btn-primary"
                                  onClick={() => this.setMorePaneSection('channels')}
                                  style={{'height': '35px','fontSize': '14px'}}>
                          <i className="fa fa-plus"></i> Add More Channels
                          </button>
                        </Col>
                      </Row>
                      <SortableList items={this.state.displayingChannels} itemType='channels' onSortEnd={this.onSortEnd.bind(this, 'channels')} axis='xy' pressDelay={200}/>
                    </div>
                  </TabPane>

                  <TabPane tabId="classifications">
                    <div>
                      <Row style={{'paddingTop': '20px'}}>
                        <div className="search-section" style={{marginLeft:'15px',paddingBottom:'15px'}}>
                          <div className='aaa'>
                            <input onKeyUp={(e)=>this.search('classifications', e.target.value)}
                                   type="search" placeholder="What are you looking for?" />
                          </div>
                        </div>
                        <Col sm='1'>
                          <button className="btn btn-primary"
                                  onClick={() => this.setMorePaneSection('classifications')}
                                  style={{'height': '35px','fontSize': '14px'}}>
                          <i className="fa fa-plus"></i> Add More Classifications
                          </button>
                        </Col>
                      </Row>
                      <SortableList items={this.state.displayingClassifications} itemType='classifications' onSortEnd={this.onSortEnd.bind(this, 'classifications')} axis='xy' pressDelay={200}/>
                    </div>
                  </TabPane>

                  <TabPane tabId="plans">
                    <div>
                      <Row style={{'paddingTop': '20px'}}>
                        <div className="search-section" style={{marginLeft:'15px',paddingBottom:'15px'}}>
                          <div className='aaa'>
                            <input onKeyUp={(e)=>this.search('plans', e.target.value)}
                                   type="search" placeholder="What are you looking for?" />
                          </div>
                        </div>
                        <Col sm='1'>
                          <button className="btn btn-primary"
                                  onClick={() => this.setMorePaneSection('plans')}
                                  style={{'height': '35px','fontSize': '14px'}}>
                          <i className="fa fa-plus"></i> Add More Plans
                          </button>
                        </Col>
                      </Row>
                      <SortableList items={this.state.displayingPlans} itemType='plans' onSortEnd={this.onSortEnd.bind(this, 'plans')} axis='xy' pressDelay={200}/>
                    </div>
                  </TabPane>

                  <TabPane tabId="packages">
                    <div>
                      <Row style={{'paddingTop': '20px'}}>
                        <div className="search-section" style={{marginLeft:'15px',paddingBottom:'15px'}}>
                          <div className='aaa'>
                            <input onKeyUp={(e)=>this.search('packages', e.target.value)}
                                   type="search" placeholder="What are you looking for?" />
                          </div>
                        </div>
                        <Col sm='1'>
                          <button className="btn btn-primary"
                                  onClick={() => this.setMorePaneSection('packages')}
                                  style={{'height': '35px','fontSize': '14px'}}>
                          <i className="fa fa-plus"></i> Add More Packages
                          </button>
                        </Col>
                      </Row>
                      <SortableList items={this.state.displayingPackages} itemType='packages' onSortEnd={this.onSortEnd.bind(this, 'packages')} axis='xy' pressDelay={200}/>
                    </div>
                  </TabPane>
                </TabContent>
                {morePaneSection !== '' ? <MorePane type={activeTab.slice(0, -1)}
                                            existingObjects={existingObjects}
                                            closeMorePane={this.closeMorePane}
                                            openedPackage={openedPackage}
                                            packageType={packageType}
                                            apiPath={morePaneSection}
                                            showNotificationPanel={this.showNotificationPanel}
                                            currentClassification={currentClassification}
                                            /> : null}
                {showPackagePlanForm ? <PackagePlanForm existingObjects={existingObjects}
                                        packageId={openedPackage}
                                        showNotificationPanel={this.showNotificationPanel}
                                        closePackagePlanFormPane={this.togglePackagePlanForm}
                                        planId={openedPlan}/> : null}
              </div>
            }

      </div>
    );
  }
}

export default PackageTabs;
