import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAllZipCodesByDmaCodeId, fetchAllDmaCodes, createADmaCodesZipcode, updateADmaCodesZipcode } from '../../../../scripts/redux/actions'
import Modal from 'react-modal';
import Select from 'react-select';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import _ from 'lodash';

export class DmaCodesZipcodeForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      ontechQual: this.props.ontechQual,
      locastQual: this.props.locastQual,
      otaQual: this.props.otaQual || "indoor",
      id: this.props.id || undefined,
      dmaCodeId: this.props.dmaCodeId || undefined,
      zipcodeId: this.props.zipcodeId || undefined,
      newDmaCodesZipcode: this.props.newDmaCodesZipcode || false
    }
  }

  handleChange(key, value) {
    switch(key) {
      case 'ontechQual': {
        this.setState((prevState) => {
          return { ontechQual: !prevState.ontechQual }
        });
        break;
      }
      case 'locastQual': {
        this.setState((prevState) => {
          return { locastQual: !prevState.locastQual }
        });
        break;
      }
      case 'dmaCodeId': {
        this.props.fetchAllZipCodesByDmaCodeId(value);
      }
      default: {
        this.setState({[key]: value})
      }
    }
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    if (this.state.newDmaCodesZipcode) {
      this.props.fetchAllDmaCodes();
    }
    this.setState({
      isPaneOpen: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    const {dmaCodes, zipCodes} = nextProps;
    this.setState({dmaCodes, zipCodes});
    if (nextProps.statusCode === "Success") {
      this.props.closeDmaCodesZipcodeFormPane(true)
      this.setState({ isPaneOpen: false })
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const { id, zipcodeId, dmaCodeId, otaQual, ontechQual, locastQual, newDmaCodesZipcode } = this.state
    if (newDmaCodesZipcode) {
      var params = { dmaCodeId, otaQual, ontechQual, locastQual };
    } else {
      let params = { id, zipcodeId, dmaCodeId, otaQual, ontechQual, locastQual };
      if (ontechQual) {
        params = { ...params, ontechQual: 1 }
      } else if (!ontechQual) {
        params = { ...params, ontechQual: 0 }
      }
      if (locastQual) {
        params = { ...params, locastQual: 1 }
      } else if (!locastQual) {
        params = { ...params, locastQual: 0 }
      }
      if (otaQual === 'none') {
        params = { ...params, otaQual: 'no_qual' }
      }
      this.props.updateADmaCodesZipcode(params);
    }
  }

  render() {
    const { locastQual, ontechQual, otaQual, isPaneOpen, dmaCodes, zipCodes, id, newDmaCodesZipcode } = this.state;
    const otaQuals = [
      'indoor',
      'outdoor', 
      'none'
    ];

    return (
      <div ref={ref => this.el = ref} className='translation-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='DMA Codes Zipcode Form'
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ isPaneOpen: false });
            this.props.closeDmaCodesZipcodeFormPane()
          }}>
          <Form onSubmit={(e) => {this.handleSubmit(e)}}>
            { newDmaCodesZipcode ? 
            <div>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <select
                    key={id}
                    style={{ width: "35%", height: "20px" }}
                    disabled={!newDmaCodesZipcode}
                    onChange={(e) => this.handleChange('dmaCodeId', e.target.value)}
                  >
                    {dmaCodes ? dmaCodes.map(dmaCode =>
                      <option key={dmaCode.id} value={dmaCode.id}>{dmaCode.dmaCode} ({dmaCode.name})</option>
                    ) : null};
                  </select>
                  <Label for="dmaCode">DMA Codes</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Select
                    options={zipCodes}
                    onChange={(e) => this.handleChange('zipcodeId', e)}
                    value={this.state.zipcodeId}
                    disabled={!newDmaCodesZipcode}
                    placeholder="Select Zipcode"
                    style={{ width: "35%", height: "20px" }}
                  />
                  <Label for="zipCode">Zipcode</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="force-create" id="force-create" checked={false} onChange={(e) => this.handleChange('forceCreate')} />
                <Label check className="text-center">
                  Force Create
                </Label>
              </FormGroup>
            </div> : null}
            <FormGroup className="col-sm-12">
              <div className="field">
                <select
                  key={id}
                  style={{ width: "20%", height: "20px" }}
                  defaultValue={otaQual}
                  onChange={(e) => this.handleChange('otaQual', e.target.value)}
                >
                  {otaQuals.map(otaQual =>
                    <option key={otaQual} value={otaQual}>{otaQual}</option>
                  )};
                </select>
                <Label for="locale">OTA Qual</Label>
              </div>
            </FormGroup>
            
            <FormGroup className="col-sm-12 form-checkbox">
              <Input type="checkbox" name="ontech-qual" id="ontech-qual" checked={ontechQual} onChange={() => this.handleChange('ontechQual')} />
              <Label check className="text-center">
                On Tech Qual
              </Label>
            </FormGroup><FormGroup className="col-sm-12 form-checkbox">
              <Input type="checkbox" name="locast-qual" id="locast-qual" checked={locastQual} onChange={() => this.handleChange('locastQual')} />
              <Label check className="text-center">
                Locast Qual
              </Label>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <button className="btn btn-primary"
                      style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                      Submit
              </button>
            </FormGroup>
          </Form>
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({FlashMessageData, dmaCodesZipcodesData}) => {
  return {
    statusCode: FlashMessageData.statusCode,
    dmaCodes: dmaCodesZipcodesData.dmaCodes,
    zipCodes: dmaCodesZipcodesData.zipCodes
  };
}

export default connect(mapStateToProps, {
  fetchAllDmaCodes,
  fetchAllZipCodesByDmaCodeId,
  createADmaCodesZipcode,
  updateADmaCodesZipcode
})(DmaCodesZipcodeForm);
