import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchPartnerPlatforms,
  fetchDefaultClassifications,
  fetchAPartnerClassification,
  createAPartnerClassification,
  updatePartnerClassification
} from '../../../redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Collapse, Form, FormGroup, Label, Input } from 'reactstrap';

const styles = {
  partnerPlatformsButton: {
    fontSize: '14px',
    border: '1px solid darkgray',
    borderRadius: '50px',
    marginBottom: '1rem',
  }
}

export class PartnerClassificationForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      loadingAPartnerClassification: true,
      partnerPlatformId: 1,
      classification: 1,
      enabled: false,
      priority: 0,
      displayPackageCount: 1,
      displayNewPackages: [],
      selectAll: false,
      searchActive: false,
      searchVal: '',
      showAllSelected: false
    }
    this.clearSearch = this.clearSearch.bind(this)
    this.showAllHandler = this.showAllHandler.bind(this)
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    const {
      openedPartnerClassification,
      fetchPartnerPlatforms,
      fetchDefaultClassifications,
    } = this.props;
    fetchPartnerPlatforms().then(() => {
      if (openedPartnerClassification) {
        const { priority, partnerPlatformId, classificationId, enabled, displayPackageCount, id } = openedPartnerClassification
        fetchDefaultClassifications(id, 'partnerClassification').then(() => {
          this.setState({ priority, partnerPlatformId, classificationId, enabled, displayPackageCount, id })
        })
      }
      this.setState({
        loadingAPartnerClassification: false
      })
    })
  
    this.setState({ isPaneOpen: true })
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  searchNewPackage(searchVal) {
    let checkEmpty = !this.isEmpty(searchVal);
    let newFilteredData = this.state.partnerPackagesList.filter(pE => pE.identifier.includes(searchVal) || pE.packageType.includes(searchVal));
    this.setState({ displayNewPackages: newFilteredData, searchActive: checkEmpty, searchVal });
  }

  clearSearch() {
    this.searchNewPackage("");
  }

  componentWillReceiveProps(nextProps) {
    const { statusCode, allPartnerPlatforms, defaultClassifications, partnerPackagesList } = nextProps

    this.setState({ statusCode, allPartnerPlatforms, defaultClassifications, partnerPackagesList, displayNewPackages: partnerPackagesList })

    if (nextProps.statusCode === "Success") {
      this.props.closePartnerClassificationFormPane(null, true)
    }
  }

  selectAllHandler() {
    let newExtras = this.state.displayNewPackages.map(ex => {
      if (!this.state.selectAll) {
        ex.isExtra = true;
        ex.priority = this.isEmpty(ex.priority) ? 0 : ex.priority;
        ex.enabled = true;
      } else {
        ex.isExtra = false;
      }
      return ex
    });
    this.setState((prevState) => {
      return { selectAll: !prevState.selectAll, displayNewPackages: newExtras }
    });
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  handleAddNewPackage(packageId) {
    let allFilteredExtraCopy = this.state.displayNewPackages.filter(pE => pE.packageId !== packageId);
    let extraCopy = this.state.displayNewPackages.find(pE => pE.packageId === packageId);
    extraCopy.isExtra = !extraCopy.isExtra;
    let newUpdatedExtras = [ ...allFilteredExtraCopy, extraCopy ];
    this.setState({ displayNewPackages: this.state.showAllSelected ? this.state.displayNewPackages.filter(n => n.isExtra === true) : newUpdatedExtras });
  }

  handleEnabledChange(packageId) {
    let filteredData = this.state.displayNewPackages.filter(pE => pE.packageId !== packageId);
    let extraCopy = this.state.displayNewPackages.find(pE => pE.packageId === packageId);
    extraCopy.enabled = !extraCopy.enabled;
    let newUpdatedVal = [ ...filteredData, extraCopy ];
    this.setState({ displayNewPackages: newUpdatedVal });
  }

  handlePriorityChange(e, packageId) {
    let filteredData = this.state.displayNewPackages.filter(pE => pE.packageId !== packageId);
    let extraCopy = this.state.displayNewPackages.find(pE => pE.packageId === packageId);
    extraCopy.priority = e.target.value;
    let newUpdatedVal = [ ...filteredData, extraCopy ];
    this.setState({ displayNewPackages: newUpdatedVal });
  }

  showAllHandler() {
    this.setState((prevState) => {
      return { showAllSelected: !prevState.showAllSelected, displayNewPackages: this.state.showAllSelected ? this.state.partnerPackagesList : this.state.displayNewPackages.filter(n => n.isExtra === true) }
    });
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const {
      enabled,
      priority,
      displayPackageCount,
      id,
      displayNewPackages
    } = this.state
    let newExtras = displayNewPackages.filter(pE => pE.isExtra);
    var params = {
      enabled,
      priority,
      displayPackageCount,
      id,
      displayNewPackages: newExtras
    };

    this.props.updatePartnerClassification(params)
  }

  render() {
    const {
     partnerPlatformId,
     classificationId,
     enabled,
     priority,
     displayPackageCount,
     allPartnerPlatforms,
     defaultClassifications,
     isPaneOpen,
     displayNewPackages,
     searchActive,
     searchVal,
     showAllSelected
    } = this.state;

    let sortedPackageList = _.sortBy(displayNewPackages, 'identifier');

    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Partner Classification Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closePartnerClassificationFormPane()
          }} >
          <Form onSubmit={(e) => {this.handleSubmit(e)}}>
            <FormGroup className="col-sm-12">
              <div className="field">
                <Input type="select" name="partnerPlatformId" id="partnerPlatformId"  value={partnerPlatformId} onChange={(e) => this.handleChange('partnerPlatformId', e.target.value)} disabled >
                  {this.renderOptions(allPartnerPlatforms)}
                </Input>
                <Label for="partnerPlatformId" className='select-label'>Partner Platform</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <Input type="select" name="classification" id="classification"  value={classificationId} onChange={(e) => this.handleChange('classification', e.target.value)} disabled >
                  {this.renderOptions(defaultClassifications)}
                </Input>
                <Label for="classification" className='select-label'>Classification</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="number" name="displayPackageCount" id="displayPackageCount" placeholder="Package Count" onChange={(e) => this.handleChange('displayPackageCount', e.target.value)} value={displayPackageCount}/>
                <Label for="displayPackageCount">Package Count</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="number" name="priority" id="priority" placeholder="Priority" onChange={(e) => this.handleChange('priority', e.target.value)} value={priority} required />
                <Label for="priority">Priority</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12 form-checkbox">
              <Input type="checkbox" name="enabled" id="enabled" checked={enabled} onChange={(e) => this.handleChange('enabled', !enabled)} />{' '}
              <Label check className="text-center"> Enabled </Label>
            </FormGroup>
            <div>
              <button onClick={this.props.onToggleNewPackage} style={styles.partnerPlatformsButton}>
                Partner Base Packages
              </button>
              <Collapse isOpen={this.props.packageListOpen}>
                <div className="search-section" style={{marginLeft:'15px', paddingBottom:'55px', width: '50%'}}>
                  <div className='aaa'>
                    <input onChange={(e)=>this.searchNewPackage(e.target.value)}
                            type="search" value={searchVal} placeholder="What are you looking for?" />
                  </div>
                </div>
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="selectall-package" id="selectall-package" checked={this.state.selectAll} onChange={() => this.selectAllHandler()} />
                  <Label check className="text-center">
                    {this.state.selectAll ? "Deselect All" : "Select All"}
                  </Label>
                </FormGroup>
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="showall-selected" id="showall-selected" checked={showAllSelected} onChange={(e) => this.showAllHandler(e)} />
                  <Label check className="text-center">
                    {showAllSelected ? "Show All" : "Show All Selected"}
                  </Label>
                </FormGroup>
                {
                  searchActive ?
                  <div>
                    <span className="note-section">Note: Clear the search to enable the submit button</span>
                    <FormGroup className="col-sm-12">
                      <button className="btn btn-primary"
                              onClick={this.clearSearch}
                              style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                              Clear Search
                      </button>
                    </FormGroup>
                  </div>
                    :
                  <FormGroup className="col-sm-12">
                    <button type="submit"
                            className="btn btn-primary"
                            disabled={searchActive}
                            style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                            Submit
                    </button>
                  </FormGroup>
                }
                {!this.isEmpty(sortedPackageList) ? sortedPackageList.map(pP =>
                  (
                    <FormGroup key={pP.packageId} className="col-sm-12 form-checkbox">
                      <Input type="checkbox" name="new-package" id={pP.packageId} checked={pP.isExtra} onChange={() => this.handleAddNewPackage(pP.packageId)} />
                      <span className="check-css">
                        {pP.identifier} ({pP.packageType})
                        {
                          pP.isExtra ?
                          <span style={{ float: 'right' }}>
                            <input type="number" name="priority" id={`priority-${pP.packageId}`} placeholder="Priority" value={pP.priority} onChange={(e) => this.handlePriorityChange(e, pP.packageId)} />
                            <i className={pP.enabled ? "fa fa-toggle-on text-green" : "fa fa-toggle-off float-right text-red"} onClick={() => this.handleEnabledChange(pP.packageId)}/>
                          </span>
                          : null
                        }
                      </span>

                    </FormGroup>
                  )
                ) : null}
              </Collapse>
            </div>
            {
              searchActive ?
              <div>
                <span className="note-section">Note: Clear the search to enable the submit button</span>
                <FormGroup className="col-sm-12">
                  <button className="btn btn-primary"
                          onClick={this.clearSearch}
                          style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                          Clear Search
                  </button>
                </FormGroup>
              </div>
                :
              <FormGroup className="col-sm-12">
                <button type="submit"
                        className="btn btn-primary"
                        disabled={searchActive}
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            }
          </Form>
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({
    FlashMessageData,
    DefaultDataCalls,
    LineOfBusinessPlanData,
    PartnerPackageData
  }) => {
  return {
    statusCode: FlashMessageData.statusCode,
    allPartnerPlatforms: LineOfBusinessPlanData.partnerPlatforms,
    defaultClassifications: DefaultDataCalls.defaultClassifications,
    partnerPackagesList: PartnerPackageData.partnerPackagesList
  };
}

export default connect(mapStateToProps, {
  fetchPartnerPlatforms,
  fetchDefaultClassifications,
  fetchAPartnerClassification,
  createAPartnerClassification,
  updatePartnerClassification
})(PartnerClassificationForm);
