import {
  FETCH_FREEMIUMS_BY_CLASSIFICATION,
  LOADING_FREEMIUM_FORM,
  FETCH_A_FREEMIUM,
  RESET_FREEMIUM_FORM,
  EDIT_FREEMIUM,
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  packageId: '',
  identifier: '',
  priority: '',
  startDate: '',
  endDate: '',
  previewHours: '',
  entitlementType: '',
  previewStartsAt: '',
  previewEndsAt: '',
  classificationId: '',
  previewType: '',
  previewStartTime: '',
  previewEndTime: '',
  previewTimeZone: '',
  packages: [],
  displayingPackages: [],
  loading: true,
  dwClassification: 1,
  dwlob: 'domestic',
  packageName: '',
  loadingAPackage: false,
  editPackage: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FREEMIUMS_BY_CLASSIFICATION:
      let {packages, displayingPackages} = action.payload
      return {...state, packages, displayingPackages, loading: false};
    case FETCH_A_FREEMIUM:
      let {
        packageId, identifier, priority, startDate, endDate,
        previewHours, entitlementType, previewStartsAt, previewEndsAt, classificationId, previewType,
        previewStartTime, previewEndTime, previewTimeZone,
      } = action.payload
      return {
        ...state, packageId, identifier, priority, startDate, endDate,
        previewHours, entitlementType, previewStartsAt, previewEndsAt, classificationId, previewType,
        previewStartTime, previewEndTime, previewTimeZone, loadingAPackage: false
      };
    case LOADING_FREEMIUM_FORM:
      return {...state, loadingAPackage: !state.loadingAPackage};
    case RESET_FREEMIUM_FORM:
      let emptyPackageForm = _.omit(INITIAL_STATE, ['packages', 'displayingPackages','loading', 'loadingAPackage']);
      return {...state, ...emptyPackageForm, loadingAPackage: true };
    case EDIT_FREEMIUM:
      let {editFreemium} = action.payload;
      return {...state, editFreemium};
    default:
      return state;
  }
}
