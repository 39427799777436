import {
  FETCH_A_PARTNER_CLASSIFICATION,
  RESET_PARTNER_CLASSIFICATION_FORM,
  LOADING_PARTNER_CLASSIFICATION_FORM,
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  partnerPlatformId: '',
  classification: '',
  enabled: false,
  priority: '',
  displayPackageCount: '',
  loadingAPartnerClassification: true,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_A_PARTNER_CLASSIFICATION:
      const {
        partnerPlatformId,
        classification,
        enabled,
        priority,
        displayPackageCount,
        } = action.payload
      
      return {
        ...state, partnerPlatformId,
        classification,
        enabled,
        priority,
        displayPackageCount,
        loadingAPartnerClassification: false,
      }
    case RESET_PARTNER_CLASSIFICATION_FORM:
      let emptyPartnerClassificationForm = _.omit(INITIAL_STATE, ['loadingAPartnerClassification']);
      return {
        ...state,
        ...emptyPartnerClassificationForm,
        loadingAPartnerClassification: false
      };
    case LOADING_PARTNER_CLASSIFICATION_FORM:
      return {
        ...state,
        loadingAPartnerClassification: !state.loadingAPartnerClassification,
      }
    default:
    return state;
  }
}