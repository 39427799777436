import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import { clearCache, pcNodeJSCacheClear } from '../../../../scripts/redux/actions'

const ClearCache = (props) => {
  const { clearCache, pcNodeJSCacheClear } = props
  const cacheClearPC = {
    URL: `${process.env.REACT_APP_OLD_PC_URL}/cache/clear`,
    msg: 'Cleared PC cache successfully'
  }
  const cacheClearPPV = {
    URL: `${process.env.REACT_APP_OLD_PC_URL}/cache/clear`,
    msg: 'Cleared PPV cache successfully'
  }
  const cacheClearOTT = {
    URL: `${process.env.REACT_APP_OLD_PC_URL}/cache/clear`,
    msg: 'Cleared OTT cache successfully'
  }
  const cacheClearPCNodeJS = {
    URL: `${process.env.REACT_APP_NODE_JS_PC_URL}/cache/clear`,
    msg: 'Cleared Node JS PC cache successfully'
  }
  // const migrate = {
  //   URL: `${process.env.REACT_APP_OLD_PC_URL}/db/migrate`,
  //   msg: 'Ran migration successfully'
  // }
  // const deploymentsLatest = {
  //   URL: `${process.env.REACT_APP_OLD_PC_URL}/deployments/latest`,
  //   msg: 'Ran deployments latest successfully'
  // }

  return (
    <div className='row' style={{padding: '50px'}}>
      <FlashMessage />
      <div className='col-md-3'>
        <Card className='display-card'>
          <CardBody style={{height:'152px',background:'#5BC6FF',color:'#282c33'}} onClick={() => { if (window.confirm('Are you sure you wish to do this action?')) clearCache(cacheClearPC) } }>
              <span className="text-left" style={{fontSize:'18px'}}>Cache Clear PC - Rails</span>
          </CardBody>
        </Card>
      </div>

      <div className='col-md-3'>
        <Card className='display-card'>
          <CardBody style={{height:'152px',background:'#5BC6FF',color:'#282c33'}} onClick={() => { if (window.confirm('Are you sure you wish to do this action?')) pcNodeJSCacheClear(cacheClearPCNodeJS) } }>
              <span className="text-left" style={{fontSize:'18px'}}>Cache Clear PC - Node JS</span>
          </CardBody>
        </Card>
      </div>

      <div className='col-md-3'>
        <Card className='display-card'>
          <CardBody style={{height:'152px',background:'#5BC6FF',color:'#282c33'}} onClick={() => { if (window.confirm('Are you sure you wish to do this action?')) clearCache(cacheClearPPV, 'ppv') } }>
              <span className="text-left" style={{fontSize:'18px'}}>PPV Cache Clear</span>
          </CardBody>
        </Card>
      </div>

      <div className='col-md-3'>
        <Card className='display-card'>
          <CardBody style={{height:'152px',background:'#5BC6FF',color:'#282c33'}} onClick={() => { if (window.confirm('Are you sure you wish to do this action?')) clearCache(cacheClearOTT, 'ott') } }>
              <span className="text-left" style={{fontSize:'18px'}}>OTT Cache Clear</span>
          </CardBody>
        </Card>
      </div>

      {/* <div className='col-md-3'>
        <Card className='display-card'>
          <CardBody style={{height:'152px',background:'#5BC6FF',color:'#282c33'}} onClick={() => { if (window.confirm('Are you sure you wish to this action?')) clearCache(migrate) } }>
              <span className="text-left" style={{fontSize:'18px'}}>Db:Migrate</span>
          </CardBody>
        </Card>
      </div>

      <div className='col-md-3'>
        <Card className='display-card'>
          <CardBody style={{height:'152px',background:'#5BC6FF',color:'#282c33'}} onClick={() => { if (window.confirm('Are you sure you wish to this action?')) clearCache(deploymentsLatest) } }>
              <span className="text-left" style={{fontSize:'18px'}}>Deployments:Latest</span>
          </CardBody>
        </Card>
      </div> */}
    </div>
  )
}

export default connect(null, {
  clearCache,
  pcNodeJSCacheClear,
})(ClearCache);
