import Cookie from 'js-cookie';
import {
  FETCH_A_PACKAGE_PLAN,
} from '../ActionType';
import {signOut} from '../helper';

export const fetchPackagePlan = (planId, packageId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}package-plan/${packageId}/${planId}.json`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let { planPackagePrice, displayPrice, freeTrial, iauEnabled, id, plan_id
      } = json;
      planPackagePrice = planPackagePrice || ''
      displayPrice = displayPrice || ''
      dispatch({
        type: FETCH_A_PACKAGE_PLAN,
        payload: {
          planPackagePrice, displayPrice, freeTrial, iauEnabled, id, plan_id
        }
      })
    })
    .catch(error => {
        console.log('fetchPackagePlan Error - ', error);
    })
  }
}
