import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchDefaultPackageExtras,
  updateAllPartnerPackageExtras,
} from '../../../redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';
import NoData from "../../Common/NoData/NoData";
import './PartnerPackage.css';

export class PartnerPackageExtraForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingAPartnerPackage: true,
      collapseBanner: '',
      collapseCycle: "",
      collapseReleaseDate: "",
      collapseLayout:"",
      partnerPackageExtrasIds: []
    }
    this.toggle = this.toggle.bind(this);
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  toggle(e) {
    let event = e.target.dataset.event;
    if (event) {
      let prevEvent = this.state.collapseBanner;
      this.setState({ collapseBanner: event === prevEvent ? '' : event })
    }
  }

  handleEnabledChange(partnerPackageExtrasId) {
    let defaultPkgExtraCopy = this.state.defaultPackageExtras.find(pE => pE.partnerPackageExtrasId === partnerPackageExtrasId);
    defaultPkgExtraCopy.enabled = !defaultPkgExtraCopy.enabled;
    let allPkfExtrafiltered = this.state.defaultPackageExtras.filter(pE => pE.partnerPackageExtrasId !== partnerPackageExtrasId);
    let allSortedPkgExtra = _.sortBy([...allPkfExtrafiltered, defaultPkgExtraCopy], 'priority');
    this.setState({ defaultPackageExtras: allSortedPkgExtra });
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    const {
      openedPartnerPackageExtra,
      fetchDefaultPackageExtras
    } = this.props;
    fetchDefaultPackageExtras(openedPartnerPackageExtra.openedPartnerPackageExtra).then(() => {
      this.setState({
        loadingAPartnerPackage: false
      })
    })
    this.setState({
      isPaneOpen: true,
    })
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    const { defaultPackageExtras } = nextProps
    this.setState({ defaultPackageExtras })

    if (nextProps.statusCode === "SUCCESS") {
      this.props.closePartnerPackageExtraFormPane(null, true)
    }
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    let prevData = [];
    let pushedData = [];
    let updatedPushedData = [];
    let counter = newIndex + 1;
    let initData = {...this.state.defaultPackageExtras[oldIndex]}
    initData.priority = newIndex + 1;
    let filteredData = this.state.defaultPackageExtras.filter(pE => pE.partnerPackageExtrasId !== initData.partnerPackageExtrasId);
    let sortedFilteredData = _.sortBy(filteredData, 'priority');
    let defaultPackageExtrasNew = [];
    for (const [i, val] of sortedFilteredData.entries()) {
      val.priority = i + 1;
      defaultPackageExtrasNew.push(val)
    }

    for (const r of defaultPackageExtrasNew) {
      if (r.priority < newIndex + 1) {
        prevData.push(r);
      }
      if (r.priority >= newIndex + 1) {
        pushedData.push(r);
      }
    }

    if (pushedData.length > 0) {
      for (const echData of pushedData) {
        echData.priority = counter + 1;
        updatedPushedData.push(echData);
        counter += 1;
      }
    }
    this.setState({ defaultPackageExtras: [...prevData, initData, ...updatedPushedData] });
  }

  deletePartnerPackageExtras(id) {
    let filteredCopy = this.state.defaultPackageExtras.filter(pE => pE.partnerPackageExtrasId !== id);
    let idsToBeDeleted = [...this.state.partnerPackageExtrasIds];
    idsToBeDeleted.push(id);
    this.setState({ defaultPackageExtras: filteredCopy, partnerPackageExtrasIds: idsToBeDeleted });
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    let sortedData = _.sortBy(this.state.defaultPackageExtras, 'priority');
    let updatedDefaultPackageExtras = [];
    for (const [i, val] of sortedData.entries()) {
      val.priority = i + 1;
      updatedDefaultPackageExtras.push(val)
    }
    let params = {defaultPackageExtras: this.state.defaultPackageExtras, partnerPackageExtrasIds: this.state.partnerPackageExtrasIds}
    this.props.updateAllPartnerPackageExtras(params);
  }

  render() {
    const {
      loadingAPartnerPackage, isPaneOpen, defaultPackageExtras
    } = this.state;

    const SortableItem = SortableElement(({packages}) =>
      <div>
        <Card style={{ marginBottom: '1rem' }} key={packages.priority}>
          <CardHeader className={packages.enabled ? "extra-package-accordian-title text-green" : "extra-package-accordian-title text-red"} onClick={this.toggle} data-event={packages.partnerPackageExtrasId} data-type="collapseBanner">{packages.identifier} <i className='extra-package-accordian-title fa fa-trash float-right margin-left-50' onClick={() => this.deletePartnerPackageExtras(packages.partnerPackageExtrasId)} /><i className={packages.enabled ? "fa fa-toggle-on margin-left-50 float-right" : "fa fa-toggle-off margin-left-50 float-right"} onClick={() => this.handleEnabledChange(packages.partnerPackageExtrasId)}/></CardHeader>
          <Collapse isOpen={this.state.collapseBanner === (packages.partnerPackageExtrasId).toString() ? true : false}>
            <CardBody>
              <div className="col-sm-12">
                <div className="field">
                  <Input type="text" name='extraId' value={packages.identifier} disabled={true} />
                  <Label for="extraId" className='select-label'>Partner Package Extra</Label>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="field">
                  <input type="number" name="priority" id={`priority-${packages.priority}`} placeholder="Priority" value={packages.priority} onChange={(e) => this.handlePriorityChange(e, packages.partnerPackageExtrasId)} disabled={true} />
                  <Label for="priority">Priority</Label>
                </div>
              </div>
              <div className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="enabled" id={`enabled-${packages.priority}`} checked={packages.enabled} onChange={() => this.handleEnabledChange(packages.partnerPackageExtrasId)} />
                <Label check className="text-center"> Enabled </Label>
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );

    const SortableList = SortableContainer(({items}) => {
      if (items.length > 0) {
        return (
          <div style={{padding:'10px'}}>
            {
              items.map((value, index) => (
                <SortableItem key={`item-${index}`} packages={value} index={index} />
              ))
            }
          </div>
        );
      } else {
        return (<NoData />)
      }
    });

    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Partner Package Extras Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closePartnerPackageExtraFormPane()
          }}>
          { loadingAPartnerPackage ? <Loading /> :
            <div className="extra-packages">
              <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <SortableList items={defaultPackageExtras} onSortEnd={this.onSortEnd.bind(this)} axis='xy' pressDelay={200} />
                <FormGroup className="col-sm-12">
                  { defaultPackageExtras.length > 0 ?
                  <button className="btn btn-primary"
                          style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                          Submit
                  </button> : null}
                </FormGroup>
              </Form>
            </div>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({
    FlashMessageData,
    DefaultDataCalls,
  }) => {
  return {
    defaultPackageExtras: DefaultDataCalls.defaultPackageExtras,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchDefaultPackageExtras,
  updateAllPartnerPackageExtras,
})(PartnerPackageExtraForm);
