import {
  FETCH_ALL_PARTNER_PLATFORMS,
  FETCH_A_PARTNER_PLATFORM,
  LOADING_PARTNER_PLATFORM_FORM,
  RESET_PARTNER_PLATFORM_FORM,
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
    partnerPlatforms: [],
    displayingPartnerPlatforms: [],
    loading: true,
    loadingAPartnerPlatform: false,
    id: '',
    partner: '',
    platform: '',
    enabled: false,
    hasDoorways: false,
    hasPartnerSkus: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_PARTNER_PLATFORMS:
      let {partnerPlatforms} = action.payload
      return {
        ...state,
        partnerPlatforms,
        displayingPartnerPlatforms: partnerPlatforms,
        loading: false
      };
      case FETCH_A_PARTNER_PLATFORM:
        let { id, partner, platform, enabled, hasDoorways, hasPartnerSkus } = action.payload
        return {
          ...state, id, partner, platform, enabled, hasDoorways, hasPartnerSkus, loadingAPartnerPlatform: false
        }
      case LOADING_PARTNER_PLATFORM_FORM:
        return {
          ...state,
          loadingAPartnerPlatform: !state.loadingAPartnerPlatform
        };
      case RESET_PARTNER_PLATFORM_FORM:
        let emptyPartnerPlatformForm = _.omit(INITIAL_STATE, ['partnerPlatforms', 'displayingPartnerPlatforms', 'loading', 'loadingAPartnerPlatform']);
        return {
          ...state,
          ...emptyPartnerPlatformForm,
          loadingAnPartnerPlatform: false
        };
    default:
      return state;
  }
}
