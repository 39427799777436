import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAPartnerPlatform, updateAPartnerPlatform } from '../../../redux/actions'
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

export class PartnerPlatformForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingAPartnerPlatform: true
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    this.props.fetchAPartnerPlatform(this.props.partnerPlatformId);
    this.setState({ isPaneOpen: true })
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    const { id, partner, platform, enabled, hasDoorways, loadingAPartnerPlatform, hasPartnerSkus } = nextProps
    this.setState({ id, partner, platform, enabled, hasDoorways, loadingAPartnerPlatform, hasPartnerSkus })

    if (nextProps.statusCode === "Success") {
      this.props.closeEditPartnerPlatformPane(null, true)
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const {
      id,
      enabled,
      hasDoorways,
      hasPartnerSkus
    } = this.state
    let params = {
      id,
      enabled,
      hasDoorways,
      hasPartnerSkus
    };
    this.props.updateAPartnerPlatform(params)
  }

  render() {
    const {
      enabled, partner, platform, hasDoorways, hasPartnerSkus,
      isPaneOpen, loadingAPartnerPlatform
    } = this.state;
    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Partner Platform Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closeEditPartnerPlatformPane()
          }}>
          { loadingAPartnerPlatform ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="partner" id="partner" value={partner} placeholder="Partner" onChange={(e) => this.handleChange('partner', e.target.value)} disabled={true} />
                    <Label for="partner">Partner</Label>
                  </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="platform" id="platform" value={platform} placeholder="Platform" onChange={(e) => this.handleChange('platform', e.target.value)} disabled={true} />
                    <Label for="platform">Platform</Label>
                  </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="enabled" id="enabled" checked={enabled} onChange={(e) => this.handleChange('enabled', !enabled)} />{' '}
                <Label check className="text-center"> Enabled </Label>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="hasDoorways" id="hasDoorways" checked={hasDoorways} onChange={(e) => this.handleChange('hasDoorways', !hasDoorways)} />{' '}
                <Label check className="text-center"> Has Doorways </Label>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="hasPartnerSkus" id="hasPartnerSkus" checked={hasPartnerSkus} onChange={(e) => this.handleChange('hasPartnerSkus', !hasPartnerSkus)} />{' '}
                <Label check className="text-center"> Has Partner SKU's </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({PartnerPlatformData, FlashMessageData}) => {
  return {
    loadingAPartnerPlatform: PartnerPlatformData.loadingAPartnerPlatform,
    id: PartnerPlatformData.id,
    partner: PartnerPlatformData.partner,
    platform: PartnerPlatformData.platform,
    enabled: PartnerPlatformData.enabled,
    hasDoorways: PartnerPlatformData.hasDoorways,
    hasPartnerSkus: PartnerPlatformData.hasPartnerSkus,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchAPartnerPlatform, updateAPartnerPlatform
})(PartnerPlatformForm);
