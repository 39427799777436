import {
  FETCH_A_PACKAGE_PLAN,
} from '../ActionType';

const INITIAL_STATE = {
  planPackagePrice: 0,
  displayPrice: 0,
  freeTrial: false,
  iauEnabled: false,
  id: 0,
  plan_id: 1,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_A_PACKAGE_PLAN:
      let {
        planPackagePrice, displayPrice, freeTrial, iauEnabled, id, plan_id
      } = action.payload
      return {
        ...state, planPackagePrice, displayPrice, freeTrial, iauEnabled, id,
        plan_id, loadingAPackage: false
      };
    default:
      return state;
  }
}
