import {
  FETCH_LOBS,
  FETCH_AN_LOB,
  LOADING_LOB_FORM,
  RESET_LOB_FORM,
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
    lobs: [],
    displayingLobs: [],
    loading: true,
    loadingAnLob: false,
    identifier: '',
    enabled: false,
    name: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LOBS:
      let {lobs} = action.payload
      return {...state, lobs, displayingLobs: lobs, loading: false};
    case FETCH_AN_LOB:
      let {identifier, enabled, name,} = action.payload
      return {
        ...state, identifier, enabled, name, loadingAnLob: false
      }
    case LOADING_LOB_FORM:
      return {
        ...state,
        loadingAnLob: !state.loadingAnLob
      };
    case RESET_LOB_FORM:
      let emptyLobForm = _.omit(INITIAL_STATE, ['lobs', 'displayingLobs', 'loading', 'loadingAnLob']);
      return {
        ...state,
        ...emptyLobForm,
        loadingAnLob: false
      };
    default:
      return state;
  }
}