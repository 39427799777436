import Cookie from 'js-cookie';
import {
  FETCH_PPV_PACKAGES,
  FETCH_A_PPV_PACKAGE,
  LOADING_PPV_FORM,
  RESET_PPV_FORM,
  SET_NOTIFICATION_PANEL,
} from '../ActionType';
import _ from 'lodash';
import {signOut} from '../helper';

export const fetchAllPPVPackages = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}/ppv-packages`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      let ppvPackages = json;
      ppvPackages = _.sortBy(ppvPackages, 'priority');
      dispatch({
        type: FETCH_PPV_PACKAGES,
        payload: {ppvPackages}
      });
    })
    .catch(error => {
        console.log('fetchAllPPV Error - ', error);
    })
  }
}

export const fetchAPPVPackage = (id = null) => {
  return (dispatch) => {
    if (id) {
      const URL = `${process.env.REACT_APP_PC_URL}pay-per-view/${id}`;
      return fetch(URL, {method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        const {
          name, description, tvodGuid, startDate, endDate, imageSmUrl,
          imageLgUrl,
        } = json;
        dispatch({
          type: FETCH_A_PPV_PACKAGE,
          payload: {
            name, description, tvodGuid, startDate, endDate, imageSmUrl,
            imageLgUrl,
          }
        })
      })
    } else {
      dispatch(clearPPVForm())
    }
  }
}

export const clearPPVForm = () => {
  return (dispatch) => {
    dispatch({type: LOADING_PPV_FORM})
    dispatch({type: RESET_PPV_FORM})
  }
}

export const createUpdatePPVPackage = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-update-pay-per-view`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllPPVPackages())
    })
    .catch(error => {
      console.log('createUpdatePPVPackage Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}
