import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAClassification, createAClassification } from '../../../../scripts/redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import {CUSTOMER_CATEGORY_ID} from '../Constants.js';
import Loading from '../../Common/Loading/Loading.js';

export class ClassificationForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      allPackages: [],
      allPlans: [],
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingAClassification: false,
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  handleAppSupportedChange(appName) {
    const {appsSupported} = this.state
    let app = _.find(appsSupported, {appName})
    app.isSupported = !app.isSupported
    this.setState(appsSupported)
  }

  componentDidMount() {
      Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.props.fetchAClassification(this.props.classificationId)
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    const {
      name, identifier, priority, iapPriority, featuredImagePath,
      featuredShowLogoPath, customerCategoryId, defaultPackageId,
      defaultPlanId, appsSupported, salesNumber, supportNumber,
      enabled, allPlans, allPackages, loadingAClassification
    } = nextProps

    this.setState({
      name, identifier, priority, iapPriority, featuredImagePath,
      featuredShowLogoPath, customerCategoryId, defaultPackageId,
      defaultPlanId, appsSupported, salesNumber, supportNumber,
      enabled, allPlans, allPackages, loadingAClassification
    })

    if (nextProps.statusCode === "SUCCESS") {
      this.props.closeEditClassificationPane(null, true)
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    let {
      name, identifier, priority, iapPriority, featuredImagePath,
      featuredShowLogoPath, customerCategoryId, defaultPackageId,
      defaultPlanId, appsSupported, salesNumber, supportNumber,
      enabled
    } = this.state;
    enabled = enabled ? 1 : 0
    var params = {
      name, identifier, priority, iapPriority, featuredImagePath,
      featuredShowLogoPath, customerCategoryId, defaultPackageId,
      defaultPlanId, appsSupported, salesNumber, supportNumber,
      enabled
    };
    this.props.createAClassification(params)
  }

  renderAppsSupported() {
    return _.map(this.state.appsSupported, (app) => {
      return (
        <FormGroup check key={app.appName} className="form-checkbox" style={{padding:'0px'}}>
          <Input type="checkbox" name={app.appName} id={app.appName} checked={app.isSupported} onChange={(e) => this.handleAppSupportedChange(app.appName)} />{' '}
          <Label check className="text-center black">
            {app.appName}
          </Label>
        </FormGroup>
      );
    });
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    const {
      name, identifier, priority, iapPriority, featuredImagePath,
      featuredShowLogoPath, customerCategoryId, defaultPackageId,
      defaultPlanId, salesNumber, supportNumber, enabled, isPaneOpen,
      allPackages, allPlans, loadingAClassification
    } = this.state;
    return (
      <div>
        <div ref={ref => this.el = ref} className='classification-form'>
          <SlidingPane
            className='slinding-pane'
            overlayClassName='some-custom-overlay-class'
            isOpen={ isPaneOpen }
            title='Classification Form'
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              this.setState({ isPaneOpen: false });
              this.props.closeEditClassificationPane()
            }}>
            { loadingAClassification ? <Loading /> :
              <Form onSubmit={(e) => {this.handleSubmit(e)}}>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="name" id="name" placeholder="Name" onChange={(e) => this.handleChange('name', e.target.value)} value={name}/>
                    <Label for="name">Name</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleChange('identifier', e.target.value)} value={identifier}/>
                    <Label for="identifier">Identifier</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="priority" id="priority" placeholder="Priority" onChange={(e) => this.handleChange('priority', e.target.value)} value={priority}/>
                    <Label for="priority">Priority</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="iapPriority" id="iapPriority" placeholder="IAP Priority" onChange={(e) => this.handleChange('iapPriority', e.target.value)} value={iapPriority}/>
                    <Label for="iapPriority">IAP Priority</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="featuredImagePath" id="featuredImagePath" placeholder="Featured Image Path" onChange={(e) => this.handleChange('featuredImagePath', e.target.value)} value={featuredImagePath}/>
                    <Label for="featuredImagePath">Featured Image Path</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="featuredShowLogoPath" id="featuredShowLogoPath" placeholder="Featured Show Logo Path" onChange={(e) => this.handleChange('featuredShowLogoPath', e.target.value)} value={featuredShowLogoPath}/>
                    <Label for="featuredShowLogoPath">Featured Show Logo Path</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <Input type="select" name="customerCategoryId" id="customerCategoryId"  value={customerCategoryId} onChange={(e) => this.handleChange('customerCategoryId', e.target.value)}>
                      {this.renderOptions(CUSTOMER_CATEGORY_ID)}
                    </Input>
                    <Label for="customerCategoryId" className='select-label'>Customer Category Id</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <Input type="select" name="defaultPackageId" id="defaultPackageId"  value={defaultPackageId} onChange={(e) => this.handleChange('defaultPackageId', e.target.value)}>
                      {this.renderOptions(allPackages)}
                    </Input>
                    <Label for="defaultPackageId" className='select-label'>Default Package Id</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <Input type="select" name="defaultPlanId" id="defaultPlanId"  value={defaultPlanId} onChange={(e) => this.handleChange('defaultPlanId', e.target.value)}>
                      {this.renderOptions(allPlans)}
                    </Input>
                    <Label for="defaultPlanId" className='select-label'>Default Plan Id</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <Label className="select-label">Apps Supported</Label>
                  {this.renderAppsSupported()}
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="salesNumber" id="salesNumber" placeholder="Sales Number" onChange={(e) => this.handleChange('salesNumber', e.target.value)} value={salesNumber}/>
                    <Label for="salesNumber">Sales Number</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <div className="field">
                    <input type="text" name="supportNumber" id="supportNumber" placeholder="Sales Number" onChange={(e) => this.handleChange('supportNumber', e.target.value)} value={supportNumber}/>
                    <Label for="supportNumber">Support Number</Label>
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="enabled" id="enabled" checked={enabled} onChange={(e) => this.handleChange('enabled', !enabled)} />{' '}
                  <Label check className="text-center">
                    This Classification is currently active in production
                  </Label>
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <button className="btn btn-primary"
                          style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                          Submit
                  </button>
                </FormGroup>
              </Form>
            }

            <br />
          </SlidingPane>
        </div>
        <div>
          <input className="input2" type="text" name="name"></input>
          <span className="focus-input2" data-placeholder="NAME"></span>
        </div>
      </div>
    )
  }

}

const mapStateToProps = ({classificationsData, DefaultDataCalls, FlashMessageData}) => {
  return {
    name: classificationsData.name || '',
    identifier: classificationsData.identifier || '',
    priority: classificationsData.priority || '',
    iapPriority: classificationsData.iapPriority || '',
    featuredImagePath: classificationsData.featuredImagePath || '',
    featuredShowLogoPath: classificationsData.featuredShowLogoPath || '',
    customerCategoryId: classificationsData.customerCategoryId || '',
    defaultPackageId: classificationsData.defaultPackageId || '',
    defaultPlanId: classificationsData.defaultPlanId || '',
    appsSupported: classificationsData.appsSupported || '',
    salesNumber: classificationsData.salesNumber || '',
    supportNumber: classificationsData.supportNumber || '',
    enabled: classificationsData.enabled || false,
    loadingAClassification: classificationsData.loadingAClassification || false,
    allPlans: DefaultDataCalls.defaultPlans,
    allPackages: DefaultDataCalls.defaultPackages,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchAClassification,
  createAClassification,
})(ClassificationForm);
