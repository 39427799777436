import { combineReducers } from 'redux';
import Classification from './Classification';
import DmaCodesZipcode from './DmaCodesZipcode';
import Offer from './Offer';
import Package from './Package';
import Freemium from './Freemium';
import Plan from './Plan';
import Channel from './Channel';
import Translation from './Translation';
import FlashMessage from './FlashMessage';
import DefaultDataCalls from './DefaultDataCalls';
import MoreObjects from './MoreObjects';
import PackagePlan from './PackagePlan';
import Login from './Login';
import PackageClassification from './PackageClassification';
import PlanPackage from './PlanPackages';
import PayPerView from './PayPerView';
import LineOfBusiness from './LineOfBusiness';
import LineOfBusinessPlan from './LineOfBusinessPlan';
import IapClassification from './IapClassification';
import PartnerPlatform from './PartnerPlatform';
import PartnerClassification from './PartnerClassification';
import PartnerPackage from './PartnerPackage';
import ContentTag from './ContentTag';

export default combineReducers({
	ContentTagsData: ContentTag,
	classificationsData: Classification,
	offersData: Offer,
	packagesData: Package,
	freemiumsData: Freemium,
	plansData: Plan,
	channelsData: Channel,
	dmaCodesZipcodesData: DmaCodesZipcode,
	translationsData: Translation,
	FlashMessageData: FlashMessage,
	DefaultDataCalls: DefaultDataCalls,
	MoreObjects: MoreObjects,
	PackagePlanData: PackagePlan,
	loginData: Login,
	packageClassificationData: PackageClassification,
	planPackageData: PlanPackage,
	payPerViewData: PayPerView,
	LineOfBusinessData: LineOfBusiness,
	LineOfBusinessPlanData: LineOfBusinessPlan,
	IapClassificationData: IapClassification,
	PartnerPlatformData: PartnerPlatform,
	PartnerClassificationData: PartnerClassification,
	PartnerPackageData: PartnerPackage,
});
