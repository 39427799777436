import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchChildren, fetchContentTagsDropDown, fetchAContentTag, updateContentTagDetails } from '../../../../scripts/redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input, Card, CardHeader } from 'reactstrap';
import './ContentTagDetailsPane.css';

export class ContentTagDetailsPane extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      showPackage: true,
      displayPackage: [],
      showLinesOfBusiness: false,
      displayLinesOfBusiness: [],
      showPlan: false,
      displayPlan: [],
      showChannel: false,
      displayChannel: [],
      displayContentTag: [],
      showContentTag: false,
      searchVal: '',
      parentId: this.props.tagCategoryId,
      searchActive: false,
      selectAllPkg: false,
      selectAllLob: false,
      selectAllPlan: false,
      selectAllChannel: false,
      selectAllContentTag: false,
      showAllPkg: false,
      showAllLob: false,
      showAllPlan: false,
      showAllChannel: false,
      showAllContentTag: false,
      makeACopy: false
    }

    this.handleEntityChange = this.handleEntityChange.bind(this);
    this.handleParentChange = this.handleParentChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addDelete = this.addDelete.bind(this);
    this.addDisplayPriority = this.addDisplayPriority.bind(this);
    this.search = this.search.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.selectAllHandler = this.selectAllHandler.bind(this);
    this.showAllHandler = this.showAllHandler.bind(this);
    this.handleMakeACopyChange = this.handleMakeACopyChange.bind(this);
  }

  handleMakeACopyChange() {
    this.setState(prevState => {
      return { makeACopy: !prevState.makeACopy }
    });
  }

  showAllHandler(e) {
    switch(e.target.name) {
      case 'showall-package': {
        this.setState((prevState) => {
          return { showAllPkg: !prevState.showAllPkg, displayPackage: this.state.showAllPkg ? this.state.packageList : this.state.displayPackage.filter(n => n.isChild === true) }
        });
        break;
      }
      case 'showall-lob': {
        this.setState((prevState) => {
          return { showAllLob: !prevState.showAllLob, displayLinesOfBusiness: this.state.showAllLob ? this.state.linesOfBusinessList : this.state.displayLinesOfBusiness.filter(n => n.isChild === true) }
        });
        break;
      }
      case 'showall-plan': {
        this.setState((prevState) => {
          return { showAllPlan: !prevState.showAllPlan, displayPlan: this.state.showAllPlan ? this.state.planList : this.state.displayPlan.filter(n => n.isChild === true) }
        });
        break;
      }
      case 'showall-channel': {
        this.setState((prevState) => {
          return { showAllChannel: !prevState.showAllChannel, displayChannel: this.state.showAllChannel ? this.state.channelList : this.state.displayChannel.filter(n => n.isChild === true) }
        });
        break;
      }
      case 'showall-contentTag': {
        this.setState((prevState) => {
          return { showAllContentTag: !prevState.showAllContentTag, displayContentTag: this.state.showAllContentTag ? this.state.contentTagList : this.state.displayContentTag.filter(n => n.isChild === true) }
        });
        break;
      }
    }
  }

  selectAllHandler(e) {
    switch(e.target.name) {
      case 'selectall-package': {
        let newAdded = this.state.displayPackage.map(ex => {
          ex.isChild = this.state.selectAllPkg ? false : true;
          return ex;
        });
        this.setState((prevState) => {
          return { selectAllPkg: !prevState.selectAllPkg, displayPackage: newAdded }
        });
        break;
      }
      case 'selectall-lob': {
        let newAdded = this.state.displayLinesOfBusiness.map(ex => {
          ex.isChild = this.state.selectAllLob ? false : true;
          return ex;
        });
        this.setState((prevState) => {
          return { selectAllLob: !prevState.selectAllLob, displayLinesOfBusiness: newAdded }
        });
        break;
      }
      case 'selectall-plan': {
        let newAdded = this.state.displayPlan.map(ex => {
          ex.isChild = this.state.selectAllPlan ? false : true;
          return ex;
        });
        this.setState((prevState) => {
          return { selectAllPlan: !prevState.selectAllPlan, displayPlan: newAdded }
        });
        break;
      }
      case 'selectall-channel': {
        let newAdded = this.state.displayChannel.map(ex => {
          ex.isChild = this.state.selectAllChannel ? false : true;
          return ex;
        });
        this.setState((prevState) => {
          return { selectAllChannel: !prevState.selectAllChannel, displayChannel: newAdded }
        });
        break;
      }
      case 'selectall-contentTag': {
        let newAdded = this.state.displayContentTag.map(ex => {
          ex.isChild = this.state.selectAllContentTag ? false : true;
          return ex;
        });
        this.setState((prevState) => {
          return { selectAllContentTag: !prevState.selectAllContentTag, displayContentTag: newAdded }
        });
        break;
      }
    }
  }

  search(searchVal) {
    let checkEmpty = !this.isEmpty(searchVal);
    if (this.state.showPackage) {
      let newFilteredData = this.state.packageList.filter(pE => pE.label.includes(searchVal));
      this.setState({ displayPackage: newFilteredData, searchActive: checkEmpty, searchVal });
    }
    if (this.state.showLinesOfBusiness) {
      let newFilteredData = this.state.linesOfBusinessList.filter(pE => pE.label.includes(searchVal));
      this.setState({ displayLinesOfBusiness: newFilteredData, searchActive: checkEmpty, searchVal });
    }
    if (this.state.showPlan) {
      let newFilteredData = this.state.planList.filter(pE => pE.label.includes(searchVal));
      this.setState({ displayPlan: newFilteredData, searchActive: checkEmpty, searchVal });
    }
    if (this.state.showChannel) {
      let newFilteredData = this.state.channelList.filter(pE => pE.label.includes(searchVal));
      this.setState({ displayChannel: newFilteredData, searchActive: checkEmpty, searchVal });
    }
    if (this.state.showContentTag) {
      let newFilteredData = this.state.contentTagList.filter(pE => pE.label.includes(searchVal));
      this.setState({ displayContentTag: newFilteredData, searchActive: checkEmpty, searchVal });
    }
  }

  clearSearch() {
    let resetPakcageDisplayData = [ ...this.state.packageList ];
    let resetLobDisplayData = [ ...this.state.linesOfBusinessList ];
    let resetPlanDisplayData = [ ...this.state.planList ];
    let resetChannelDisplayData = [ ...this.state.channelList ];
    let resetContentTagDisplayData = [ ...this.state.contentTagList ];
    this.setState({
      displayPackage: resetPakcageDisplayData,
      displayLinesOfBusiness: resetLobDisplayData,
      displayPlan: resetPlanDisplayData,
      displayChannel: resetChannelDisplayData,
      displayContentTag: resetContentTagDisplayData,
      searchActive: false,
      searchVal: ""
    });
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  handleEntityChange(entityVal) {
    this.clearSearch();
    switch(entityVal) {
      case 'package': {
        this.setState({ showPackage: true, showLinesOfBusiness: false, showPlan: false, showChannel: false, showContentTag: false });
        break;
      }
      case 'linesOfBusiness': {
        this.setState({ showPackage: false, showLinesOfBusiness: true, showPlan: false, showChannel: false, showContentTag: false });
        break;
      }
      case 'plans': {
        this.setState({ showPackage: false, showLinesOfBusiness: false, showPlan: true, showChannel: false, showContentTag: false });
        break;
      }
      case 'channels': {
        this.setState({ showPackage: false, showLinesOfBusiness: false, showPlan: false, showChannel: true, showContentTag: false });
        break;
      }
      case 'contentTags': {
        this.setState({ showPackage: false, showLinesOfBusiness: false, showPlan: false, showChannel: false, showContentTag: true });
        break;
      }
      default: {
        this.setState({ showPackage: true, showLinesOfBusiness: false, showPlan: false, showChannel: false, showContentTag: false });
        break;
      }
    }
  }

  handleParentChange(newParent) {
    this.setState({ parentId: newParent })
  }

  handleChange(e) {
    switch (e.target.name) {
      case 'tagName': {
        if (!this.isEmpty(this.state.displayText)) {
          let displayTextCopy = {...this.state.displayText};
          if (this.isEmpty(displayTextCopy.translatable_type)) {
            displayTextCopy.translatable_type = "Tag";
          }
          if (this.isEmpty(displayTextCopy.translatable_id)) {
            displayTextCopy.translatable_id = this.props.tagId;
          }
          displayTextCopy.key = `model.tag.${e.target.value}.display_text`;
          displayTextCopy.value = e.target.value;
          this.setState({ displayText: displayTextCopy, tagName: e.target.value });
        } else {
          this.setState({ tagName: e.target.value });
        }
        break;
      }
      case 'tagType': {
        this.setState({ tagType: e.target.value });
        break;
      }
      case 'displayText': {
        if (!this.isEmpty(this.state.displayText)) {
          let displayTextCopy = {...this.state.displayText};
          if (this.isEmpty(displayTextCopy.translatable_type)) {
            displayTextCopy.translatable_type = "Tag";
          }
          if (this.isEmpty(displayTextCopy.translatable_id)) {
            displayTextCopy.translatable_id = this.props.tagId;
          }
          displayTextCopy.value = e.target.value;
          this.setState({ displayText: displayTextCopy });
        } else {
          let newDisplayTextObj = {}
          newDisplayTextObj['key'] = `model.tag.${this.state.tagName}.display_text`;
          newDisplayTextObj['value'] = e.target.value;
          newDisplayTextObj['interpolations'] = null;
          newDisplayTextObj['is_proc'] = false;
          newDisplayTextObj['translatable_type'] = "Tag";
          newDisplayTextObj['translatable_id'] = this.props.tagId;
          newDisplayTextObj['application'] = "catalog";
          this.setState({ displayText: newDisplayTextObj });
        }
        break;
      }
      case 'priority': {
        this.setState({ priority: e.target.value });
        break;
      }
      case 'enabled': {
        this.setState(prevState => {
          return { enabled: !prevState.enabled }
        });
      }
    }
  }

  addDisplayPriority(e, entityId) {
    switch(e.target.name) {
      case 'pkg-display-priority': {
        let filteredData = this.state.displayPackage.filter(pkg => pkg.packageId !== entityId);
        let dataCopy = { ...this.state.displayPackage.find(pkg => pkg.packageId === entityId) };

        let filteredPkgData = this.state.packageList.filter(pkg => pkg.packageId !== entityId);
        let dataPkgCopy = { ...this.state.packageList.find(pkg => pkg.packageId === entityId) };

        dataCopy.displayPriority = e.target.value;
        dataPkgCopy.displayPriority = e.target.value;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedPkgResult = [ ...filteredPkgData, dataPkgCopy ];
        this.setState({ displayPackage: updatedResult, packageList: updatedPkgResult });
        break;
      }
      case 'lob-display-priority': {
        let filteredData = this.state.displayLinesOfBusiness.filter(lob => lob.linesOfBusinessId !== entityId);
        let dataCopy = { ...this.state.displayLinesOfBusiness.find(lob => lob.linesOfBusinessId === entityId) };

        let filteredLobData = this.state.linesOfBusinessList.filter(lob => lob.linesOfBusinessId !== entityId);
        let dataLobCopy = { ...this.state.linesOfBusinessList.find(lob => lob.linesOfBusinessId === entityId) };

        dataCopy.displayPriority = e.target.value;
        dataLobCopy.displayPriority = e.target.value;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedLobResult = [ ...filteredLobData, dataLobCopy ];
        this.setState({ displayLinesOfBusiness: updatedResult, linesOfBusinessList: updatedLobResult });
        break;
      }
      case 'plan-display-priority': {
        let filteredData = this.state.displayPlan.filter(plan => plan.planId !== entityId);
        let dataCopy = { ...this.state.displayPlan.find(plan => plan.planId === entityId) };

        let filteredPlanData = this.state.planList.filter(plan => plan.planId !== entityId);
        let dataPlanCopy = { ...this.state.planList.find(plan => plan.planId === entityId) };

        dataCopy.displayPriority = e.target.value;
        dataPlanCopy.displayPriority = e.target.value;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedPlanResult = [ ...filteredPlanData, dataPlanCopy ];
        this.setState({ displayPlan: updatedResult, planList: updatedPlanResult });
        break;
      }
      case 'channel-display-priority': {
        let filteredData = this.state.displayChannel.filter(channel => channel.channelId !== entityId);
        let dataCopy = { ...this.state.displayChannel.find(channel => channel.channelId === entityId) };

        let filteredChannelData = this.state.channelList.filter(channel => channel.channelId !== entityId);
        let dataChannelCopy = { ...this.state.channelList.find(channel => channel.channelId === entityId) };

        dataCopy.displayPriority = e.target.value;
        dataChannelCopy.displayPriority = e.target.value;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedChannelResult = [ ...filteredChannelData, dataChannelCopy ];
        this.setState({ displayChannel: updatedResult, channelList: updatedChannelResult });
        break;
      }
      case 'contentTag-display-priority': {
        let filteredData = this.state.displayContentTag.filter(contentTag => contentTag.contentTagId !== entityId);
        let dataCopy = { ...this.state.displayContentTag.find(contentTag => contentTag.contentTagId === entityId) };

        let filteredContentTagData = this.state.contentTagList.filter(contentTag => contentTag.contentTagId !== entityId);
        let dataContentTagCopy = { ...this.state.contentTagList.find(contentTag => contentTag.contentTagId === entityId) };

        dataCopy.displayPriority = e.target.value;
        dataContentTagCopy.displayPriority = e.target.value;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedContentTagResult = [ ...filteredContentTagData, dataContentTagCopy ];
        this.setState({ displayContentTag: updatedResult, contentTagList: updatedContentTagResult });
        break;
      }
    }
  }

  addDelete(e, entityId) {
    switch(e.target.name) {
      case 'addDeletePackage': {
        let filteredData = this.state.displayPackage.filter(pkg => pkg.packageId !== entityId);
        let dataCopy = { ...this.state.displayPackage.find(pkg => pkg.packageId === entityId) };

        let filteredPkgData = this.state.packageList.filter(pkg => pkg.packageId !== entityId);
        let dataPkgCopy = { ...this.state.packageList.find(pkg => pkg.packageId === entityId) };

        dataCopy.isChild = !dataCopy.isChild;
        dataPkgCopy.isChild = !dataPkgCopy.isChild;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedPkgResult = [ ...filteredPkgData, dataPkgCopy ];
        this.setState({ displayPackage: updatedResult, packageList: updatedPkgResult });
        break;
      }
      case 'addDeleteLob': {
        let filteredData = this.state.displayLinesOfBusiness.filter(lob => lob.linesOfBusinessId !== entityId);
        let dataCopy = { ...this.state.displayLinesOfBusiness.find(lob => lob.linesOfBusinessId === entityId) };

        let filteredLobData = this.state.linesOfBusinessList.filter(lob => lob.linesOfBusinessId !== entityId);
        let dataLobCopy = { ...this.state.linesOfBusinessList.find(lob => lob.linesOfBusinessId === entityId) };

        dataCopy.isChild = !dataCopy.isChild;
        dataLobCopy.isChild = !dataLobCopy.isChild;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedLobResult = [ ...filteredLobData, dataLobCopy ];
        this.setState({ displayLinesOfBusiness: updatedResult, linesOfBusinessList: updatedLobResult });
        break;
      }
      case 'addDeletePlan': {
        let filteredData = this.state.displayPlan.filter(plan => plan.planId !== entityId);
        let dataCopy = { ...this.state.displayPlan.find(plan => plan.planId === entityId) };

        let filteredPlanData = this.state.planList.filter(plan => plan.planId !== entityId);
        let dataPlanCopy = { ...this.state.planList.find(plan => plan.planId === entityId) };

        dataCopy.isChild = !dataCopy.isChild;
        dataPlanCopy.isChild = !dataPlanCopy.isChild;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedPlanResult = [ ...filteredPlanData, dataPlanCopy ];
        this.setState({ displayPlan: updatedResult, planList: updatedPlanResult });
        break;
      }
      case 'addDeleteChannel': {
        let filteredData = this.state.displayChannel.filter(channel => channel.channelId !== entityId);
        let dataCopy = { ...this.state.displayChannel.find(channel => channel.channelId === entityId) };

        let filteredChannelData = this.state.channelList.filter(channel => channel.channelId !== entityId);
        let dataChannelCopy = { ...this.state.channelList.find(channel => channel.channelId === entityId) };

        dataCopy.isChild = !dataCopy.isChild;
        dataChannelCopy.isChild = !dataChannelCopy.isChild;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedChannelResult = [ ...filteredChannelData, dataChannelCopy ];
        this.setState({ displayChannel: updatedResult, channelList: updatedChannelResult });
        break;
      }
      case 'addDeleteContentTag': {
        let filteredData = this.state.displayContentTag.filter(contentTag => contentTag.contentTagId !== entityId);
        let dataCopy = { ...this.state.displayContentTag.find(contentTag => contentTag.contentTagId === entityId) };

        let filteredContentTagData = this.state.contentTagList.filter(contentTag => contentTag.contentTagId !== entityId);
        let dataContentTagCopy = { ...this.state.contentTagList.find(contentTag => contentTag.contentTagId === entityId) };

        dataCopy.isChild = !dataCopy.isChild;
        dataContentTagCopy.isChild = !dataContentTagCopy.isChild;

        let updatedResult = [ ...filteredData, dataCopy ];
        let updatedContentTagResult = [ ...filteredContentTagData, dataContentTagCopy ];
        this.setState({ displayContentTag: updatedResult, contentTagList: updatedContentTagResult });
        break;
      }
    }
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.props.fetchChildren(this.props.tagId)
    this.props.fetchAContentTag(this.props.tagId)
    this.props.fetchContentTagsDropDown()
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    const { packageList, linesOfBusinessList, planList, channelList, contentTagList, parentDropDown, tagName, tagType, enabled, priority, displayText } = nextProps;
    let newContentTagList = contentTagList.filter(cT => cT.entityId !== this.props.tagId);
    let newParentDropDownList = parentDropDown.filter(pD => pD.value !== this.props.tagId);
    this.setState({ displayPackage: packageList, packageList, displayLinesOfBusiness: linesOfBusinessList, linesOfBusinessList, displayPlan: planList, planList, displayChannel: channelList, channelList, displayContentTag: newContentTagList, contentTagList: newContentTagList, parentDropDown: newParentDropDownList, tagName, tagType, enabled, priority, displayText });
    if (nextProps.statusCode === "Success") {
      this.props.closeContentTagDetailsPane()
    }
  }

  handleEnableChanges() {
    this.setState(prevState => {
      return { enabled: !prevState.enabled }
    });
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const {
      tagName, tagType, enabled, priority, parentId,
      displayText, displayChannel, displayContentTag,
      displayLinesOfBusiness, displayPackage, displayPlan,
      makeACopy
    } = this.state;

    let allSelectedPkg = displayPackage.filter(pkg => pkg.isChild === true);
    let allSelectedLob = displayLinesOfBusiness.filter(pkg => pkg.isChild === true);
    let allSelectedPlan = displayPlan.filter(pkg => pkg.isChild === true);
    let allSelectedChannel = displayChannel.filter(pkg => pkg.isChild === true);
    let allSelectedContentTag = displayContentTag.filter(pkg => pkg.isChild === true);

    const parentParams = {
      oldParentId: this.props.tagCategoryId,
      newParentId: parentId
    }

    var params = {
      id: this.props.tagId, tagName, tagType, enabled, priority, parentParams,
      displayText, allSelectedChannel, allSelectedContentTag,
      allSelectedLob, allSelectedPkg, allSelectedPlan, makeACopy
    };

    this.props.updateContentTagDetails(params);
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    const {
      displayPackage, displayLinesOfBusiness, displayPlan, displayChannel, displayContentTag, parentDropDown, isPaneOpen,
      tagName, tagType, enabled, priority, displayText, searchVal, searchActive,
      showPackage, showLinesOfBusiness, showPlan, showChannel, showContentTag, parentId,
      selectAllPkg, selectAllLob, selectAllPlan, selectAllChannel, selectAllContentTag,
      showAllPkg, showAllLob, showAllPlan, showAllChannel, showAllContentTag, makeACopy
    } = this.state;

    let sortedPackageList = _.sortBy(displayPackage, 'displayPriority');
    let sortedLinesOfBusinessList = _.sortBy(displayLinesOfBusiness, 'displayPriority');
    let sortedPlanList = _.sortBy(displayPlan, 'displayPriority');
    let sortedChannelList = _.sortBy(displayChannel, 'displayPriority');
    let sortedContentTagList = _.sortBy(displayContentTag, 'displayPriority')

    const entityType = [
      {
        "label": "Package",
        "value": "package"
      },
      {
        "label": "Lines Of Business",
        "value": "linesOfBusiness"
      },
      {
        "label": "Plan",
        "value": "plans"
      },
      {
        "label": "Channel",
        "value": "channels"
      },
      {
        "label": "Content Tag",
        "value": "contentTags"
      },
    ]

    let renderPackageList = !this.isEmpty(sortedPackageList) ? sortedPackageList.map((pP, index) =>
      (
        <div className="each-row-main-div" key={index}>
          <div className="each-row-child" key={`${pP.packageId}/${pP.label}`} key={`rowGrid-${index}`}>
            <div style={{ marginLeft: '20px' }} key={`addDelete-${index}`}>
              <Input className="del-checkbox" type="checkbox" name="addDeletePackage" id="addDeletePackage" checked={pP.isChild} onChange={(e) => {this.addDelete(e, pP.packageId)}} />
            </div>
            <div style={{ marginRight: '10px' }} key={`displayPriority-${index}`}>
              <Input type="number" name="pkg-display-priority" id="pkg-display-priority" value={pP.displayPriority} onChange={(e) => {this.addDisplayPriority(e, pP.packageId)}} />
            </div>
            <div key={`indentifier-${index}`}>
              <CardHeader className="card-header-details" >{pP.label}</CardHeader>
            </div>
          </div>
        </div>
      )
    ) : null;

    let renderLinesOfBusinessList = !this.isEmpty(sortedLinesOfBusinessList) ? sortedLinesOfBusinessList.map((lob, index) =>
      (
        <div className="each-row-main-div" key={index}>
          <div className="each-row-child" key={`${lob.linesOfBusinessId}/${lob.label}`} key={`rowGrid-${index}`}>
            <div style={{ marginLeft: '20px' }} key={`addDelete-${index}`}>
              <Input className="del-checkbox" type="checkbox" name="addDeleteLob" id="addDeleteLob" checked={lob.isChild} onChange={(e) => {this.addDelete(e, lob.linesOfBusinessId)}} />
            </div>
            <div style={{ marginRight: '10px' }} key={`displayPriority-${index}`}>
              <Input type="number" name="lob-display-priority" id="lob-display-priority" value={lob.displayPriority} onChange={(e) => {this.addDisplayPriority(e, lob.linesOfBusinessId)}} />
            </div>
            <div key={`indentifier-${index}`}>
              <CardHeader className="card-header-details" >{lob.label}</CardHeader>
            </div>
          </div>
        </div>
      )
    ) : null;

    let renderPlanList = !this.isEmpty(sortedPlanList) ? sortedPlanList.map((plan, index) =>
      (
        <div className="each-row-main-div" key={index}>
          <div className="each-row-child" key={`${plan.planId}/${plan.label}`} key={`rowGrid-${index}`}>
            <div style={{ marginLeft: '20px' }} key={`addDelete-${index}`}>
              <Input className="del-checkbox" type="checkbox" name="addDeletePlan" id="addDeletePlan" checked={plan.isChild} onChange={(e) => {this.addDelete(e, plan.planId)}} />
            </div>
            <div style={{ marginRight: '10px' }} key={`displayPriority-${index}`}>
              <Input type="number" name="plan-display-priority" id="plan-display-priority" value={plan.displayPriority} onChange={(e) => {this.addDisplayPriority(e, plan.planId)}} />
            </div>
            <div key={`indentifier-${index}`}>
              <CardHeader className="card-header-details" >{plan.label}</CardHeader>
            </div>
          </div>  
        </div>
      )
    ) : null;

    let renderChannelList = !this.isEmpty(sortedChannelList) ? sortedChannelList.map((channel, index) =>
      (
        <div className="each-row-main-div" key={index}>
          <div className="each-row-child" key={`${channel.channelId}/${channel.label}`} key={`rowGrid-${index}`}>
            <div style={{ marginLeft: '20px' }} key={`addDelete-${index}`}>
              <Input className="del-checkbox" type="checkbox" name="addDeleteChannel" id="addDeleteChannel" checked={channel.isChild} onChange={(e) => {this.addDelete(e, channel.channelId)}} />
            </div>
            <div style={{ marginRight: '10px' }} key={`displayPriority-${index}`}>
              <Input type="number" name="channel-display-priority" id="channel-display-priority" value={channel.displayPriority} onChange={(e) => {this.addDisplayPriority(e, channel.channelId)}} />
            </div>
            <div key={`indentifier-${index}`}>
              <CardHeader className="card-header-details" >{channel.label}</CardHeader>
            </div>
          </div>
        </div> 
      )
    ) : null;

    let renderContentTagList = !this.isEmpty(sortedContentTagList) ? sortedContentTagList.map((contentTag, index) =>
      (
        <div className="each-row-main-div" key={index}>
          <div className="each-row-child" key={`${contentTag.contentTagId}/${contentTag.label}`} key={`rowGrid-${index}`}>
            <div style={{ marginLeft: '20px' }} key={`addDelete-${index}`}>
              <Input className="del-checkbox" type="checkbox" name="addDeleteContentTag" id="addDeleteContentTag" checked={contentTag.isChild} onChange={(e) => {this.addDelete(e, contentTag.contentTagId)}} />
            </div>
            <div style={{ marginRight: '10px' }} key={`displayPriority-${index}`}>
              <Input type="number" name="contentTag-display-priority" id="contentTag-display-priority" value={contentTag.displayPriority} onChange={(e) => {this.addDisplayPriority(e, contentTag.contentTagId)}} />
            </div>
            <div key={`indentifier-${index}`}>
              <CardHeader className="card-header-details" >{contentTag.label}</CardHeader>
            </div>
          </div>
        </div>
      )
    ) : null;

    return (
      <div ref={ref => this.el = ref} className='contentTagDetails-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Content Category Tag Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closeContentTagDetailsPane();
          }}>
          <Form onSubmit={(e) => {this.handleSubmit(e)}}>
            <FormGroup className="col-sm-12">
              <div className="field">
                <Input style={{ width: '60%' }} type="select" name="tagCategoryId" id="tagCategoryId" value={parentId} onChange={(e) => this.handleParentChange(e.target.value)} >
                  {this.renderOptions(parentDropDown)}
                </Input>
                <Label for="tagCategoryId" className='select-label'>Parent Tag</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12 form-checkbox">
              <Input type="checkbox" name="makeACopy" id="makeACopy" checked={makeACopy} onChange={() => this.handleMakeACopyChange()} />
              <Label check className="text-center">Make A Copy</Label>
            </FormGroup>
            <hr/>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="tagName" id="tagName" placeholder="Name" value={!this.isEmpty(tagName) ? tagName : ''} onChange={(e) => this.handleChange(e)} required/>
                <Label for="tagName">Current Tag Name</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="tagType" id="tagType" placeholder="Tag Type" value={!this.isEmpty(tagType) ? tagType : ''} onChange={(e) => this.handleChange(e)} required/>
                <Label for="tagType">Current Tag Type</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="text" name="displayText" id="displayText" placeholder="Display Text" value={!this.isEmpty(displayText) ? displayText.value : ''} onChange={(e) => this.handleChange(e)} />
                <Label for="displayText">Display Text</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <div className="field">
                <input type="number" name="priority" id="priority" placeholder="Priority" value={!this.isEmpty(priority) ? priority : 0} onChange={(e) => this.handleChange(e)} />
                <Label for="priority">Priority</Label>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12 form-checkbox">
              <Input type="checkbox" name="enabled" id="enabled" checked={enabled || false} onChange={(e) => this.handleChange(e)} />
              <Label check className="text-center">Enabled</Label>
            </FormGroup>
            <hr/>
            <h4 style={{ textAlign: 'center' }}>Child Tags</h4>
            <FormGroup className="col-sm-12">
              <div className="field">
                <Input style={{ width: '60%' }} type="select" name="entity" id="entity" onChange={(e) => this.handleEntityChange(e.target.value)} >
                  {this.renderOptions(entityType)}
                </Input>
                <Label for="entity" className='select-label'>Select Entity</Label>
              </div>
            </FormGroup>
            <div className="search-section" style={{marginLeft:'15px', paddingBottom:'55px', width: '50%'}} >
              <input type="search" name="search" id="search" placeholder="What are you looking for?" value={searchVal} onChange={(e) => {this.search(e.target.value)}} />
            </div>
            { showPackage ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="selectall-package" id="selectall-package" checked={selectAllPkg} onChange={(e) => this.selectAllHandler(e)} />
                  <Label check className="text-center">
                    {selectAllPkg ? "Deselect All" : "Select All"}
                  </Label>
                </FormGroup>
              : null }
            { showPackage ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="showall-package" id="showall-package" checked={showAllPkg} onChange={(e) => this.showAllHandler(e)} />
                  <Label check className="text-center">
                    {showAllPkg ? "Show All" : "Show All Selected"}
                  </Label>
                </FormGroup>
              : null }
            { showLinesOfBusiness ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="selectall-lob" id="selectall-lob" checked={selectAllLob} onChange={(e) => this.selectAllHandler(e)} />
                    <Label check className="text-center">
                      {selectAllLob ? "Deselect All" : "Select All"}
                    </Label>
                </FormGroup>
              : null }
            { showLinesOfBusiness ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="showall-lob" id="showall-lob" checked={showAllLob} onChange={(e) => this.showAllHandler(e)} />
                  <Label check className="text-center">
                    {showAllLob ? "Show All" : "Show All Selected"}
                  </Label>
                </FormGroup>
              : null }
            { showPlan ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="selectall-plan" id="selectall-plan" checked={selectAllPlan} onChange={(e) => this.selectAllHandler(e)} />
                    <Label check className="text-center">
                      {selectAllPlan ? "Deselect All" : "Select All"}
                    </Label>
                </FormGroup>
              : null }
            { showPlan ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="showall-plan" id="showall-plan" checked={showAllPlan} onChange={(e) => this.showAllHandler(e)} />
                  <Label check className="text-center">
                    {showAllPlan ? "Show All" : "Show All Selected"}
                  </Label>
                </FormGroup>
              : null }
            { showChannel ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="selectall-channel" id="selectall-channel" checked={selectAllChannel} onChange={(e) => this.selectAllHandler(e)} />
                    <Label check className="text-center">
                      {selectAllChannel ? "Deselect All" : "Select All"}
                    </Label>
                </FormGroup>
              : null }
            { showChannel ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="showall-channel" id="showall-channel" checked={showAllChannel} onChange={(e) => this.showAllHandler(e)} />
                  <Label check className="text-center">
                    {showAllChannel ? "Show All" : "Show All Selected"}
                  </Label>
                </FormGroup>
              : null }
            { showContentTag ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="selectall-contentTag" id="selectall-contentTag" checked={selectAllContentTag} onChange={(e) => this.selectAllHandler(e)} />
                    <Label check className="text-center">
                      {selectAllContentTag ? "Deselect All" : "Select All"}
                    </Label>
                </FormGroup>
              : null }
            { showContentTag ?
                <FormGroup className="col-sm-12 form-checkbox">
                  <Input type="checkbox" name="showall-contentTag" id="showall-contentTag" checked={showAllContentTag} onChange={(e) => this.showAllHandler(e)} />
                  <Label check className="text-center">
                    {showAllContentTag ? "Show All" : "Show All Selected"}
                  </Label>
                </FormGroup>
              : null }
            {
              searchActive ?
              <div>
                <span className="note-section">Note: Clear the search to enable the submit button</span>
                <FormGroup className="col-sm-12">
                  <button className="btn btn-primary"
                          onClick={this.clearSearch}
                          style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                          Clear Search
                  </button>
                </FormGroup>
              </div>
                :
              <FormGroup className="col-sm-12">
                <button type="submit"
                        className="btn btn-primary"
                        disabled={searchActive}
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            }
            
            <div style={{ borderBottom: '1px solid gray', width: '85%', marginLeft: '10px', marginBottom: '10px' }}>
              <div className="each-row-child">
                <div style={{ textAlign: 'left' }}>
                  <strong>Add/Delete</strong>
                </div>
                <div style={{ marginRight: '10px' }}>
                  <strong>Display Priority</strong>
                </div>
                <div>
                  <strong>Entity Identifier</strong>
                </div>
              </div>
            </div>
            { showPackage ? <div className="scroll-entity-list">{renderPackageList}</div> : null }
            { showLinesOfBusiness ? <div className="scroll-entity-list">{renderLinesOfBusinessList}</div> : null }
            { showPlan ? <div className="scroll-entity-list">{renderPlanList}</div> : null }
            { showChannel ? <div className="scroll-entity-list">{renderChannelList}</div> : null }
            { showContentTag ? <div className="scroll-entity-list">{renderContentTagList}</div> : null }
            
            {
              searchActive ?
              <div>
                <span className="note-section">Note: Clear the search to enable the submit button</span>
                <FormGroup className="col-sm-12">
                  <button className="btn btn-primary"
                          onClick={this.clearSearch}
                          style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                          Clear Search
                  </button>
                </FormGroup>
              </div>
                :
              <FormGroup className="col-sm-12">
                <button type="submit"
                        className="btn btn-primary"
                        disabled={searchActive}
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            }
          </Form>
          <br />
        </SlidingPane>
      </div>
    );
  }
}

const mapStateToProps = ({ContentTagsData, FlashMessageData}) => {
  return {
    tagName: ContentTagsData.tagName,
    tagType: ContentTagsData.tagType,
    enabled: ContentTagsData.enabled,
    priority: ContentTagsData.priority,
    displayText: ContentTagsData.displayText,
    packageList: ContentTagsData.packageList,
    linesOfBusinessList: ContentTagsData.linesOfBusinessList,
    planList: ContentTagsData.planList,
    channelList: ContentTagsData.channelList,
    contentTagList: ContentTagsData.contentTagList,
    parentDropDown: ContentTagsData.parentDropDown,
    statusCode: FlashMessageData.statusCode
  };
}

export default connect(mapStateToProps, {
  fetchChildren,
  fetchContentTagsDropDown,
  fetchAContentTag,
  updateContentTagDetails
})(ContentTagDetailsPane);
