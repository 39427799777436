import React from 'react'
import './Loading.css';

const Loading = ({color=''}) => (
  <div className='spinner'>
    <div className={`rect1 ${color}`}></div>
    <div className={`rect2 ${color}`}></div>
    <div className={`rect3 ${color}`}></div>
    <div className={`rect4 ${color}`}></div>
    <div className={`rect5 ${color}`}></div>
  </div>
)

export default Loading
