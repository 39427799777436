import Cookie from 'js-cookie';
import {
  FETCH_LOBS,
  SET_NOTIFICATION_PANEL,
  LOADING_LOB_FORM,
  FETCH_AN_LOB,
  FETCH_LOB_PLANS,
  FETCH_AN_LOB_PLAN,
  FETCH_PARTNER_PLATFORMS,
  RESET_LOB_PLAN_FORM,
  RESET_LOB_FORM,
} from '../ActionType';
// import _ from 'lodash';
import {signOut} from '../helper';

export const fetchAllLOBs = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}lines-of-business`;
    return fetch(URL, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let lobs = json;
        dispatch({
          type: FETCH_LOBS,
          payload: {
            lobs
          }
        });
      })
      .catch(error => {
        console.log('fetchAllLOBs Error - ', error);
      })
  }
}

export const fetchAnLOB = (id) => {
  return (dispatch) => {
    dispatch({ type: LOADING_LOB_FORM })
    const URL = `${process.env.REACT_APP_NODE_PC_DEV_URL}lines-of-business/${id}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        identifier, enabled, name,
      } = json;
      dispatch({
        type: FETCH_AN_LOB,
        payload: {
          identifier, enabled, name,
        }
      })
    })
  }
}

export const createAnLob = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-lines-of-business`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllLOBs())
    })
    .catch(error => {
      console.log('createAnLob Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const toggleLinesOfBusiness = ({lobId, value}) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}disable-lines-of-business/${lobId}/${value}`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
      dispatch(fetchAllLOBs())
    })
  }
}

export const fetchLineOfBusinessPlans = (lob) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}line-of-business-plans`;
    return fetch(URL, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let lobPlans = json;
        dispatch({
          type: FETCH_LOB_PLANS,
          payload: {
            lobPlans
          }
        });
      })
      .catch(error => {
        console.log('fetchAllLOBs Error - ', error);
      })
  }
}

export const createAnLobPlan = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-line-of-business-plans`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('createAnLobPlan Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const fetchAnLOBPlan = (partnerPlatformId, lineOfBusinessId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_PC_URL}line-of-business-plan/${partnerPlatformId}/${lineOfBusinessId}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { partnerPlatformId, lineOfBusinessId, planId, enabled } = json;
      dispatch({
        type: FETCH_AN_LOB_PLAN,
        payload: { partnerPlatformId, lineOfBusinessId, planId, enabled }
      })
    })
  }
}

export const fetchPartnerPlatforms = () => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-platforms`;
    return fetch(URL, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        let partnerPlatforms = json;
        dispatch({
          type: FETCH_PARTNER_PLATFORMS,
          payload: {
            partnerPlatforms
          }
        });
      })
      .catch(error => {
        console.log('fetchAllLOBs Error - ', error);
      })
  }
}

export const resetLobPlanForm = () => {
   return (dispatch) => {
      return dispatch({
        type: RESET_LOB_PLAN_FORM
      })
    }
}

export const resetLobForm = () => {
  return (dispatch) => {
    return dispatch({
      type: RESET_LOB_FORM
    })
  }
}
