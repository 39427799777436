import Cookie from 'js-cookie';
import {
  FETCH_PARTNER_CLASSIFICATIONS,
  LOADING_PARTNER_PACKAGES,
  SET_NOTIFICATION_PANEL,
  LOADING_PARTNER_PACKAGE_FORM,
  RESET_PARTNER_PACKAGE_FORM,
  FETCH_A_PARTNER_PACKAGE,
  FETCH_A_PACKAGE_LIST_TO_ADD
} from '../ActionType';
import {
  signOut
} from '../helper';

export const fetchPartnerClassifications = (partnerPlatform) => {
  return (dispatch) => {
    dispatch(loadingPartnerPackages())
    const URL = `${process.env.REACT_APP_PC_URL}partner-classifications/${partnerPlatform}`;
    return fetch(URL, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(partnerClassifications => {
        if (partnerClassifications.exception === 'io.jsonwebtoken.ExpiredJwtException') {
          signOut()
        }
        dispatch({
          type: FETCH_PARTNER_CLASSIFICATIONS,
          payload: {
            partnerClassifications
          }
        });
        dispatch(loadingPartnerPackages())
      })
      .catch(error => {
        console.log('fetchPartnerClassifications Error - ', error);
      })
  }
}

export const loadingPartnerPackages = () => {
  return (dispatch) => {
    return dispatch({
      type: LOADING_PARTNER_PACKAGES
    })
  }
}

export const togglePartnerPackage = ({
    partnerPackageId,
    value
  }, partnerClassificationId) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}disable-partner-package/${partnerPackageId}/${value}`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
  }
}

export const createAPartnerPackage = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-partner-package`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('createAPartnerPlatform Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const fetchAPartnerPackage = (partnerClassificationId, packageId) => {
  return (dispatch) => {
    dispatch({ type: LOADING_PARTNER_PACKAGE_FORM })
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-packages/${partnerClassificationId}/${packageId}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const {
        id, partnerClassificationId, packageId, pack, enabled, priority,
      } = json.data.partnerPackage;
      const { allPackageExtras } = json.data;
      dispatch({
        type: FETCH_A_PARTNER_PACKAGE,
        payload: {
          id, partnerClassificationId, packageId, pack, enabled, priority, allPackageExtras
        }
      })
    })
  }
}

export const loadingPartnerPackageForm = () => {
  return (dispatch) => {
    return dispatch({
      type: LOADING_PARTNER_PACKAGE_FORM
    })
  }
}

export const resetPartnerPackageForm = () => {
  return (dispatch) => {
    return dispatch({
      type: RESET_PARTNER_PACKAGE_FORM
    })
  }
}

export const createAPartnerPackageExtra = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_PC_URL}create-partner-package-extra`;
    return fetch(URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('createAPartnerPlatform Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const updateAllPartnerPackageExtras = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-package-extra`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('updateAllPartnerPackageExtras Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const updateAPartnerPackage = (params) => {
  return (dispatch) => {
    var URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner-package`;
    return fetch(URL, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      }),
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { code, message } = json.status
      dispatch({
        type: SET_NOTIFICATION_PANEL,
        payload: {code, message}
      });
    })
    .catch(error => {
      console.log('updateAPartnerPackage Error - ', error)
      // this.props.showNotificationPanel(this.state.showNotification, this.state.statusCode, this.state.statusMessage)
    });
  }
}

export const fetchPackageToAdd = (partnerClassificationId) => {
  return (dispatch) => {
    const URL = `${process.env.REACT_APP_NODE_PC_ADMIN_URL}partner_packages_by_classificationId/${partnerClassificationId}`;
    return fetch(URL, {method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token')
      })
    })
    .then(response => response.json())
    .then(json => {
      if (json.exception === 'io.jsonwebtoken.ExpiredJwtException') {
        signOut()
      }
      const { partnerPackagesList } = json.data;
      dispatch({
        type: FETCH_A_PACKAGE_LIST_TO_ADD,
        payload: { partnerPackagesList }
      })
    })
    .catch(error => {
      console.log('fetchPackageToAdd Error - ', error)
    });
  }
}
