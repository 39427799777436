import React, { Component } from 'react';
import Sidebar from './components/Common/Sidebar/Sidebar.js';
import Main from './components/Main.js';
import '../assets/styles/index.css';
import {PC_ITEMS} from './components/PC/Constants.js';
import IdleTimer from 'react-idle-timer';
import {signOut} from './redux/helper';
import {IdleTimeOutModal} from './components/Common/Modal/IdleTimeOutModal.js';
class App extends Component {
  constructor (props) {
    super(props);
    this.idleTimer = null
    this.state = {
      shrinkSidebar: false,
      timeout: 1000 * 60 * 10,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false
    }
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  _onAction() {
    this.setState({isTimedOut: false})
  }

  _onActive() {
    this.setState({isTimedOut: false})
  }

  _onIdle() {
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      signOut()
    } else {
      this.setState({showModal: true})
      this.idleTimer.reset();
      this.setState({isTimedOut: true})
    }
  }

  handleClose() {
    this.setState({showModal: false})
  }

  handleLogout() {
    this.setState({showModal: false})
    signOut()
  }

  render() {
    const loginPath = window.location.pathname === '/login' || window.location.pathname === '/';
    const hideSideBar = ['/', '/pc'].includes(window.location.pathname);
    let sidebarItems = [];
    if (window.location.pathname.startsWith('/pc')) {
      if (!PC_ITEMS.some(i => i.label === 'Home')) {
        PC_ITEMS.unshift({ pathname: '/', label: 'Home', icon: 'home' })
      }
      sidebarItems = PC_ITEMS
    }
    return (
      loginPath ? <div className="main margin-left-none login-page"><Main /></div> :
      <div>
        {/* <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} /> */}
        {
          hideSideBar ? null :
          <div className={`al-sidebar ${this.state.shrinkSidebar ? "width-50" : ""}`}>
            <Sidebar sidebarItems={sidebarItems} location={window.location.pathname}/>
          </div>
        }
        <div className={`main ${this.state.shrinkSidebar ? "margin-left-35" : ""}`}><Main/></div>
        {/* <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        /> */}
      </div>
    )
  }
}


export default App;


