import React from "react";
import {connect} from 'react-redux';
import ReactTable from "react-table";
import OttTranslationForm  from '../TranslationForm/OttTranslationForm.js';
import { fetchAllOttTranslations } from '../../../../scripts/redux/actions';
import './OttTranslations.css';

// import "react-table/react-table.css";

import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import './Translations.css';
class OttTranslations extends React.Component {
  constructor() {
    super();
    this.state = {
      translations: [],
			displayingTranslations: [],
      showTranslationForm: false,
      key: '',
      locale: '',
      value: '',
      application: '',
      id: '',
      filtered: []
    };
    this.searchKey = "";
    this.renderEditable = this.renderEditable.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
    this.closeTranslationForm = this.closeTranslationForm.bind(this)
  }

  componentWillMount() {
    this.props.fetchAllOttTranslations();
  }

  componentWillReceiveProps(nextProps) {
    const {translations, displayingTranslations, loading} = nextProps
    this.setState({translations, displayingTranslations, loading})
  }

  renderEditable(cellInfo) {
    return (
      <div className="translation-input text-left">
        {this.state.translations[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  updateState(cellInfo, value) {
    const {displayingTranslations} = this.state;
    displayingTranslations[cellInfo.index][cellInfo.column.id] = value;
    displayingTranslations[cellInfo.index]['edited'] = true
    this.setState({ displayingTranslations });
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => {this.setState({showNotification: false})}, 3000);
  }

  updateTranslation(params) {
    let {value, locale, key, application, id} = params
    this.setState({ showTranslationForm: true, value, key, locale, application, id })
  }

  closeTranslationForm(getTranslations=false) {
    if (getTranslations) {
      this.props.fetchAllOttTranslations()
    }
    this.setState({filtered: [],showTranslationForm: false, value: '', key: '', locale: '', application: '', id: ''})

  }

  renderButton(cellInfo) {
    return (
      <div className="text-center">
        <i className="fa fa-pencil edit-icon" onClick={(e) => {this.updateTranslation(cellInfo.original)}}></i>
      </div>
    )
  }

  search(val) {
    let displayingTranslations;
		let {translations} = this.state;
    let displayingKeys = translations.filter(translation => translation.key.toLowerCase().search(val.toLowerCase()) !== -1)
    let displayingValues = translations.filter(translation => translation.value.toLowerCase().search(val.toLowerCase()) !== -1)
    if (displayingKeys.length > 1) {
      displayingTranslations = displayingKeys;
    } else {
      displayingTranslations = displayingValues;
    }
    this.setState({displayingTranslations});
	}

  // Use this when locale field is made as dropdown
  // renderLocaleDropdown(cellInfo) {
  //   return (
  //     <Select
  //       value={cellInfo.original.locale}
  //       onChange={(e) => {this.updateState(cellInfo, e.target.value)}}
  //       options={['en', 'es', 'gu', 'hi', 'pa', 'pt-BR']}
  //     />
  //   )
  // }

  render() {
    const {
      displayingTranslations, showTranslationForm, locale, key, value, application, id
    } = this.state;
    return (
      <div>
        <FlashMessage />
        <div className='translation-header'>
          <div className='new-translation-div'>
            <button className="btn btn-primary new-translation-btn"
                    onClick={()=> this.setState({ showTranslationForm: true })}>
            <i className="fa fa-plus"></i> &nbsp; New Translation
            </button>
          </div>
        </div>
        <div className='translation-content'>
          <ReactTable className="my-table"
            filterable
            filtered={this.state.filtered}
            onFilteredChange={filtered => {this.setState({ filtered });}}
            data={displayingTranslations}
            columns={[
              {
                Header: "App",
                accessor: 'application',
                Filter: ({ filter, onChange }) =>
                <select
                  style={{ width: "100%" }}
                  value={"catalog"}
                >
                  <option value="ottweb">Ottweb</option>
                </select>,
                Cell: this.renderEditable,
              },
              {
                Header: "Locale",
                accessor: "locale",
                filterMethod: (filter, row) => {return row[filter.id].toLowerCase().includes(filter.value.toLowerCase())},
                Cell: this.renderEditable
              },
              {
                Header: "Key",
                accessor: "key",
                filterMethod: (filter, row) => {return row[filter.id].toLowerCase().includes(filter.value.toLowerCase())},
                Cell: this.renderEditable
              },
              {
                Header: "Value",
                accessor: "value",
                filterMethod: (filter, row) => {return row[filter.id].toLowerCase().includes(filter.value.toLowerCase())},
                Cell: this.renderEditable
              },
              {
                Header: "",
                accessor: '',
                Cell: this.renderButton,
                filterable: false
              }
            ]}
            defaultPageSize={15}
            onSortedChange={data => this.setState({ data })}
            pageSizeOptions= {[15, 20, 25, 50, 100]}
          />
        </div>
        <br />
        { showTranslationForm ? <OttTranslationForm id={id} application={application} locale={locale} tkey={key} value={value} closeTranslationFormPane={this.closeTranslationForm} showNotificationPanel={this.showNotificationPanel}/> : null }
      </div>
    );
  }
}

const mapStateToProps = ({translationsData}) => {
  return {
    translations: translationsData.translations,
    displayingTranslations: translationsData.displayingTranslations,
    loading: translationsData.loading,
  }
}
export default connect(mapStateToProps, {
  fetchAllOttTranslations,
})(OttTranslations);
