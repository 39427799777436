import {
  FETCH_DEFAULT_PLANS,
  FETCH_DEFAULT_PACKAGES,
  FETCH_DEFAULT_CLASSIFICATIONS,
  FETCH_DEFAULT_PACKAGE_TYPES,
  FETCH_SHIPPING_METHODS,
  FETCH_DW_CLASSIFICATIONS,
  FETCH_ENABLED_PACKAGES,
  FETCH_DEFAULT_PACKAGE_EXTRAS,
  FETCH_DEFAULT_PACKAGE_BASE_PACKS,
} from '../ActionType';

const INITIAL_STATE = {
  defaultPlans: [],
  defaultPackages: [],
  defaultPackageTypes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DEFAULT_PLANS:
      let {defaultPlans} = action.payload
      return {...state, defaultPlans};
    case FETCH_DEFAULT_PACKAGES:
      let {defaultPackages} = action.payload
      return {...state, defaultPackages};
    case FETCH_DEFAULT_CLASSIFICATIONS:
      let {defaultClassifications} = action.payload
      return {...state, defaultClassifications};
    case FETCH_DEFAULT_PACKAGE_TYPES:
      let {defaultPackageTypes} = action.payload
      return {...state, defaultPackageTypes}
    case FETCH_SHIPPING_METHODS:
      let {defaultShippingMethods} = action.payload
      return {...state, defaultShippingMethods}
    case FETCH_DW_CLASSIFICATIONS:
      let {dwClassifications} = action.payload
      return {...state, dwClassifications}
    case FETCH_ENABLED_PACKAGES:
      let {enabledPackages} = action.payload
      return {...state, enabledPackages}
    case FETCH_DEFAULT_PACKAGE_EXTRAS:
      let { defaultPackageExtras } = action.payload
      return {...state, defaultPackageExtras}
    case FETCH_DEFAULT_PACKAGE_BASE_PACKS:
      let { defaultPackageBasePacks } = action.payload
      return {...state, defaultPackageBasePacks}
    default:
      return state;
  }
}
