import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchAPackage,
  createAPackage,
  updateAPackage,
  fetchDWClassifications,
  fetchPackageIauEnabledStatus,
  fetchAPackagePartnerPlatform,
  fetchAllPartnerPlatformsForPackages
} from '../../../../scripts/redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import {DWLOB} from '../Constants.js';
import { Collapse, Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

const styles = {
  imageUrl: {
    overflowWrap: 'break-word',
    paddingBottom: '1rem'
  },
  imageUrlContainer: {
    fontSize:'14px',
    margin: '1rem 0'
  },
  logoContainer: {
    backgroundImage:
    `linear-gradient(45deg, #e0e0e0 25%, transparent 25%, transparent 75%, #e0e0e0 75%, #e0e0e0),
    linear-gradient(45deg, #e0e0e0 25%, #d4d4d4 25%, #d4d4d4 75%, #e0e0e0 75%, #e0e0e0)`,
    backgroundSize: '50px 50px', /* Must be a square */
    backgroundPosition: '0 0, 25px 25px', /* Must be half of the square */
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem 0'
  },
  partnerPlatformsButton: {
    fontSize: '14px',
    border: '1px solid darkgray',
    borderRadius: '50px',
    marginBottom: '1rem',
  }
}

export class PackageForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      allClassifications: [],
      isPaneOpen: false,
      isPaneOpenLeft: false,
      dwClassifications: [],
      disabledPartnerPlatform: [],
      validPartnerPlatform: [],
      partnerPlatformIdsByPackageId: [],
      classificationName: '',
      selectAll: false,
    }
  }

  isEmpty(value) {
    return (
      value === null ||
      typeof value === "undefined" ||
      (value.hasOwnProperty("length") && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  handlePromoChange() {
    this.setState((prevState) => {
      return { promo: !prevState.promo }
    })
  }

  handleTranslationChange(key, value) {
    switch (key) {
      case 'name': {
        if (Object.keys(this.state.name).length === 0) {
          let newNameObj = {}
          newNameObj['key'] = `model.package.${this.state.identifier}.name`;
          newNameObj['value'] = value;
          newNameObj['interpolations'] = null;
          newNameObj['is_proc'] = false;
          newNameObj['translatable_type'] = "Package";
          newNameObj['translatable_id'] = this.props.packageId;
          newNameObj['application'] = "catalog";
          this.setState({name: newNameObj});
        }
        if (!this.isEmpty(this.state.name)) {
          let nameCopy = {...this.state.name};
          if (this.isEmpty(nameCopy.translatable_type)) {
            nameCopy.translatable_type = "Package";
          }
          if (this.isEmpty(nameCopy.translatable_id)) {
            nameCopy.translatable_id = this.props.packageId;
          }
          nameCopy.value = value;
          this.setState({name: nameCopy});
        }
        break;
      }
      case 'description': {
        if (Object.keys(this.state.description).length === 0) {
          let newDescriptionObj = {}
          newDescriptionObj['key'] = `model.package.${this.state.identifier}.description`;
          newDescriptionObj['value'] = value;
          newDescriptionObj['interpolations'] = null;
          newDescriptionObj['is_proc'] = false;
          newDescriptionObj['translatable_type'] = "Package";
          newDescriptionObj['translatable_id'] = this.props.packageId;
          newDescriptionObj['application'] = "catalog";
          this.setState({description: newDescriptionObj});
        }
        if (!this.isEmpty(this.state.description)) {
          let descriptionCopy = {...this.state.description};
          if (this.isEmpty(descriptionCopy.translatable_type)) {
            descriptionCopy.translatable_type = "Package";
          }
          if (this.isEmpty(descriptionCopy.translatable_id)) {
            descriptionCopy.translatable_id = this.props.packageId;
          }
          descriptionCopy.value = value;
          this.setState({description: descriptionCopy});
        }
        break;
      }
      case 'description_1': {
        if (Object.keys(this.state.description_1).length === 0) {
          let newDescription1Obj = {}
          newDescription1Obj['key'] = `model.package.${this.state.identifier}.description_1`;
          newDescription1Obj['value'] = value;
          newDescription1Obj['interpolations'] = null;
          newDescription1Obj['is_proc'] = false;
          newDescription1Obj['translatable_type'] = "Package";
          newDescription1Obj['translatable_id'] = this.props.packageId;
          newDescription1Obj['application'] = "catalog";
          this.setState({description_1: newDescription1Obj});
        }
        if (!this.isEmpty(this.state.description_1)) {
          let description_1Copy = {...this.state.description_1};
          if (this.isEmpty(description_1Copy.translatable_type)) {
            description_1Copy.translatable_type = "Package";
          }
          if (this.isEmpty(description_1Copy.translatable_id)) {
            description_1Copy.translatable_id = this.props.packageId;
          }
          description_1Copy.value = value;
          this.setState({description_1: description_1Copy});
        }
        break;
      }
      case 'description_2': {
        if (Object.keys(this.state.description_2).length === 0) {
          let newDescription2Obj = {}
          newDescription2Obj['key'] = `model.package.${this.state.identifier}.description_2`;
          newDescription2Obj['value'] = value;
          newDescription2Obj['interpolations'] = null;
          newDescription2Obj['is_proc'] = false;
          newDescription2Obj['translatable_type'] = "Package";
          newDescription2Obj['translatable_id'] = this.props.packageId;
          newDescription2Obj['application'] = "catalog";
          this.setState({description_2: newDescription2Obj});
        }
        if (!this.isEmpty(this.state.description_2)) {
          let description_2Copy = {...this.state.description_2};
          if (this.isEmpty(description_2Copy.translatable_type)) {
            description_2Copy.translatable_type = "Package";
          }
          if (this.isEmpty(description_2Copy.translatable_id)) {
            description_2Copy.translatable_id = this.props.packageId;
          }
          description_2Copy.value = value;
          this.setState({description_2: description_2Copy});
        }
        break;
      }
      case 'description_3': {
        if (Object.keys(this.state.description_3).length === 0) {
          let newDescription3Obj = {}
          newDescription3Obj['key'] = `model.package.${this.state.identifier}.description_3`;
          newDescription3Obj['value'] = value;
          newDescription3Obj['interpolations'] = null;
          newDescription3Obj['is_proc'] = false;
          newDescription3Obj['translatable_type'] = "Package";
          newDescription3Obj['translatable_id'] = this.props.packageId;
          newDescription3Obj['application'] = "catalog";
          this.setState({description_3: newDescription3Obj});
        }
        if (!this.isEmpty(this.state.description_3)) {
          let description_3Copy = {...this.state.description_3};
          if (this.isEmpty(description_3Copy.translatable_type)) {
            description_3Copy.translatable_type = "Package";
          }
          if (this.isEmpty(description_3Copy.translatable_id)) {
            description_3Copy.translatable_id = this.props.packageId;
          }
          description_3Copy.value = value;
          this.setState({description_3: description_3Copy});
        }
        break;
      }
    }
  }

  handleIdentifierChange(value) {
    if (!this.isEmpty(this.state.name)) {
      let nameCopy = {...this.state.name};
      nameCopy.key = `model.package.${value}.name`;
      this.setState({name: nameCopy});
    }
    if (!this.isEmpty(this.state.description)) {
      let descriptionCopy = {...this.state.description};
      descriptionCopy.key = `model.package.${value}.description`;
      this.setState({description: descriptionCopy});
    }
    if (!this.isEmpty(this.state.description_1)) {
      let description1Copy = {...this.state.description_1};
      description1Copy.key = `model.package.${value}.description_1`;
      this.setState({description_1: description1Copy});
    }
    if (!this.isEmpty(this.state.description_2)) {
      let description2Copy = {...this.state.description_2};
      description2Copy.key = `model.package.${value}.description_2`;
      this.setState({description_2: description2Copy});
    }
    if (!this.isEmpty(this.state.description_3)) {
      let description3Copy = {...this.state.description_3};
      description3Copy.key = `model.package.${value}.description_3`;
      this.setState({description_3: description3Copy});
    }
    this.setState({identifier: value});
  }

  handleAppSupportedChange(appName) {
    const {appsSupported} = this.state
    let app = _.find(appsSupported, {appName})
    app.isSupported = !app.isSupported
    this.setState(appsSupported)
  }

  handleShippingMethodChange(id) {
    const {shippingMethods} = this.state
    if (shippingMethods.includes(id)){
      const index = shippingMethods.indexOf(id)
      shippingMethods.splice(index, 1)
    } else {
      shippingMethods.push(id)
    }
    this.setState(shippingMethods)
  }

  componentDidMount() {
      Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
    this.props.fetchAPackage(this.props.packageId);
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    const {
      identifier, guid, sku, priority, defaultClassificationId,
      packageType, amount, promo, status, enabled, appsSupported, shippingMethods,
      name, extraType, loadingAPackage, allClassifications, packageTypes, defaultShippingMethods,
      editPackage, dwClassifications, dwClassification, dwlob, packageName,
      description, description_1, description_2, description_3,
      migratedTo, dvrRequired, enabledPackages, iauEnabledStatusPackageExtra,
      iauEnabledStatusPlanPackage, partnerPackages, allPartnerPlatform, partnerPlatformIdsByPackageId,
      otaQualOutdoor, otaQualIndoor, otaQualNone, imageUrls
    } = nextProps
    let partnerPackagesIds = Object.keys(partnerPackages).map(pP => parseInt(pP));
    let allDisablePartnerPlatform = allPartnerPlatform.filter(aPP => !partnerPlatformIdsByPackageId.includes(aPP.id)).map(aPP => aPP.id);
    let allValidPartnerPlatformIds = allPartnerPlatform.filter(aPP => partnerPackagesIds.includes(aPP.id)).map(aPP => aPP.id);
    if (!this.isEmpty(allClassifications)) {
      let packageClassificationName = allClassifications.find(aC => aC.value === defaultClassificationId).label;
      this.setState({ classificationName: packageClassificationName });
    }
    this.setState({
      identifier, guid, sku, priority, defaultClassificationId,
      packageType, amount, promo, status, enabled, appsSupported, shippingMethods,
      name, extraType, loadingAPackage, allClassifications, packageTypes, defaultShippingMethods,
      editPackage, dwClassifications, dwClassification, dwlob, packageName,
      description, description_1, description_2, description_3,
      migratedTo, dvrRequired, enabledPackages, iauEnabledStatusPackageExtra,
      iauEnabledStatusPlanPackage, allPartnerPlatform, partnerPackages,
      otaQualOutdoor, otaQualIndoor, otaQualNone, imageUrls,
      disabledPartnerPlatform: allDisablePartnerPlatform, validPartnerPlatform: allValidPartnerPlatformIds, partnerPlatformIdsByPackageId
    })
    if (nextProps.statusCode === "Success") {
      let filterClassification = this.state.allClassifications.find(aC => aC.value === parseInt(this.state.defaultClassificationId));
      let packageTypesFilter = [
        {value: 'all', label: 'All'},
        {value: 0, label: 'Base Linear'},
        {value: 1, label: 'Extra Linear'},
        {value: 4, label: 'Device Packages'}
      ]
      this.props.onPackageTypeChangeFilter([0, 1, 4].includes(parseInt(this.state.packageType)) ? packageTypesFilter.find(pT => pT.value === parseInt(this.state.packageType)) : {value: 'all', label: 'All'});
      if (!this.isEmpty(filterClassification)) {
        this.props.onClassificationChangeFilter(filterClassification);
      }
      this.props.closePackageFormPane(null, true)
    }
  }

  handlePartnerPlatformChange(id) {
    let partnerPackagesCopy = {...this.state.partnerPackages};
    partnerPackagesCopy[`${id}`] = !partnerPackagesCopy[`${id}`];
    this.setState({ partnerPackages: partnerPackagesCopy });
  }

  handleIauEnableChanges(key) {
    if (key === 'planPackage') {
      this.setState((prevState) => {
        return { iauEnabledStatusPlanPackage: !prevState.iauEnabledStatusPlanPackage }
      });
    } else if (key === 'packageExtra') {
      this.setState((prevState) => {
        return { iauEnabledStatusPackageExtra: !prevState.iauEnabledStatusPackageExtra }
      });
    }
  }

  selectAllHandler() {
    if (!this.state.selectAll) {
      this.setState((prevState) => {
        return { selectAll: !prevState.selectAll, partnerPackages: [ ...this.state.partnerPlatformIdsByPackageId, Object.keys(this.state.partnerPackages)].reduce((acc, key) => {acc[key] = true; return acc; }, {}) }
      });
    } else {
      this.setState((prevState) => {
        return { selectAll: !prevState.selectAll, partnerPackages: [ ...this.state.partnerPlatformIdsByPackageId, Object.keys(this.state.partnerPackages)].reduce((acc, key) => {acc[key] = false; return acc; }, {}) }
      });
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const {
      name, description, description_1, description_2, description_3,
      identifier, guid, sku, priority,
      defaultClassificationId, packageType, amount, promo, status,
      enabled, appsSupported, shippingMethods, extraType, dwClassification, dwlob,
      packageName, migratedTo, dvrRequired, iauEnabledStatusPackageExtra,
      iauEnabledStatusPlanPackage, partnerPackages,
      otaQualOutdoor, otaQualIndoor, otaQualNone
    } = this.state
    var params = {
      name, description, description_1, description_2, description_3,
      identifier, guid, sku, priority,
      defaultClassificationId, packageType, amount, promo, status,
      enabled, appsSupported, shippingMethods, extraType, packageName, migratedTo,
      dvrRequired, iauEnabledStatusPackageExtra,
      iauEnabledStatusPlanPackage,
      otaQualOutdoor, otaQualIndoor, otaQualNone
    };
    if (!this.props.editPackage) {
      params['dwClassification'] = parseInt(dwClassification)
      params['dwLineOfBusiness'] = dwlob
      params['packageName'] = packageName
    }

    if (this.isEmpty(params.name.key)) {
      params.name.key = `model.package.${identifier}.name`;
    }
    if (this.isEmpty(params.name.value)) {
      params.name.value = name.value;
    }
    if (this.isEmpty(params.name.is_proc)) {
      params.name.is_proc = false;
    }
    if (this.isEmpty(params.name.translatable_type)) {
      params.name.translatable_type = "Package";
    }
    if (this.isEmpty(params.name.translatable_id)) {
      params.name.translatable_id = this.props.packageId;
    }
    if (this.isEmpty(params.name.application)) {
      params.name.application = "catalog";
    }

    if (this.isEmpty(params.description.key)) {
      params.description.key = `model.package.${identifier}.description`;
    }
    if (this.isEmpty(params.description.value)) {
      params.description.value = description.value;
    }
    if (this.isEmpty(params.description.is_proc)) {
      params.description.is_proc = false;
    }
    if (this.isEmpty(params.description.translatable_type)) {
      params.description.translatable_type = "Package";
    }
    if (this.isEmpty(params.description.translatable_id)) {
      params.description.translatable_id = this.props.packageId;
    }
    if (this.isEmpty(params.description.application)) {
      params.description.application = "catalog";
    }

    if (this.isEmpty(params.packageType)) {
      params.packageType = "1";
    }

    if (this.props.editPackage) {
      params.id = this.props.packageId;
      params.partnerPackages = partnerPackages;
      this.props.updateAPackage(params)
    } else {
    this.props.createAPackage(params)
    }
  }

  renderAppsSupported() {
    return _.map(this.state.appsSupported, (app) => {
      return (
        <FormGroup check key={app.appName} className="form-checkbox" style={{padding:'0px'}}>
          <Input type="checkbox" name={app.appName} id={app.appName} checked={app.isSupported} onChange={(e) => this.handleAppSupportedChange(app.appName)} />{' '}
          <Label check className="text-center black">
            {app.appName}
          </Label>
        </FormGroup>
      );
    });
  }

  renderShippingMethod() {
    return _.map(this.state.defaultShippingMethods, (app) => {
      return (
          <FormGroup check key={app.id} className="form-checkbox" style={{padding:'0px'}}>
            <Input type="checkbox" name={app.identifier} id={app.identifier}
                   checked={this.state.shippingMethods?.includes(app.id)}
                   onChange={() => this.handleShippingMethodChange(app.id)} />{' '}
            <Label check className="text-center black">
              {app.identifier}
            </Label>
          </FormGroup>
      );
    });
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  render() {
    const {
      name, description, description_1, description_2, description_3, identifier, guid, sku, priority,
      defaultClassificationId, packageType, amount, promo, status,
      enabled, extraType, isPaneOpen, allClassifications, loadingAPackage,
      packageTypes, packageName, dwlob, dwClassifications, editPackage,
      dwClassification, migratedTo, dvrRequired, enabledPackages, allPartnerPlatform,
      iauEnabledStatusPackageExtra, iauEnabledStatusPlanPackage, partnerPlatformIdsByPackageId,
      otaQualOutdoor, otaQualIndoor, otaQualNone, imageUrls
    } = this.state;

    let noteText;
    if (partnerPlatformIdsByPackageId.length > 0) {
      noteText = (
        <p><strong>Note: The disabled partner platforms below cannot be added to this package because a valid partner_classification has not been established for classification <em>{this.state.classificationName}</em></strong></p>
      )
    } else if (this.state.partnerPlatformIdsByPackageId.length === 0) {
      noteText = (
        <p><strong>NOTE: No partner classifications exist for classification <em>{this.state.classificationName}</em>. To associate this package with partner platforms, create entries in the partner_classifications table for the platforms you want to support.</strong></p>
      )
    }

    let renderUrls = null;
    if (imageUrls && imageUrls.length > 0) {
      const urls = imageUrls.map((imageData) => {
        const url = `${imageData.common_path}/${imageData.sub_path}/${imageData.type}/${imageData.theme}/${imageData.name}.${imageData.format}`
        return (
          <div key={imageData.id} style={styles.imageUrlContainer}>
            <p style={styles.imageUrl}>{url}</p>
            <div style={styles.logoContainer}>
              <img
                src={url}
                alt="Image currently unavailable at this URL"
                style={{width: '16rem'}}
              />
            </div>
            <hr/>
          </div>
        )
      });
      renderUrls = (
        <div>
          <button onClick={this.props.onToggleImageUrls} style={styles.partnerPlatformsButton}>
            Image URLs:
          </button>
          <Collapse isOpen={this.props.imageUrlsOpen}>
            {urls}
          </Collapse>
        </div>
      );
    } else {renderUrls = null}

    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Package Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closePackageFormPane();
          }}>
          { loadingAPackage ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="name" id="name" placeholder="Name" onChange={(e) => this.handleTranslationChange('name', e.target.value)} defaultValue={name ? name.value : ''}/>
                  <Label for="name">Name</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="description" id="description" placeholder="Description" onChange={(e) => this.handleTranslationChange('description', e.target.value)} defaultValue={description ? description.value : ''}/>
                  <Label for="description">Description</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="identifier" id="identifier" placeholder="Identifier" onChange={(e) => this.handleIdentifierChange(e.target.value)} value={identifier} required/>
                  <Label for="identifier">Identifier *</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="guid" id="guid" placeholder="GUID" onChange={(e) => this.handleChange('guid', e.target.value)} value={guid} required/>
                  <Label for="guid">GUID *</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="sku" id="sku" placeholder="SKU" onChange={(e) => this.handleChange('sku', e.target.value)} value={sku} required/>
                  <Label for="sku">SKU *</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="defaultClassificationId" id="defaultClassificationId"  value={defaultClassificationId} onChange={(e) => this.handleChange('defaultClassificationId', e.target.value)}>
                    {this.renderOptions(allClassifications)}
                  </Input>
                  <Label for="defaultClassificationId" className='select-label'>Default Classification Id</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="packageType" id="packageType"  value={packageType} onChange={(e) => this.handleChange('packageType', parseInt(e.target.value))}>
                    {this.renderOptions(packageTypes)}
                  </Input>
                  <Label for="packageType" className='select-label'>Package Type</Label>
                </div>
              </FormGroup>
              {(packageType === 4) && <FormGroup className="col-sm-12">
                <Label className="select-label">Shipping Methods</Label>
                { this.renderShippingMethod() }
              </FormGroup>}
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="amount" id="amount" placeholder="0.00" onChange={(e) => this.handleChange('amount', e.target.value)} value={amount} required/>
                  <Label for="amount">Amount *</Label>
                </div>
              </FormGroup>
              {
                editPackage ?
                <div>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="iau-enable-plans" id="iau-enable-plans" checked={iauEnabledStatusPlanPackage} onChange={(e) => this.handleIauEnableChanges('planPackage')} />
                    <Label check className="text-center">
                      IAU eligible for all associated plans.
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="iau-enable-extras" id="iau-enable-extras" checked={iauEnabledStatusPackageExtra} onChange={(e) => this.handleIauEnableChanges('packageExtra')} />
                    <Label check className="text-center">
                      All associated extras are IAU eligible.
                    </Label>
                  </FormGroup>
                </div> : null
              }
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="extraType" id="extraType" placeholder="Extra Type" onChange={(e) => this.handleChange('extraType', e.target.value)} value={extraType}/>
                  <Label for="extraType">Extra Type</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="promo" id="promo" checked={promo} onChange={() => this.handlePromoChange()} />{' '}
                <Label check className="text-center">
                  Promo
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="text" name="status" id="status" placeholder="eg: Legacy" onChange={(e) => this.handleChange('status', e.target.value)} value={status}/>
                  <Label for="status">Status</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="enabled" id="enabled" checked={enabled} onChange={(e) => this.handleChange('enabled', !enabled)} />{' '}
                <Label check className="text-center">
                  This Package is currently active in production
                </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="priority" id="priority" placeholder="Priority" onChange={(e) => this.handleChange('priority', e.target.value)} value={priority}/>
                  <Label for="priority">Priority</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <Label className="select-label">Apps Supported</Label>
                {this.renderAppsSupported()}
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="migratedTo" id="migratedTo"  value={migratedTo} onChange={(e) => this.handleChange('migratedTo', e.target.value)}>
                    {this.renderOptions(enabledPackages)}
                  </Input>
                  <Label for="migratedTo">Migrated To</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="dvrRequired" id="dvrRequired" checked={dvrRequired} onChange={(e) => this.handleChange('dvrRequired', !dvrRequired)} />{' '}
                <Label check className="text-center">
                  Dvr Required
                </Label>
              </FormGroup>
              { editPackage ?
                parseInt(packageType) === 4 ?
                <div>
                  <FormGroup className="col-sm-12">
                    <div className="field">
                      <Label for="migratedTo">OTA Qualifiers</Label>
                    </div>
                  </FormGroup>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="otaQualOutdoor" id="otaQualOutdoor" checked={otaQualOutdoor} onChange={(e) => this.handleChange('otaQualOutdoor', !otaQualOutdoor)} />{' '}
                    <Label check className="text-center">
                      OTA Qual Outdoor
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="otaQualIndoor" id="otaQualIndoor" checked={otaQualIndoor} onChange={(e) => this.handleChange('otaQualIndoor', !otaQualIndoor)} />{' '}
                    <Label check className="text-center">
                      OTA Qual Indoor
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="otaQualNone" id="otaQualNone" checked={otaQualNone} onChange={(e) => this.handleChange('otaQualNone', !otaQualNone)} />{' '}
                    <Label check className="text-center">
                      OTA Qual None
                    </Label>
                  </FormGroup>
                </div> : null
               :
                <div>
                  <FormGroup className="col-sm-12">
                    <div className="field">
                      <Label for="migratedTo">OTA Qualifiers</Label>
                    </div>
                  </FormGroup>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="otaQualOutdoor" id="otaQualOutdoor" checked={otaQualOutdoor} onChange={(e) => this.handleChange('otaQualOutdoor', !otaQualOutdoor)} />{' '}
                    <Label check className="text-center">
                      OTA Qual Outdoor
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="otaQualIndoor" id="otaQualIndoor" checked={otaQualIndoor} onChange={(e) => this.handleChange('otaQualIndoor', !otaQualIndoor)} />{' '}
                    <Label check className="text-center">
                      OTA Qual Indoor
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-sm-12 form-checkbox">
                    <Input type="checkbox" name="otaQualNone" id="otaQualNone" checked={otaQualNone} onChange={(e) => this.handleChange('otaQualNone', !otaQualNone)} />{' '}
                    <Label check className="text-center">
                      OTA Qual None
                    </Label>
                  </FormGroup>
                </div>
              }
              <div>
                <button onClick={this.props.onToggleFeatureDescription} style={styles.partnerPlatformsButton}>
                  Feature List
                </button>
                <Collapse isOpen={this.props.featureDescriptionOpen}>
                  <FormGroup className="col-sm-12">
                    <div className="field">
                      <input type="text" name="description_1" id="description_1" placeholder="Description_1" onChange={(e) => this.handleTranslationChange('description_1', e.target.value)} defaultValue={description_1 ? description_1.value : ''}/>
                      <Label for="description_1">Feature Description 1</Label>
                    </div>
                  </FormGroup>
                  <FormGroup className="col-sm-12">
                    <div className="field">
                      <input type="text" name="description_2" id="description_2" placeholder="Description_2" onChange={(e) => this.handleTranslationChange('description_2', e.target.value)} defaultValue={description_2 ? description_2.value : ''}/>
                      <Label for="description_2">Feature Description 2</Label>
                    </div>
                  </FormGroup>
                  <FormGroup className="col-sm-12">
                    <div className="field">
                      <input type="text" name="description_3" id="description_3" placeholder="Description_3" onChange={(e) => this.handleTranslationChange('description_3', e.target.value)} defaultValue={description_3 ? description_3.value : ''}/>
                      <Label for="description_3">Feature Description 3</Label>
                    </div>
                  </FormGroup>
                </Collapse>
              </div>
              {renderUrls}
              {
                editPackage ?
                <div>
                  <button onClick={this.props.onTogglePartnerPlatforms} style={styles.partnerPlatformsButton}>
                    PartnerPlatforms
                  </button>
                  <Collapse isOpen={this.props.partnerPlatformsOpen}>
                    {noteText}
                    <FormGroup className="col-sm-12 form-checkbox">
                      <Input type="checkbox" name="selectall-partner-platform" id="selectall-partner-platform" checked={this.state.selectAll} onChange={() => this.selectAllHandler()} />
                      <Label check className="text-center">
                        {this.state.selectAll ? "Deselect All" : "Select All"}
                      </Label>
                    </FormGroup>
                    {!this.isEmpty(allPartnerPlatform) ? allPartnerPlatform.map(pP =>
                      (
                        <FormGroup key={pP.id} className="col-sm-12 form-checkbox">
                          <Input type="checkbox" disabled={this.state.disabledPartnerPlatform.includes(pP.id) ? true : false} name="partner-platform" id={pP.id} checked={this.state.partnerPackages[`${pP.id}`]} onChange={() => this.handlePartnerPlatformChange(pP.id)} />
                          <Label check className={this.state.disabledPartnerPlatform.includes(pP.id) ? "greyed-form-check-label" : "text-center"}>
                            {pP.partner}/{pP.platform}
                          </Label>
                        </FormGroup>
                      )
                    ) : null}
                  </Collapse>
                </div> :
                <div>
                  <FormGroup className="col-sm-12">
                  <div className="field">
                      <input type="text" name="packageName" id="packageName" placeholder="DW Package Name" onChange={(e) => this.handleChange('packageName', e.target.value)} value={packageName}/>
                      <Label for="packageName">DW Package Name</Label>
                  </div>
                </FormGroup>
                  <FormGroup className="col-sm-12">
                  <div className="field">
                    <Input type="select" name="dwlob" id="dwlob"  value={dwlob} onChange={(e) => this.handleChange('dwlob', e.target.value)}>
                      {this.renderOptions(DWLOB)}
                    </Input>
                    <Label for="dwlob" className='select-label'>DW Line of Business</Label>
                  </div>
                </FormGroup>
                  <FormGroup className="col-sm-12">
                    <div className="field">
                      <Input type="select" name="dwClassification" id="dwClassification"  value={dwClassification} onChange={(e) => this.handleChange('dwClassification', e.target.value)}>
                        {this.renderOptions(dwClassifications)}
                      </Input>
                      <Label for="dwClassification" className='select-label'>DW Classification</Label>
                    </div>

                  </FormGroup>
                </div>
              }
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({packagesData, DefaultDataCalls, FlashMessageData}) => {
  return {
    name: packagesData.name,
    description: packagesData.description,
    description_1: packagesData.description_1,
    description_2: packagesData.description_2,
    description_3: packagesData.description_3,
    identifier: packagesData.identifier || '',
    guid: packagesData.guid || '',
    sku: packagesData.sku || '',
    priority: packagesData.priority || '',
    defaultClassificationId: packagesData.defaultClassificationId || '',
    packageType: packagesData.packageType,
    amount: packagesData.amount || '',
    promo: packagesData.promo || false,
    status: packagesData.status || '',
    enabled: packagesData.enabled || false,
    appsSupported: packagesData.appsSupported,
    shippingMethods: packagesData.shippingMethods,
    loadingAPackage: packagesData.loadingAPackage || false,
    extraType: packagesData.extraType || '',
    migratedTo: packagesData.migratedTo,
    dvrRequired: packagesData.dvrRequired || '',
    editPackage: packagesData.editPackage,
    dwClassification: packagesData.dwClassification,
    dwlob: packagesData.dwlob,
    otaQualOutdoor: packagesData.otaQualOutdoor,
    otaQualIndoor: packagesData.otaQualIndoor,
    otaQualNone: packagesData.otaQualNone,
    packageName: packagesData.packageName || '',
    iauEnabledStatusPlanPackage: packagesData.iauEnabledStatusPlanPackage,
    iauEnabledStatusPackageExtra: packagesData.iauEnabledStatusPackageExtra,
    partnerPackages: packagesData.defaultPackagePartnerPackages,
    allPartnerPlatform: packagesData.allPartnerPlatform,
    imageUrls: packagesData.imageUrls,
    partnerPlatformIdsByPackageId: packagesData.partnerPlatformIdsByPackageId,
    allClassifications: DefaultDataCalls.defaultClassifications,
    packageTypes: DefaultDataCalls.defaultPackageTypes,
    defaultShippingMethods: DefaultDataCalls.defaultShippingMethods,
    dwClassifications: DefaultDataCalls.dwClassifications,
    enabledPackages: DefaultDataCalls.enabledPackages,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchAPackage,
  createAPackage,
  updateAPackage,
  fetchDWClassifications,
  fetchPackageIauEnabledStatus,
  fetchAPackagePartnerPlatform,
  fetchAllPartnerPlatformsForPackages,
})(PackageForm);
