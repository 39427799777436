import {
  FETCH_ALL_CONTENT_TAG,
  FETCH_A_CONTENT_TAG,
  FETCH_CONTENT_TAG_TREE,
  FETCH_CONTENT_TAG_CHILDRENS,
  FETCH_CONTENT_TAG_DROPDOWN,
  FETCH_CONTENT_TAG_VALIDATION_DROPDOWN,
} from '../ActionType';
import _ from 'lodash';

const INITIAL_STATE = {
  treeData: [],
  tagName: '',
  tagType: '',
  enabled: true,
  priority: 0,
  data: [],
  parentDropDown: [],
  packageList: [],
  linesOfBusinessList: [],
  planList: [],
  channelList: [],
  contentTagList: [],
  displayText: {},
  loading: true,
  planDropDown: [],
  lobDropDown: [],
  partnerPlatformDropDown: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CONTENT_TAG:
      let { content_tags } = action.payload;
      return { ...state, data: content_tags };
    case FETCH_CONTENT_TAG_TREE:
      let { treeData } = action.payload;
      return { ...state, treeData, loading: false };
    case FETCH_A_CONTENT_TAG:
      let { tagName, tagType, enabled, priority, displayText } = action.payload;
      return { ...state, tagName, tagType, enabled, priority, displayText };
    case FETCH_CONTENT_TAG_CHILDRENS:
      let { packageList, linesOfBusinessList, planList, channelList, contentTagList } = action.payload;
      return { ...state, packageList, linesOfBusinessList, planList, channelList, contentTagList };
    case FETCH_CONTENT_TAG_DROPDOWN:
      let { parentDropDown } = action.payload;
      let sortedParentDrop = _.orderBy(parentDropDown, ['label']);
      let defaultVal = {
        label: 'Select Parent',
        value: null
      }
      return { ...state, parentDropDown: [ defaultVal, ...sortedParentDrop ] };
    case FETCH_CONTENT_TAG_VALIDATION_DROPDOWN:
      let { planDropDown, lobDropDown, partnerPlatformDropDown } = action.payload;
      return { ...state, planDropDown, lobDropDown, partnerPlatformDropDown };
    default:
      return state;
  }
}
