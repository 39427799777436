import React from "react";
import {connect} from 'react-redux';
import {fetchAllTranslationsAllApps} from '../../../../scripts/redux/actions';
import TranslationForm  from '../TranslationForm/TranslationForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loading from '../../Common/Loading/Loading.js';
import './Translations.css';


class Translations extends React.Component {
  constructor() {
    super();
    this.state = {
      translations: [],
			displayingTranslations: [],
      showTranslationForm: false,
      key: '',
      locale: '',
      value: '',
      application: 'catalog',
      id: '',
      filtered: [],
      loading: true,
      newTranslation: false,
    };
    this.searchKey = "";
    this.renderEditable = this.renderEditable.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
    this.closeTranslationForm = this.closeTranslationForm.bind(this)
  }

  componentWillMount() {
    this.props.fetchAllTranslationsAllApps()
	}

  componentWillReceiveProps(nextProps) {
    const {translations, displayingTranslations, loading} = nextProps
    this.setState({translations, displayingTranslations, loading})
  }

  renderEditable(cellInfo) {
    return (
      <div className="translation-input text-left">
        {this.state.translations[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  updateState(cellInfo, value) {
    const {displayingTranslations} = this.state;
    displayingTranslations[cellInfo.index][cellInfo.column.id] = value;
    displayingTranslations[cellInfo.index]['edited'] = true
    this.setState({ displayingTranslations });
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => {this.setState({showNotification: false})}, 3000);
  }

  updateTranslation(params) {
    let {value, locale, key, application, id} = params
    this.setState({ showTranslationForm: true, value, key, locale, application, id })
  }

  closeTranslationForm(getTranslations=true) {
    this.setState({filtered: [],showTranslationForm: false, value: '', key: '', locale: '', application: '', id: '', newTranslation: false})
    if (getTranslations) {
      this.props.fetchAllTranslationsAllApps()
    }
  }

  renderButton(cell, row) {
    return (
      <div className="text-center">
        <i className="fa fa-pencil edit-icon" onClick={(e) => {this.updateTranslation(row)}}></i>
      </div>
    )
  }

  render() {
    const {
      loading, displayingTranslations, showTranslationForm, locale, key, value, application, id
    } = this.state;
    const applications = {
      'catalog': 'catalog',
      'ottweb': 'ottweb'
    };
    const locales = {
      'en': 'en',
      'es': 'es',
      'pt-BR': 'pt-BR',
      'hi': 'hi',
      'pa': 'pa',
      'gu': 'gu'
    };
    const columns = [
      {
        headerStyle: () => {
          return { width: '6vw', textAlign: 'left' };
        },
        text: "App",
        dataField: "application",
        sort: true,
        filter: selectFilter({
          options: applications,
          placeholder: 'Show All',
          defaultValue: 'catalog'
        })
      },
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'left' };
        },
        text: "Locale",
        dataField: "locale",
        sort: true,
        filter: selectFilter({
          options: locales,
          placeholder: 'Show All',
          defaultValue: 'en'
        })
      },
      {
        headerStyle: () => {
          return { width: '20vw', textAlign: 'left' };
        },
        text: "Key",
        dataField: "key",
        sort: true,
        filter: textFilter({ placeholder: ' ' })
      },
      {
        headerStyle: () => {
          return { width: '45vw', textAlign: 'left' };
        },
        text: "Value",
        dataField: "value",
        sort: true,
        filter: textFilter({ placeholder: ' ' })
      },
      {
        headerStyle: () => {
          return { width: '5vw', textAlign: 'center' };
        },
        text: "Edit",
        dataField: " ",
        formatter: this.renderButton
      }
    ];

    return (
      <div >
        <FlashMessage />
        <div className='translation-header'>
          {!loading
          ?<div className='new-translation-div'>
            <button className="btn btn-primary new-translation-btn"
                    onClick={()=> this.setState({
                      showTranslationForm: true,
                      newTranslation: true
                    })}>
            <i className="fa fa-plus"></i> New Translation
            </button>
          </div>
          : ''}
        </div>
        <div className='translation-content'>
          {loading
          ?<div><Loading /> <h3 className="text-center">Please wait, translations are loading...</h3> </div>
          :<div>
            <BootstrapTable
            class="my-table"
            keyField='id'
            data={ displayingTranslations }
            columns={ columns }
            placeHolder={ false }
            filter={ filterFactory() }
            pagination={ paginationFactory(
              {
                sizePerPageList: ([15, 20, 25, 50, 100]),
                style: {
                  fontSize: '50px'
                }
              }
            )}
            />
            <br />
            { showTranslationForm ? <TranslationForm id={id} application={application} locale={locale} tkey={key} value={value} newTranslation={this.state.newTranslation} closeTranslationFormPane={this.closeTranslationForm} showNotificationPanel={this.showNotificationPanel}/> : null }
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({translationsData}) => {
  return {
    translations: translationsData.translations,
    displayingTranslations: translationsData.displayingTranslations,
    loading: translationsData.loading,
  }
}
export default connect(mapStateToProps, {
  fetchAllTranslationsAllApps,
})(Translations);
