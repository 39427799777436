import React, { Component } from 'react';
import "react-table/react-table.css";
import _ from 'lodash';
import {TabContent, TabPane, Card, CardFooter, CardBody, Row, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import '../PackageTabs/PackageTabs.css';
import Cookie from 'js-cookie';

class ChannelTabs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      baseLinearPacks: [],
      activeTab: 'channels',
      openedPackage: this.props.openedPackage,
      packageType: this.props.packageType,
      channels: [],
      displayingChannels: [],
      plans: [],
      displayingPlans: [],
      packages: [],
      displayingPackages: [],
      morePaneSection: '',
      existingObjects: [],
      loading: true,
      showNotification: '',
      statusCode: '',
      statusMessage: '',
      showPackageChannelForm: false,
      displayCardClass: '',
    }
    this.closeMorePane = this.closeMorePane.bind(this)
    this.showNotificationPanel = this.showNotificationPanel.bind(this)
  }

  setDisplayCardClass() {
    let displayCardClass = 'col-md-3';
    if (window.innerWidth <= 1422) {
      displayCardClass = 'col-md-3'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      displayCardClass = 'col-md-3'
    } else if (window.innerWidth > 1440){
      displayCardClass = 'col-lg-2'
    }
    this.setState({displayCardClass})
  }

  closeMorePane(updated = false) {
    this.setState({ morePaneSection: '' });
    if (updated) {
      this.toggleTabs(this.state.activeTab)
    }
  }

  getPackages() {
    if (this.props.openedChannel) {
      this.setState({
        loading:true,
        activeTab: 'packages',
      })
      const URL = `${process.env.REACT_APP_PC_URL}channel-based-packages/${this.props.openedChannel}`
      fetch(URL, {method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: Cookie.get('token')
        })
      })
      .then(response => response.json())
      .then(json => {
        json = _.sortBy(json, 'priority');
        json = _.filter(json, 'enabled')
        this.setState({
          loading: false,
          packages: json,
          displayingPackages: json,
        });
      })
      .catch(error => {
          console.log('PackageTabs Error 1 - ', error);
      })
    }
  }

  componentWillMount() {
    this.setDisplayCardClass()
    this.getPackages()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({openedPackage: nextProps.openedPackage})
    this.getPackages()
  }

  showNotificationPanel(showNotification, statusCode, statusMessage) {
    this.setState({ showNotification, statusCode, statusMessage });
    setTimeout(() => {this.setState({showNotification: false})}, 3000);
  }

  toggleTabs(tab) {
    switch (tab) {
      default:
      this.getPackages();
      break;
    }
  }

  search(key, val) {
    const obj = this.state[key]
    let displayingObj = obj.filter(o => (o.name.toLowerCase().search(val.toLowerCase()) !== -1) || (o.identifier.toLowerCase().search(val.toLowerCase()) !== -1))
    switch (key) {
      case 'packages':
        this.setState({displayingPackages: displayingObj})
        break;
      default:
        console.log('default case')
        break;
    }
  }

  deleteRecord(packageId) {
    let params = {
      channelId: this.props.openedChannel,
      packageId,
    }

    const URL = `${process.env.REACT_APP_PC_URL}delete-package-channel`
    fetch(URL, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookie.get('token'),
      },
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(json => {
      this.showNotificationPanel(true, json.status.code, json.status.message)
      this.toggleTabs(this.state.activeTab)
    })
    .catch(error => {
        console.log('PackageTabs Error 3 - ', error);
    })
  }

  render() {
    const {
      showNotification, statusCode, statusMessage, loading,
      activeTab, displayCardClass
    } = this.state

    const Item = ({value}) => {
      return (
        <div className={displayCardClass} style={{padding:'0px 5px 10px', zIndex:'99'}}>
          <Card className='display-card' style={{'color': '#666666'}}>
            <CardBody style={{background:'#46464C',color:'white',height:'100px'}}>
              <span className="text-left" style={{fontSize:'18px'}}>{value.name || value.identifier}</span>
            </CardBody>
            <CardFooter style={{height: '30px'}} className='tabs-card-footer'>
                <div className="text-center" style={{background:'#46464C','fontSize':'20px','color':'white','display': 'flex', 'alignItems': 'left', 'justifyContent': 'center'}}>
                  <i onClick={() => { if (window.confirm('Are you sure you wish to delete this?')) this.deleteRecord(value.id)} }
                    className='fa fa-trash'/>
                </div>
            </CardFooter>
          </Card>
        </div>
      )
    };

    const List = ({items}) => {
      if (items.length > 0) {
        return (
          <Row style={{padding:'10px'}}>
            {
              items.map((value, index) => (
                <Item key={`item-${index}`} index={index} value={value} />
              ))
            }
          </Row>
        );
      } else {
        return (<NoData />)
      }
    };

    const NavItems = ({items}) => {
      return (
        <Nav tabs>
          {
            items.map((item, index) => (
                <NavItem key={index}
                        className={classnames({ active: activeTab === item })}>
                  <NavLink onClick={() => { this.toggleTabs(item); }}>
                    {item}
                  </NavLink>
                </NavItem>
            ))
          }
        </Nav>
      )
    }

    return (
      <div className='package-tabs'>

        {showNotification && <FlashMessage statusCode={statusCode} statusMessage={statusMessage} />}
            <NavItems items={['packages']} />
            {
              loading ? <Loading color='orange'/> :
              <div>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="packages">
                    <div>
                      <Row style={{'paddingTop': '20px'}}>
                        <div className="search-section" style={{marginLeft:'15px',paddingBottom:'15px'}}>
                          <div className='aaa'>
                            <input onKeyUp={(e)=>this.search('packages', e.target.value)}
                                   type="search" placeholder="What are you looking for?" />
                          </div>
                        </div>
                      </Row>
                      <List items={this.state.displayingPackages} itemType='packages' />
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            }
      </div>
    );
  }
}

export default ChannelTabs;
