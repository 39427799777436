import {
  FETCH_A_PACKAGE_CLASSIFICATION,
} from '../ActionType';

const INITIAL_STATE = {
  classPackagePriority: 1,
  appSupported: [
                  {appName: "icepak", isSupported: false},
                  {appName: "ottweb", isSupported: false},
                  {appName: "fakeblock", isSupported: false}
  ],
  loadingAPackageClassification: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_A_PACKAGE_CLASSIFICATION:
      let {
        classPackagePriority, appSupported,
      } = action.payload
      return {
        ...state, classPackagePriority, appSupported, loadingAPackageClassification: false
      };
    default:
      return state;
  }
}
