import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchDefaultPlans,
  fetchAllLOBs,
  updateAPartnerLobPlan
} from '../../../redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

export class PartnerLobPlanForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingAPartnerLobPlan: true,
      enabled: false,
      lineOfBusinessId: 1,
      planId: 1,
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    const {
      openedPartnerLobPlan,
      fetchDefaultPlans,
      fetchAllLOBs
    } = this.props;

    if (openedPartnerLobPlan) {
      const {
        enabled,
        id,
        lineOfBusiness,
        lineOfBusinessId,
        partnerPlatform,
        partnerPlatformId,
        plan,
        planId,
        lobplanId
      } = openedPartnerLobPlan
      this.setState(() => ({
        enabled,
        id,
        lineOfBusiness,
        lineOfBusinessId,
        partnerPlatform,
        partnerPlatformId,
        plan,
        planId,
        lobplanId
      }))
    }
    
    this.setState({
      isPaneOpen: true,
    })

    fetchDefaultPlans().then(() => {
      fetchAllLOBs().then(() => {
        this.setState({
          loadingAPartnerLobPlan: false
        })
      })
    })
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    const {
      defaultPlans,
      allLobs,
    } = nextProps
    
    this.setState({
      defaultPlans,
      allLobs
    })

    if (nextProps.statusCode === "Success") {
      this.props.closePartnerLobPlanFormPane(null, true)
    }
  }

  renderOptions(options) {
    return _.map(options, (opt) => {
      return (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      );
    });
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const {
      lineOfBusinessId,
      planId,
      enabled,
      lobplanId
    } = this.state
    const {
      partnerPlatformId
    } = this.props
    var params = {
      partnerPlatformId, enabled, lineOfBusinessId, planId, lobplanId
    };

    this.props.updateAPartnerLobPlan(params)
  }

  render() {
    const {
     enabled, lineOfBusinessId, loadingAPartnerLobPlan,
     defaultPlans, isPaneOpen, planId, allLobs
    } = this.state;
    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Partner LOB Plan Form'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closePartnerLobPlanFormPane()
          }}>
          { loadingAPartnerLobPlan ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="lineOfBusinessId" id="lineOfBusinessId"  value={lineOfBusinessId} onChange={(e) => this.handleChange('lineOfBusinessId', e.target.value)}>
                    {this.renderOptions(allLobs)}
                  </Input>
                  <Label for="lineOfBusinessId" className='select-label'>Line of Business</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <Input type="select" name="planId" id="planId"  value={planId} onChange={(e) => this.handleChange('planId', e.target.value)}>
                    {this.renderOptions(_.sortBy(defaultPlans, 'label'))}
                  </Input>
                  <Label for="planId" className='select-label'>Plan</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12 form-checkbox">
                <Input type="checkbox" name="enabled" id="enabled" checked={enabled} onChange={(e) => this.handleChange('enabled', !enabled)} />{' '}
                <Label check className="text-center"> Enabled </Label>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({
    FlashMessageData,
    LineOfBusinessData,
    DefaultDataCalls,
  }) => {
  return {
    allLobs: LineOfBusinessData.lobs,
    defaultPlans: DefaultDataCalls.defaultPlans,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchDefaultPlans,
  fetchAllLOBs,
  updateAPartnerLobPlan,
})(PartnerLobPlanForm);
