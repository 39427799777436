import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchAllIapClassications, toggleIapClassification} from '../../../../scripts/redux/actions'
import IapClassificationForm from './IapClassificationForm.js';
import FlashMessage from '../../Common/FlashMessage/FlashMessage.js';
import NoData from '../../Common/NoData/NoData.js';
import Loading from '../../Common/Loading/Loading.js';
import { Card, CardFooter, CardBody, Row } from 'reactstrap';
import '../Classification/Classification.css';

class IapClassification extends Component {
  constructor (props) {
    super(props)
    this.state = {
      iapClassifications: [],
      displayingIapClassifications: [],
      showIapClassificationForm: false,
      openedIapClassification: null,
      loading: true,
      displayCardClass: '',
    }
    this.toggleIapClassificationForm = this.toggleIapClassificationForm.bind(this)
    this.toggleIapClassification = this.toggleIapClassification.bind(this)
  }

  setIapClassificationTabsSectionClass() {
    let packageTabsSectionClass = 'package-tabs-section';
    let siCardBodyMd = '';
    let siCardMd = '';
    let tileSelected = 'tgc-tile-selected';
    if (window.innerWidth <= 1422) {
      packageTabsSectionClass = 'package-tabs-section'
    } else if (window.innerWidth > 1422 && window.innerWidth <= 1440) {
      packageTabsSectionClass = 'package-tabs-section'
      siCardBodyMd = 'si-card-body-md'
      siCardMd = 'si-card-md'
      tileSelected = 'tgc-tile-selected tile-selected-md'
    } else if (window.innerWidth > 1440){
      packageTabsSectionClass = 'package-tabs-section package-tabs-section-lg'
    }
    this.setState({packageTabsSectionClass, siCardBodyMd, siCardMd, tileSelected})
  }

  toggleIapClassification(value) {
    let params = {
      iapClassificationId: value.id,
      value: !value.enabled ? 1 : 0
    }
    this.props.toggleIapClassification(params);
  }
  componentWillMount() {
    this.setIapClassificationTabsSectionClass()
  }

  componentDidMount() {
    this.props.fetchAllIapClassications()
  }

  componentWillReceiveProps(nextProps) {
    const {iapClassifications, displayingIapClassifications, loading} = nextProps
    this.setState({iapClassifications, displayingIapClassifications, loading})
  }

  toggleIapClassificationForm(id=null, getIapClassifications=false) {
    if (id) {
      this.setState({ showIapClassificationForm: true, openedIapClassification: id });
    } else {
      this.setState({ showIapClassificationForm: false})
    }
    if (getIapClassifications) {
      this.props.fetchAllIapClassications()
    }
  }

  search(val) {
    const {iapClassifications} = this.state
    let displayingIapClassifications = iapClassifications.filter(c => (c.name.toLowerCase().search(val.toLowerCase()) !== -1) || c.identifier.toLowerCase().search(val.toLowerCase()) !== -1)
    this.setState({displayingIapClassifications})
  }

  render() {
    const { openedIapClassification, displayingIapClassifications, loading,
            showIapClassificationForm, selectedDiv, tileSelected,
            siCardMd, siCardBodyMd,
          } = this.state

    const Item = ({value, name}) => {
      return (
        <div className='package-sortable-item'>
          <div className={`${name === selectedDiv ? tileSelected : ""}`}>
            <Card className={`margin-bottom-5 ${siCardMd}`}>
              <CardBody className={`si-card-body ${siCardBodyMd}`}>
                <span className="text-left font-size-18">{value.name || value.identifier}</span>
              </CardBody>
              <CardFooter className={`height-48 card-footer-color ${name === selectedDiv ? tileSelected : ""}`}>
                <div className="text-center si-card-footer">
                  {<i className="fa fa-pencil" onClick={() => this.toggleIapClassificationForm && this.toggleIapClassificationForm(value.identifier)}></i>}
                  { ((value.enabled || value.active) ?
                    <i className="fa fa-toggle-on margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.toggleIapClassification && this.toggleIapClassification(value) } } /> :
                    <i className="fa fa-toggle-off margin-left-50" onClick={() => { if (window.confirm('Are you sure you wish to this action?')) this.toggleIapClassification && this.toggleIapClassification(value) } } />)
                  }
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>
      )
    };

    const List = ({items}) => {
      return (
        <Row>
          {
            items.map((value, index) => (
              <Item key={index} index={index} value={value} name={index}/>
            ))
          }
        </Row>
      );
    };

    return (
      <div>
        <FlashMessage />
        <div className='classification'>
          <div className='classification-header'>
            <div className="search-section classification-search">
              <div className='aaa'>
                <input onKeyUp={(e)=>this.search(e.target.value)}
                       type="search" placeholder="What are you looking for?" />
              </div>
            </div>
            <div className='new-classification-div'>
              <button className="btn btn-primary new-classification-btn"
                      onClick={()=> this.setState({
                                                    showIapClassificationForm: true,
                                                    openedIapClassification: null})}>
              <i className="fa fa-plus"></i> New IAP Classification
              </button>
            </div>
          </div>
          <div className='new-classification-content'>
            { loading && <Loading /> }
            {
              (!loading && displayingIapClassifications.length === 0) ? <NoData></NoData> :
              <List items={displayingIapClassifications} />
            }
            {
              showIapClassificationForm ?
              <IapClassificationForm iapClassificationId={openedIapClassification}
                                  closeEditIapClassificationPane={this.toggleIapClassificationForm}/>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({IapClassificationData}) => {
  return {
    iapClassifications: IapClassificationData.iapClassifications,
    displayingIapClassifications: IapClassificationData.displayingIapClassifications,
    loading: IapClassificationData.loading,
  };
}

export {IapClassification};

export default connect(mapStateToProps, {
  fetchAllIapClassications,
  toggleIapClassification,
})(IapClassification);
