import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAPackageClassification, updateAPackageClassification } from '../../../../scripts/redux/actions'
import _ from 'lodash';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from '../../Common/Loading/Loading.js';

export class PackageClassificationForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      loadingAPackageClassification: true
    }
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  handleAppSupportedChange(appName) {
    const {appSupported} = this.state
    let app = _.find(appSupported, {appName})
    app.isSupported = !app.isSupported
    this.setState(appSupported)
  }

  componentDidMount() {
      Modal.setAppElement(this.el);
  }

  componentWillMount() {
    const {packageId, classificationId}  = this.props;
    Modal.setAppElement('body');
    this.props.fetchAPackageClassification(packageId, classificationId)
    this.setState({ isPaneOpen: true })
  }

  componentWillReceiveProps(nextProps) {
    const { appSupported, classPackagePriority, loadingAPackageClassification } = nextProps

    this.setState({ appSupported, classPackagePriority, loadingAPackageClassification })

    if (nextProps.statusCode === "SUCCESS") {
      this.props.closePackageClassificationFormPane(null, true)
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const { classPackagePriority, appSupported } = this.state
    const { packageId, classificationId } = this.props
    var params = { classPackagePriority, appsSupported: appSupported, packageId, classificationId };
    this.props.updateAPackageClassification(params)
  }

  renderAppsSupported() {
    return _.map(this.state.appSupported, (app) => {
      return (
        <FormGroup check key={app.appName} className="form-checkbox" style={{padding:'0px'}}>
          <Input type="checkbox" name={app.appName} id={app.appName} checked={app.isSupported} onChange={(e) => this.handleAppSupportedChange(app.appName)} />{' '}
          <Label check className="text-center black">
            {app.appName}
          </Label>
        </FormGroup>
      );
    });
  }

  render() {
    const {
      classPackagePriority, isPaneOpen, loadingAPackageClassification
    } = this.state;
    console.log({loadingAPackageClassification})
    return (
      <div ref={ref => this.el = ref} className='package-form'>
        <SlidingPane
          className='slinding-pane'
          overlayClassName='some-custom-overlay-class'
          isOpen={ isPaneOpen }
          title='Edit a package classification'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
            this.props.closePackageClassificationFormPane()
          }}>
          { loadingAPackageClassification ? <Loading /> :
            <Form onSubmit={(e) => {this.handleSubmit(e)}}>
              <FormGroup className="col-sm-12">
                <div className="field">
                  <input type="number" name="classPackagePriority" id="classPackagePriority" placeholder="Class Package Priority" onChange={(e) => this.handleChange('classPackagePriority', e.target.value)} value={classPackagePriority}/>
                  <Label for="classPackagePriority">Class Package Priority</Label>
                </div>
              </FormGroup>
              <FormGroup className="col-sm-12">
                <Label className="select-label">Apps Supported</Label>
                {this.renderAppsSupported()}
              </FormGroup>
              <FormGroup className="col-sm-12">
                <button className="btn btn-primary"
                        style={{height:'35px',fontSize:'14px',width:'25%',marginTop:'10px'}}>
                        Submit
                </button>
              </FormGroup>
            </Form>
          }
          <br />
        </SlidingPane>
      </div>);
  }

}

const mapStateToProps = ({packageClassificationData, FlashMessageData}) => {
  return {
    loadingAPackageClassification: packageClassificationData.loadingAPackageClassification,
    classPackagePriority: packageClassificationData.classPackagePriority,
    appSupported: packageClassificationData.appSupported,
    statusCode: FlashMessageData.statusCode,
  };
}

export default connect(mapStateToProps, {
  fetchAPackageClassification,
  updateAPackageClassification,
})(PackageClassificationForm);
